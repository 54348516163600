import React from 'react';
import { TextField, Typography } from '@mui/material';

export const CustomNBSTextField = ({
                                label,
                                value,
                                onInputChange,
                                // valid,
                                error,
                                inputType,
                                placeholder,
                                name,
                                required,
                                maxLength,
                                disabled
                            }) => {
    const styles = {
        input: {
            border: '2px solid #2b357c', // NBS Bank blue
            borderRadius: '5px',
            padding: '10px 12px',
            fontSize: '16px',
            outline: 'none',
            transition: 'border-color 0.3s',
        },
        inputFocus: {
            borderColor: '#ed1b2e', // NBS Bank red
        },
        feedback: {
            fontSize: '12px',
            marginTop: '5px',
            textAlign: 'left',
            color: '#ed1b2e'
        },
        validFeedback: {
            color: 'green',
            display: 'none',
        },
        invalidFeedback: {
            color: 'red',
            display: 'none',
        },
        inputValid: {
            display: 'inline',
        },
        inputInvalid: {
            display: 'inline',
        },
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" color="#2b357c" style={{ marginBottom: '5px', textAlign: 'left', fontSize: '16px' }}>
                {label}
            </Typography>
            <TextField
                type={inputType}
                name={name}
                value={value}
                onChange={onInputChange}
                placeholder={placeholder}
                variant="outlined"
                fullWidth
                required={required === 'true'}
                inputProps={{ maxLength }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderColor: styles.input.borderColor,
                        '& fieldset': {
                            borderColor: '#2b357c', // NBS Bank blue
                            borderWidth: '2px',
                            borderRadius: '7px',
                        },
                        '&:hover fieldset': {
                            borderColor: '#ed1b2e', // NBS Bank red on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#ed1b2e', // NBS Bank red on focus
                        },
                    },
                }}
                disabled={disabled}
            />
            <div style={styles.feedback}>
        {/*<span style={{ ...styles.validFeedback, ...(value ? styles.inputValid : {}) }}>*/}
        {/*  {valid}*/}
        {/*</span>*/}
                <span style={{ ...styles.invalidFeedback, ...(error ? styles.inputInvalid : {}) }}>
          {error}
        </span>
            </div>
        </div>
    );
};

