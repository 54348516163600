import {memo} from "react";

export const PDFDocumentViewer = memo(({documentType}) => {
    //NOTE: documentType is always treated as an array [MUST always be]
    return (
        <>
            <div>
                {documentType.map(document => <img src={document} alt="" width="100%"/>)}`
            </div>
        </>
    );
});