import React, {memo, useState, useEffect} from 'react';
import {submitCustomerData, submitCustomerDataWithoutFiles, validateCustomerData} from '../../helpers/Diaspora';
import {FaExclamationTriangle} from 'react-icons/fa';
import {removeTempKeyFromCustomerProfileIfExists} from "../../helpers/Utils";
import {CustomStyle} from "../../helpers/styles";
import {CheckCircleOutline} from "@mui/icons-material";
import {Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import CircularProgress from "@mui/material/CircularProgress";

export const AcknowledgeReceipt = memo(({updateCurrentPage, customerDetails, setCustomerDetails}) => {
    const title = "Your account opening request has been received successfully.";

    const [submissionCompletedSuccessfully, setSubmissionCompletedSuccessfully] = useState(false);
    const [customerAlreadyExists, setCustomerAlreadyExists] = useState(false);
    const [submittingApplication, setSubmittingApplication] = useState(true);
    const [unknownError, setUnknownError] = useState(false);
    const [resubmit, setResubmit] = useState(false);

    const goToHome = () => {
        document.location.reload()
        updateCurrentPage("HomePage");
    }

    const resubmitData = () => {
        setSubmittingApplication(true)
        setResubmit(!resubmit)
    }
    const amendViewStatusToThatOfDiaspora = (customerDetails) => {
        customerDetails.view_status = 1; //Important
        return customerDetails;
    }

    useEffect(() => {
        async function submitDiasporaCustomerData() {
            customerDetails = removeTempKeyFromCustomerProfileIfExists(customerDetails);

            if (customerDetails.id_scanned_copy) {
                customerDetails.id_scanned_copy_url = customerDetails.id_scanned_copy;
            }
            if (customerDetails.signature) {
                customerDetails.signature_photo_url = customerDetails.signature;
            }
            if (customerDetails.customer_picture) {
                customerDetails.customer_photo_url = customerDetails.customer_picture;
            }
            // const res = await submitCustomerData(customerDetails).then(r => r.data).catch(err => {
            //     console.error(err);
            //     return null;
            // });

            const res = await submitCustomerDataWithoutFiles(customerDetails).then(r => r.data).catch(err => {
                console.error(err);
                return null;
            });

            if (res === null || res === undefined) {
                setSubmittingApplication(false);
                setUnknownError(true);
            } else {
                let duplicate = res.duplicate;
                let success = res.success;

                if (!duplicate && !success) {
                    //Not duplicated and not successful (Something went wrong definitely)
                    setSubmittingApplication(false);
                    setUnknownError(true);
                } else {
                    setSubmittingApplication(false);
                    setSubmissionCompletedSuccessfully(!duplicate && success);
                    setCustomerAlreadyExists(duplicate && !success);
                }
            }
        }

        submitDiasporaCustomerData();
    }, [resubmit]);

    return (
        <>
            {(() => {
                let element;
                if (submissionCompletedSuccessfully) {
                    element =
                        <div style={CustomStyle.pageWrapper}>
                            <div style={CustomStyle.contentContainer}>

                                <CheckCircleOutline style={{fontSize: 80, color: 'green'}}/>

                                <Typography variant="h5" sx={CustomStyle.subtitle}>{title}</Typography>
                                <Typography variant="h5" sx={CustomStyle.subtitle}>You will receive an email</Typography>

                                <div style={CustomStyle.buttonContainer}>

                                <CustomNBSRedButton onClick={goToHome}
                                                    disabled={false}>
                                    Finish
                                </CustomNBSRedButton>
                                </div>
                                {/*<Row className="text-center">*/}
                                {/*    <div style={{marginBottom: '2rem'}}>*/}
                                {/*        <svg className="bi bi-check-circle-fill" width="3em" height="3em"*/}
                                {/*             viewBox="0 0 16 16" fill="green" xmlns="http://www.w3.org/2000/svg">*/}
                                {/*            <path fillRule="evenodd"*/}
                                {/*                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>*/}
                                {/*        </svg>*/}
                                {/*    </div>*/}
                                {/*    <h4 style={{fontWeight: "500", color: "#2b357c"}}> {title} </h4>*/}
                                {/*    <h4 style={{fontWeight: "500", color: "#2b357c"}}>You will receive an email</h4>*/}
                                {/*    <hr/>*/}
                                {/*</Row>*/}

                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <Button className="rounded-pill btn-light mx-auto d-block"*/}
                                {/*                style={{backgroundColor: "#ed1b2e", color: "white"}}*/}
                                {/*                onClick={goToHome}>Finish</Button>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                            </div>
                        </div>
                } else if (customerAlreadyExists) {
                    element = <div style={CustomStyle.pageWrapper}>
                        <div style={CustomStyle.contentContainer}>

                            <FaExclamationTriangle style={{fontSize: 80, color: 'red'}}/>
                            <Typography variant="h5" sx={CustomStyle.subtitle}>Information for a customer using ID
                                Number {customerDetails.id_number} was already submitted</Typography>

                            <div style={CustomStyle.buttonContainer}>

                            <CustomNBSRedButton onClick={goToHome}
                                                disabled={false}>
                                Finish
                            </CustomNBSRedButton>
                            </div>
                            {/*<Row className="text-center">*/}
                            {/*    <h4 style={{fontWeight: "500", color: "#2b357c"}}>Information for a customer using ID*/}
                            {/*        Number {customerDetails.id_number} was already submitted</h4>*/}
                            {/*    <Col>*/}
                            {/*        <Button className="rounded-pill btn-light mx-auto d-block"*/}
                            {/*                style={{backgroundColor: "#ed1b2e", color: "white"}}*/}
                            {/*                onClick={goToHome}>Finish</Button>*/}
                            {/*    </Col>*/}

                            {/*</Row>*/}
                        </div>
                    </div>
                } else if (submittingApplication) {
                    element = <div style={CustomStyle.pageWrapper}>
                        <div style={CustomStyle.contentContainer}>

                            <CircularProgress/>
                            <Typography variant="h5" sx={CustomStyle.subtitle}>Submitting your application, please
                                wait...</Typography>
                            {/*<Row className="text-center">*/}
                            {/*    <h4 style={{fontWeight: "500", color: "#2b357c"}}>Submitting your application, please*/}
                            {/*        wait...</h4>*/}
                            {/*</Row>*/}
                        </div>
                    </div>
                } else if (unknownError) {
                    element = <div style={CustomStyle.pageWrapper}>
                        <div style={CustomStyle.contentContainer}>

                            <FaExclamationTriangle style={{fontSize: 80, color: 'red'}}/>
                            {/*<div className="text-center" style={{fontSize: "30px", color: "#ed1b2e"}}>*/}
                            {/*    <FaExclamationTriangle/>*/}
                            {/*</div>*/}

                            <Typography variant="h5" sx={CustomStyle.subtitle}>We're sorry, something went wrong while submitting your
                                application. Please try again later</Typography>

                            <div style={CustomStyle.buttonContainer}>

                            <CustomNBSRedButton onClick={resubmitData}
                                                    disabled={false}>
                                    Retry
                                </CustomNBSRedButton>

                                <CustomNBSRedButton onClick={goToHome}
                                                    disabled={false}>
                                    Home
                                </CustomNBSRedButton>
                            </div>
                            {/*<div className="rounded">*/}
                            {/*    <Container className="diaspora-button-links mt-3">*/}
                            {/*        <Button*/}
                            {/*            className="rounded-pill btn-light d-block"*/}
                            {/*            style={{backgroundColor: "#ed1b2e", color: "white", width: "100%"}}*/}
                            {/*            onClick={resubmitData}>*/}
                            {/*            Retry*/}
                            {/*        </Button>*/}

                            {/*        <Button*/}
                            {/*            className="rounded-pill btn-light d-block"*/}
                            {/*            style={{backgroundColor: "#ed1b2e", color: "white", width: "100%"}}*/}
                            {/*            onClick={goToHome}>*/}
                            {/*            Home*/}
                            {/*        </Button>*/}
                            {/*    </Container>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                }
                return element;
            })()}
        </>
    );
});