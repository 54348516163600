import React, {useState, memo, useEffect} from 'react';
import CustomButton from "./custom-componets/Button";
import {Card, Image, Form, Button, Spinner} from 'react-bootstrap';
import {RadioGroup} from 'react-radio-buttons';
import {submitCustomerDetailsWithoutFiles} from './ApiRequests'
import Atm from "./Atm";
import MonthlyFees from "./MonthlyFees";
import PerTransactionFees from "./PerTransactionFees";
import ReactQRScanner from './ReactQRScanner';
import CustomInput from './custom-componets/CustomInput';
import Districts from './Districts';
import GeneralDeclarationForm from './GeneralDeclarationForm';
import {CameraFeed} from './CameraFeed';
import SignaturePad from 'react-signature-pad-wrapper'
import BundledServicesComponent from './BundledServicesComponent';
import {SummaryPageTable} from './SummaryPageTable';
import HomeLocationMap from './HomeLocationMap';
import ConditionSection from './ConditionSection';
import domtoimage from "dom-to-image-more";
import PicturesComponets from './PicturesComponets';
import AccountProduct from './AccountProduct';
import XtraServices from './XtraServices';
import {businessTypes, districtByCategory, industryOptions, occupations, sourceOfIncome, sectors} from './data'
import {
    resolveAndGetImageFileBase64
} from '../helpers/Diaspora.js';
import {
    accountCheck,
    amendDocuments, amendDocumentsWithFileUpload,
    DIASPORA_ACCOUNT_TYPES,
    fetchAccountDocumentsToAmend,
    getBase64, replaceUnderscoreAndRemoveURL, saveAndPreviewDoc
} from '../helpers/Diaspora.js';
import {AcknowledgeReceipt as DiasporaAccountCreationFinishPage} from './diaspora/AcknowledgeReceipt';
import {setEmployment, setPostStatus} from '../redux/Data';
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment';
import $ from "jquery";
import {
    convertBase64ToImageOrPDFFile, createFileUploadObject,
    documentResubmissionRemarks,
    isBase64PDF,
    nbsColors,
    pageIsBeingRenderedFromMobileDevice
} from "../helpers/Utils";
import {
    Alert as MuiAlert,
    Box,
    CardActions,
    CardContent,
    CircularProgress,
    Container,
    Divider, FormControl, FormControlLabel, InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText, MenuItem, Radio, Tab, Tabs,
    Typography
} from "@mui/material";
import Slide from "@mui/material/Slide";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {ImagePreviewDialog} from "./custom-componets/ImagePreviewDialog";
import PDFPreview from "./custom-componets/PDFPreview";
// Import the validator library

import {cameraPermissionsGranted} from "../helpers/Utils";
import MonthlyCurrentFees from "./current/MonthlyFees";
import CurrentPerTransactionFees from "./current/CurrentPerTransactionFees";
import CurrentBundledServicesComponent from "./current/CurrentBundledServicesComponent";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";
import CustomNBSRedButton from "./custom-componets/CustomNBSRedButton";
import {
    CustomRadioButtonStyled,
    FieldLabel,
    StyledRadioGroup,
    customSelectStyles,
    CustomStyle, CustomTabs, CustomTab
} from "../helpers/styles";
import CustomNBSLabel from "./custom-componets/CustomNBSLabel";
import {CustomNBSRadioButton} from "./custom-componets/CustomNBSRadioButton";
import {CustomNBSTextField} from "./custom-componets/CustomNBSTextField";
import {CheckCircleOutline, ErrorOutline} from "@mui/icons-material";
import logo from "../assets/images/logo_nav.png";

export const AmendDocuments = memo(({}) => {
    const [accountNumber, setAccountNumber] = React.useState('');
    const [startSearchingAccount, setStartSearchingAccount] = React.useState(false);
    const [accountSearchCompleted, setAccountSearchCompleted] = React.useState(false);
    const [startSearchingDocuments, setStartSearchingDocuments] = React.useState(false);
    const [documentSearchCompleted, setDocumentSearchCompleted] = React.useState(false);
    const [documentsReadyForUpload, setDocumentsReadyForUpload] = React.useState([]);
    const [docsFiles, setDocsFiles] = React.useState([]);
    // const [fileNames, setFileNames] = React.useState({});

    const [uploading, setUploading] = React.useState(false);
    const [documentsResubmissionCompletedSuccessfully, setDocumentsResubmissionCompletedSuccessfully] = React.useState(false);
    const [documentsResubmissionCompletedWithError, setDocumentsResubmissionCompletedWithError] = React.useState(false);

    const [documentsToAmend, setDocumentsToAmend] = React.useState([]);
    const [docToPreview, setDocToPreview] = React.useState(null);
    const [canPreviewDoc, setCanPreviewDoc] = React.useState(false);
    const [changedDocName, setChangedDocName] = React.useState("");

    const [isPDF, setIsPDF] = React.useState(false);
    const [PDFFileUrl, setPDFFileUrl] = React.useState(null);

    const [accountSearchResults, setAccountSearchResults] = React.useState({
        found: false,
        accountDetails: {
            name: ''
        }
    });

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    React.useEffect(() => {
        if (startSearchingAccount) {
            searchAccount();
        }
    }, [startSearchingAccount]);

    React.useEffect(() => {
        if (startSearchingDocuments) {
            searchDocumentsToAmend();
        }
    }, [startSearchingDocuments]);

    React.useEffect(() => {
    }, [documentsReadyForUpload.length]);

    React.useEffect(() => {
        if (docToPreview != null && docToPreview.length > 0) {
            setCanPreviewDoc(true);
        }
    }, [docToPreview]);


    const searchAccount = async () => {
        await accountCheck(accountNumber.trim())
            .then(res => {
                setAccountSearchResults((prev) => {
                    let accountName = '';
                    if (Object.keys(res).includes("accountName")) {
                        accountName = res["accountName"];
                    }

                    return {
                        ...prev,
                        found: res["status"],
                        accountDetails: {
                            name: accountName
                        }
                    };
                })
                setStartSearchingAccount(false);
                setStartSearchingDocuments(true);
                setAccountSearchCompleted(true);
            })
            .catch(err => {
                console.error(err);
                // setStartSearchingAccount(false);
                // setAccountSearchCompleted(true);
                setStartSearchingAccount(false);
                setStartSearchingDocuments(true);
                setAccountSearchCompleted(true);
            })
    }
    const searchDocumentsToAmend = async () => {
        await fetchAccountDocumentsToAmend(accountNumber.trim())
            .then(res => {
                setDocumentsToAmend(res);
                setStartSearchingDocuments(false);
                setDocumentSearchCompleted(true);
            })
            .catch(err => {
                setStartSearchingDocuments(false);
                setDocumentSearchCompleted(true);
            })
    }

    const handleSearch = async (e) => {
        e.preventDefault();
        setDocumentsToAmend([]);
        setDocumentsReadyForUpload([]);
        setDocsFiles([]);
        setDocumentsResubmissionCompletedSuccessfully(false);
        setDocumentsResubmissionCompletedWithError(false);
        setStartSearchingAccount(true);
    }

    const handleFileChange = async (e, docName) => {
        e.preventDefault();
        setChangedDocName(docName);

        let file = e.target.files[0];

        //TODO: Use formData to send to express server

        if (file) {
            const reader = new FileReader();
            await reader.readAsDataURL(file)

            reader.onload = async () => {
                if (reader.result !== null && reader.result !== undefined && reader.result.length > 0) {
                    setDocToPreview(reader.result);

                    setDocsFiles(prevState => {
                        const index = prevState.findIndex(_file => _file["name"] === docName);
                        const fileName = file.name;
                        const fileExtension = fileName.split('.').pop();
                        const fileNameObject = {
                            [`${fileName}`]: `${docName}.${fileExtension}`
                        };

                        if (index === -1) {
                            prevState.push({
                                "file": file,
                                "name": docName,
                                "fileNameObject": fileNameObject
                            });
                        } else {
                            prevState.map((item, _index) => {
                                if (_index === index) {
                                    item["file"] = file;
                                    item["fileNameObject"] = fileNameObject
                                }
                                return item;
                            });
                        }
                        return prevState;
                    });
                    setCanPreviewDoc(true);

                    if (isBase64PDF(reader.result)) {
                        console.log("Going to save and preview doc...");
                        const res = await saveAndPreviewDoc(file);
                        setIsPDF(true);
                        setPDFFileUrl(res);
                    }

                }
            };
        }
    }

    const handleListItemButtonClick = (fileInputRef) => {
        fileInputRef.current.click();
    };

    const startUpload = async () => {
        setUploading(true);

        let finalFileNames = {};
        let finalDocFiles = [];
        docsFiles.forEach(dfs => {
            Object.assign(finalFileNames, dfs.fileNameObject);
            finalDocFiles.push(dfs.file);
        });

        await amendDocumentsWithFileUpload(accountNumber.trim(), documentsReadyForUpload, finalDocFiles, finalFileNames)
            .then((_status) => {
                const docsUploaded = documentsReadyForUpload.map(d => d.name);
                let documentsToAmendCopy = documentsToAmend;
                let _remainingDocsToAmend = [];

                for (let i = 0; i < documentsToAmendCopy.length; i++) {
                    if (!docsUploaded.includes(documentsToAmendCopy[i].name)) {
                        _remainingDocsToAmend.push(documentsToAmendCopy[i]);
                    }
                }

                setDocumentsReadyForUpload([]);
                setDocumentsToAmend(_remainingDocsToAmend);

                setUploading(false);
                setDocumentsResubmissionCompletedSuccessfully(_status === true);
                setDocumentsResubmissionCompletedWithError(_status === false);
            })
            .catch((err) => {
                setDocumentsResubmissionCompletedSuccessfully(false);
                setDocumentsResubmissionCompletedWithError(true);
            })

        // await amendDocuments(accountNumber.trim(), documentsReadyForUpload)
        //     .then(() => {
        //         const docsUploaded = documentsReadyForUpload.map(d => d.name);
        //         let documentsToAmendCopy = documentsToAmend;
        //         let _remainingDocsToAmend = [];
        //
        //         for (let i = 0; i<documentsToAmendCopy.length; i++) {
        //             if (!docsUploaded.includes(documentsToAmendCopy[i].name)) {
        //                 _remainingDocsToAmend.push(documentsToAmendCopy[i]);
        //             }
        //         }
        //
        //         setDocumentsReadyForUpload([]);
        //         setDocumentsToAmend(_remainingDocsToAmend);
        //
        //         setUploading(false);
        //         setDocumentsResubmissionCompletedSuccessfully(true);
        //     })
    }

    const ListItemComponent = ({doc, handleListItemButtonClick, handleFileChange}) => {
        const fileInputRef = React.useRef(null);

        return (
            <React.Fragment>
                <ListItem disablePadding>
                    <ListItemButton
                        role={undefined}
                        onClick={() => handleListItemButtonClick(fileInputRef)}
                        dense
                        sx={{display: 'flex', justifyContent: 'space-between'}}
                    >
                        <input
                            type="file"
                            ref={fileInputRef}
                            id={doc.name}
                            style={{display: 'none'}}
                            onChange={(e) => handleFileChange(e, doc.name)}
                        />

                        <div sx={{flex: '0 0 auto', marginRight: 'auto'}}>
                            <ListItemText>
                                <Typography variant="h6" sx={{color: nbsColors.blue}}>
                                    {replaceUnderscoreAndRemoveURL(doc.name)}
                                </Typography>
                            </ListItemText>
                        </div>
                        <div sx={{flex: '0 0 auto', marginLeft: 'auto'}}>
                            <ListItemText>
                                {doc.readyForUpload ? (
                                    // <Typography variant="h6" sx={{ color: "#32CD32", fontSize: 12 }}>
                                    //     {"Ready for upload".toUpperCase()}
                                    // </Typography>
                                    <DoneAllIcon sx={{color: "#3CB043"}}/>
                                ) : (
                                    <Typography variant="h6" sx={{color: nbsColors.blue, fontSize: 12}}>
                                        {doc.remark.toUpperCase()}
                                    </Typography>
                                )}
                            </ListItemText>
                        </div>
                    </ListItemButton>
                </ListItem>
                <Divider sx={{borderTopWidth: 2}}/>
            </React.Fragment>
        );
    };

    const canActivateDocumentUpload = () => {
        return (
            documentsReadyForUpload.length &&
            documentsToAmend.length &&
            documentsReadyForUpload.length === documentsToAmend.length
        );
    }

    const closeDocumentPreviewDialog = () => {
        console.log("Cancel doc");
        setCanPreviewDoc(false);
    }
    const documentOk = () => {
        let doc = {};
        setDocumentsToAmend((prevState) => {
            return prevState.map((document) => {
                if (document.name === changedDocName) {
                    doc = Object.assign({}, document);

                    const docExistsInDocsToUpload = documentsReadyForUpload
                        .findIndex(_doc => _doc.name === changedDocName);

                    if (docExistsInDocsToUpload === -1) {
                        delete doc.readyForUpload
                        if (doc.remark === "not clear") {
                            doc.remark = documentResubmissionRemarks.unclear
                        }

                        documentsReadyForUpload.push(doc);
                        setDocumentsReadyForUpload(documentsReadyForUpload)
                    }

                    return Object.assign(
                        {},
                        document,
                        {readyForUpload: true}
                    );
                }
                // Return the original document for other elements
                return document;
            });
        });
        closeDocumentPreviewDialog();
    }

    // const isValidImage = (base64) => {
    //     const base = base64.toString().indexOf(",")
    // }


    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Documents Resubmission
                </Typography>
                <form onSubmit={(e) => handleSearch(e)}>


                        <CustomNBSTextField
                            label="Account Number *"
                            onInputChange={(e) => setAccountNumber(e.target.value)}
                            // value={formData.accountNumber || ''}
                            inputType="text"
                            name="accountNumber"
                            required="true"
                        />
                    <div style={CustomStyle.buttonContainer}>

                        <CustomNBSRedButton type="submit"
                                            disabled={false}>
                            Search
                        </CustomNBSRedButton>
                    </div>
                </form>

                {/*<Box sx={{mt: 1}}>*/}
                {/*    <form onSubmit={(e) => handleSearch(e)}>*/}
                {/*        <div style={{display: "flex", gap: "10px"}}>*/}
                {/*            <div style={{flex: "75%"}}>*/}
                {/*                <div className="form-floating">*/}
                {/*                    <input*/}
                {/*                        type="text"*/}
                {/*                        className="form-control rounded-pill"*/}
                {/*                        required*/}
                {/*                        onChange={(e) => setAccountNumber(e.target.value)}*/}
                {/*                        id="accountNumberInput"*/}
                {/*                        placeholder="Enter your account number"*/}
                {/*                        style={{width: "100%"}}*/}
                {/*                    />*/}
                {/*                    <label htmlFor="accountNumberInput" className="form-label">*/}
                {/*                        Account number*/}
                {/*                    </label>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <Button*/}
                {/*                type="submit"*/}
                {/*                className="rounded-pill"*/}
                {/*                variant="contained"*/}
                {/*                style={{backgroundColor: nbsColors.red, color: "white", flex: "1"}}>*/}
                {/*                Search*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </form>*/}
                {/*</Box>*/}

                {
                    startSearchingAccount
                        ? <CircularProgress/>
                        : <>
                            {!accountSearchResults.found && accountSearchCompleted && (
                                <Box sx={{mt: 2}}>
                                    <MuiAlert severity="error">
                                        <Typography variant="h6">
                                            Account not found
                                        </Typography>
                                    </MuiAlert>
                                </Box>
                            )}
                            {accountSearchResults.found && accountSearchCompleted && (
                                <>
                                    <Box sx={{mt: 2}}>
                                        <Card>
                                            <CardContent>
                                                <Typography sx={{fontSize: 14, fontWeight: "bold"}} color="text.secondary"
                                                            gutterBottom>
                                                    Account found
                                                </Typography>
                                                <Typography variant="h6" component="div" sx={{color: nbsColors.blue}}>
                                                    {accountSearchResults.accountDetails.name}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Box>

                                    <>
                                        <Box sx={{mt: 2}}>
                                            {
                                                startSearchingDocuments
                                                    ? <>
                                                        <CircularProgress/>
                                                    </>
                                                    : <>
                                                        {
                                                            documentsToAmend.length <= 0
                                                                ? <>
                                                                    <MuiAlert severity={
                                                                        documentsResubmissionCompletedSuccessfully && !documentsResubmissionCompletedWithError
                                                                            ? "success"
                                                                            : !documentsResubmissionCompletedSuccessfully && documentsResubmissionCompletedWithError
                                                                                ? "error"
                                                                                : "warning"
                                                                    }>
                                                                        <Typography variant="h6">
                                                                            {
                                                                                documentsResubmissionCompletedSuccessfully
                                                                                    ? "Document uploads completed successfully"
                                                                                    : documentsResubmissionCompletedWithError
                                                                                        ? "An error occurred"
                                                                                        : "No need to resubmit your documents at the moment."
                                                                            }
                                                                        </Typography>
                                                                    </MuiAlert>
                                                                </>
                                                                : <>
                                                                    <Card>
                                                                        <CardContent>
                                                                            <Typography sx={{fontSize: 14, fontWeight: "bold"}}
                                                                                        color="text.secondary" gutterBottom>
                                                                                Click on each of the documents below to upload a
                                                                                copy
                                                                            </Typography>

                                                                            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                                                                                {documentsToAmend.map((doc, index) => (
                                                                                    <ListItemComponent
                                                                                        key={doc.name}
                                                                                        doc={doc}
                                                                                        handleListItemButtonClick={handleListItemButtonClick}
                                                                                        handleFileChange={handleFileChange}
                                                                                    />
                                                                                ))}
                                                                            </List>
                                                                        </CardContent>

                                                                        <CardActions>
                                                                            <Button
                                                                                disabled={!canActivateDocumentUpload()}
                                                                                className="rounded-pill btn-light d-block"
                                                                                style={{
                                                                                    backgroundColor: !canActivateDocumentUpload()
                                                                                        ? "#D3D3D3"
                                                                                        : nbsColors.red,
                                                                                    color: "white",
                                                                                    width: "100%"
                                                                                }}
                                                                                onClick={startUpload}>
                                                                                {
                                                                                    uploading
                                                                                        ? <>
                                                                                            <CircularProgress/>
                                                                                        </>
                                                                                        : "Upload"
                                                                                }
                                                                            </Button>
                                                                        </CardActions>
                                                                    </Card>
                                                                </>
                                                        }

                                                    </>
                                            }
                                        </Box>
                                    </>
                                </>
                            )}
                        </>
                }

                {canPreviewDoc && (
                    <>
                        {isPDF && PDFFileUrl !== null && (
                            <div>
                                <h1>PDF Preview</h1>
                                <PDFPreview fileUrl={PDFFileUrl}/>
                            </div>
                        )}
                        {!isPDF && PDFFileUrl === null && (
                            <ImagePreviewDialog
                                src={docToPreview}
                                cancelCallback={closeDocumentPreviewDialog}
                                okCallback={documentOk}
                                open={canPreviewDoc}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
});

export const Home = memo(({updateCurrentPage}) => {
    const requirements = [
        'National ID card',
        'Payslip (if applicable)',
        'Utility Bill/sketch map'
    ];

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Open a new NBS Bank account</Typography>

                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    To open an account, you should have the following documents
                    ready:</Typography>

                <Typography style={CustomStyle.contentText}>
                    {requirements.map((requirement, index) => (
                        <Box key={index} display="flex" alignItems="center" mb={1}>
                            <Box
                                component="img"
                                src={logo}
                                alt="Logo"
                                sx={{width: 20, borderRadius: '50%'}}
                            />
                            <Typography variant="body1" sx={{ml: 1}}>
                                {requirement}
                            </Typography>
                        </Box>
                    ))}
                </Typography>
                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton onClick={() => updateCurrentPage('Nationality')}>
                        Next
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>

    );
})

export const Nationality = memo(({updateCurrentPage, setCustomerDetails}) => {
    const [isAlert, setAlert] = useState(false);

    const handleLabelClick = (e, customerDetailsValue, nextPage) => {
        setCustomerDetails((prev) => ({...prev, ...customerDetailsValue}));
        updateCurrentPage(nextPage);
    };
    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Nationality</Typography>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    What is your nationality</Typography>
                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSLabel
                        label="Malawian"
                        onClick={(e) => handleLabelClick(e, {"malawian": 1}, "Product")}
                        isAlert={isAlert}
                        showAlert={setAlert}
                    />
                    <CustomNBSLabel
                        label="Non-malawian"
                        onClick={(e) => handleLabelClick(e, {"malawian": 0}, "Nationality")}
                        isAlert={isAlert}
                        showAlert={setAlert}
                    />
                </div>

            </div>
        </div>
    );
})

export const Product = memo(({updateCurrentPage, setCustomerDetails}) => {
    const [isAlert, setAlert] = useState(false);

    const handleLabelClick = (e, customerDetailsValue, nextPage) => {
        setCustomerDetails((prev) => ({...prev, ...customerDetailsValue}));
        updateCurrentPage(nextPage);
    };
    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>

                    Choose your Product</Typography>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    Are you a student ?</Typography>
                <CustomNBSLabel
                    label="Yes"
                    onClick={(e) => handleLabelClick(e, {
                        "account_product": "student",
                        // "type_of_business": "student"
                    }, "Student")}
                    isAlert={isAlert}
                    showAlert={setAlert}
                />
                <CustomNBSLabel
                    label="No"
                    onClick={(e) => handleLabelClick(e, {"account_product": ""}, "AccountType")}
                    isAlert={isAlert}
                    showAlert={setAlert}
                />
            </div>
        </div>
    );
})

export const AccountType = memo(({updateCurrentPage, setCustomerDetails}) => {
    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Choose your product</Typography>

                <CustomNBSLabel
                    label="Savings Account"
                    onClick={() => updateCurrentPage("PaymentBasis")}
                />
                <CustomNBSLabel
                    label="Current Account"
                    onClick={() => updateCurrentPage("CurrentAccountPaymentBasis")}
                />
            </div>
        </div>

    );
})

export const Student = memo(({customerObject}) => {
    const {updateCurrentPage} = customerObject
    const [selectedProduct, setSelectedProduct] = useState("student");

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Product Details</Typography>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    *Fees are subject to change at the bank's discretion</Typography>
                {/*<AccountProduct customerObject={customerObject}/>*/}
                <Card
                    style={{
                        // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        marginBottom: '20px',
                        maxWidth: '100%',
                        border: '2px solid #2b357c',
                        outline: 'none',

                    }}

                >
                    <CardContent>
                        <Typography variant="h6" sx={{mb: 2, color: '#2b357c', fontWeight: 'bold'}}>
                            {selectedProduct} Account Details
                        </Typography>
                        <MonthlyFees product={selectedProduct}/>
                    </CardContent>
                </Card>

                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton
                        onClick={() => updateCurrentPage("BundledServices")}>
                        Next
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>

    );
})

export const PaymentBasis = (({customerObject}) => {
    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    How would you like to pay your bank fees?</Typography>
                <CustomNBSLabel
                    label="Monthly basis"
                    onClick={() => updateCurrentPage("MonthlyBasis")}
                />
                <CustomNBSLabel
                    label="Per transaction"
                    onClick={() => updateCurrentPage("PerTransaction")}
                />
            </div>
        </div>

    );
})

export const CurrentAccountPaymentBasis = (({customerObject}) => {
    const {updateCurrentPage, customerDetails} = customerObject;

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    How would you like to pay your bank fees?</Typography>

                <CustomNBSLabel
                    label="Monthly basis"
                    onClick={() => updateCurrentPage("CurrentMonthlyBasis")}
                />
                <CustomNBSLabel
                    label="Per transaction"
                    onClick={() => updateCurrentPage("CurrentPerTransaction")}
                />

            </div>
        </div>

    );
})

export const CurrentServices = memo((props) => {
    const {updateCurrentPage, customerDetailsObject} = props;
    const {customerDetails, setCustomerDetails} = customerDetailsObject;
    const [disableButton, setDisableButton] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        sms_alert: 'No',
        mobile_banking: 'No',
        eazy_app: 'No',
        internet_banking: 'No',
        cheque_book: 'No'
    })

    const handleChange = (event) => {
        const {name, value} = event.target;
        setSelectedOptions(prevState => ({...prevState, [name]: value}));
        setCustomerDetails(prev => ({...prev, [name]: value === 'Yes' ? 1 : 0}));
    };

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Services
                </Typography>

                <Typography variant="h5"
                            sx={{...CustomStyle.subtitle, textAlign: 'left', marginBottom: '10px'}}>
                    Free Services
                </Typography>

                <CurrentBundledServicesComponent/>

                <Typography variant="h5"
                            sx={{...CustomStyle.subtitle, textAlign: 'left', marginBottom: '10px'}}>
                    Select which extra services you would like:</Typography>
                <Box sx={{
                    mb: 3, textAlign: 'left',
                    borderColor: '#2b357c',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    padding: '16px',
                    borderRadius: '8px'
                }}>
                    <FormControl component="fieldset" sx={{mb: 3}}>

                        <Typography variant="h5"
                                    sx={{
                                        ...CustomStyle.subtitle,
                                        textAlign: 'left',
                                        marginBottom: '10px',
                                        fontWeight: 'bold'
                                    }}>
                            SMS Alerts?
                        </Typography>
                        <RadioGroup
                            name="sms_alert"
                            value={selectedOptions.sms_alert}
                            onChange={handleChange}
                            sx={{display: 'flex', flexDirection: 'row', alignItems: 'left', mb: 2}}
                        >
                            <CustomNBSRadioButton
                                label="Yes"
                                name="sms_alert"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.sms_alert === 'Yes'}
                            />
                            <CustomNBSRadioButton
                                label="No"
                                name="sms_alert"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.sms_alert === 'No'}
                            />
                        </RadioGroup>

                        <Typography variant="h5"
                                    sx={{
                                        ...CustomStyle.subtitle,
                                        textAlign: 'left',
                                        marginBottom: '5px',
                                        fontSize: '18px',
                                        fontWeight: 'bold'
                                    }}>
                            Eazy Mobile
                        </Typography>
                        <RadioGroup
                            name="mobile_banking"
                            value={selectedOptions.mobile_banking}
                            onChange={handleChange}
                            sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}
                        >
                            <CustomNBSRadioButton
                                label="Yes"
                                name="mobile_banking"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.mobile_banking === 'Yes'}
                            />
                            <CustomNBSRadioButton
                                label="No"
                                name="mobile_banking"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.mobile_banking === 'No'}
                            />
                        </RadioGroup>

                        <Typography variant="h5"
                                    sx={{
                                        ...CustomStyle.subtitle,
                                        textAlign: 'left',
                                        marginBottom: '10px',
                                        fontWeight: 'bold'
                                    }}>
                            Eazy App
                        </Typography>
                        <RadioGroup
                            name="eazy_app"
                            value={selectedOptions.eazy_app}
                            onChange={handleChange}
                            sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}
                        >
                            <CustomNBSRadioButton
                                label="Yes"
                                name="eazy_app"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.eazy_app === 'Yes'}
                            />
                            <CustomNBSRadioButton
                                label="No"
                                name="eazy_app"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.eazy_app === 'No'}
                            />
                        </RadioGroup>

                        <Typography variant="h5"
                                    sx={{
                                        ...CustomStyle.subtitle,
                                        textAlign: 'left',
                                        marginBottom: '10px',
                                        fontWeight: 'bold'
                                    }}>
                            Internet Banking
                        </Typography>
                        <RadioGroup
                            name="internet_banking"
                            value={selectedOptions.internet_banking}
                            onChange={handleChange}
                            sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}
                        >
                            <CustomNBSRadioButton
                                label="Yes"
                                name="internet_banking"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.internet_banking === 'Yes'}
                            />
                            <CustomNBSRadioButton
                                label="No"
                                name="internet_banking"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.internet_banking === 'No'}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>


                <Typography variant="h5"
                            sx={{...CustomStyle.subtitle, textAlign: 'left', marginBottom: '10px'}}>
                    Services below attract a separate fee:</Typography>
                <Typography variant="h6" sx={{...CustomStyle.contentText, color: '#ed1b2e'}}>
                    * Fees are subject to change at the bank's discretion
                </Typography>

                <Box sx={{
                    mb: 3, textAlign: 'left',
                    borderColor: '#2b357c',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    padding: '16px',
                    borderRadius: '8px'
                }}>
                    <FormControl component="fieldset" sx={{mb: 3}}>
                        <Typography variant="h5"
                                    sx={{
                                        ...CustomStyle.subtitle,
                                        textAlign: 'left',
                                        marginBottom: '10px',
                                        fontWeight: 'bold'
                                    }}>
                            Cheque Book
                        </Typography>
                        <RadioGroup
                            name="cheque_book"
                            value={selectedOptions.cheque_book}
                            onChange={handleChange}
                            sx={{display: 'flex', flexDirection: 'row', alignItems: 'left', mb: 2}}
                        >
                            <CustomNBSRadioButton
                                label="Yes"
                                name="cheque_book"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.cheque_book === 'Yes'}
                            />
                            <CustomNBSRadioButton
                                label="No"
                                name="cheque_book"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.cheque_book === 'No'}
                            />
                        </RadioGroup>
                        <Typography variant="h5"
                                    sx={{...CustomStyle.subtitle, textAlign: 'left', marginBottom: '10px'}}>
                            Apply for ATM Card
                        </Typography>
                        <Atm updateCurrentPage={updateCurrentPage} customerDetailsObject={customerDetailsObject}
                             setDisableButton={setDisableButton}/>

                    </FormControl>
                </Box>
                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton
                        onClick={() => updateCurrentPage('CustomerDetails')}
                        disabled={disableButton}
                    >
                        Next
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>
    );
})
export const BundledServices = memo((props) => {
    const {updateCurrentPage, customerDetailsObject} = props
    const [disableButton, setDisableButton] = useState(false);
    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={{...CustomStyle.contentContainer, alignItems: 'flex-start'}}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Services Offered</Typography>

                <Typography variant="h6" sx={{...CustomStyle.subtitle, textAlign: 'left'}}>
                    Below are the services that come with the account:</Typography>
                <BundledServicesComponent/>

                <Typography variant="h6" sx={{...CustomStyle.subtitle, textAlign: 'left'}}>
                    Services below attract a separate fee:</Typography>
                <Typography variant="body2" sx={{textAlign: "justify", color: "#ed1b2e", mb: 2}}>
                    * Fees are subject to change at the bank's discretion
                </Typography>
                <Typography variant="h6" sx={{...CustomStyle.subtitle, textAlign: 'left'}}>
                    Apply for ATM card (K8,999)
                </Typography>
                <Box sx={{
                    mb: 3, textAlign: 'left',
                    borderColor: '#2b357c',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    padding: '16px',
                    borderRadius: '8px'
                }}>

                    <Atm updateCurrentPage={updateCurrentPage} customerDetailsObject={customerDetailsObject}
                         setDisableButton={setDisableButton}/>
                </Box>
                <br/>
                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton
                        onClick={() => updateCurrentPage('CustomerDetails')}
                        disabled={disableButton}
                    >
                        Next
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>

    );
})

export const CustomerDetails = memo(({updateCurrentPage, setCustomerDetails}) => {
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [maritalStatus, setMaritalStatus] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const validateFields = () => {
        const errors = {};

        if (!selectedTitle) errors.selectedTitle = "This field is required";
        if (!maritalStatus) errors.maritalStatus = "This field is required";

        setFormErrors(errors);
        // console.log(errors)
        setIsFormValid(Object.keys(errors).length === 0);
    };

    const titleOptions = [
        {value: 'DR', label: 'Dr'},
        {value: 'MISS', label: 'Miss'},
        {value: 'MR', label: 'Mr'},
        {value: 'MRS', label: 'Mrs'},
        {value: 'PROF', label: 'Prof'},
    ];

    const changeMaritalStatus = (event) => {
        const value = event.target.value;

        setMaritalStatus(value);
        setCustomerDetails((prev) => ({
            ...prev,
            marital_status: value,
        }));
    };
    const handleTitleChange = (selectedOption) => {
        setSelectedTitle(selectedOption);
        setCustomerDetails((prev) => ({
            ...prev,
            cust_title: selectedOption?.value || '',
        }));
    };

    useEffect(() => {
        validateFields();
    }, [selectedTitle, maritalStatus]);

    // useEffect(() => {
    //     // Validate the form: the button should be enabled only when both fields are filled
    //     setIsFormValid(selectedTitle !== null && maritalStatus !== null);
    // }, [selectedTitle, maritalStatus]);


    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={{...CustomStyle.heading, color: '#2b357c'}}>
                    Customer Details
                </Typography>

                <form className="was-validated">
                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '18px',
                            marginBottom: '10px'
                        }}>Title *</Typography>
                        <Select
                            options={titleOptions}
                            value={selectedTitle}
                            onChange={handleTitleChange}
                            styles={customSelectStyles}
                            placeholder="Select Title"
                            isClearable
                            required
                        />
                        {formErrors.selectedTitle &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.selectedTitle}
                            </Typography>
                        }
                    </FormControl>

                    <Typography variant="h6" sx={{
                        ...CustomStyle.subtitle,
                        textAlign: 'left',
                        fontSize: '18px',
                        marginBottom: '10px'
                    }}>Marital status *</Typography>
                    <Box sx={{mb: 3, textAlign: 'left'}}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                name="maritalStatus"
                                value={maritalStatus}
                                onChange={changeMaritalStatus}
                            >
                                <CustomNBSRadioButton
                                    label="Single"
                                    value="Single"
                                    checked={maritalStatus === "Single"}
                                />
                                <CustomNBSRadioButton
                                    label="Married"
                                    value="Married"
                                    checked={maritalStatus === "Married"}
                                />
                                {/*<CustomNBSRadioButton*/}
                                {/*    label="Married"*/}
                                {/*    name="maritalStatus"*/}
                                {/*    changeNeedCardStatus={changeMaritalStatus}*/}
                                {/*    checked={maritalStatus === 'Married'}*/}
                                {/*/>*/}
                            </RadioGroup>
                            {formErrors.maritalStatus &&
                                <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                    {formErrors.maritalStatus}
                                </Typography>
                            }
                        </FormControl>

                    </Box>
                    <FieldLabel id="title-label">Please scan the QR code at the back of your National
                        ID-card.*</FieldLabel>
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                        <Image src={require('../assets/images/National-id.png')} alt="National ID"
                               className="img-fluid"/>
                    </Box>
                    <div style={CustomStyle.buttonContainer}>

                        <CustomNBSRedButton onClick={() => updateCurrentPage('IdScanner')} disabled={!isFormValid}>
                            Scan QR Now
                        </CustomNBSRedButton>
                    </div>

                </form>
            </div>
        </div>
    );
})

export const MonthlyBasis = memo(({customerObject}) => {
    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;
    const [selectedProduct, setSelectedProduct] = useState("EazySave");

    useEffect(() => {
        setCustomerDetails((prev) => ({
            ...prev,
            account_product: selectedProduct + "Bundled"
        }));
    }, [selectedProduct, setCustomerDetails]);

    const handleSelectProduct = (product) => {
        setSelectedProduct(product);
    }

    return (
        <div style={{...CustomStyle.pageWrapper, marginTop: '60px'}}>
            <div style={{...CustomStyle.contentContainer, maxWidth: '800px', margin: 'auto', padding: '16px'}}>
                <Typography variant="h5"
                            sx={{...CustomStyle.heading, fontSize: {xs: '18px', md: '24px'}, textAlign: 'center'}}>
                    Monthly Basis Fees
                </Typography>
                <Typography variant="h6"
                            sx={{...CustomStyle.subtitle, fontSize: {xs: '14px', md: '18px'}, textAlign: 'center'}}>
                    Please select one of the options below:
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 3,
                        gap: 2,
                    }}
                >
                    <RadioGroup
                        value={selectedProduct}
                        onChange={(event) => handleSelectProduct(event.target.value)}
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', md: 'row'},
                            justifyContent: 'center',
                            gap: '10px',
                            alignItems: 'center',
                        }}>
                        {['EazySave', 'EazyTransact'].map((product) => (
                            <FormControlLabel
                                key={product}
                                control={
                                    <Radio
                                        value={product}
                                        checked={selectedProduct === product}
                                        sx={{
                                            mr: 1, // Adjusted margin to reduce spacing between icon and text
                                            color: '#2b357c',
                                            '&.Mui-checked': {
                                                color: '#ed1b2e',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="h6"
                                                sx={{
                                                    color: '#2b357c',
                                                    fontWeight: 'bold',
                                                    fontSize: {xs: '14px', md: '16px'}
                                                }}
                                    >
                                        {product}
                                    </Typography>
                                }
                                sx={{
                                    mb: {xs: 1, md: 0},
                                }}
                            />
                        ))}
                    </RadioGroup>
                </Box>
                <Card
                    sx={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        mb: 3,
                        maxWidth: {xs: '100%', md: '600px'},
                        border: '2px solid #2b357c',
                        padding: {xs: '16px', md: '24px'},
                        margin: 'auto',
                    }}
                >
                    <CardContent>
                        <Typography variant="h6" sx={{
                            mb: 2,
                            color: '#2b357c',
                            fontWeight: 'bold',
                            fontSize: {xs: '16px', md: '18px'}
                        }}>
                            {selectedProduct} Details
                        </Typography>
                        <MonthlyFees product={selectedProduct}/>

                    </CardContent>
                </Card>

                <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton
                        onClick={() => updateCurrentPage('BundledServices')}
                        disabled={!selectedProduct}
                    >
                        Proceed
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>
    );
});

export const CurrentMonthlyBasis = memo(({customerObject}) => {
    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;
    const [label, setLabel] = useState("");

    useEffect(() => {
        setCustomerDetails((prev) => ({
            ...prev,
            account_product: `${label}Bundled`,
            purpose_of_account: "transactionalAccount",
        }));
    }, [label, setCustomerDetails]);

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={{...CustomStyle.contentContainer, maxWidth: '800px', margin: 'auto', padding: '16px'}}>
                <Typography variant="h5"
                            sx={{
                                ...CustomStyle.heading,
                                fontSize: {xs: '18px', md: '24px'},
                                textAlign: 'center',
                            }}>
                    Select Current Account Type
                </Typography>
                <Card
                    style={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        marginBottom: '10px',
                        maxWidth: '100%',
                        border: '2px solid #2b357c'
                    }}
                >
                    <CardContent>
                        <MonthlyCurrentFees setButtonLabel={(e) => setLabel(e.target.value)}/>
                    </CardContent>
                </Card>
                {label && (
                    <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton
                            onClick={() => updateCurrentPage("CurrentServices")}
                            disabled={label === ""}
                        >
                            Proceed
                        </CustomNBSRedButton>
                    </div>
                )}
            </div>
        </div>
    );


})

export const PerTransaction = memo((props) => {
    const {updateCurrentPage, customerDetailsObject} = props
    const [selectedProduct, setSelectedProduct] = useState("EazySave");

    const {setCustomerDetails} = customerDetailsObject;

    useEffect(() => {
        setCustomerDetails((prev) => ({
            ...prev,
            account_product: selectedProduct + "Unbundled"
        }));
    }, [selectedProduct, setCustomerDetails]);

    const handleSelectProduct = (product) => {
        setSelectedProduct(product);
    }

    return (
        <div style={{...CustomStyle.pageWrapper, padding: '0 16px', marginTop: '60px'}}>
            <div style={{...CustomStyle.contentContainer, maxWidth: '800px', margin: 'auto'}}>
                <Typography variant="h5" style={CustomStyle.heading}>
                    Unbundled Accounts
                </Typography>
                <Typography variant="h5" sx={{
                    ...CustomStyle.heading,
                    fontSize: {xs: '18px', md: '24px'},
                    textAlign: 'center',
                    marginBottom: '16px',
                }}>
                    Please select one of the options below:
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'},
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                    }}
                >
                    <RadioGroup
                        value={selectedProduct}
                        onChange={(event) => handleSelectProduct(event.target.value)}
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', sm: 'row'},
                            gap: '10px',
                            justifyContent: 'center',
                        }}
                    >
                        {['EazySave', 'EazyTransact', 'Pafupi'].map((product) => (
                            <FormControlLabel
                                key={product}
                                control={
                                    <Radio
                                        value={product}
                                        checked={selectedProduct === product}
                                        sx={{
                                            mr: 1,
                                            color: '#2b357c',
                                            '&.Mui-checked': {
                                                color: '#ed1b2e',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="h6"
                                                sx={{
                                                    color: '#2b357c',
                                                    fontWeight: 'bold',
                                                    fontSize: {xs: '14px', md: '16px'}
                                                }}>
                                        {product}
                                    </Typography>
                                }
                                sx={{mb: 2}}
                            />
                        ))}
                    </RadioGroup>
                </Box>
                <Card
                    sx={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        mb: 3,
                        maxWidth: {xs: '100%', md: '600px'},
                        border: '2px solid #2b357c',
                        padding: {xs: '16px', md: '24px'},
                        margin: 'auto',
                    }}
                >
                    <CardContent>
                        <Typography variant="h6"
                                    sx={{
                                        mb: 2,
                                        color: '#2b357c',
                                        fontWeight: 'bold',
                                        fontSize: {xs: '16px', md: '18px'}
                                    }}>
                            {selectedProduct} Details
                        </Typography>
                        <PerTransactionFees product={selectedProduct}/>

                    </CardContent>
                </Card>

                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton
                        onClick={() => updateCurrentPage("UnbundledExtraServices")}
                        disabled={!selectedProduct}
                    >
                        Proceed
                    </CustomNBSRedButton>
                </div>
                {/*)}*/}
            </div>
        </div>
    );

})

export const CurrentPerTransaction = memo(({customerObject}) => {
    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;

    const [label, setLabel] = useState("CurrentSilver");
    useEffect(() => {
        setCustomerDetails((prev) => ({
            ...prev,
            account_product: `${label}Unbundled`,
            purpose_of_account: "transactionalAccount",
        }));
    }, [label, setCustomerDetails]);

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={{...CustomStyle.contentContainer, maxWidth: '1200px', margin: 'auto'}}>
                <Typography variant="h5" sx={{mb: 3, color: '#2b357c', fontWeight: 'bold'}}>
                    Select Current Account Type
                </Typography>
                <Card
                    style={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        marginBottom: '10px',
                        maxWidth: '100%',
                        border: '2px solid #2b357c'
                    }}
                >
                    <CardContent>
                        <CurrentPerTransactionFees setButtonLabel={setLabel} selectedLabel={label}/>
                    </CardContent>
                </Card>
                {label && (
                    <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton
                            onClick={() => updateCurrentPage("CurrentServices")}
                            disabled={label === ""}
                        >
                            Proceed
                        </CustomNBSRedButton>
                    </div>
                )}
            </div>
        </div>
    );

})

export const UnbundledExtraServices = memo((props) => {
    const {updateCurrentPage, customerDetailsObject} = props;
    const {customerDetails, setCustomerDetails} = customerDetailsObject;
    const [disableButton, setDisableButton] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        sms_alert: 'No',
        mobile_banking: 'No',
        eazy_app: 'No',
        internet_banking: 'No',
    })

    const handleChange = (event) => {
        const {name, value} = event.target;
        setSelectedOptions(prevState => ({...prevState, [name]: value}));
        setCustomerDetails(prev => ({...prev, [name]: value === 'Yes' ? 1 : 0}));
    };
    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Services below attract a separate fee:
                </Typography>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    *Fees are subject to change at the bank's discretion
                </Typography>

                <Box sx={{
                    mb: 3, textAlign: 'left',
                    borderColor: '#2b357c',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    padding: '16px',
                    borderRadius: '8px'
                }}>
                    <FormControl component="fieldset" sx={{mb: 3}}>

                        {/*<Typography variant="h6" sx={{ ...CustomStyle.subtitle, textAlign: 'left' }}>*/}

                        <Typography variant="h5"
                                    sx={{
                                        ...CustomStyle.subtitle,
                                        textAlign: 'left',
                                        marginBottom: '10px',
                                        fontWeight: 'bold'
                                    }}>
                            SMS Alerts? (K510.00 per month)
                        </Typography>
                        <RadioGroup
                            name="sms_alert"
                            value={selectedOptions.sms_alert}
                            onChange={handleChange}
                            sx={{display: 'flex', flexDirection: 'row', alignItems: 'left', mb: 2}}
                        >
                            <CustomNBSRadioButton
                                label="Yes"
                                name="sms_alert"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.sms_alert === 'Yes'}
                            />
                            <CustomNBSRadioButton
                                label="No"
                                name="sms_alert"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.sms_alert === 'No'}
                            />
                        </RadioGroup>

                        <Typography variant="h5"
                                    sx={{
                                        ...CustomStyle.subtitle,
                                        textAlign: 'left',
                                        marginBottom: '10px',
                                        fontWeight: 'bold'
                                    }}>
                            Eazy Mobile (Pay as you transact)
                        </Typography>
                        <RadioGroup
                            name="mobile_banking"
                            value={selectedOptions.mobile_banking}
                            onChange={handleChange}
                            sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}
                        >
                            <CustomNBSRadioButton
                                label="Yes"
                                name="mobile_banking"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.mobile_banking === 'Yes'}
                            />
                            <CustomNBSRadioButton
                                label="No"
                                name="mobile_banking"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.mobile_banking === 'No'}
                            />
                        </RadioGroup>

                        <Typography variant="h5"
                                    sx={{
                                        ...CustomStyle.subtitle,
                                        textAlign: 'left',
                                        marginBottom: '10px',
                                        fontWeight: 'bold'
                                    }}>
                            Eazy App
                        </Typography>
                        <RadioGroup
                            name="eazy_app"
                            value={selectedOptions.eazy_app}
                            onChange={handleChange}
                            sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}
                        >
                            <CustomNBSRadioButton
                                label="Yes"
                                name="eazy_app"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.eazy_app === 'Yes'}
                            />
                            <CustomNBSRadioButton
                                label="No"
                                name="eazy_app"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.eazy_app === 'No'}
                            />
                        </RadioGroup>

                        <Typography variant="h5"
                                    sx={{
                                        ...CustomStyle.subtitle,
                                        textAlign: 'left',
                                        marginBottom: '10px',
                                        fontWeight: 'bold'
                                    }}>
                            Internet Banking
                        </Typography>
                        <RadioGroup
                            name="internet_banking"
                            value={selectedOptions.internet_banking}
                            onChange={handleChange}
                            sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}
                        >
                            <CustomNBSRadioButton
                                label="Yes"
                                name="internet_banking"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.internet_banking === 'Yes'}
                            />
                            <CustomNBSRadioButton
                                label="No"
                                name="internet_banking"
                                changeNeedCardStatus={handleChange}
                                checked={selectedOptions.internet_banking === 'No'}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Typography variant="h5" sx={{...CustomStyle.subtitle, textAlign: 'left', marginBottom: '10px'}}>
                    Apply for ATM Card (K8,999)
                </Typography>

                <Box sx={{
                    mb: 3, textAlign: 'left',
                    borderColor: '#2b357c',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    padding: '16px',
                    borderRadius: '8px'
                }}>
                    <Atm updateCurrentPage={updateCurrentPage} customerDetailsObject={customerDetailsObject}
                         setDisableButton={setDisableButton}/>

                </Box>

                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton
                        onClick={() => updateCurrentPage('CustomerDetails')}
                        disabled={disableButton}
                    >
                        Next
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>

    );
})

export const IdScanner = memo((props) => {
    const {updateCurrentPage, customerDetailsObject} = props

    const [isNext, setIsNext] = useState(false);
    const [loadingScan, setLoadingScan] = useState(false);

    const enableNextButton = val => setIsNext(val);

    const isFromMobileWebView = pageIsBeingRenderedFromMobileDevice();
    const isCalledFromAgencyApp = window.agencyApp !== undefined;

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Scan Your National ID
                </Typography>
                <Box sx={{mb: 3}}>
                    <ReactQRScanner
                        uploadPictureImmediately={isFromMobileWebView && !isCalledFromAgencyApp}
                        enableNextButton={enableNextButton}
                        customerDetailsObject={customerDetailsObject}
                        updateCurrentPage={updateCurrentPage}
                        setLoadingScanState={setLoadingScan}
                    />
                </Box>
                <div style={CustomStyle.buttonContainer}>
                    {isNext && (
                        <CustomNBSRedButton
                            onClick={() => updateCurrentPage('CustomerIDPhoto')}
                            disabled={loadingScan}
                        >
                            Next
                        </CustomNBSRedButton>
                    )}
                </div>
            </div>
        </div>
    );
})
export const CustomerIDPhoto = React.memo(({customerObject}) => {
    const {customerDetails} = customerObject;

    // Determine the next page based on account product
    const nextPage = /PafupiUnbundled/i.test(customerDetails.account_product.toString())
        ? "EmploymentDetails"
        : "ContactDetails";

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    National ID Photo</Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3}}>
                    <CameraFeed
                        label="Capture"
                        name="id_scanned_copy"
                        facingMode="environment"
                        customerObject={customerObject}
                        next={nextPage}
                    />
                </Box>
            </div>
        </div>

    );
})

export const ContactDetails = (({updateCurrentPage, customerDetailsObject}) => {
    const {customerDetails, setCustomerDetails} = customerDetailsObject;
    const [customerDetailsData, setCustomerDetailsData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [isTouched, setIsTouched] = useState({});
    const [disableNextButton, setDisableNextButton] = useState(true);
    const validatePhoneNumber = (phoneNumber) => {
        return /^0\d{6,11}$/.test(phoneNumber);
    };


    const validatePostalAddress = (address) => {
        return address && address.length <= 20;
    };

    const validateEmail = (email) => {
        return !email || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCustomerDetailsData(prev => ({...prev, [name]: value}));
        setIsTouched(prev => ({...prev, [name]: true}));
    };

    // Update customer details on data change
    useEffect(() => {
        setCustomerDetails(prev => ({...prev, ...customerDetailsData}));
    }, [customerDetailsData]);

    // Validate the form
    useEffect(() => {
        const errors = {};
        if (!validatePhoneNumber(customerDetails.mobile_phone)) {
            errors.mobile_phone = "Please enter a valid phone number starting with 0 (max 12 digits)";
        }
        if (!validatePostalAddress(customerDetails.postal_address)) {
            errors.postal_address = "Please enter a valid postal address (max 20 characters)";
        }
        if (!validatePostalAddress(customerDetails.postal_town)) {
            errors.postal_town = "Please enter a valid postal town (max 20 characters)";
        }
        if (!validateEmail(customerDetails.email_address)) {
            errors.email_address = "Please enter a valid email address";
        }
        setFormErrors(errors);
        setDisableNextButton(Object.keys(errors).length > 0);
    }, [customerDetails]);

    let nextPage;
    if (/PafupiUnbundled/i.test(customerDetails.account_product)) {
        nextPage = "EmploymentDetails";
    } else if (/student/i.test(customerDetails.account_product)) {
        nextPage = "StudentDetails";
    } else {
        nextPage = "EmploymentDetails";
    }

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Contact Details</Typography>
                <form className="was-validated">
                    <CustomNBSTextField
                        label="Mobile phone number *"
                        className={`form-control ${validatePhoneNumber(customerDetails.mobile_phone) ? 'is-valid' : 'is-invalid'}`}
                        value={customerDetails.mobile_phone || ''}
                        onInputChange={handleChange}
                        error={formErrors.mobile_phone}
                        inputType="number"
                        placeholder="08/09/02..."
                        name="mobile_phone"
                        required="true"

                    />

                    <CustomNBSTextField
                        label="Other mobile phone number"
                        className="form-control"
                        value={customerDetails.other_phone || ''}
                        onInputChange={handleChange}
                        // valid={formErrors.other_phone ? '' : 'Valid'}
                        error={formErrors.other_phone}
                        inputType="number"
                        placeholder="08/09"
                        name="other_phone"
                        required="false"

                    />

                    <CustomNBSTextField
                        label="Email address"
                        className="form-control"
                        value={customerDetails.email_address || ''}
                        onInputChange={handleChange}
                        error={formErrors.email_address}
                        inputType="email"
                        placeholder="nbsbank@nbs.mw"
                        name="email_address"
                        required="false"
                    />

                    <CustomNBSTextField
                        label="Postal address *"
                        className={`form-control ${validatePostalAddress(customerDetails.postal_address) ? 'is-valid' : 'is-invalid'}`}
                        value={customerDetails.postal_address || ''}
                        onInputChange={handleChange}
                        error={formErrors.postal_address}
                        inputType="text"
                        name="postal_address"
                        required="true"
                        maxLength={25}
                    />

                    <Typography variant="h6" sx={{
                        ...CustomStyle.subtitle,
                        textAlign: 'left',
                        fontSize: '16px', marginBottom: '10px'
                    }}>Postal town *</Typography>
                    <Districts
                        name="postal_town"
                        // defaultValue="Lilongwe"
                        nullDefault={false}
                        handleChange={handleChange}
                    />

                    <br/>
                    <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton
                            onClick={() => updateCurrentPage(nextPage)}
                            disabled={disableNextButton}
                        >
                            Next
                        </CustomNBSRedButton>
                    </div>

                </form>
            </div>
        </div>
    );

})

export const StudentDetails = memo(({customerObject}) => {
    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject
    const [disableButton, setDisableButton] = useState(true)
    const [formErrors, setFormErrors] = useState({});

    const handleInputChange = (e) => {
        setCustomerDetails((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSelectChange = (name) => (selectedOption) => {
        setCustomerDetails((prev) => ({
            ...prev,
            [name]: selectedOption?.value || '',
        }));
    };

    useEffect(() => {
        validateFields();
    }, [customerDetails]);
    const validateFields = () => {
        const errors = {};
        const {
            institution,
            student_id,
            admission_year,
            completion_year
        } = customerDetails;

        if (!institution || institution.trim().length === 0) errors.institution = "This field is required";
        if (!student_id || student_id.trim().length === 0) errors.student_id = "This field is required";
        if (!admission_year) errors.admission_year = "This field is required";
        if (!completion_year) errors.completion_year = "This field is required";

        setFormErrors(errors);
        // console.log(errors)
        setDisableButton(Object.keys(errors).length > 0);
    };

    const currentYear = new Date().getFullYear();

    const admissionYearOptions = Array.from({length: currentYear - 2022 + 1}, (_, i) => {
        const year = 2022 + i;
        return {value: year.toString(), label: year.toString()};
    });

    const completionYearOptions = Array.from({length: 6}, (_, i) => {
        const year = currentYear + i;
        return {value: year.toString(), label: year.toString()};
    });

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Student Details</Typography>
                <form className="was-validated">
                    <CustomNBSTextField
                        label="Student ID Number *"
                        onInputChange={handleInputChange}
                        error={formErrors.student_id}
                        value={customerDetails.student_id || ''}
                        inputType="text"
                        name="student_id"
                        required="false"
                    />

                    <CustomNBSTextField
                        label="Name of School *"
                        onInputChange={handleInputChange}
                        error={formErrors.institution}
                        value={customerDetails.institution || ''}
                        inputType="text"
                        name="institution"
                        required="true"
                    />

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '16px', marginBottom: '10px'
                        }}>Year of Admission *</Typography>
                        <Select
                            options={admissionYearOptions}
                            value={admissionYearOptions.find(option => option.value === customerDetails.admission_year)}
                            onChange={handleSelectChange('admission_year')}
                            styles={customSelectStyles}
                            placeholder="Select Year"
                            isClearable
                            required
                        />
                        {formErrors.admission_year &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.admission_year}
                            </Typography>
                        }
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '16px',
                            marginBottom: '10px'
                        }}>Year of Completion *</Typography>
                        <Select
                            options={completionYearOptions}
                            value={completionYearOptions.find(option => option.value === customerDetails.completion_year)}
                            onChange={handleSelectChange('completion_year')}
                            styles={customSelectStyles}
                            placeholder="Select Year"
                            isClearable
                            required
                        />
                        {formErrors.completion_year &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.completion_year}
                            </Typography>
                        }
                    </FormControl>
                    <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton
                            onClick={() => updateCurrentPage('EmploymentDetails')}
                            disabled={disableButton}
                        >
                            Next
                        </CustomNBSRedButton>
                    </div>
                </form>
            </div>
        </div>
    );
})


export const EmploymentDetails = memo(({customerObject}) => {
    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;
    const [tabValue, setTabValue] = useState('Employed');
    const [formErrors, setFormErrors] = useState({});
    const [disableNextButton, setDisableNextButton] = useState(true);

    const accountProducts = {
        STUDENT: 'student',
        PAFUPI: 'PafupiUnbundled'
    };

    const accountPurposeOptions = [
        {value: 'TRANSACTIONAL', label: 'Transactional'},
        {value: 'SAVINGS', label: 'Savings'}
    ];

    const validateFields = () => {
        const errors = {};
        const {
            occupation, type_of_business, employment_status, burseries,
            income_main_source, purpose_of_account, gross_monthly_income, work_phone,
            one_off_income, other_sources_income, mobile_phone, account_product
        } = customerDetails;

        // if (accountProducts.STUDENT !== customerDetails.account_product && !occupation) errors.occupation = "This field is required";
        if (!occupation) errors.occupation = "This field is required";
        if (!type_of_business) errors.type_of_business = "This field is required";
        if (!burseries) errors.burseries = "This field is required";
        if (!income_main_source) errors.income_main_source = "This field is required";
        if (!purpose_of_account) errors.purpose_of_account = "This field is required";
        if (!gross_monthly_income) errors.gross_monthly_income = "This field is required";
        if (accountProducts.PAFUPI === customerDetails.account_product) {
            if (!mobile_phone) {
                errors.mobile_phone = "This field is required";
            } else if (!validatePhoneNumber(mobile_phone)) {
                errors.mobile_phone = "Phone must start with 0 and 12 digits max";
            }
        }
        if (accountProducts.PAFUPI !== account_product && accountProducts.STUDENT !== account_product) {
            if (tabValue === 'Employed' && work_phone && !validatePhoneNumber(work_phone)) {
                errors.work_phone = "Work phone must start with 01, 08, or 09 and be 10 digits long";
            }
        }

        // console.log(errors)
        setFormErrors(errors);
        setDisableNextButton(Object.keys(errors).length > 0);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCustomerDetails(prev => ({...prev, [name]: value}));
    };

    const handleSelectChange = (name) => (selectedOption) => {
        setCustomerDetails(prev => ({...prev, [name]: selectedOption?.value || ''}));
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setCustomerDetails(prev => ({...prev, employment_status: newValue}));

    };

    const handleCurrencyChange = (name) => (value) => {
        setCustomerDetails(prev => ({...prev, [name]: value}));
    };

    useEffect(() => {
        validateFields();
    }, [customerDetails, tabValue]);

    const determineNextPage = () => {
        const accountProduct = customerDetails.account_product;
        // console.log("Account Product:", accountProduct);
        if (accountProduct === accountProducts.STUDENT) {
            return 'StudentIDPhoto';
        } else if (accountProduct === accountProducts.PAFUPI) {
            return 'CustomerPic';
        } else {
            return 'IncomeProofPic';
        }
    };

    const handleNextButtonClick = () => {
        const nextPage = determineNextPage();
        // console.log("Next Page:", nextPage);
        updateCurrentPage(nextPage);
    };

    const validatePhoneNumber = (phoneNumber) => {
        return /^0\d{6,11}$/.test(phoneNumber);
    };

    const sortedOccupations = occupations.sort((a, b) => a.label.localeCompare(b.label));
    const sortedIndustryOptions = industryOptions.sort((a, b) => a.label.localeCompare(b.label));
    const sortedSectors = sectors.sort((a, b) => a.label.localeCompare(b.label));
    const sortedSourceOfIncome = sourceOfIncome.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Income Declaration
                </Typography>

                {customerDetails.account_product !== accountProducts.STUDENT && customerDetails.account_product !== accountProducts.PAFUPI && (
                    <CustomTabs value={tabValue} onChange={handleTabChange} aria-label="employment tabs">
                        <CustomTab label="Employed" value="Employed"/>
                        <CustomTab label="Self-employed" value="Self-employed"/>
                    </CustomTabs>
                )}

                <form className="was-validated">
                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Occupation Type *
                        </Typography>
                        <Select
                            options={sortedOccupations}
                            value={sortedOccupations.find(option => option.value === customerDetails.occupation)}
                            onChange={handleSelectChange('occupation')}
                            styles={customSelectStyles}
                            placeholder="Select Occupation Type"
                            isClearable
                            required
                        />
                        {formErrors.occupation &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.occupation}
                            </Typography>
                        }
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Industry Type *
                        </Typography>
                        <Select
                            options={sortedIndustryOptions}
                            value={sortedIndustryOptions.find(option => option.value === customerDetails.type_of_business)}
                            onChange={handleSelectChange('type_of_business')}
                            styles={customSelectStyles}
                            placeholder="Select Industry Type"
                            isClearable
                            required
                        />
                        {formErrors.type_of_business &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.type_of_business}
                            </Typography>
                        }
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Sector *
                        </Typography>
                        <Select
                            options={sortedSectors}
                            value={sortedSectors.find(option => option.value === customerDetails.burseries)}
                            onChange={handleSelectChange('burseries')}
                            styles={customSelectStyles}
                            placeholder="Select Sector"
                            isClearable
                            required
                        />
                        {formErrors.burseries &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.burseries}
                            </Typography>
                        }
                    </FormControl>


                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Source of Income *
                        </Typography>
                        <Select
                            options={sortedSourceOfIncome}
                            value={sortedSourceOfIncome.find(option => option.value === customerDetails.income_main_source)}
                            onChange={handleSelectChange('income_main_source')}
                            styles={customSelectStyles}
                            placeholder="Select Source of Income"
                            isClearable
                            required
                        />
                        {formErrors.income_main_source &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.income_main_source}
                            </Typography>
                        }
                    </FormControl>


                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Purpose of Account *
                        </Typography>
                        <Select
                            options={accountPurposeOptions}
                            value={accountPurposeOptions.find(option => option.value === customerDetails.purpose_of_account)}
                            onChange={handleSelectChange('purpose_of_account')}
                            styles={customSelectStyles}
                            placeholder="Select Account Purpose"
                            isClearable
                            required
                        />
                        {formErrors.purpose_of_account &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.purpose_of_account}
                            </Typography>
                        }
                    </FormControl>

                    {tabValue === 'Employed' && customerDetails.account_product !== accountProducts.PAFUPI &&
                        customerDetails.account_product !== accountProducts.STUDENT && (
                            <CustomNBSTextField
                                label="Work Phone"
                                onInputChange={handleChange}
                                error={formErrors.work_phone}
                                value={customerDetails.work_phone || ''}
                                inputType="text"
                                name="work_phone"
                                required="true"
                            />

                        )}

                    {customerDetails.account_product === accountProducts.PAFUPI && (
                        <CustomNBSTextField
                            label="Mobile Phone Number *"
                            onInputChange={handleChange}
                            error={formErrors.mobile_phone}
                            value={customerDetails.mobile_phone || ''}
                            inputType="text"
                            name="mobile_phone"
                            required="true"
                        />
                    )}
                    <div style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Monthly Income (MK) *
                        </Typography>
                        <CurrencyInput
                            style={CustomStyle.currencyInput}
                            className="form-control"
                            id="gross_monthly_income"
                            name="gross_monthly_income"
                            placeholder="Please enter an amount"
                            min={1}
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            value={customerDetails.gross_monthly_income || ''}
                            onValueChange={handleCurrencyChange('gross_monthly_income')}
                            required
                        />
                        {formErrors.gross_monthly_income &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.gross_monthly_income}
                            </Typography>
                        }
                    </div>
                    {(customerDetails.account_product === accountProducts.STUDENT || tabValue === 'Self-employed') && (
                        <div style={{marginBottom: '16px'}}>
                            <Typography variant="h6" sx={{
                                ...CustomStyle.subtitle,
                                textAlign: 'left',
                                fontSize: '15px',
                                marginBottom: '10px'
                            }}>
                                One-Off Income
                            </Typography>
                            <CurrencyInput
                                style={CustomStyle.currencyInput}
                                className="form-control"
                                id="one_off_income"
                                name="one_off_income"
                                placeholder="Please enter an amount"
                                min={0}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                value={customerDetails.one_off_income || ''}
                                onValueChange={handleCurrencyChange('one_off_income')}
                            />
                        </div>
                    )}
                    {(customerDetails.account_product === accountProducts.STUDENT || tabValue === 'Self-employed') && (
                        <div style={{marginBottom: '16px'}}>
                            <Typography variant="h6" sx={{
                                ...CustomStyle.subtitle,
                                textAlign: 'left',
                                fontSize: '15px',
                                marginBottom: '10px'
                            }}>
                                Income From Other Sources
                            </Typography>
                            <CurrencyInput
                                style={CustomStyle.currencyInput}
                                className="form-control"
                                id="other_sources_income"
                                name="other_sources_income"
                                placeholder="Please enter an amount"
                                min={0}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                value={customerDetails.other_sources_income || ''}
                                onValueChange={handleCurrencyChange('other_sources_income')}
                            />
                        </div>
                    )}
                    <div style={CustomStyle.buttonContainer}>

                        <CustomNBSRedButton
                            onClick={handleNextButtonClick}
                            disabled={disableNextButton}
                        >
                            Next
                        </CustomNBSRedButton>
                    </div>
                </form>
            </div>
        </div>
    );
});

export const EmploymentStatus = React.memo(({customerObject}) => {
    const {updateCurrentPage, setCustomerDetails} = customerObject;
    const handleClick = (e) => {
        console.log(e.target.label);
    }
    let element = (
        <div className="content" onClick={handleClick}>
            <p style={{fontWeight: "bold", textAlign: "center"}}>
                Employment status</p>
            <CustomButton label="Employed" updateCurrentPage={updateCurrentPage}
                          customerDetailsValue={{"employment_status": "Employed"}}
                          setCustomerDetails={setCustomerDetails}
                          next={"IncomeProof"}/><br/>
            <CustomButton label="Self-employed" updateCurrentPage={updateCurrentPage}
                          customerDetailsValue={{"employment_status": "Self-employed"}}
                          setCustomerDetails={setCustomerDetails} next={"IncomeDeclarationForm"}/>
        </div>
    );
    return element;
})

export const IncomeDeclarationForm = React.memo(({customerObject}) => {

    let element = (
        <GeneralDeclarationForm customerObject={customerObject}/>
    );
    return element;
})

export const IncomeProof = React.memo(({customerObject, generalDeclarationFormVariables}) => {
    const employment = useSelector((state) => state.data.employment)
    const dispatch = useDispatch();

    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;
    const [occupation, setOccupation] = useState({class_name: /^$/i.test(customerDetails.occupation_of_customer) ? "form-control rounded-pill is-invalid" : "form-control rounded-pill is-valid"})

    const {
        handleContactDetailsData,
        disableButton,
        otherPhone,
    } = generalDeclarationFormVariables


    const changeMonthlyIncome = (e) => {
        setCustomerDetails((prev) => {
            return {...prev, gross_monthly_income: e}
        })
    }

    const handleOccupationChange = (selectedOption) => {
        handleContactDetailsData({
            target: {
                name: 'occupation',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const handleIndustryChange = (selectedOption) => {
        handleContactDetailsData({
            target: {
                name: 'type_of_business',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const handleSectorChange = (selectedOption) => {
        handleContactDetailsData({
            target: {
                name: 'burseries',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const handleSourceOfIncomeChange = (selectedOption) => {
        handleContactDetailsData({
            target: {
                name: 'income_main_source',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const styles = {
        rounded: {
            borderRadius: '100px',
            height: '50px'
        }
    }

    let element = (
        <div>
            {/*<p  style={{fontWeight:"bold",textAlign:"center"}}>*/}
            {/*    Proof of income</p>*/}

            <form className="was-validated">

                <br/>
                <label htmlFor="occupation">Select Occupation Type *</label>
                <Select
                    styles={{control: (base) => ({...base, ...styles.rounded})}}
                    onChange={handleOccupationChange}
                    options={occupations}
                    value={occupations.find(option => option.value === customerDetails.occupation)}
                    name="occupation"
                    isClearable
                    required
                />
                <br/>

                <label htmlFor="type_of_business">Select Your Industry *</label>
                <Select
                    styles={{control: (base) => ({...base, ...styles.rounded})}}
                    onChange={handleIndustryChange}
                    options={industryOptions}
                    value={industryOptions.find(option => option.value === customerDetails.type_of_business)}
                    name="type_of_business"
                    isClearable
                    required
                />
                <br/>

                <label htmlFor="sector">Select Your Sector *</label>
                <Select
                    styles={{control: (base) => ({...base, ...styles.rounded})}}
                    onChange={handleSectorChange}
                    options={sectors}
                    value={sectors.find(option => option.value === customerDetails.sector)}
                    name="sector"
                    isClearable
                    required
                />
                <br/>

                <label htmlFor="source_of_income">Select Source of Income *</label>
                <Select
                    styles={{control: (base) => ({...base, ...styles.rounded})}}
                    onChange={handleSourceOfIncomeChange}
                    options={sourceOfIncome}
                    value={sourceOfIncome.find(option => option.value === customerDetails.source_of_income)}
                    name="source_of_income"
                    isClearable
                    required
                />
                <br/>

                <CustomInput label="Work phone" className={otherPhone.class_name} value={customerDetails.work_phone}
                             onInputChange={handleContactDetailsData} valid="Valid" error="Please enter a valid value"
                             inputType="number" placeholder="08/09/01" name="work_phone" required="false"/>


                {!["CurrentSilverBundled", "CurrentSilverUnbundled", "CurrentGoldBundled", "CurrentPlatinumBundled"].includes(customerDetails.account_product) && (
                    <>
                        <label htmlFor="admissionYear">Purpose of opening account *</label>
                        <select className="rounded-pill form-select w3-text-indigo form-floating"
                                value={customerDetails.purpose_of_account} onChange={handleContactDetailsData}
                                aria-label="Default select example" name="purpose_of_account" defaultValue="" required>
                            <option value=""></option>
                            <option value="savingsAccount">Savings account</option>
                            <option value="transactionalAccount">Transactional account</option>
                        </select>
                    </>
                )}

                <br/>

                <div style={{marginBottom: '10px'}}>
                    <label htmlFor="gross_monthly_income">Your monthly income* (MK)</label>
                    <CurrencyInput
                        style={styles.rounded}
                        className="form-control"
                        id="gross_monthly_income"
                        name="gross_monthly_income"
                        placeholder="Please enter an amount"
                        min={1}
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        onValueChange={changeMonthlyIncome}
                        required
                    />
                </div>
                <CustomButton label="Next" disableButton={disableButton} updateCurrentPage={updateCurrentPage}
                              next="IncomeProofPic"/>
            </form>
        </div>
    );
    return element;
})

export const StudentIDPhoto = React.memo(({customerObject}) => {

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Student ID</Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3}}>

                    <CameraFeed label="Capture Student ID" name="StudentIDPhoto" facingMode="environment"
                                customerObject={customerObject} next="CustomerPic"/>
                </Box>
            </div>
        </div>

    );

})
export const CustomerPic = React.memo(({customerObject}) => {


    const {customerDetails} = customerObject;
    const isDiasporaAccount = [
        DIASPORA_ACCOUNT_TYPES.INVESTMENT,
        DIASPORA_ACCOUNT_TYPES.TRANSACTIONAL
    ].includes(customerDetails.account_product);

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Customer Photo</Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3}}>

                    <CameraFeed
                        label="Capture your photo"
                        name="customer_picture"
                        facingMode="user"
                        customerObject={customerObject}
                        next={isDiasporaAccount ? "DiasporaApplicantDetails" : "ResidentialDetails"}
                    />
                </Box>

            </div>
        </div>
    );
})

export const IncomeProofPic = React.memo(({
                                              customerObject
                                          }) => {
    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Payslip not more than three months
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3}}>

                    <CameraFeed
                        label="Take payslip photo"
                        name="IncomeProofPic"
                        facingMode="environment"
                        customerObject={customerObject}
                        next="CustomerPic"
                    />
                </Box>
            </div>
        </div>
    );
})

export const PafupiLocationImage = React.memo(({
                                                   customerObject
                                               }) => {
    const {customerDetails, updateCurrentPage} = customerObject;
    const [scanSignature, setScanSignature] = useState(false);

    return (
        <div className="content">
            <p style={{fontWeight: "bold", textAlign: "center"}}>
                Scan your location map image
            </p>
            {scanSignature ? (
                <CameraFeed label="Capture" name="location" facingMode="environment" customerObject={customerObject}
                            next="AccountToBeOpened"/>
            ) : (
                <button className="mx-auto d-block rounded-pill"
                        style={{border: "0px", color: "#ed1b2e", backgroundColor: "white"}} onMouseOver={(e) => {
                    e.target.style.color = "white";
                    e.target.style.backgroundColor = "#ed1b2e";
                }} onMouseOut={(e) => {
                    e.target.style.color = "#ed1b2e";
                    e.target.style.backgroundColor = "white";
                }} onClick={() => {
                    setScanSignature(!scanSignature);
                }}>
                    Scan your location map image
                </button>
            )}
            <br/>
            {/*{scanSignature && (*/}
            {/*    <CustomButton label="Next" disableButton={false} updateCurrentPage={updateCurrentPage} next="AccountToBeOpened" />*/}
            {/*)}*/}
        </div>
    );
});

export const ResidentialDetails = React.memo(({
                                                  customerObject
                                              }) => {

    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;
    const [formErrors, setFormErrors] = useState({});
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [traditionalAuthorityOptions, setTraditionalAuthorityOptions] = useState([]);

    const districtOptions = Object.keys(districtByCategory).map(district => ({
        value: district,
        label: district.charAt(0) + district.slice(1).toLowerCase().replace('_', ' ')
    }));

    const accountProducts = {
        STUDENT: 'student',
        PAFUPI: 'PafupiUnbundled'
    };
    const validateFields = () => {
        const errors = {};
        const {
            account_product,
            email_address,
            postal_address,
            physical_address,
            street_address,
            district,
            trad_auth,
            village
        } = customerDetails;

        if (account_product === accountProducts.PAFUPI) {
            if (!email_address) errors.email_address = "This field is required";
            if (!postal_address) errors.postal_address = "This field is required";
            if (!physical_address) errors.physical_address = "This field is required";
            if (!street_address) errors.street_address = "This field is required";

        } else {
            if (!physical_address) errors.physical_address = "This field is required";
            if (!street_address) errors.street_address = "This field is required";
            // if (!permanent_address) errors.permanent_address = "This field is required";
            if (!district) errors.district = "This field is required";
            if (!trad_auth) errors.trad_auth = "This field is required";
            if (!village) errors.village = "This field is required";
        }

        setFormErrors(errors);
        setDisableNextButton(Object.keys(errors).length > 0);
    };


    const locationTypeOptions = [
        {value: 'RURAL', label: 'Rural'},
        {value: 'LAKESHORE', label: 'Lakeshore'},
        {value: 'CITY', label: 'City'},
        {value: 'BORDER', label: 'Border'}

    ];
    const handleChange = (e) => {
        const {name, value} = e.target;
        setCustomerDetails(prev => ({...prev, [name]: value}));
    };

    const handleSelectChange = (name) => (selectedOption) => {
        setCustomerDetails(prev => ({...prev, [name]: selectedOption?.value || ''}));
    };

    useEffect(() => {
        validateFields();
    }, [customerDetails]);

    useEffect(() => {
        if (customerDetails.district) {
            setTraditionalAuthorityOptions(
                districtByCategory[customerDetails.district].map(ta => ({
                    value: ta,
                    label: ta
                }))
            );
        } else {
            setTraditionalAuthorityOptions([]);
        }
    }, [customerDetails.district]);

    const determineNextPage = () => {
        const accountProduct = customerDetails.account_product;
        if (accountProduct === accountProducts.STUDENT) {
            return 'NextOfKin';
        } else if (accountProduct === accountProducts.PAFUPI) {
            return 'NextOfKin';
        } else {
            return 'NextOfKin';
        }
    };

    const handleNextButtonClick = () => {
        const nextPage = determineNextPage();
        updateCurrentPage(nextPage);
    };

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Residential Details
                </Typography>

                <form className="was-validated">
                    {customerDetails.account_product === accountProducts.PAFUPI && (
                        <>

                            <CustomNBSTextField
                                label="Email Address *"
                                onInputChange={handleChange}
                                error={formErrors.email_address}
                                value={customerDetails.email_address || ''}
                                inputType="text"
                                name="email_address"
                                required="true"
                            />

                            <CustomNBSTextField
                                label="Postal Address *"
                                onInputChange={handleChange}
                                error={formErrors.postal_address}
                                value={customerDetails.postal_address || ''}
                                inputType="text"
                                name="postal_address"
                                required="true"
                            />

                            <Typography variant="h6" sx={{
                                ...CustomStyle.subtitle,
                                textAlign: 'left',
                                fontSize: '15px', marginBottom: '10px'
                            }}>Postal town *</Typography>
                            <Districts
                                name="postal_town"
                                defaultValue="Lilongwe"
                                nullDefault={false}
                                handleChange={handleChange}
                            />

                        </>
                    )}


                    <CustomNBSTextField
                        label="Physical Address *"
                        onInputChange={handleChange}
                        error={formErrors.physical_address}
                        value={customerDetails.physical_address || ''}
                        inputType="text"
                        name="physical_address"
                        required="true"
                    />

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Location Type *
                        </Typography>
                        <Select
                            options={locationTypeOptions}
                            value={locationTypeOptions.find(option => option.value === customerDetails.street_address)}
                            onChange={handleSelectChange('street_address')}
                            styles={customSelectStyles}
                            placeholder="Select Location Type"
                            isClearable
                            required
                        />
                        {formErrors.street_address &&
                            <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                {formErrors.street_address}
                            </Typography>
                        }
                    </FormControl>

                    {customerDetails.account_product !== accountProducts.PAFUPI && (
                        <>

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    District *
                                </Typography>
                                <Select
                                    options={districtOptions}
                                    value={districtOptions.find(option => option.value === customerDetails.district)}
                                    onChange={handleSelectChange('district')}
                                    styles={customSelectStyles}
                                    placeholder="Select District"
                                    isClearable
                                    required
                                />
                                {formErrors.district &&
                                    <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                        {formErrors.district}
                                    </Typography>
                                }
                            </FormControl>


                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Traditional Authority *
                                </Typography>
                                <Select
                                    options={traditionalAuthorityOptions}
                                    value={traditionalAuthorityOptions.find(option => option.value === customerDetails.trad_auth)}
                                    onChange={handleSelectChange('trad_auth')}
                                    styles={customSelectStyles}
                                    placeholder="Select Traditional Authority"
                                    isClearable
                                    required
                                />
                                {formErrors.trad_auth &&
                                    <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                        {formErrors.trad_auth}
                                    </Typography>
                                }
                            </FormControl>

                            <CustomNBSTextField
                                label="Village *"
                                onInputChange={handleChange}
                                error={formErrors.village}
                                value={customerDetails.village || ''}
                                inputType="text"
                                name="village"
                                required="true"
                            />
                        </>
                    )}
                    <div style={CustomStyle.buttonContainer}>

                        <CustomNBSRedButton
                            onClick={handleNextButtonClick}
                            disabled={disableNextButton}
                        >
                            Next
                        </CustomNBSRedButton>
                    </div>
                </form>
            </div>
        </div>
    );
});

export const NextOfKin = React.memo(({
                                         customerObject
                                     }) => {

    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;
    const [disableButton, setDisableButton] = useState(true);
    const [customerDetailsData, setCustomerDetailsData] = useState({...customerDetails});
    const [formErrors, setFormErrors] = useState({});
    const accountProducts = {
        STUDENT: 'student',
        PAFUPI: 'PafupiUnbundled'
    };
    const handleChange = (e) => {
        const {name, value} = e.target;
        setCustomerDetailsData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name) => (selectedOption) => {
        setCustomerDetailsData(prev => ({
            ...prev,
            [name]: selectedOption?.value || ''
        }));
    };


    useEffect(() => {
        setCustomerDetails((prev) => ({
            ...prev,
            ...customerDetailsData
        }));
    }, [customerDetailsData, setCustomerDetails]);

    useEffect(() => {
        validateFields();
    }, [customerDetailsData]);

    const validateFields = () => {
        const errors = {};
        const {
            kin_fullname,
            kin_tel_number,
            kin_relationship_type,
            ref_name,
            ref_phone_number,
            residence_status
        } = customerDetailsData;

        if (customerDetails.account_product !== accountProducts.PAFUPI && (!kin_fullname || kin_fullname.trim().length === 0)) {
            errors.kin_fullname = "This field is required";
        }
        if (customerDetails.account_product !== accountProducts.PAFUPI && (!kin_tel_number || kin_tel_number.trim().length === 0)) {
            errors.kin_tel_number = "This field is required";
        }
        if (customerDetails.account_product !== accountProducts.PAFUPI && (!kin_relationship_type || kin_relationship_type.trim().length === 0)) {
            errors.kin_relationship_type = "This field is required";
        }
        if (!ref_name || ref_name.trim().length === 0) errors.ref_name = "This field is required";
        if (!ref_phone_number || ref_phone_number.trim().length === 0) errors.ref_phone_number = "This field is required";
        if (!residence_status || residence_status.trim().length === 0) errors.residence_status = "This field is required";

        setFormErrors(errors);
        // console.log(errors)
        setDisableButton(Object.keys(errors).length > 0);
    };

    const kinRelationTypeOptions = [
        {value: 'AUNT', label: 'Aunt'},
        {value: 'BROTHER', label: 'Brother'},
        {value: 'CHILD', label: 'Child'},
        {value: 'COUSIN', label: 'Cousin'},
        {value: 'GRANNY', label: 'Granny'},
        {value: 'NEPHEW', label: 'Nephew'},
        {value: 'NIECE', label: 'Niece'},
        {value: 'PARENT', label: 'Parent'},
        {value: 'SISTER', label: 'Sister'},
        {value: 'UNCLE', label: 'Uncle'},
        {value: 'WIFE', label: 'Wife'},


    ];

    const determineNextPage = () => {
        const accountProduct = customerDetails.account_product;
        if (accountProduct === accountProducts.STUDENT) {
            return 'UtilityBill';
        } else if (accountProduct === accountProducts.PAFUPI) {
            return 'HomeLocation';
        } else {
            return 'UtilityBill';
        }
    };

    const handleNextButtonClick = () => {
        const nextPage = determineNextPage();
        updateCurrentPage(nextPage);
    };


    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                {customerDetails.account_product !== accountProducts.PAFUPI && (
                    <>
                        <Typography variant="h5" sx={CustomStyle.heading}>Next of Kin</Typography>

                        <CustomNBSTextField
                            label="Full name *"
                            onInputChange={handleChange}
                            error={formErrors.kin_fullname}
                            value={customerDetailsData.kin_fullname || ''}
                            inputType="text"
                            name="kin_fullname"
                            required="true"
                        />

                        <CustomNBSTextField
                            label="Telephone number *"
                            onInputChange={handleChange}
                            error={formErrors.kin_tel_number}
                            value={customerDetailsData.kin_tel_number || ''}
                            inputType="text"
                            name="kin_tel_number"
                            required="true"
                        />

                        <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                            <Typography variant="h6" sx={{
                                ...CustomStyle.subtitle,
                                textAlign: 'left',
                                fontSize: '15px',
                                marginBottom: '10px'
                            }}>
                                Relationship *
                            </Typography>
                            <Select
                                options={kinRelationTypeOptions}
                                value={kinRelationTypeOptions.find(option => option.value === customerDetailsData.kin_relationship_type)}
                                onChange={handleSelectChange('kin_relationship_type')}
                                styles={customSelectStyles}
                                placeholder="Select Relationship"
                                isClearable
                                required
                            />
                            {formErrors.kin_relationship_type &&
                                <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                    {formErrors.kin_relationship_type}
                                </Typography>
                            }
                        </FormControl>

                        <CustomNBSTextField
                            label="Address"
                            onInputChange={handleChange}
                            error={formErrors.kin_address}
                            value={customerDetailsData.kin_address || ''}
                            inputType="text"
                            name="kin_address"
                            required="true"
                        />

                        <CustomNBSTextField
                            label="Email Address"
                            onInputChange={handleChange}
                            error={formErrors.kin_email}
                            value={customerDetailsData.kin_email || ''}
                            inputType="text"
                            name="kin_email"
                            required="true"
                        />
                    </>

                )}
                <br/>
                <Typography variant="h5" sx={CustomStyle.heading}>Referee</Typography>

                <CustomNBSTextField
                    label="Referee name *"
                    onInputChange={handleChange}
                    error={formErrors.ref_name}
                    value={customerDetailsData.ref_name || ''}
                    inputType="text"
                    name="ref_name"
                    required="true"
                />

                <CustomNBSTextField
                    label="Referee phone *"
                    onInputChange={handleChange}
                    error={formErrors.ref_phone_number}
                    value={customerDetailsData.ref_phone_number || ''}
                    inputType="text"
                    name="ref_phone_number"
                    required="true"
                />

                <CustomNBSTextField
                    label="Referee Address *"
                    onInputChange={handleChange}
                    error={formErrors.residence_status}
                    value={customerDetailsData.residence_status || ''}
                    inputType="text"
                    name="residence_status"
                    required="true"
                />
                <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={handleNextButtonClick}
                                        disabled={disableButton}>
                        Next
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>
    );
});


export const UtilityBill = React.memo(({
                                           customerObject
                                       }) => {
    const {customerDetails} = customerObject

    let element = (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Utility Bill
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3}}>

                    <CameraFeed
                        label="Utility bill"
                        name="utility_bill_url"
                        facingMode="environment"
                        customerObject={customerObject}
                        next="HomeLocation"
                    />
                </Box>
            </div>
        </div>
    );
    // let element = (
    //     <div className="content">
    //         <p style={{fontWeight: "bold", textAlign: "center"}}>
    //             Utility bill</p>
    //         <CameraFeed label="Utility bill" name="utility_bill_url" facingMode="environment"
    //                     customerObject={customerObject} next="HomeLocation"/>
    //     </div>
    // );
    if (customerDetails.account_product === "student") {
        element = (
            <div style={CustomStyle.pageWrapper}>
                <div style={CustomStyle.contentContainer}>
                    <Typography variant="h5" sx={CustomStyle.heading}>
                        Letter from Institution
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3}}>

                        <CameraFeed
                            label="Capture Letter"
                            name="institution_letter"
                            facingMode="environment"
                            customerObject={customerObject}
                            next="HomeLocation"
                        />
                    </Box>
                </div>
            </div>
        );
    } else if (customerDetails.account_product === "PafupiUnbundled") {
        element = (
            <div style={CustomStyle.pageWrapper}>
                <div style={CustomStyle.contentContainer}>
                    <Typography variant="h5" sx={CustomStyle.heading}>
                        Map to your location
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3}}>

                        <CameraFeed
                            label="Scan location map"
                            name="location"
                            facingMode="environment"
                            customerObject={customerObject}
                            next="AccountToBeOpened"
                        />
                    </Box>
                </div>
            </div>
        );
    }

    return element;
})

export const OccupationMap = React.memo(({
                                             customerObject
                                         }) => {
    const [disableButton, setDisableButton] = useState(false);
    const [displayNextButton, setDisplayNextButton] = useState(false);
    const [className, setClassName] = useState({
        next: "rounded-pill btn-light mx-auto d-none",
        confirm: "rounded-pill btn-light mx-auto d-none"
    });
    const [updateCurrentPage, setUpdateCurrentPage] = useState(false);
    let mapLoaded = false
    // let myImage = new Image();
    useEffect(() => {
        if (displayNextButton)
            setClassName((prev) => {
                return ({
                    ...prev, ...{
                        confirm: "rounded-pill btn-light mx-auto d-none",
                        next: "rounded-pill btn-light mx-auto d-block"
                    }
                })
            })
    }, [displayNextButton])
    useEffect(() => {
        if (updateCurrentPage) {
            setClassName((prev) => {
                return ({
                    ...prev, ...{
                        next: "rounded-pill btn-light mx-auto d-block",
                        confirm: "rounded-pill btn-light mx-auto d-none"
                    }
                })
            })
        }
    }, [updateCurrentPage])
    const captureImage = (page) => {
        var node = document.getElementById('map');
        domtoimage.toPng(node)
            .then(function (dataUrl) {
                //myImage.src = dataUrl;
                //console.log(dataUrl)
                //setDisplayNextButton((prev2)=>!prev2)
                customerObject.setCustomerDetails((prev) => {
                    return ({...prev, occupation_map: dataUrl})
                });
                customerObject.updateCurrentPage(page)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }
    return (
        <div className="content">
            <p style={{fontWeight: "bold", textAlign: "center"}}>
                Location of your work place</p>
            <HomeLocationMap mapLoaded={mapLoaded} customerObject={{...setDisableButton, ...customerObject}}
                             setDisplayNextButton={setDisplayNextButton}/>
            <CustomButton label="Next" disableButton={disableButton} className={className.next}
                          updateCurrentPage={captureImage} next="IncomeDeclarationForm"/>
            {/* <CustomButton label="Confirm" disableButton={disableButton} className={className.confirm} updateCurrentPage={setUpdateCurrentPage}  next={true}/>  */}
        </div>
    )
})
export const HomeLocation = React.memo(({
                                            customerObject
                                        }) => {
    const [disableButton, setDisableButton] = useState(false);
    const [displayNextButton, setDisplayNextButton] = useState(false);
    const [updateCurrentPage, setUpdateCurrentPage] = useState(false);
    let mapLoaded = false
    // let myImage = new Image();
    useEffect(() => {
        if (displayNextButton) {
            setUpdateCurrentPage(true);
        }
    }, [displayNextButton]);

    const captureImage = (page) => {
        var node = document.getElementById('map');
        domtoimage.toPng(node)
            .then(function (dataUrl) {
                const file = convertBase64ToImageOrPDFFile(dataUrl, 'location');
                const {customerDetails} = customerObject;

                createFileUploadObject(file, 'location', customerDetails.id_number, customerDetails.surname)
                    .then(data => {
                        customerObject.setCustomerDetails((prev) => ({
                            ...prev,
                            [data.name]: data.url,
                        }));

                        customerObject.setCustomerDetails((prev) => {
                            prev.temp[data.name] = dataUrl;
                            return prev;
                        });

                        customerObject.updateCurrentPage(page);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            })
            .catch(function (error) {
                console.error('Oops, something went wrong!', error);
            });
    };

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Home Residence
                </Typography>

                <HomeLocationMap
                    mapLoaded={mapLoaded}
                    customerObject={{...setDisableButton, ...customerObject}}
                    setDisplayNextButton={setDisplayNextButton}
                />
                {displayNextButton && (
                    <div style={CustomStyle.buttonContainer}>

                        <CustomNBSRedButton onClick={() => captureImage('SummaryPage')}
                                            disabled={disableButton}>
                            Next
                        </CustomNBSRedButton>
                    </div>
                )}
            </div>
        </div>
    )
})


export const AccountToBeOpened = React.memo(({
                                                 customerObject
                                             }) => {
    let element = (
        <div className="content">
            <p style={{fontWeight: "bold", textAlign: "center"}}>
                {/Unbundled/i.test(customerObject.customerDetails.account_product)
                    ?
                    "Your are about to open below unbundled account"
                    :
                    "Your are about to open below bundled account"
                }
            </p>
            <AccountProduct customerObject={customerObject}/>
            <XtraServices customerObject={customerObject}/>
            <CustomButton label="Next" updateCurrentPage={customerObject.updateCurrentPage} next="SummaryPage"/>
        </div>
    );

    return element;
})

export const SummaryPage = React.memo(({
                                           customerObject
                                       }) => {
    const {updateCurrentPage, customerDetails} = customerObject

    return (

        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Summary
                </Typography>
                <SummaryPageTable customerObject={customerDetails}/>

                <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={() => updateCurrentPage('Pictures')}
                                        disabled={false}>
                        Next
                    </CustomNBSRedButton>
                </div>
                {/*<CustomButton label="Next" updateCurrentPage={customerObject.updateCurrentPage} next="Pictures"/>*/}
            </div>
        </div>
    )
})
export const Pictures = React.memo(({
                                        customerObject
                                    }) => {
    const {updateCurrentPage, customerDetails} = customerObject

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Uploaded Pictures
                </Typography>
                <PicturesComponets customerObject={customerObject}/>

                <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={() => updateCurrentPage('Condition')}
                                        disabled={false}>
                        Next
                    </CustomNBSRedButton>
                </div>
                {/*<CustomButton label="Next" updateCurrentPage={customerObject.updateCurrentPage} next="Condition"/>*/}
            </div>
        </div>
    );
})

export const Condition = React.memo(({
                                         customerObject
                                     }) => {
    const {updateCurrentPage} = customerObject
    const [disableButton, setDisableButton] = useState(true)

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Account Opening Terms and Conditions
                </Typography>
                <ConditionSection customerObject={{...customerObject, setDisableButton}}/>

                <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={() => updateCurrentPage('Signature')}
                                        disabled={disableButton}>
                        Add Signature
                    </CustomNBSRedButton>
                </div>

            </div>
        </div>
    )

})
export const PafupiSignature = React.memo(({
                                               customerObject
                                           }) => {
    /*const [start, setStart] = useState(false);
    const [areCameraPermissionsGranted, setCameraPermissionsGranted] = useState(false);
    async function checkCameraPermissions() {
        setCameraPermissionsGranted(await cameraPermissionsGranted().then(r=>r).catch(e=>e));
    }
    useEffect(() => {
        checkCameraPermissions();
    }, [start]);*/

    return (
        <div className="content">
            <p style={{fontWeight: "bold", textAlign: "center"}}>
                Customer signature photo</p>
            <CameraFeed label="Capture customer signature" name="signature_photo_url" facingMode="environment"
                        customerObject={customerObject} next="FinishingRegistration"/>
        </div>
    );
})

export const Signature = React.memo(({
                                         customerObject
                                     }) => {
    // create canvas element and append it to document body
    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject
    const [scanSignature, setScanSignature] = useState(false)
    const isDiasporaAccount = [DIASPORA_ACCOUNT_TYPES.INVESTMENT, DIASPORA_ACCOUNT_TYPES.TRANSACTIONAL].includes(customerDetails.account_product);
    const [signatureUploaded, setSignatureUploaded] = useState(false);
    const [uploadedSignatureSrc, setUploadedSignatureSrc] = useState();
    const [promptSignatureInput, setPromptSignatureInput] = useState(false);
    const [signaturePad, setSignaturePad] = useState(
        <SignaturePad clearButton="true" height={300}
                      options={{backgroundColor: "#6F8FAF", width: "100%", height: "100%"}}/>
    );
    const [initializeSignaturePad, setInitializeSignaturePad] = useState(true);
    const [initialSignatureBase64, setInitialSignatureBase64] = useState(undefined);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const ic = document.querySelector("canvas");
        setInitialSignatureBase64(ic.toDataURL());
    }, [initializeSignaturePad]);

    const changeSignature = async (e) => {
        const signaturePhoto = e.target.files[0];

        let base64 = "";
        if (signaturePhoto) {
            setLoading(true);
            base64 = await resolveAndGetImageFileBase64(signaturePhoto);
            setLoading(false);
        }

        // const base64 = await getBase64(signaturePhoto)
        //     .then(r => r)
        //     .catch(e => {
        //         console.error(e);
        //         return ""
        //     });


        // const file = convertBase64ToImageFile(signaturePhotoUrl, 'signature', 'jpg');
        createFileUploadObject(signaturePhoto, 'signature', customerDetails.id_number, customerDetails.surname)
            .then(data => {
                setCustomerDetails((prev) => {
                    prev.signature = data['url'];
                    if (prev.customer_photo_url && !prev.customer_picture) {
                        prev.customer_picture = prev.customer_photo_url;
                    }
                    if (prev.residential_map_url && !prev.location) {
                        prev.location = prev.residential_map_url;
                    }
                    if (prev.kin_utility_bill && !prev.utility_bill_pic) {
                        prev.utility_bill_pic = prev.kin_utility_bill;
                    }
                    if (prev.signature_photo_url && !prev.signature) {
                        prev.signature = prev.signature_photo_url;
                    }
                    return prev;
                })
            })
            .catch((err) => {
                console.error(err);
            })

        setSignatureUploaded(customerDetails.signature.length > 0);
        setUploadedSignatureSrc(customerDetails.signature);
        setPromptSignatureInput(false);
    }

    /**
     * Clears Signature Data on Input where Customer can upload a signature file
     */
    const clearUploadSignatureInput = () => {
        setPromptSignatureInput(false)
        setSignatureUploaded(false);
        let $el = $('#uploadedSignature');
        $el.wrap('<form>').closest('form').get(0).reset();
        $el.unwrap();
    }

    const clearCanvas = (page) => {
        var canvas = document.querySelector("canvas");

        if (isDiasporaAccount) {
            clearUploadSignatureInput();
        }

        let context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        canvas.style.backgroundColor = "#6F8FAF";

        updateCurrentPage(page);
    }
    const captureImage = async (val) => {
        var canvas = document.querySelector("canvas");
        //console.log(canvas.toDataURL())

        //Logic Below should work for both Diaspora and Self-Onboarding
        let signaturePhotoUrl = null;

        // if (isDiasporaAccount) {
        if (canvas !== null) {
            // console.log(initialSignatureBase64 === canvas.toDataURL())
            if (initialSignatureBase64 === canvas.toDataURL() && !signatureUploaded) {
                setPromptSignatureInput(true);
            } else {
                signaturePhotoUrl = canvas.toDataURL();
                if (signaturePhotoUrl === initialSignatureBase64) {
                    signaturePhotoUrl = uploadedSignatureSrc;
                }
            }
        } else if (signatureUploaded) {
            signaturePhotoUrl = uploadedSignatureSrc;
        }
        // } else if (isCurrentAccount) {
        //     if (canvas !== null) {
        //         console.log(initialSignatureBase64 === canvas.toDataURL())
        //         if (initialSignatureBase64 === canvas.toDataURL() && !signatureUploaded) {
        //             setPromptSignatureInput(true);
        //         } else {
        //             signaturePhotoUrl = canvas.toDataURL();
        //             if (signaturePhotoUrl === initialSignatureBase64) {
        //                 signaturePhotoUrl = uploadedSignatureSrc;
        //             }
        //         }
        //     } else if (signatureUploaded) {
        //         signaturePhotoUrl = uploadedSignatureSrc;
        //     }
        // } else {
        //     signaturePhotoUrl = canvas.toDataURL();
        // }

        if (signaturePhotoUrl !== null && signaturePhotoUrl.length > 0) {
            //setCustomerDetails((prev)=>{return({...prev,...{"signature_photo":signaturePhotoUrl}})})

            const file = convertBase64ToImageOrPDFFile(signaturePhotoUrl, 'signature');
            createFileUploadObject(file, 'signature', customerDetails.id_number, customerDetails.surname)
                .then(data => {
                    if (!isDiasporaAccount) {
                        setCustomerDetails((prev) => {
                            prev.signature = data['url'];
                            prev.temp['signature'] = signaturePhotoUrl;
                            return prev;
                        })
                    }

                    if (isDiasporaAccount) {
                        setCustomerDetails((prev) => {
                            prev.signature = data['url'];
                            // prev.temp['signature'] = signaturePhotoUrl;
                            // prev.customer_picture = "";
                            prev.view_status = 1;
                            return prev;
                        })
                    }
                    customerObject.updateCurrentPage(val);
                    // console.log(customerDetails)
                })
                .catch((err) => {
                    console.error(err);
                })

        }
    }

    let element = (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Your signature</Typography>

                <Typography variant="body2" sx={{textAlign: "center", color: "#ed1b2e", mb: 2}}>
                    Sign in the pad below or upload your signature picture instead.
                </Typography>

                {!signatureUploaded && isDiasporaAccount && (
                    <div>
                        {signaturePad}
                    </div>

                )}
                {signatureUploaded && (
                    <Card.Body>
                        <div>
                            <img style={{marginLeft: "-1rem", height: '300px', width: "100%"}}
                                 src={uploadedSignatureSrc}/>
                        </div>
                    </Card.Body>
                )}

                {isDiasporaAccount && (
                    <div>

                        <br/>
                        <Form.Group>
                            <Form.Label style={{fontWeight: "500", color: "#2b357c"}}>Or Upload Your Signature
                                Instead</Form.Label>
                            <Form.Control id={"uploadedSignature"} style={{borderRadius: '100px'}} type="file"
                                          accept="image/*" onChange={changeSignature} required/>
                        </Form.Group>
                        <br/>
                    </div>
                )}

                {promptSignatureInput && (
                    <div>
                    <span style={{
                        fontWeight: "500",
                        color: "#ed1b2e"
                    }}>Please provide your signature before proceeding</span>
                    </div>
                )}

                {!isDiasporaAccount && (
                    <div style={{
                        textAlign: 'center',
                        alignItems: 'center',
                        marginBottom: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <SignaturePad clearButton="true" height={300}
                                      options={{backgroundColor: "#6F8FAF", width: "100%", height: "100%"}}/>

                        <CustomNBSRedButton onClick={() => setScanSignature(!scanSignature)}
                                            disabled={false}>
                            Upload your signature
                        </CustomNBSRedButton>
                    </div>
                )}

                <div style={CustomStyle.buttonContainer}>


                    {loading
                        ? <Spinner className="content" animation="border"/>
                        : <CustomNBSRedButton onClick={() => captureImage('FinishingRegistration')}
                                              disabled={false}>
                            Next
                        </CustomNBSRedButton>
                    }

                    <CustomNBSRedButton onClick={() => clearCanvas('Signature')}
                                        disabled={false}>
                        Redo
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>
    )
    if (scanSignature)
        element = (
            <div style={CustomStyle.pageWrapper}>
                <div style={CustomStyle.contentContainer}>
                    <Typography variant="h5" sx={CustomStyle.heading}>
                        Customer signature photo</Typography>
                    <CameraFeed label="Capture customer signature" name="signature" facingMode="environment"
                                customerObject={customerObject} next="FinishingRegistration"/>
                </div>
            </div>
        )
    return element;
})


export const FinishingRegistration = React.memo(({
                                                     customerObject
                                                 }) => {
    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;
    const dispatch = useDispatch();
    const isDiasporaAccount = [DIASPORA_ACCOUNT_TYPES.INVESTMENT, DIASPORA_ACCOUNT_TYPES.TRANSACTIONAL].includes(customerDetails.account_product);
    const [loading, setLoading] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null); // null, "success", "failure"

    const upperCaseDetails = (details) => {
        const fieldsToUpperCase = [
            'occupation', 'postal_address', 'postal_town', 'trad_auth',
            'street_address', 'village', 'kin_fullname', 'district_address',
            'employment_status', 'gender', 'district', 'marital_status',
            'resident_country', 'inputter_name'
        ];
        fieldsToUpperCase.forEach(field => {
            if (details[field]) details[field] = details[field].toUpperCase();
        });

        // Handle trimming "CITY" from specific districts
        if (details.district) {
            if (details.district === "BLANTYRECITY") {
                details.district = "BLANTYRE";
            } else if (details.district === "LILONGWECITY") {
                details.district = "LILONGWE";
            } else if (details.district === "ZOMBACITY") {
                details.district = "ZOMBA";
            }
        }
    };

    const handleClick = async () => {
        setLoading(true);
        upperCaseDetails(customerDetails);

        customerDetails.inputter_name = customerDetails.surname + customerDetails.firstname.charAt(0);
        customerDetails.id_issue_date = moment(customerDetails.id_issue_date).format("YYYY-MM-DD");
        customerDetails.id_expiry_date = moment(customerDetails.id_expiry_date).format("YYYY-MM-DD");
        customerDetails.date_of_birth = moment(customerDetails.date_of_birth).format("YYYY-MM-DD");

        if (customerDetails.employment_status === "SELF-EMPLOYED") {
            customerDetails.IncomeProofPic = "";
        }

        if (!customerDetails.monthly_transactions) {
            customerDetails.monthly_transactions = 0.0;
        }

        try {
            const res = await submitCustomerDetailsWithoutFiles(customerDetails);
            localStorage.setItem('customer_details', JSON.stringify(customerDetails));
            localStorage.setItem('post_status', JSON.stringify(res.data));
            dispatch(setPostStatus(res.data));
            setSubmissionStatus(res.data.success ? "success" : "failure");
        } catch (error) {
            console.error(error);
            setSubmissionStatus("failure");
        } finally {
            setLoading(false);
        }
    };

    const handleRetry = () => {
        setSubmissionStatus(null);
    };

    const handleGoHome = () => {
        updateCurrentPage("HomePage"); // Adjust to your homepage route
    };

    const element = (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                {submissionStatus === null && (
                    <>
                        <Typography variant="h5" sx={CustomStyle.heading}>
                            Thank you for choosing NBS Bank.
                        </Typography>
                        <Box sx={{mt: 2, mb: 10}}>

                            <Typography variant="h4" sx={{
                                ...CustomStyle.subtitle,
                                // textAlign: 'left',
                                // fontSize: '15px',
                                marginBottom: '10px'
                            }}>
                                You will receive an SMS with account details. Please visit your preferred service center
                                within 30 days to validate your National ID (E-KYC) and enable withdrawals.
                            </Typography>
                            <Typography variant="h4" sx={{
                                ...CustomStyle.subtitle,
                                // textAlign: 'left',
                                // fontSize: '15px',
                                marginBottom: '10px'
                            }}>
                                You will also receive a temporary PIN number through SMS, which you must change within 4
                                hours to log into NBS EazyApp and EazyMobile.
                            </Typography>
                        </Box>
                        <div style={CustomStyle.buttonContainer}>
                            <CustomNBSRedButton onClick={handleClick}
                                                disabled={loading}>
                                {loading ? <CircularProgress size={24}/> : "Register Account"}
                            </CustomNBSRedButton>
                        </div>

                    </>
                )}

                {submissionStatus === "success" && (
                    <Box textAlign="center">
                        <CheckCircleOutline style={{fontSize: 80, color: 'green'}}/>

                        <Typography variant="h5" sx={CustomStyle.subtitle}>
                            Your account has been successfully registered!
                        </Typography>

                        <div style={CustomStyle.buttonContainer}>

                            <CustomNBSRedButton onClick={handleGoHome}
                                                disabled={loading}>
                                Go to Home
                            </CustomNBSRedButton>
                        </div>

                    </Box>
                )}

                {submissionStatus === "failure" && (
                    <Box textAlign="center">
                        <ErrorOutline style={{fontSize: 80, color: 'red'}}/>


                        <Typography variant="h5" sx={{...CustomStyle.heading, color: 'red'}}>
                            Registration failed. Please try again.
                        </Typography>

                        <div style={CustomStyle.buttonContainer}>

                            <CustomNBSRedButton onClick={handleRetry}
                                                disabled={loading}>
                                Retry
                            </CustomNBSRedButton>
                        </div>

                    </Box>
                )}
            </div>
        </div>
    );


    return isDiasporaAccount ?
        <DiasporaAccountCreationFinishPage updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                           setCustomerDetails={setCustomerDetails}/> : element;
})


export const AfterRegistration = React.memo(({
                                                 customerObject
                                             }) => {
    const {updateCurrentPage, customerDetails, setCustomerDetails} = customerObject;
    const post_status = useSelector((state) => state.data.post_status)
    const [postSatatus, setPostStatus] = useState(JSON.parse(localStorage.getItem("post_status")))
    const isDiasporaAccount = [DIASPORA_ACCOUNT_TYPES.INVESTMENT, DIASPORA_ACCOUNT_TYPES.TRANSACTIONAL].includes(customerDetails.account_product);
    let color = "#ed1b2e";
    let class_name = "rounded-pill btn-light mx-auto d-block";
    const handleClick = async () => {
        // window.location.reload(true);
        console.log(post_status)
        console.log(customerDetails)
        if (postSatatus.success !== undefined && postSatatus.success === true) {
            localStorage.clear();
            if (/agency_banking_onboarding/ig.test(customerObject.customerDetails.channel))
                window.changeWindow = function () {
                    window.agencyApp.onProcessFinished();
                }
            else window.location.reload(true);
        } else {
            const res = await submitCustomerDetailsWithoutFiles(customerDetails).then(r => {
                if (r.data !== undefined)
                    return r.data
                else return {
                    success: false,
                    msg: "Error"
                }
            }).catch(err => {
                console.error(err);
                return {
                    success: false,
                    msg: err
                }
            });
            if (res.success)
                setPostStatus(res)
        }
    }
    useEffect(() => {
        console.log(postSatatus)
        console.log("huwa tests")

    }, [postSatatus]);
    let element = (
        <div id="thankYou" className="container-fluid justify-content-center text-center">
            {postSatatus.success !== undefined && postSatatus.success === true ?
                <div className="container-fluid justify-content-center text-center" style={{textAlign: "justify"}}>
                    <div className="container-fluid justify-content-start text-center col-md-6"
                         style={{textAlign: "justify"}}>
                        <svg className="bi bi-check-circle-fill" width="3em" height="3em" viewBox="0 0 16 16"
                             fill="green" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <br/>
                        <br/>
                        <p className="w3-text-indigo">Registration successful</p>
                    </div>
                </div>
                :
                <div className="container-fluid justify-content-center text-center" style={{textAlign: "justify"}}>
                    <div className="container-fluid justify-content-start text-center col-md-6"
                         style={{textAlign: "justify"}}>
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="5em"
                             height="5em" fill="red"
                             viewBox="0 0 460.775 460.775" style={{enableBackground: "new 0 0 460.775 460.775"}}>
                            <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                            c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                            c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                            c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                            l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                            c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                        </svg>
                        <br/>
                        <br/>
                        <p className="w3-text-indigo">Registration failed</p>
                    </div>
                </div>
            }

            <Button
                className={class_name}
                disabled={false}
                style={{backgroundColor: color, color: "white"}}
                onClick={handleClick}>{postSatatus.success ? "OK" : "Retry"}</Button>
            {/* <CustomButton label="Register" updateCurrentPage={customerObject.updateCurrentPage}  next="FinishingRegistration"/> */}
        </div>
    )
    return isDiasporaAccount ?
        <DiasporaAccountCreationFinishPage updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                           setCustomerDetails={setCustomerDetails}/> : element;
})