import React, {useEffect} from 'react';
import {useState} from 'react';
import Header from './Header';
import {customerProfile} from './CustomerProfile';
import {
    Home,
    Product,
    Student,
    PaymentBasis,
    BundledServices,
    CustomerDetails,
    MonthlyBasis,
    PerTransaction,
    UnbundledExtraServices,
    IdScanner,
    ContactDetails,
    StudentDetails,
    IncomeDeclarationForm,
    EmploymentStatus,
    IncomeProof,
    StudentIDPhoto,
    ResidentialDetails,
    IncomeProofPic,
    CustomerPic,
    NextOfKin,
    UtilityBill,
    HomeLocation,
    AccountToBeOpened,
    SummaryPage,
    Condition,
    Signature,
    FinishingRegistration,
    Pictures,
    CustomerIDPhoto,
    PafupiSignature,
    OccupationMap,
    AfterRegistration,
    CurrentMonthlyBasis,
    CurrentPerTransaction,
    CurrentServices,
    AccountType,
    CurrentAccountPaymentBasis,
    AmendDocuments, PafupiLocationImage, Nationality, EmploymentDetails
} from "./MultiComponets";
import {PassportReader} from './diaspora/PassportReader';
import {DiasporaServiceFees} from './diaspora/DiasporaServiceFees';
import {DiasporaCustomerDetails} from './diaspora/DiasporaCustomerDetails';
import {DiasporaAccountKeyFacts} from './diaspora/DiasporaAccountKeyFacts';
import {HomePage} from './HomePage';
import {ApplicantDetails as DiasporaApplicantDetails} from './diaspora/ApplicantDetails';
import {NextOfKinDetails as DiasporaNextOfKinDetails} from './diaspora/NextOfKinDetails';
import {IncomeProof as DiasporaIncomeProof} from './diaspora/IncomeProof';
import {Summary as DiasporaSummary} from './diaspora/Summary';
import {Overview as DiasporaAccountsOverview} from './diaspora/accounts/Overview';
import {Requirements as DiasporaAccountsRequirements} from './diaspora/accounts/Requirements';
import {DiasporaTermsAndConditions} from './diaspora/DiasporaTermsAndConditions';
import {DeclinedTermsAndConditions} from './diaspora/DeclinedTermsAndConditions';
import {AcknowledgeReceipt} from './diaspora/AcknowledgeReceipt';
import {CurrentRequirements} from "./current/CurrentRequirements";
import {AccountNumber} from "./existing-accounts/AccountNumber";
import {AvailableAccounts} from "./existing-accounts/AvailableAccounts";
import {AccountServices} from "./existing-accounts/AccountServices";
import {existingProfile} from "./existing-accounts/ExistingProfile";
import {AccountProductInformation} from "./existing-accounts/AccountProductInformation";
import KeyFacts from './existing-accounts/KeyFacts';
import {FinishPage} from "./existing-accounts/FinishPage";
import {AccountCheck} from "./existing-accounts/AccountCheck";
import {OtpVerification} from "./existing-accounts/OtpVerification";
import {Scanner} from "./existing-accounts/Scanner";
import {ScanComponent} from "./existing-accounts/ScanComponent";
import {ScanVerification} from "./existing-accounts/ScanVerification";
import {UpgradeRequirements} from "./existing-accounts/UpgradeRequirements";
import {AdditionalFields} from "./existing-accounts/AdditionalFields";
import {Location} from "./existing-accounts/Location";
import {ScanDiaspora} from "./existing-accounts/ScanDiaspora";
import {Occupation} from "./existing-accounts/Occupation";
import {fixedDepositProfile} from "./fixed-deposit/fixed-deposit-profile";
import {AccountNumberFD} from "./fixed-deposit/AccountNumberFD";
import {KeyFactStatement} from "./fixed-deposit/KeyFactStatement";
import {ContractScreen} from "./fixed-deposit/ContractScreen";
import {FixedDepositFinish} from "./fixed-deposit/FixedDepositFinish";
import {RequestType} from "./fixed-deposit/RequestType";
import {ScannerComponent} from "./fixed-deposit/ScannerComponent";
import {Verification} from "./fixed-deposit/Verification";
import {FixedAccountsList} from "./fixed-deposit/FixedAccountsList";
import {FixedAccountDetails} from "./fixed-deposit/FixedAccountDetails";

const Main = ({routeKind}) => {
    const [currentPage, setCurrentPage] = useState(routeKind === "changu-online" ? "Home" : "HomePage");
    const [pageTracker, setPageTracker] = useState(routeKind === "changu-online" ? ["Home"] : ["HomePage"]);
    const [customerDetails, setCustomerDetails] = useState(customerProfile);
    const [existingDetails, setExistingDetails] = useState(existingProfile);
    const [fixedDepositDetails, setFixedDepositDetails] = useState(fixedDepositProfile);
    const [selectedCode, setSelectedCode] = useState("");
    const [formData, setFormData] = useState({});

    const updateFormData = (key, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [key]: value,
        }));
    };
    const updateCurrentPage = (val) => {
        setPageTracker((prev) => [...prev, val]);
        setCurrentPage(val);
        // localStorage.setItem('current_page',val);
        // localStorage.setItem('customer_details', JSON.stringify(customerDetails));
    }

    const setPage = () => {
        let channel = 'self_onboarding';
        // setCustomerDetails((prev) => {
        //     channel = channel === "agency_banking_onboarding" ? channel : "self_onboarding";
        //     return ({...prev, ...{"channel": channel, account_product: "PafupiUnbundled"}})
        // })
        // if (channel === "agency_banking_onboarding") {
        //     setPageTracker(["PerTransaction"]);
        //     setCurrentPage("PerTransaction");
        // }

        // let cd = JSON.parse(localStorage.getItem("customer_details"));
        // cd.channel = channel;
        // localStorage.setItem("customer_details", JSON.stringify(cd));

    }
    const getLocalStorage = () => {
        let current_page = localStorage.getItem('current_page');
        console.log("local storage checked");
        console.log("current_page", current_page);
        if (current_page !== undefined && current_page !== null) {
            setCustomerDetails(JSON.parse(localStorage.getItem("customer_details")))
            setCurrentPage(current_page);
            updateCurrentPage(currentPage);
        }
    }
    useEffect(() => {
        try {
            setPage()
        } catch (e) {
            console.error(e);
        }
        // getLocalStorage();
    }, [])
    let page = null;
    //console.log(customerDetails)
    if (currentPage === "HomePage")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                <HomePage updateCurrentPage={updateCurrentPage}/>

            </>
        );
    if (currentPage === "AmendDocuments")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage} />
                <AmendDocuments updateCurrentPage={updateCurrentPage} />

            </>
        );
    if (currentPage === "Home")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                <Home updateCurrentPage={updateCurrentPage}/>

            </>
        );
    if (currentPage === "AccountType")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AccountType updateCurrentPage={updateCurrentPage} setCustomerDetails={setCustomerDetails}/>}

            </>
        );
    else if (currentPage === "Nationality")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<Nationality updateCurrentPage={updateCurrentPage} setCustomerDetails={setCustomerDetails}/>}


            </>

        );
    else if (currentPage === "Product")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<Product updateCurrentPage={updateCurrentPage} setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "Student")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<Student updateCurrentPage={updateCurrentPage}
                          customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );

    else if (currentPage === "PaymentBasis")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<PaymentBasis customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "CurrentAccountPaymentBasis")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<CurrentAccountPaymentBasis
                    customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "BundledServices")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<BundledServices updateCurrentPage={updateCurrentPage}
                                  customerDetailsObject={{customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "CustomerDetails")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<CustomerDetails updateCurrentPage={updateCurrentPage} setCustomerDetails={setCustomerDetails}/>}
            </>

        );
    else if (currentPage === "MonthlyBasis")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<MonthlyBasis customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );

    else if (currentPage === "PerTransaction")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<PerTransaction updateCurrentPage={updateCurrentPage}
                                 customerDetailsObject={{customerDetails, setCustomerDetails}}/>}
            </>

        );

    else if (currentPage === "UnbundledExtraServices")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<UnbundledExtraServices updateCurrentPage={updateCurrentPage}
                                         customerDetailsObject={{customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "IdScanner") {
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<IdScanner updateCurrentPage={updateCurrentPage}
                            customerDetailsObject={{customerDetails, setCustomerDetails}}/>}
            </>

        );
    } else if (currentPage === "ContactDetails")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<ContactDetails updateCurrentPage={updateCurrentPage}
                                 customerDetailsObject={{customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "StudentDetails")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<StudentDetails customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "IncomeDeclarationForm")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<IncomeDeclarationForm customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );

    else if (currentPage === "EmploymentDetails")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<EmploymentDetails customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "EmploymentStatus")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<EmploymentStatus customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "CustomerIDPhoto")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<CustomerIDPhoto customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "IncomeProof")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<IncomeProof customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "StudentIDPhoto")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<StudentIDPhoto customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "ResidentialDetails")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<ResidentialDetails customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "PafupiLocationImage")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<PafupiLocationImage customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );

    else if (currentPage === "IncomeProofPic")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<IncomeProofPic customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>

        );
    else if (currentPage === "CustomerPic")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<CustomerPic customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "NextOfKin")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<NextOfKin customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "UtilityBill")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<UtilityBill customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "HomeLocation")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<HomeLocation customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "AccountToBeOpened")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AccountToBeOpened customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "SummaryPage")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<SummaryPage customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "Condition")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<Condition customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "Signature")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<Signature customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "FinishingRegistration")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<FinishingRegistration customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "PafupiSignature")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<PafupiSignature customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "Pictures")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<Pictures customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "OccupationMap")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<OccupationMap customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "PassportReader")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<PassportReader updateCurrentPage={updateCurrentPage} setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaServiceFees")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaServiceFees updateCurrentPage={updateCurrentPage} setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaCustomerDetails")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaCustomerDetails updateCurrentPage={updateCurrentPage}
                                          setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaAccountKeyFacts")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaAccountKeyFacts updateCurrentPage={updateCurrentPage}
                                          setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaApplicantDetails")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaApplicantDetails updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                           setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaNextOfKinDetails")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaNextOfKinDetails updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                           setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaIncomeProof")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaIncomeProof updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                      setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaSummary")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaSummary updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                  setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaAccountsRequirements")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaAccountsRequirements updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                               setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaAccountsOverview")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaAccountsOverview updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                           setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DiasporaTermsAndConditions")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DiasporaTermsAndConditions updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                             setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "DeclinedTermsAndConditions")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<DeclinedTermsAndConditions updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                             setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "AcknowledgeReceipt")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AcknowledgeReceipt updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                     setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "AfterRegistration")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AfterRegistration customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "CurrentRequirements")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<CurrentRequirements updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
                                      setCustomerDetails={setCustomerDetails}/>}
            </>
        );
    else if (currentPage === "CurrentMonthlyBasis")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<CurrentMonthlyBasis customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "CurrentPerTransaction")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<CurrentPerTransaction customerObject={{updateCurrentPage, customerDetails, setCustomerDetails}}/>}
            </>
        );
    else if (currentPage === "CurrentServices")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<CurrentServices updateCurrentPage={updateCurrentPage}
                                  customerDetailsObject={{customerDetails, setCustomerDetails}}/>}
            </>
        );

    else if (currentPage === "AccountCheck")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AccountCheck updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                               setExistingDetails={setExistingDetails}
                />}
            </>

        );
    else if (currentPage === "AccountNumber")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AccountNumber updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                                setExistingDetails={setExistingDetails}/>}
            </>
        );

    else if (currentPage === "AvailableAccounts")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AvailableAccounts updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                                    setExistingDetails={setExistingDetails}/>}
            </>
        );
    else if (currentPage === "AccountProductInformation")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AccountProductInformation updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                                            setExistingDetails={setExistingDetails}/>}
            </>
        );

    else if (currentPage === "AccountServices")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AccountServices updateCurrentPage={updateCurrentPage}
                                  existingDetails={existingDetails} setExistingDetails={setExistingDetails}/>}
            </>

        );

    else if (currentPage === "KeyFacts")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<KeyFacts updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                           setExistingDetails={setExistingDetails}
                />}
            </>

        );
    else if (currentPage === "OtpVerification")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<OtpVerification updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                                  setExistingDetails={setExistingDetails}
                />}
            </>

        );
    else if (currentPage === "ScanComponent")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<ScanComponent updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                                setExistingDetails={setExistingDetails}
                />}
            </>

        );
    else if (currentPage === "ScanDiaspora")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<ScanDiaspora updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                               setExistingDetails={setExistingDetails}
                />}
            </>

        );
    else if (currentPage === "ScanVerification")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<ScanVerification updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                                   setExistingDetails={setExistingDetails}
                />}
            </>

        );
    else if (currentPage === "UpgradeRequirements")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<UpgradeRequirements updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                                      setExistingDetails={setExistingDetails}
                                      updateFormData={updateFormData}
                />}
            </>

        );
    else if (currentPage === "AdditionalFields")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AdditionalFields formData={formData} updateCurrentPage={updateCurrentPage}
                                   existingDetails={existingDetails} setExistingDetails={setExistingDetails}

                />}
            </>

        );
    else if (currentPage === "Location")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<Location updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                           setExistingDetails={setExistingDetails}

                />}
            </>

        );
    else if (currentPage === "Occupation")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<Occupation updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                             setExistingDetails={setExistingDetails}

                />}
            </>

        );
    else if (currentPage === "FinishPage")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<FinishPage updateCurrentPage={updateCurrentPage} existingDetails={existingDetails}
                             setExistingDetails={setExistingDetails}
                />}
            </>

        );

    else if (currentPage === "RequestType")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<RequestType updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                              setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );

    else if (currentPage === "AccountNumberFixedDeposit")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<AccountNumberFD updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                                  setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );
    else if (currentPage === "ScannerComponent")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<ScannerComponent updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                                   setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );
    else if (currentPage === "Verification")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<Verification updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                               setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );
    else if (currentPage === "OneTimePinFixedDeposit")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<KeyFactStatement updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                                   setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );
    else if (currentPage === "FixedDepositContractPage")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<ContractScreen updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                                 setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );
    else if (currentPage === "KeyFactStatement")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<KeyFactStatement updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                                   setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );
    else if (currentPage === "FixedDepositFinish")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<FixedDepositFinish updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                                     setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );
    else if (currentPage === "FixedAccountsList")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<FixedAccountsList updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                                    setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );
    else if (currentPage === "FixedAccountDetails")
        page = (
            <>
                <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
                {<FixedAccountDetails updateCurrentPage={updateCurrentPage} fixedDepositDetails={fixedDepositDetails}
                                    setFixedDepositDetails={setFixedDepositDetails}
                />}
            </>

        );
    // else if (currentPage === "CurrentAccountsOverview")
    //     page = (
    //         <>
    //             <Header pageTracker={pageTracker} updateCurrentPage={updateCurrentPage}/>
    //             {<CurrentAccountsOverview updateCurrentPage={updateCurrentPage} customerDetails={customerDetails}
    //                                        setCustomerDetails={setCustomerDetails}/>}
    //         </>
    //     );

    return (page);
}
export default React.memo(Main);