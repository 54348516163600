import $ from 'jquery';
import axios from 'axios';
import {cleanUpSomeCustomerDetails, documentResubmissionRemarks} from "./Utils";
import {getUniqueList} from "./Utils";
import {serviceCenters} from "../constants/ServiceCenters";

const SERVER_URL = [
    process.env.REACT_APP_SERVER_HOST,
    Number(process.env.REACT_APP_SERVER_PORT)
].join(":")

export const DIASPORA_ACCOUNT_TYPES = {
    TRANSACTIONAL: "DiasporaTrans",
    INVESTMENT: "DiasporaInvest",
}

function clearPassportFile() {
    let $el = $('#passport');
    $el.wrap('<form>').closest('form').get(0).reset();
    $el.unwrap();
}

export function triggerScanningMalawianPassportFollowingReceiptOfForeignPassport() {
    clearPassportFile();
    $('#scannedResults').attr('hidden', false); //hide results
    $("#topContent").show();
    $("#passport").click();
}

export function triggerChoosingFileAgain() {
    //TODO: clear children of scannedResults element
    clearPassportFile();
    $('#scannedResults').attr('hidden', true); //hide results
    $('#Passport2_scannedResults').attr('hidden', true); //hide passport2 results
    $("#topContent").show();
    $("#passport").click();
}

export function setMalawianPassportFieldData(o) {
    $('#Passport2_fullName').html([o.firstname, o.surname].join(" "));
    $('#Passport2_passportNumber').html(o.id_number);
    $('#Passport2_dateOfBirth').html(o.date_of_birth);
    $('#Passport2_sex').html(o.gender);
    $('#Passport2_expiryDate').html(o.id_expiry_date);
    $('#Passport2_nationality').html(o.country);

    $('#passportScanLoader').attr('hidden', true); //hide loader
    $('#scannedResults').attr('hidden', false);
    $('#Passport2_scannedResults').attr('hidden', false); //show results
}

export function setFieldData(o) {
    $('#fullName').html([o.firstname, o.surname].join(" "));
    $('#passportNumber').html(o.id_number);
    $('#dateOfBirth').html(o.date_of_birth);
    $('#sex').html(o.gender);
    $('#expiryDate').html(o.id_expiry_date);
    $('#nationality').html(o.country);

    $('#passportScanLoader').attr('hidden', true); //hide loader
    $('#scannedResults').attr('hidden', false); //show results
    $('#Passport2_scannedResults').attr('hidden', true); //show results
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export async function submitPassportFile(e, scanningMalawianPassportAfterReceiptOfForeignPassport) {
    const fileBase64 = await getBase64(e.target.files[0]).then(r=>r).catch(()=>null);
    if (fileBase64 === null) {
       console.error("Failed to parse file into base64");
        return {
            statusCode: 500,
            data: null
        }
    }

    if (scanningMalawianPassportAfterReceiptOfForeignPassport) {
        $('#scannedResults').attr('hidden', false); //hide results
    } else {
        $('#scannedResults').attr('hidden', true); //hide results
    }

    $('#passportScanLoader').attr('hidden', false); //show loader
    // $('#scannedResults').attr('hidden', true); //hide results
    $("#invalidPassportAlert").attr('hidden', true);

    const headers = { 'Content-Type': 'multipart/form-data' };
    const data = { passport: e.target.files[0] };

    const url = [SERVER_URL,"read-passport"].join("/");
    const res = await axios.post("/read-passport", data, {headers: headers} ).then(res => {
            return {error: false, data: res}
        }).catch(err => {
            console.error(err)
            return {error: true, data: err}
        });

    let responseData;

    if (res.data === null ) {
        responseData = {valid: false, status: 500, fields: {}};
    } else if (!res.error && !res.data.notRecognizedAsPassport) {
        //No error and document is recognized as a valid passport
        responseData = res.data
    } else {
        responseData = {valid: false, status: 400, fields: {}};
    }

    if ([400, 500].includes(responseData.status)) {
        return {
            statusCode: responseData.status,
            data: null
        }
    }
    return {
        statusCode: responseData.status,
        data: prepareResponseData(responseData)
    }
}

function prepareResponseData(response) {
    response = response.data;
    if (response === null) {
        return response;
    }

    let expDate = response.fields.expirationDate;
    let deducedIssueDate = [
        Number(expDate.substring(0, 4)) - 10,
        expDate.substring(5, 7),
        expDate.substring(8)
    ].join("-"); // TODO: Needs proper/right way, not all passports expire in 10 years

    return {
        firstname: response.fields.firstName,
        surname: response.fields.lastName,
        id_number: response.fields.documentNumber,
        date_of_birth: response.fields.birthDate,
        gender: response.fields.sex,
        id_expiry_date: expDate,
        id_type: "PASSPORT",
        country: response.fields.nationality,
        id_issue_date: deducedIssueDate,
        malawian: response.fields.isMalawian ? 1 : 0
    };
}


function isNotRecommended(v) {
    return (v === null || v === undefined || v );
}

export function resetSelfEmploymentSourceOfFundsInputData(customerObject) {
    customerObject.other = 0
    customerObject.contract = 0;
    customerObject.imports = 0;
    customerObject.import = 0;
    customerObject.exports = 0;
    customerObject.export = 0;
    customerObject.rental_income = 0;
    customerObject.rentalIncome = 0;
    customerObject.annuity = 0;
    customerObject.director_fees = 0;
    customerObject.directors_fees = 0;
    customerObject.directorsFees = 0;
    customerObject.interest = 0;
    customerObject.donation = 0;
    customerObject.loan = 0;
    customerObject.inheritance = 0;
    customerObject.Inheritance = 0;
    return customerObject;
}

export function validateCustomerData(customerObject) {
    customerObject.other = isNotRecommended(customerObject.other) ? 0 : customerObject.other
    customerObject.monthly_transactions = isNotRecommended(customerObject.monthly_transactions) ? 0 : customerObject.monthly_transactions
    customerObject.contract = isNotRecommended(customerObject.contract ) ? 0 : customerObject.contract
    customerObject.imports = isNotRecommended(customerObject.imports ) ? 0 : customerObject.imports
    customerObject.exports = isNotRecommended(customerObject.exports ) ? 0 : customerObject.exports
    customerObject.rental_income = isNotRecommended(customerObject.rental_income)  ? 0 : customerObject.rental_income
    customerObject.annuity = isNotRecommended(customerObject.annuity ) ? 0 : customerObject.annuity
    customerObject.director_fees = isNotRecommended(customerObject.director_fees ) ? 0 : customerObject.director_fees
    customerObject.interest = isNotRecommended(customerObject.interest ) ? 0 : customerObject.interest
    customerObject.donation = isNotRecommended(customerObject.donation ) ? 0 : customerObject.donation
    customerObject.loan = isNotRecommended(customerObject.loan ) ? 0 : customerObject.loan
    customerObject.inheritance = isNotRecommended(customerObject.inheritance)  ? 0 : customerObject.inheritance
    customerObject.one_off_income = isNotRecommended(customerObject.one_off_income)  ? 0 : customerObject.one_off_income
    customerObject.family_income = isNotRecommended(customerObject.family_income)  ? 0 : customerObject.family_income
    customerObject.other_sources_income = isNotRecommended(customerObject.other_sources_income)  ? 0 : customerObject.other_sources_income
    customerObject.admission_year = isNotRecommended(customerObject.admission_year)  ? 0 : customerObject.admission_year
    customerObject.completion_year = isNotRecommended(customerObject.completion_year)  ? 0 : customerObject.completion_year
    customerObject.private_sponsorship = isNotRecommended(customerObject.private_sponsorship)  ? 0 : customerObject.private_sponsorship
    customerObject.guardian = isNotRecommended(customerObject.guardian)  ? 0 : customerObject.guardian
    customerObject.directors_fees = isNotRecommended(customerObject.directors_fees)  ? 0 : customerObject.directors_fees
    //customerObject.burseries = isNotRecommended(customerObject.burseries)  ? 0 : customerObject.burseries

    customerObject = cleanUpSomeCustomerDetails(customerObject);

    return customerObject;
}

export async function fetchBranches() {
    return await axios.get("/fetch-branches", { headers: { "Authorization": `Token ${process.env.REACT_APP_TOKEN}` } })
        .then(r => {
            return r.data;
        })
        .catch(err => {
            console.error(err);
            return []
        });
}

export async function submitCustomerData(customerDetails) {
    return await axios.post("/submit-customer-data", customerDetails, { headers: { "Authorization": `Token ${process.env.REACT_APP_TOKEN}` }})
        .then(res => res)
        .catch(err => err);
}

export async function submitCustomerDataWithoutFiles(customerDetails) {
    return await axios.post("/submit-customer-data-without-files", customerDetails, { headers: { "Authorization": `Token ${process.env.REACT_APP_TOKEN}` }})
        .then(res => res)
        .catch(err => err);
}

export async function customerDataAlreadySubmitted(customerScannedData) {
    // const url = [SERVER_URL,"check-customer-existence"].join("/");
    const requestBody = {
        "firstname": customerScannedData.firstname,
        "surname": customerScannedData.surname,
        "id_number": customerScannedData.id_number,
        "date_of_birth": customerScannedData.date_of_birth
    }

    // console.log(requestBody)

    return await axios.post("/check-customer-existence", requestBody, { headers: { "Authorization": `Token ${process.env.REACT_APP_TOKEN}` }})
        .then(res => res.data)
        .catch(() => false);
}

export async function getDocLinks() {
    const url = [SERVER_URL,"docs"].join("/");
    return await axios.get("/docs",{ headers: { "Authorization": `Token ${process.env.REACT_APP_TOKEN}` }})
        .then(res => {
            return res.data
        })
        .catch(err => {
            console.error(err);
            return {}
        });
}
export async function amendDocuments(accountNumber, listOfDocumentsObject) {
    try {
        const data = {
            account_number: accountNumber,
            type: "missing-details",
            status: 1,
            missing_details: listOfDocumentsObject
        }
        return await axios.post("/documents/amend", data)
            .then((r) => {
                console.dir(r);
                return r.data.status;
            })
            .catch((err) => {
                console.error(err);
                return false;
            })
    } catch (e) {
        console.error(e);
    }
    return false;
}

export async function amendDocumentsWithFileUpload(accountNumber, listOfDocumentsObject, fileList, fileNames) {
    try {
        const formData = new FormData();
        formData.append('account_number', accountNumber);
        formData.append('type', 'missing-details');
        formData.append('status', "1");
        formData.append('missing_details', listOfDocumentsObject);
        formData.append("file_names", JSON.stringify(fileNames));

        for (let i = 0; i < fileList.length; i++) {
            formData.append('files', fileList[i]);
        }

        const axiosInstance = axios.create();

        const response = await axiosInstance.post('/documents/amend-with-files', formData);
        return response.data.status;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export async function saveAndPreviewDoc (file) {
    const formData = new FormData();
    formData.append("file", file);
    const axiosInstance = axios.create();

    const response = await axiosInstance.post('http://localhost:6712/save-and-preview-doc', formData);
    return response.data;
}

export async function fetchAccountDocumentsToAmend(accountNumber) {
    try {
        const data = {
            account_number: accountNumber,
            type: "get",
            status: 0
        }

        return await axios.post("/documents/amend", data)
            .then(r => {
                const keys = Object.keys(r.data);
                let docs = [];

                if (keys.includes("missing_details")) {
                    docs = r.data["missing_details"].reduce((accumulator, md) => {
                        if (md.remark === documentResubmissionRemarks.unclear) {
                            accumulator.push({ name: md.name, remark: "not clear", readyForUpload: false });
                        } else if (md.remark === documentResubmissionRemarks.missing) {
                            accumulator.push({ name: md.name, remark: "missing", readyForUpload: false });
                        } else if (md.remark === documentResubmissionRemarks.incorrect) {
                            accumulator.push({ name: md.name, remark: "incorrect", readyForUpload: false });
                        } else if (md.remark === documentResubmissionRemarks.expired) {
                            accumulator.push({ name: md.name, remark: "expired", readyForUpload: false });
                        }
                        return accumulator;
                    }, []);
                }

                return docs;
                // { name: doc, remark: "not clear", readyForUpload: false }
            })
    } catch (err) {
        console.error(err);
    }
    const d = [
        {name:"income_proof_url", readyForUpload: false, remark: "missing"}
    ]
    return d;
}

export async function accountCheck(accountNumber) {
    return await axios.get(`/account/find/${accountNumber}`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            return false
        });
}

export async function resolveAndGetImageFileBase64(file) {
    if (file === null || file === undefined) {
        return null;
    }

    const { type } = file;

    if (!type.startsWith('image')) {
        return await getBase64(file)
            .then(r => r)
            .catch(e => {
                console.error(e);
                return ""
            });
    }

    try {
        const formData = new FormData();
        formData.append('file', file);

        const axiosInstance = axios.create();

        return await axiosInstance.post('/file/quality/resolve', formData)
            .then((r) => r.data)
            .catch((err) => {
                console.log(err);
                return null;
            })
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const fetchCities = async () => {
    try {
        const response = await axios.get('/cities');
        return getUniqueList(response.data).sort();
    } catch (error) {
        console.error('Error fetching cities:', error);
        return [];
    }
};


/**
 * Returns whether the given date is expired or not (Compared to today). If less than today, it is expired otherwise it is not
 * @param expiryDate Must be in the format "YYYY-MM-DD"
 * @returns boolean
 */
export function isExpired(expiryDate) {
    const today = new Date();
    const docExpiryDate = new Date(expiryDate);
    return docExpiryDate < today;
}


export function isAdult(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();

    // If the current month is before the birth month or if it's the birth month but the current day is before the birth day
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
        age--;
    }

    console.log(age)
    return age >= 18;
}


export const replaceUnderscoreAndRemoveURL = (str) => {
    let result = '';
    let isInsideUrl = false;

    for (let i = 0; i < str.length; i++) {
        if (str[i] === '_') {
            result += ' '; // Replace underscore with space
            continue;
        }

        if (str.substring(i, i + 3).toLowerCase() === 'url') {
            i += 2; // Skip "url" substring
            isInsideUrl = true;
            continue;
        }

        if (isInsideUrl && (str[i] === 'u' || str[i] === 'r' || str[i] === 'l')) {
            isInsideUrl = false; // Reset isInsideUrl flag
            continue;
        }

        result += str[i];
        isInsideUrl = false;
    }

    return result;
}

export async function uploadFileWithNameObject(fileList, dataObject) {
    try {
        const formData = new FormData();
        formData.append("data", JSON.stringify(dataObject));
        for (let i = 0; i < fileList.length; i++) {
            formData.append('files', fileList[i]);
        }

        const axiosInstance = axios.create();

        const response = await axiosInstance.post('/documents/upload-with-name-object', formData);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}
