import React,{ useState,memo } from 'react';
import { Button } from 'react-bootstrap';
const CustomButton=(props)=>{
    const {label,updateCurrentPage,disableButton,next,className,btnColor}=props;
    const [isLoading, setLoading] = useState(false);
    let color="#ed1b2e";
    let class_name="rounded-pill btn-light mx-auto d-block";
    if (btnColor!==undefined)color=props.btnColor;
    if (className!==undefined)class_name=className;
    const handleClick = () => {
        setLoading(false);
        updateCurrentPage(next)
    };
    return(
        <Button   
        className ={class_name}
        disabled={disableButton}
        style={{backgroundColor:color, color:"white"}}
        onClick={!isLoading ? handleClick : null}>{isLoading ? 'Loading…' : label}</Button>
    );
}

export default memo(CustomButton);