import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
export const AlertModal = ({ open, onClose, message }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{
                    color: '#ed1b2e', // White text color
                    textTransform: 'none',
                }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
