import React from 'react';
const CustomRadioButton=({label,checked,changeNeedCardSatatus,name})=>{
    const handleClick = (val) => {
            changeNeedCardSatatus(val.target.value)
    };
    let val=(
        <label style={{ borderColor: "#2b357c" }} className="w3-bar w3-text-indigo w3-round-xxlarge btn input-radio" role="button">
            <input type='radio' onClick={handleClick} value={label} name={name}/>
            {label}
        </label>
    );
    if (checked==="true")
        val=(
            <label style={{ borderColor: "#2b357c" }} className="w3-bar w3-text-indigo w3-round-xxlarge btn input-radio" role="button">
                <input type='radio' onClick={handleClick} value={label} name={name} defaultChecked/>
                {label}
            </label>
        );
    return val;
}
export default React.memo(CustomRadioButton);