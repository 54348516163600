import {FormControlLabel, Radio} from "@mui/material";

export const CustomNBSRadioButton = ({ label, name, changeNeedCardStatus, checked }) => (
    <FormControlLabel
        value={label}
        control={
            <Radio
                checked={checked}
                onChange={changeNeedCardStatus}
                sx={{
                    color: '#2b357c',
                    '&.Mui-checked': {
                        color: '#ed1b2e',
                    },
                }}
            />
        }
        label={label}
        name={name}
        sx={{
            color: '#2b357c',
            '& .MuiTypography-root': {
                fontWeight: 'bold',
            },
        }}
    />
);
