import React, {useState} from "react";
import axios from "axios";
import {CustomStyle} from "../../helpers/styles";
import {Typography, Alert, CircularProgress} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import {CustomNBSTextField} from "../custom-componets/CustomNBSTextField";

export const AccountNumberFD = ({updateCurrentPage, fixedDepositDetails, setFixedDepositDetails}) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [accountInfo, setAccountInfo] = useState(null); // Store the retrieved account information
    const [formData, setFormData] = useState({
        accountNumber: "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    console.log(fixedDepositDetails)
    const handleSearch = async () => {
        const accountNumber = formData.accountNumber.trim();

        if (accountNumber === "") {
            setMessage("Please provide an account number.");
            return;
        }
        if (!/^\d+$/.test(accountNumber)) {
            setMessage("Account number must contain only numbers.");
            return;
        }
        if (accountNumber.length < 8) {
            setMessage("Account number must be at least 8 digits long.");
            return;
        }
        setLoading(true);
        setMessage(null);

        try {
            // Make an API request to retrieve account information
            const response = await axios.post("/second-account", {
                account_number: formData.accountNumber,
            });

            if (response.data.status) {
                const {postNoDebit, postNoCredit, kycCompliant} = response.data;

                if (postNoDebit || postNoCredit || !kycCompliant) {
                    setMessage("Dear customer, please visit the branch to update your details.");
                } else {
                    setAccountInfo(response.data);
                    setFixedDepositDetails({
                        ...response.data,
                        account_number: formData.accountNumber,
                    });
                    updateCurrentPage("ScannerComponent");
                }
            } else {
                setMessage("Account information not found.");
            }
        } catch (error) {
            console.error("Error fetching account information:", error);
            setMessage("An error occurred while fetching account information.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>

                    Please provide your Existing NBS Bank Account Number below:
                </Typography>

                <CustomNBSTextField
                    label="Account Number *"
                    onInputChange={handleChange}
                    // error={Boolean(formErrors.kin_fullname)}
                    value={formData.accountNumber || ''}
                    inputType="text"
                    name="accountNumber"
                    required="true"
                />

                {message && (
                    <Alert severity="error" style={CustomStyle.alert}>
                        {message}
                    </Alert>
                )}

                <div style={CustomStyle.buttonContainer}>
                <CustomNBSRedButton onClick={handleSearch}
                                    disabled={loading}>
                    {loading ? <CircularProgress color="inherit" size={24}/> : 'Search'}
                </CustomNBSRedButton>
                </div>


            </div>
        </div>

    );
};
