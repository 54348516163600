import React from 'react'
import {Card, CardContent, Typography} from "@mui/material";
const PicturesComponets = ({customerObject}) =>{
    const { account_product, temp } = customerObject.customerDetails;
    const images = [];

    // Define a function to create image sections
    const addImageSection = (label, src) => {
        if (src) {
            images.push(
                <Card
                    key={label}
                    sx={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        border: '2px solid #2b357c',
                        mb: 2,
                        maxWidth: '100%',
                    }}
                >
                    <CardContent sx={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" sx={{ mb: 1, color: '#2b357c', fontWeight: 'bold' }}>
                            {label}
                        </Typography>
                        <img
                            src={src}
                            alt={label}
                            style={{ border: '4px solid #ed1b2e', borderRadius: '8px', maxWidth: '100%', height: 'auto' }}
                        />
                    </CardContent>
                </Card>
            );
        }
    };

    addImageSection('Proof of Income', temp.IncomeProofPic);
    addImageSection('Your Photo', temp.customer_picture);
    addImageSection('National ID Photo', temp.id_scanned_copy);

    if (account_product === 'student') {
        addImageSection('Student ID', temp.StudentIDPhoto);
        addImageSection('Home Location', temp.location);
        addImageSection('Institution Letter/Student ID', temp.institution_letter);
    } else if (account_product === 'PafupiUnbundled') {
        addImageSection('Home Location', temp.location);
    } else {
        addImageSection('Utility Bill', temp.utility_bill_url);
        if (!/Self-employed/ig.test(customerObject.customerDetails.employment_status)) {
            addImageSection('Proof of Income', temp.IncomeProofPic);
        }
        addImageSection('Home Location', temp.location);
    }

    return (
        <div>
            <Typography variant="h6" sx={{ mb: 3, textAlign: 'center', color: '#2b357c', fontWeight: 'bold' }}>
                Below are your Images
            </Typography>
            {images}
        </div>
    );
}
export default React.memo(PicturesComponets)