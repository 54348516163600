import React from 'react'
import {CustomStyle} from "../../helpers/styles";
import {Box, Typography} from "@mui/material";
import logo from '../../assets/images/logo_nav.png';
const CurrentBundledServicesComponent=React.memo(()=>{
    const services = [
        'SMS Alerts',
        'Eazy Mobile transactions',
        'Free Internet banking',
        'Free Eazyapp',
        'Unlimited Free ATM withdrawals',
        'Cheque Deposit'
    ];

    return(
        <Typography style={CustomStyle.contentText}>
            {services.map((service, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                    <Box
                        component="img"
                        src={logo}
                        alt="Logo"
                        sx={{ width: 20, borderRadius: '50%' }}
                    />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                        {service}
                    </Typography>
                </Box>
            ))}
        </Typography>
    );
})
export default CurrentBundledServicesComponent;