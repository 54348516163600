import { createSlice } from '@reduxjs/toolkit'

export const Data = createSlice({
  name: 'data',
  initialState: {
    value: {"employment":"Self-employed","post_status":{"status":false,"msg":""}},
  },
  reducers: {
    setPostStatus: (state, action) => {
      console.log(action.payload,"post_status")
      state.value.events = {...state.value.post_status,...action.payload}
    },
    setEmployment: (state, action) => {
      console.log(action.payload,"employment")
      state.value.employment = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setEmployment,setPostStatus } = Data.actions

export default Data.reducer