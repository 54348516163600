import KEY_FACT_STATEMENT_DIGITAL_1 from "../assets/pdf-as-images/Key_fact_statement_DIGITAL/1.jpg";
import KEY_FACT_STATEMENT_DIGITAL_2 from "../assets/pdf-as-images/Key_fact_statement_DIGITAL/2.jpg";

import KEY_FACT_STATEMENT_EAZYSAVE_1 from "../assets/pdf-as-images/Key_fact_statement_EAZYSAVE/1.jpg";
import KEY_FACT_STATEMENT_EAZYSAVE_2 from "../assets/pdf-as-images/Key_fact_statement_EAZYSAVE/2.jpg";

import KEY_FACT_STATEMENT_EAZYTRANSACT_1 from "../assets/pdf-as-images/Key_fact_statement_EAZYTRANSACT/1.jpg";
import KEY_FACT_STATEMENT_EAZYTRANSACT_2 from "../assets/pdf-as-images/Key_fact_statement_EAZYTRANSACT/2.jpg";

import KEY_FACT_STATEMENT_FOR_DIASPORA_INVESTMENT_ACCOUNT_1 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_DIASPORA_INVESTIMENT_ACCOUNT/1.jpg";
import KEY_FACT_STATEMENT_FOR_DIASPORA_INVESTMENT_ACCOUNT_2 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_DIASPORA_INVESTIMENT_ACCOUNT/2.jpg";

import KEY_FACT_STATEMENT_FOR_DIASPORA_TRANSACTIONAL_SAVINGS_ACCOUNT_1 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_DIASPORA_TRANSACTIONAL_SAVINGS_ACCOUNT/1.jpg";
import KEY_FACT_STATEMENT_FOR_DIASPORA_TRANSACTIONAL_SAVINGS_ACCOUNT_2 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_DIASPORA_TRANSACTIONAL_SAVINGS_ACCOUNT/2.jpg";

import KEY_FACT_STATEMENT_PAFUPI_AND_PAFUPI_PLUS_1 from "../assets/pdf-as-images/Key_fact_statement_PAFUPI_AND_PAFUPI_PLUS/1.jpg";
import KEY_FACT_STATEMENT_PAFUPI_AND_PAFUPI_PLUS_2 from "../assets/pdf-as-images/Key_fact_statement_PAFUPI_AND_PAFUPI_PLUS/2.jpg";

import KEY_FACT_STATEMENT_FOR_SILVER_BUNDLED_AND_UNBUNDLED_CURRENT_ACCOUNT_1 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_SILVER_BUNDLED_AND_UNBUNDLED_CURRENT_ACCOUNT/1.jpg";
import KEY_FACT_STATEMENT_FOR_SILVER_BUNDLED_AND_UNBUNDLED_CURRENT_ACCOUNT_2 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_SILVER_BUNDLED_AND_UNBUNDLED_CURRENT_ACCOUNT/2.jpg";
//import KEY_FACT_STATEMENT_FOR_SILVER_BUNDLED_AND_UNBUNDLED_CURRENT_ACCOUNT_3 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_SILVER_BUNDLED_AND_UNBUNDLED_CURRENT_ACCOUNT/3.jpg";

import KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_1 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS/1.jpg";
import KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_2 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS/2.jpg";
import KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_3 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS/3.jpg";
import KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_4 from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS/4.jpg";

import KEY_FACT_STATEMENT_YAAZA_STUDENT_1 from "../assets/pdf-as-images/Key_fact_statement_YAAZA_STUDENT/1.jpg";
//import KEY_FACT_STATEMENT_YAAZA_STUDENT_2 from "../assets/pdf-as-images/Key_fact_statement_YAAZA_STUDENT/2.jpg";

import KEY_FACT_STATEMENT_FIXED_DEPOSIT_ACCOUNT from "../assets/pdf-as-images/KEY_FACT_STATEMENT_FOR_FIXED_DEPOSIT_ACCOUNT/1.jpg";

import TERMS_AND_CONDITIONS_1 from "../assets/pdf-as-images/NBS_BANK_PLC_ACCOUNT_TERMS_&_CONDITIONS/1.jpg";
import TERMS_AND_CONDITIONS_2 from "../assets/pdf-as-images/NBS_BANK_PLC_ACCOUNT_TERMS_&_CONDITIONS/2.jpg";
import TERMS_AND_CONDITIONS_3 from "../assets/pdf-as-images/NBS_BANK_PLC_ACCOUNT_TERMS_&_CONDITIONS/3.jpg";

export const documentCodes = () => {
    return {
        digital_terms_and_conditions: "digital_terms_and_conditions",
        eazy_save: "eazy_save",
        eazy_transact: "eazy_transact",
        diaspora_investment: "diaspora_investment",
        diaspora_transactional_savings: "diaspora_transactional_savings",
        pafupi_and_pafupi_plus: "pafupi_and_pafupi_plus",
        student_yaaza: "student_yaaza",
        terms_and_conditions: "terms_and_conditions",
        current_silver: "current_silver",
        current_gold: "current_gold",
        current_platinum: "current_gold",
        fixed_deposit: "fixed_deposit",

    }
}

export const documents = () => {

    return {
        [`${documentCodes().digital_terms_and_conditions}`]: [KEY_FACT_STATEMENT_DIGITAL_1, KEY_FACT_STATEMENT_DIGITAL_2],
        [`${documentCodes().eazy_save}`]: [KEY_FACT_STATEMENT_EAZYSAVE_1, KEY_FACT_STATEMENT_EAZYSAVE_2],
        [`${documentCodes().eazy_transact}`]: [KEY_FACT_STATEMENT_EAZYTRANSACT_1, KEY_FACT_STATEMENT_EAZYTRANSACT_2],
        [`${documentCodes().diaspora_investment}`]: [KEY_FACT_STATEMENT_FOR_DIASPORA_INVESTMENT_ACCOUNT_1, KEY_FACT_STATEMENT_FOR_DIASPORA_INVESTMENT_ACCOUNT_2],
        [`${documentCodes().diaspora_transactional_savings}`]: [KEY_FACT_STATEMENT_FOR_DIASPORA_TRANSACTIONAL_SAVINGS_ACCOUNT_1, KEY_FACT_STATEMENT_FOR_DIASPORA_TRANSACTIONAL_SAVINGS_ACCOUNT_2],
        [`${documentCodes().pafupi_and_pafupi_plus}`]: [KEY_FACT_STATEMENT_PAFUPI_AND_PAFUPI_PLUS_1, KEY_FACT_STATEMENT_PAFUPI_AND_PAFUPI_PLUS_2],
        [`${documentCodes().student_yaaza}`]: [KEY_FACT_STATEMENT_YAAZA_STUDENT_1],
        [`${documentCodes().terms_and_conditions}`]: [TERMS_AND_CONDITIONS_1, TERMS_AND_CONDITIONS_2, TERMS_AND_CONDITIONS_3],
        [`${documentCodes().current_silver}`]: [KEY_FACT_STATEMENT_FOR_SILVER_BUNDLED_AND_UNBUNDLED_CURRENT_ACCOUNT_1, KEY_FACT_STATEMENT_FOR_SILVER_BUNDLED_AND_UNBUNDLED_CURRENT_ACCOUNT_2],
        [`${documentCodes().current_gold}`]: [KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_1, KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_2, KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_3, KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_4],
        [`${documentCodes().current_platinum}`]: [KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_1, KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_2, KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_3, KEY_FACT_STATEMENT_FOR_GOLD_AND_PLATINUM_ACCOUNTS_4],
        [`${documentCodes().fixed_deposit}`]: [KEY_FACT_STATEMENT_FIXED_DEPOSIT_ACCOUNT]

    }
}