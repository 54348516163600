import React from 'react';
import {useState, useEffect} from 'react';
import {Box, FormControl, FormControlLabel, Radio, Typography} from "@mui/material";
import {RadioGroup} from "react-radio-buttons";
import {CustomNBSTextField} from "./custom-componets/CustomNBSTextField";
import {CustomStyle} from "../helpers/styles";
import {CustomNBSRadioButton} from "./custom-componets/CustomNBSRadioButton";

const Atm = (props) => {
    const {setCustomerDetails, customerDetails} = props.customerDetailsObject
    const {setDisableButton} = props;
    const [needCard, setNeedCard] = useState(customerDetails.atm_card === 1);
    const [secretQuestion, setSecretQuestion] = useState(customerDetails.atm_secret_question);
    const [secretAnswer, setSecretAnswer] = useState(customerDetails.atm_secret_answer);
    const changeNeedCardStatus = (event) => {
        const value = event.target.value;
        const cardRequired = value === 'Yes';
        setNeedCard(cardRequired);
        if (!cardRequired) {
            setDisableButton(false); // Enable button when "No" is selected
        } else {
            setDisableButton(true); // Disable button initially when "Yes" is selected
        }
    };

    const changeSecretQuestion = (event) => {
        setSecretQuestion(event.target.value);
    };

    const changeSecretAnswer = (event) => {
        setSecretAnswer(event.target.value);
    };
    useEffect(() => {
        if (needCard) {
            // Only enable the button when both secret question and answer are provided
            if (secretQuestion.trim() !== '' && secretAnswer.trim() !== '') {
                setCustomerDetails((prev) => ({
                    ...prev,
                    atm_secret_question: secretQuestion,
                    atm_secret_answer: secretAnswer,
                    atm_card: 1,
                }));
                setDisableButton(false);
            } else {
                setDisableButton(true);
            }
        } else {
            // If "No" is selected, reset secret question and answer and enable the button
            setCustomerDetails((prev) => ({
                ...prev,
                atm_secret_question: '',
                atm_secret_answer: '',
                atm_card: 0,
            }));
            setDisableButton(false);
        }
    }, [secretAnswer, secretQuestion, needCard, setCustomerDetails, setDisableButton]);


    return (
        <div>
            <FormControl component="fieldset">
                <Typography variant="h6" style={{
                    ...CustomStyle.subtitle,
                    textAlign: 'left',
                    fontSize: '17px',
                    marginBottom: '10px',
                    fontWeight: 'bold'
                }}>
                    Do you need an ATM card?
                </Typography>

                <RadioGroup
                    value={needCard ? 'Yes' : 'No'}
                    onChange={changeNeedCardStatus}
                    sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2}}
                >
                    {/*<CustomNBSRadioButton*/}
                    {/*    label="No"*/}
                    {/*    name="internet_banking"*/}
                    {/*    changeNeedCardStatus={handleChange}*/}
                    {/*    checked={selectedOptions.internet_banking === 'No'}*/}
                    {/*/>*/}
                    <CustomNBSRadioButton
                        label="Yes"
                        value="Yes"
                        checked={needCard}
                    />
                    <CustomNBSRadioButton
                        label="No"
                        value="No"
                        checked={!needCard}
                    />
                </RadioGroup>
            </FormControl>

            {needCard && (
                <>
                    <CustomNBSTextField
                        label="Secret Question*"
                        value={secretQuestion}
                        onInputChange={changeSecretQuestion}
                        required="true"
                        fullWidth
                    />

                    <CustomNBSTextField
                        label="Secret Answer *"
                        value={secretAnswer}
                        onInputChange={changeSecretAnswer}
                        required="true"
                        fullWidth
                    />
                </>
            )}
        </div>
    );
}
export default React.memo(Atm);