import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import debounce from 'lodash.debounce';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        border: '2px solid #2b357c', // NBS Bank blue
        borderRadius: '5px',
        padding: '10px 12px',
        fontSize: '16px',
        '&.Mui-focused': {
            borderColor: '#ed1b2e', // NBS Bank red on focus
            boxShadow: '0 0 0 1px #ed1b2e',
        },
        '&:hover': {
            borderColor: '#ed1b2e', // NBS Bank red on hover
        },
    },
    '& .MuiInputBase-input': {
        padding: '10px 12px',
    },
}));

const VirtualizedAutocomplete = ({ label, itemsList, onChangeCallback }) => {
    const [inputValue, setInputValue] = useState('');
    const debouncedCityChange = useRef(debounce(onChangeCallback, 300)).current;
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [, setLoadedOptions] = useState([]);

    useEffect(() => {
        // Load initial options
        const initialItems = itemsList.slice(0, 100); // Adjust the number of items to load initially as needed
        setFilteredOptions(initialItems);
        setLoadedOptions(initialItems);
    }, [itemsList]);

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        debouncedCityChange(newInputValue);
    };

    useEffect(() => {
        // Filter options based on input
        if (itemsList.length > 0) {
            const filteredItems = itemsList.filter((item) =>
                item.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredOptions(filteredItems.slice(0, 100)); // Load a subset of filtered options
        }

    }, [inputValue, itemsList]);

    return (
        <Autocomplete
            freeSolo
            loadingText="Loading..."
            disablePortal
            noOptionsText="No match"
            id="list-of-cities"
            options={filteredOptions}
            getOptionLabel={(option) => option}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            // renderInput={(params) => <TextField {...params} label={label} />}
            disableClearable="true"
            renderInput={(params) => (
                <StyledTextField
                    required
                    {...params}
                    placeholder={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                                {inputValue && (
                                    <ClearIcon
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setInputValue('')}
                                    />
                                )}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default VirtualizedAutocomplete;
