import React, { memo, useState } from 'react';
import { Container, Accordion, Col, Row } from 'react-bootstrap';
// import { Transactional } from './accounts/Transactional';
import { diasporaAccountsKeyFacts as keyFacts } from '../Fees';
import { Overview as AccountsOverview } from './accounts/Overview';
import { Requirements as AccountOpeningRequirements } from './accounts/Requirements';

export const DiasporaAccountKeyFacts = memo(({ updateCurrentPage, setCustomerDetails }) => {
    //TODO:: Create dynamic list 
    const goToCustomerDetails = () => { 
        setCustomerDetails((prev) => {
            return ({ ...prev, malawian:1, atm_card:1, eazy_app:1, internet_banking:1});
        });
        updateCurrentPage("PassportReader");
    }

    return (
        <Container fluid>
            <div className="mt-4 p-5 rounded">
                {/* <h1 style={{ ...style.centerItem, ...style.heading }}></h1> */}
                {/* <hr/> */}
            </div>

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Accounts Overview</Accordion.Header>
                    <Accordion.Body>
                        <AccountsOverview />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Requirements</Accordion.Header>
                    <Accordion.Body>
                        <AccountOpeningRequirements />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Row>
                <Col>
                    <a className="container mt-3 w3-bar w3-hover-red  w3-round-xxlarge btn d-flex" onClick={goToCustomerDetails}>
                        <p className="float-start w3-class-label">Next</p>
                    </a>
                </Col>
            </Row>
            {/* 
            <header>
                <div className="p-5 bg-light mb-1">
                    <h1 className="">Diaspora Accounts</h1>

                    <nav className="d-flex">
                        <h6 className="mb-0">
                            <a href="" className="text-reset">Overview</a>
                            <span>/</span>
                            <a href="" className="text-reset">Requirements</a>
                        </h6>
                    </nav>

                </div>
            </header> */}

            {/* <div>
                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    fill>
                    <Tab eventKey="transactional" title="Transactional Account">
                        <Transactional/>
                    </Tab>
                    <Tab eventKey="investment" title="Investment Account">
                        <h4>Investment Account</h4>
                    </Tab>
                </Tabs>
            </div> */}

        </Container>
    );
})