import React, {useEffect, useState} from "react";
import axios from "axios";
import {FaExclamationTriangle} from "react-icons/fa";
import CircularProgress from '@mui/material/CircularProgress';
import {customSelectStyles, CustomStyle} from "../../helpers/styles";
import {FormControl, Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import {CheckCircleOutline} from "@mui/icons-material";
import Select from "react-select";

export const FixedAccountsList = (({updateCurrentPage, fixedDepositDetails, setFixedDepositDetails}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        performAPICall();
    }, []);

    const performAPICall = async () => {
        const requestBody = {
            account_number: fixedDepositDetails.account_number,
            request_type: 'FIXED_ACCOUNTS',
        };

        const url = '/get-customer-fixed-accounts';
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const result = await axios.post(url, requestBody, config);

            if (result.data.status) {
                const accounts = result.data.data;
                const accountOptions = accounts.map(account => ({
                    value: account.ACCNO,
                    label: account.ACCNO,
                }));
                setOptions(accountOptions);
            } else {
                setErrorMessage('Error fetching fixed deposit accounts.');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (!selectedOption) {
            setErrorMessage('Please select a Fixed Deposit account to proceed.');
            return;
        }
        console.log(selectedOption)
        setFixedDepositDetails({
            ...fixedDepositDetails,
            selectedOption: selectedOption.value
        })

        updateCurrentPage('FixedAccountDetails');

    };

    const goToHome = () => {
        document.location.reload();
        updateCurrentPage('HomePage');
    };

    const handleChange = (event) => {
        if (event.target.name === 'accountType') {
            setSelectedOption(event.target.value);
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <>

                    <CircularProgress size={50}/>
                    <Typography variant="h5" sx={CustomStyle.heading}>Please wait...</Typography>
                </>
            );
        }

        if (options.length === 0) {
            return (
                <>
                    <div className="text-center" style={{fontSize: "30px", color: "#ed1b2e"}}>
                        <FaExclamationTriangle/>
                    </div>
                    <Typography variant="h5" sx={CustomStyle.heading}>
                        Dear Customer, You do not have fixed deposit accounts.
                    </Typography>
                    <div style={CustomStyle.buttonContainer}>

                        <CustomNBSRedButton onClick={goToHome}
                                            disabled={false}>
                            Home
                        </CustomNBSRedButton>
                    </div>

                </>
            );
        }

        if (isSuccess) {
            return (
                <>

                    <CheckCircleOutline style={{fontSize: 80, color: 'green'}}/>
                    <Typography variant="h5" sx={CustomStyle.subtitle}>
                        Fixed account has been closed successfully.
                    </Typography>

                    <div style={CustomStyle.buttonContainer}>

                        <CustomNBSRedButton onClick={goToHome}
                                            disabled={false}>
                            Home
                        </CustomNBSRedButton>
                    </div>
                </>
            );
        }

        if (errorMessage) {
            return (
                <>
                    <div className="text-center" style={{fontSize: "30px", color: "#ed1b2e"}}>
                        <FaExclamationTriangle/>
                    </div>
                    <Typography variant="h5" sx={CustomStyle.subtitle}>
                        {errorMessage}</Typography>

                    <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton onClick={goToHome}
                                            disabled={false}>
                            Home
                        </CustomNBSRedButton>
                    </div>
                </>
            );
        }


        return (
            <>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Please select Fixed Deposit account below:
                </Typography>

                <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                    <Typography variant="h6" sx={{
                        ...CustomStyle.subtitle,
                        textAlign: 'left',
                        fontSize: '16px',
                        marginBottom: '10px'
                    }}>
                        Fixed Deposit Accounts Available *
                    </Typography>
                    <Select
                        options={options}
                        name="accountType"
                        value={selectedOption} // Ensure correct value selection
                        onChange={setSelectedOption}
                        styles={customSelectStyles}
                        placeholder="Select Fixed Account"
                        isClearable
                        required
                    />
                </FormControl>

                <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={handleSubmit}
                                        disabled={false}>
                        {loading ? 'Closing...' : 'Proceed'}
                    </CustomNBSRedButton>
                </div>
            </>
        );
    };
    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                {renderContent()}
            </div>
        </div>
    )

})
