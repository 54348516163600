import React from 'react';
import {
    Box,
    Typography
} from '@mui/material';
import {eazySaveBundled, eazyTransactBundled, student} from './Fees';

const MonthlyFees = ({ product }) => {
    let fees;

    // Determine which fees to show based on the product
    switch (product) {
        case 'EazySave':
            fees = eazySaveBundled;
            break;
        case 'EazyTransact':
            fees = eazyTransactBundled;
            break;
        case 'student':
            fees = student;
            break;
        default:
            fees = {};
            break;
    }

    const keys = Object.keys(fees);

    return (

         <Box sx={{ mt: 2 }}>
            {keys.map((key) => (
                <Box key={key} sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 2,
                    alignItems: 'center',
                    mb: 1,
                    borderBottom: '1px solid #e0e0e0',
                    padding: '8px 0',
                }}>
                    <Typography variant="body2"
                                sx={{ color: '#2b357c',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    wordWrap: 'break-word'
                    }}>
                        {key.replace(/_/g, ' ').toUpperCase()}:
                    </Typography>
                    <Typography variant="body2" sx={{
                        color: '#2b357c',
                        textAlign: 'right',
                        wordWrap: 'break-word',
                    }}>
                        {fees[key]}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
}
export default MonthlyFees;
