import axios from 'axios';
import {removeTempKeyFromCustomerProfileIfExists} from "../helpers/Utils";
// const SERVER_URL = [
//     process.env.REACT_APP_SERVER_HOST,
//     Number(process.env.REACT_APP_SERVER_PORT)
// ].join(":")
export async function submitCustomerDetails(customerDetails) {
    // const url = [SERVER_URL,"submit-customer-data"].join("/");
    return await axios.post("/submit-customer-data", customerDetails, { headers: { "Authorization": `Token ${process.env.REACT_APP_TOKEN}` }})
        .then(res => res)
        .catch(err => err);
}

export async function submitCustomerDetailsWithoutFiles(customerDetails) {
    // const url = [SERVER_URL,"submit-customer-data"].join("/");

    customerDetails = removeTempKeyFromCustomerProfileIfExists(customerDetails);

    //Since these fields are treated somehow interchangeably by the backend, we want to be just sure they all hold similar values.
    if (!customerDetails.id_scanned_copy_url && customerDetails.id_scanned_copy) {
        customerDetails.id_scanned_copy_url = customerDetails.id_scanned_copy;
    }
    if (!customerDetails.signature_photo_url && customerDetails.signature) {
        customerDetails.signature_photo_url = customerDetails.signature;
    }
    if (!customerDetails.utility_bill_pic && customerDetails.utility_bill_url) {
        customerDetails.utility_bill_pic = customerDetails.utility_bill_url;
    }
    if (!customerDetails.residential_map_url && customerDetails.location) {
        customerDetails.residential_map_url = customerDetails.location;
    }
    if (!customerDetails.customer_photo_url && customerDetails.customer_picture) {
        customerDetails.customer_photo_url = customerDetails.customer_picture;
    }
    if (!customerDetails.income_proof_url && customerDetails.IncomeProofPic) {
        customerDetails.income_proof_url = customerDetails.IncomeProofPic;
    }

    return await axios.post("/submit-customer-data-without-files", customerDetails, { headers: { "Authorization": `Token ${process.env.REACT_APP_TOKEN}` }})
        .then(res => res)
        .catch(err => err);
}