import React, { memo, useState } from 'react';
import { diasporaAccountsKeyFacts } from '../Fees';
import CustomButton from "../custom-componets/Button";
import $ from 'jquery';

export const DiasporaServiceFees = memo(({ updateCurrentPage, setCustomerDetails }) => {
    const nextPage = "DiasporaCustomerDetails";
    const [accountSelectLabel, setAccountSelectLabel] = useState("");

    const savingsAccount = diasporaAccountsKeyFacts.accountTypes.savings;
    const investmentAccount = diasporaAccountsKeyFacts.accountTypes.investment;

    //Consult on what are the correct/right account products for both savings and investment accounts
    const accountType = {
        SAVINGS: "DIASPORA SAVINGS",
        INVESTMENT: "DIASPORA INVESTMENT"
    }

    const handleOnRadioChecked = (v) => {
        let updateDataValues = { account_product: '' }
        if (v.target.value === "savingsAccount") {
            updateDataValues.account_product = accountType.SAVINGS;
        } else if (v.target.value === "investmentAccount") {
            updateDataValues.account_product = accountType.INVESTMENT;
        }

        setCustomerDetails((prev) => ({ ...prev, ...updateDataValues }));
        setAccountSelectLabel(updateDataValues.account_product);
        $('#accountSelectionBtn').show();
    }


    // TODO:: Set saving and investment details as separate component
    const mainElement = (
        <table className="table table-sm table-bordered">
            <thead>
                <tr>
                    <th scope="col" className="col-md-2">Product Description</th>
                    <th className="col-md-5">
                        <label>
                            <input type="radio" onClick={handleOnRadioChecked} name="colorRadio" value="savingsAccount" />
                            Diaspora Savings Account
                        </label>
                    </th>
                    <th className="col-md-5">
                        <label>
                            <input type="radio" onClick={handleOnRadioChecked} name="colorRadio" value="investmentAccount" />
                            Diaspora Investment Account
                        </label>
                    </th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                {/* Interest Rates */}
                <tr className="unhovarable">
                    <td scope="row">{savingsAccount.interestRates.description}</td>
                    {/* Savings */}
                    <td>
                        {/* Dollar Account */}
                        <div style={{ marginBottom: '-1rem' }}>
                            <div><span style={{ fontWeight: 'bold' }}>{savingsAccount.interestRates.dollarAccount.title}</span></div>
                            <div><small>{savingsAccount.interestRates.dollarAccount.LOWER}</small></div>
                            <div><small>{savingsAccount.interestRates.dollarAccount.MIDDLE}</small></div>
                            <div><small>{savingsAccount.interestRates.dollarAccount.UPPER}</small></div>
                        </div>
                        <hr />
                        {/* Pound Account */}
                        <div style={{ marginBottom: '-1rem', marginTop: '-1rem' }}>
                            <div><span style={{ fontWeight: 'bold' }}>{savingsAccount.interestRates.poundAccount.title}</span></div>
                            <div><small>{savingsAccount.interestRates.poundAccount.LOWER}</small></div>
                            <div><small>{savingsAccount.interestRates.poundAccount.MIDDLE}</small></div>
                            <div><small>{savingsAccount.interestRates.poundAccount.UPPER}</small></div>
                        </div>
                        <hr />

                        {/* South African Rand Account */}
                        <div style={{ marginBottom: '-1rem', marginTop: '-1rem' }}>
                            <div><span style={{ fontWeight: 'bold' }}>{savingsAccount.interestRates.southAfricanRandAccount.title}</span></div>
                            <div><small>{savingsAccount.interestRates.southAfricanRandAccount.LOWER}</small></div>
                            <div><small>{savingsAccount.interestRates.southAfricanRandAccount.MIDDLE}</small></div>
                            <div><small>{savingsAccount.interestRates.southAfricanRandAccount.UPPER}</small></div>
                        </div>
                        <hr />

                        {/* Euro Account */}
                        <div style={{ marginTop: '-1rem' }}>
                            <div><span style={{ fontWeight: 'bold' }}>{savingsAccount.interestRates.euroAccount.title}</span></div>
                            <div><small>{savingsAccount.interestRates.euroAccount.LOWER}</small></div>
                            <div><small>{savingsAccount.interestRates.euroAccount.MIDDLE}</small></div>
                            <div><small>{savingsAccount.interestRates.euroAccount.UPPER}</small></div>
                        </div>
                    </td>

                    {/* Investment */}
                    <td>
                        {/* Dollar Account */}
                        <div style={{ marginBottom: '-1rem' }}>
                            <div><span style={{ fontWeight: 'bold' }}>{investmentAccount.interestRates.dollarAccount.title}</span></div>
                            <div><small>{investmentAccount.interestRates.dollarAccount.LOWER}</small></div>
                            <div><small>{investmentAccount.interestRates.dollarAccount.MIDDLE}</small></div>
                            <div><small>{investmentAccount.interestRates.dollarAccount.UPPER}</small></div>
                        </div>
                        <hr />
                        {/* Pound Account */}
                        <div style={{ marginBottom: '-1rem', marginTop: '-1rem' }}>
                            <div><span style={{ fontWeight: 'bold' }}>{investmentAccount.interestRates.poundAccount.title}</span></div>
                            <div><small>{investmentAccount.interestRates.poundAccount.LOWER}</small></div>
                            <div><small>{investmentAccount.interestRates.poundAccount.MIDDLE}</small></div>
                            <div><small>{investmentAccount.interestRates.poundAccount.UPPER}</small></div>
                        </div>
                        <hr />

                        {/* South African Rand Account */}
                        <div style={{ marginBottom: '-1rem', marginTop: '-1rem' }}>
                            <div><span style={{ fontWeight: 'bold' }}>{investmentAccount.interestRates.southAfricanRandAccount.title}</span></div>
                            <div><small>{investmentAccount.interestRates.southAfricanRandAccount.LOWER}</small></div>
                            <div><small>{investmentAccount.interestRates.southAfricanRandAccount.MIDDLE}</small></div>
                            <div><small>{investmentAccount.interestRates.southAfricanRandAccount.UPPER}</small></div>
                        </div>
                        <hr />

                        {/* Euro Account */}
                        <div style={{ marginTop: '-1rem' }}>
                            <div><span style={{ fontWeight: 'bold' }}>{investmentAccount.interestRates.euroAccount.title}</span></div>
                            <div><small>{investmentAccount.interestRates.euroAccount.LOWER}</small></div>
                            <div><small>{investmentAccount.interestRates.euroAccount.MIDDLE}</small></div>
                            <div><small>{investmentAccount.interestRates.euroAccount.UPPER}</small></div>
                        </div>
                    </td>


                </tr>

                {/* Book Balance */}
                <tr className="unhovarable">
                    <td scope="row">{savingsAccount.bookBalance.description}</td>
                    <td>
                        <div><small>{savingsAccount.bookBalance.fees}</small></div>
                    </td>
                    <td>
                        <div><small>{investmentAccount.bookBalance.fees.kwacha}</small></div>
                        <div><small>{investmentAccount.bookBalance.fees.dollar}</small></div>
                        <div><small>{investmentAccount.bookBalance.fees.rand}</small></div>
                        <div><small>{investmentAccount.bookBalance.fees.euro}</small></div>
                        <div><small>{investmentAccount.bookBalance.fees.pound}</small></div>
                    </td>
                </tr>

                {/* Debit Card */}
                <tr className="unhovarable">
                    <td scope="row">{savingsAccount.debitCard.description}</td>
                    <td>
                        <div><small>{savingsAccount.debitCard.fees}</small></div>
                    </td>
                </tr>

                {/* Account closure */}
                <tr className="unhovarable">
                    <td scope="row">{savingsAccount.accountClosure.description}</td>
                    <td>
                        <div><small>{savingsAccount.accountClosure.fee}</small></div>
                    </td>
                    <td>
                        <div><small>{investmentAccount.accountClosure.fee}</small></div>
                    </td>
                </tr>

            </tbody>
        </table>
    );

    const accountSelectionBtnElement = (
        <div className="content content-table" style={{display: 'none'}} id="accountSelectionBtn">
            <CustomButton label={accountSelectLabel}  updateCurrentPage={updateCurrentPage} next={nextPage} />
        </div>
    );

    return (
        <div>
            {mainElement}
            {accountSelectionBtnElement}
        </div>
    );
})