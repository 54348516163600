import React from 'react';
import { pafupiUnbundled,eazyTransactUnbundled,eazySaveUnBundled} from './Fees';
import {Box, FormControlLabel, Radio, Typography} from "@mui/material";
const PerTransactionFees=({product})=>{
    const fees = product === 'EazySave'
        ? eazySaveUnBundled
        : product === 'EazyTransact'
            ? eazyTransactUnbundled
            : pafupiUnbundled;

    const keys = Object.keys(fees);

    return (
        <Box sx={{ mt: 2 }}>
            {keys.map((key) => (
                <Box key={key} sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 2,
                    alignItems: 'center',
                    mb: 1,
                    borderBottom: '1px solid #e0e0e0',
                    padding: '8px 0',
                }}>
                    <Typography variant="body2" sx={{
                        color: '#2b357c',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        wordWrap: 'break-word',
                    }}>
                        {key.replace(/_/g, ' ')}:
                    </Typography>
                    <Typography variant="body2" sx={{
                        color: '#2b357c',
                        textAlign: 'right',
                        wordWrap: 'break-word',
                    }}>
                        {fees[key]}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
}
export default React.memo(PerTransactionFees);