import React, {useEffect, useState} from "react";
import axios from "axios";
import {CustomStyle} from "../../helpers/styles";
import {Alert, Box, CircularProgress, Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import {CustomNBSTextField} from "../custom-componets/CustomNBSTextField";

export const Verification = (({updateCurrentPage, fixedDepositDetails, setFixedDepositDetails}) => {
    const [message, setMessage] = useState(null);
    const [showRescanButton, setShowRescanButton] = useState(false);
    const [loading, setLoading] = useState(true);
    const [otpLoading, setOtpLoading] = useState(false);
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [formData, setFormData] = useState({otp: ""});
    const [otpVerified] = useState(false);
    const [isOtpValid, setIsOtpValid] = useState(true);

    const {scannedData} = fixedDepositDetails;
    const parsedData = JSON.parse(scannedData);
    const id_number = parsedData.id_number;
    const account = fixedDepositDetails.account_number;

    useEffect(() => {
        const verifyScan = async () => {
            setLoading(true);
            try {
                const response = await axios.post('/verify-scan', {
                    type: "scanning",
                    id_number: id_number,
                    account_number: account,
                });

                if (response.status === 200 && response.data.status) {
                    setVerificationSuccess(true);
                    const otpResponse = await axios.post("/otp", {
                        account_number: fixedDepositDetails.account_number,
                        type: "request",
                    });

                    setMessage(otpResponse.data.status ? otpResponse.data.msg : "Failed to initiate OTP request.");
                } else {
                    setMessage(response.data.msg);
                    setShowRescanButton(true);
                }
            } catch (error) {
                setMessage("Failed to verify ID details. Please try again.");
                console.error("Failed to verify scan:", error);
                setShowRescanButton(true);
            } finally {
                setLoading(false);  // Stop loading after the initial verification and OTP request
            }
        };

        verifyScan();
    }, [id_number, account, fixedDepositDetails.account_number]);

    const handleOtpVerification = async () => {
        const otp = formData.otp.trim();

        if (otp === "") {
            setIsOtpValid(false);
            setMessage("Please provide an OTP.");
            return;
        }

        if (otp.length < 6) {
            setIsOtpValid(false);
            setMessage("OTP must be 6 digits long.");
            return;
        }

        setIsOtpValid(true);
        setOtpLoading(true);
        setMessage(null);

        try {
            const otpVerificationResponse = await axios.post("/verify-otp", {
                account_number: fixedDepositDetails.account_number,
                otp: formData.otp,
                type: "verify",
                mobile_no: fixedDepositDetails.mobile_no,
            });


            if (otpVerificationResponse.data.status) {
                setMessage("OTP verification successful.");
                updateCurrentPage("RequestType");
            } else {
                setMessage("OTP verification failed.");
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setMessage("An error occurred while verifying OTP.");
        } finally {
            setOtpLoading(false);
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        // Only allow numeric input and limit to 6 digits
        if (/^\d{0,6}$/.test(value)) {
            setFormData({...formData, [name]: value});
        }
    };

    const handleRescan = () => {
        updateCurrentPage("ScannerComponent");
    };

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>OTP Verification</Typography>
                {message && (
                    <Alert severity="info" style={CustomStyle.alert}>
                        {message}
                    </Alert>
                )}

                {loading && (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh'}}>
                        <CircularProgress/>
                    </Box>
                )}

                {showRescanButton && (
                    <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={handleRescan} disabled={false}>
                        Rescan
                    </CustomNBSRedButton>
                    </div>
                )}

                {!loading && verificationSuccess && (
                    <>
                        <CustomNBSTextField
                            label="Enter OTP"
                            onInputChange={handleChange}
                            value={formData.otp}
                            inputType="text"
                            name="otp"
                            error={!isOtpValid}
                            helperText={!isOtpValid ? "OTP must be numeric and up to 6 digits." : ""}
                            required
                        />

                        <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton onClick={handleOtpVerification} disabled={otpLoading }>
                            {otpLoading  ? <CircularProgress color="inherit" size={24}/> : 'Verify OTP'}
                        </CustomNBSRedButton>
                        </div>

                    </>
                )}
            </div>
        </div>
    )
});
