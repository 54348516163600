import React, {memo, useState, useEffect} from 'react';
import {Form, Button, Row, Card, Container, ProgressBar, Spinner} from 'react-bootstrap';
import country from "country-list-js";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Select from 'react-select';
import Districts from '../Districts';
import {districtByCategory} from '../data';
import {fetchCities, resolveAndGetImageFileBase64} from '../../helpers/Diaspora.js';
import {
    convertBase64ToImageOrPDFFile,
    createFileUploadObject,
    emptyStringIfNull,
    getUniqueList
} from "../../helpers/Utils";
import VirtualizedAutoComplete from "../VirtualizedAutoComplete";
import {customSelectStyles, CustomStyle} from "../../helpers/styles";
import {CircularProgress, FormControl, MenuItem, Typography} from "@mui/material";
import {CustomNBSTextField} from "../custom-componets/CustomNBSTextField";
import CustomPhoneInput from "../custom-componets/CustomPhoneInput";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import CustomNBSBlueButton from "../custom-componets/CustomNBSBlueButton";

function getSalutations(obj) {
    const commonSalutations = [
        {value: 'DR', label: 'Dr'},
        {value: 'PROF', label: 'Prof'}
    ];
    if (obj.gender === "MALE") {
        return [
            {value: 'MR', label: 'Mr'},
            {value: 'FR', label: 'Fr'},
            {value: 'SR', label: 'Sr'}
        ].concat(commonSalutations);
    } else if (obj.gender === "FEMALE") {
        return [
            {value: 'MRS', label: 'Mrs'},
            {value: 'MISS', label: 'Miss'}
        ].concat(commonSalutations);
    }
    return [
        {value: 'MR', label: 'Mr'},
        {value: 'MRS', label: 'Mrs'},
        {value: 'DR', label: 'Dr'},
        {value: 'FR', label: 'Fr.'},
        {value: 'SR', label: 'Sr.'},
        {value: 'MISS', label: 'Miss'}
    ];
}

export const ApplicantDetails = memo(({updateCurrentPage, customerDetails, setCustomerDetails}) => {
    const [countries, setCountries] = useState(country.names().sort());
    const [phone, setPhone] = useState(emptyStringIfNull(customerDetails.mobile_phone));
    const [email, setEmail] = useState(emptyStringIfNull(customerDetails.email_address));
    const [postalAddress, setPostalAddress] = useState(emptyStringIfNull(customerDetails.postal_address));
    const [physicalAddress, setphysicalAddress] = useState(emptyStringIfNull(customerDetails.physical_address));
    const [selectedSalutation, setSelectedSalutation] = useState(null);
    const [salutations, setSalutations] = useState(getSalutations(customerDetails));
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);

    const [selectedDistrict, setSelectedDistrict] = useState("BALAKA");
    const title = "Customer Details";
    const subTitle = "Details should be from the country in which you are residing.";
    const nextPage = "DiasporaIncomeProof";
    const southAfricaIso2Code = "ZA";
    const [defaultCountry, setDefaultCountry] = useState(country.findByIso2(southAfricaIso2Code).name || countries[0]);
    const [defaultCountryCode, setDefaultCountryCode] = useState(country.findByName(defaultCountry).code.iso2);
    const [loading, setLoading] = useState(false);

    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('No file chosen');


    useEffect(() => {
        fetchCities()
            .then(_cities => setCityList(_cities))
            .catch(() => setCityList([]))
    }, []);


    const cityOptions = cityList.map(city => ({
        value: city,
        label: city
    }));

    const handleCityChange = (selectedOption) => {
        console.log('Selected city:', selectedOption);
        setSelectedCity(selectedOption);
        setCustomerDetails(prev => ({
            ...prev,
            postal_town: selectedOption?.value.slice(0, 15) || ''
        }));
    };

    const styles = {
        rounded: {
            borderRadius: '100px'
        }
    }

    const residentCountryChanged = (selectedOption) => {
        const selectedCountry = selectedOption.value;
        setDefaultCountry(selectedCountry);
        setDefaultCountryCode(country.findByName(selectedCountry).code.iso2);
        setCustomerDetails((prev) => {
            return {...prev, resident_country: selectedCountry}
        })
    }

    const handleSalutationChange = (selectedOption) => {
        setSelectedSalutation(selectedOption);
        setCustomerDetails((prev) => ({
            ...prev,
            cust_title: selectedOption?.value || '',
        }));
    };

    const handleMaritalStatusChange = (selectedOption) => {
        setSelectedMaritalStatus(selectedOption);
        setCustomerDetails((prev) => ({
            ...prev,
            marital_status: selectedOption?.value || '',
        }));
    };

    const phoneNumberChanged = (e) => {
        setCustomerDetails((prev) => {
            return {...prev, mobile_phone: e}
        })
    }

    const physicalAddressChanged = (e) => {
        const sanitizedValue = e.target.value.replace(/,/g, '');

        setCustomerDetails((prev) => {
            return {
                ...prev,
                physical_address: sanitizedValue,
                street_address: sanitizedValue,
                district_address: sanitizedValue
            }
        })
    }

    const postalAddressChanged = (e) => {
        const sanitizedValue = e.target.value.replace(/,/g, '');
        setCustomerDetails((prev) => {
            return {...prev, postal_address: sanitizedValue}
        })
    }
    const emailChanged = (e) => {
        setCustomerDetails((prev) => {
            return {...prev, email_address: e.target.value}
        })
    }

    const malawiNationalIdChanged = async (e) => {
        const NATIONALID = e.target.files[0];

        // const base64 = await getBase64(NATIONALID)
        //     .then(r => r)
        //     .catch(e => { console.error(e); return "" });
        //
        // console.dir(NATIONALID);

        let base64 = "";
        if (NATIONALID) {
            setSelectedFileName(NATIONALID.name);
            setLoading(true);
            base64 = await resolveAndGetImageFileBase64(NATIONALID);
            setLoading(false);
        }

        const nationalIDJPegFile = convertBase64ToImageOrPDFFile(base64, 'diaspora_national_id');
        const data = await createFileUploadObject(nationalIDJPegFile, 'diaspora_national_id', customerDetails.id_number, customerDetails.surname)
            .then(data => data)
            .catch(() => null);

        if (data != null) {
            setCustomerDetails((prev) => {
                return {...prev, [data['name']]: data['url']}
            })
            setCustomerDetails((prev) => {
                prev.temp[data['name']] = base64;
                return prev;
            });

        } else {
            //TODO: handle error
        }
    }

    const maritalStatusOptions = [
        {value: 'SINGLE', label: 'Single'},
        {value: 'MARRIED', label: 'Married'}
    ];

    function setDistrict(e) {
        const district = e.target.value.toUpperCase();
        setSelectedDistrict(district);
        setCustomerDetails((prev) => {
            return ({...prev, district: district})
        });

        //Update traditional auth - first one
        setCustomerDetails((prev) => {
            return ({...prev, trad_auth: districtByCategory[e.target.value.toUpperCase()][0]})
        });
    }

    const traditionalAuthorityOptions = districtByCategory[selectedDistrict.toUpperCase()].map(ward => ({
        value: ward,
        label: ward
    }));

    const handleTraditionalAuthorityChange = (selectedOption) => {
        setCustomerDetails((prev) => ({
            ...prev,
            trad_auth: selectedOption?.value || '',
        }));
    };

    function setVillage(e) {
        const sanitizedValue = e.target.value.replace(/,/g, '');
        setCustomerDetails((prev) => {
            const v = ({...prev, village: sanitizedValue});
            console.log(v);
            return v;
        });
    }

    useEffect(() => {
        setCustomerDetails((prev) => {
            return ({...prev, country: defaultCountry, resident_country: defaultCountry})
        });
    }, [defaultCountry])

    function submitForm() {
        updateCurrentPage(nextPage);
    }


    const countryOptions = countries.map((country) => ({
        value: country,
        label: country
    }));

    function changedCity(city) {
        setCustomerDetails((prev) => {
            return {...prev, postal_town: city.slice(0, 15)}
        })
    }

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    {title}</Typography>
                <form onSubmit={submitForm}>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Title *
                        </Typography>
                        <Select
                            options={salutations}
                            value={selectedSalutation}
                            onChange={handleSalutationChange}
                            styles={customSelectStyles}
                            placeholder="Select Title"

                            isClearable
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Marital status *
                        </Typography>
                        <Select
                            options={maritalStatusOptions}
                            value={selectedMaritalStatus}
                            onChange={handleMaritalStatusChange}
                            styles={customSelectStyles}
                            placeholder="Select Marital Status"

                            isClearable
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Country *
                        </Typography>
                        <Select
                            options={countryOptions}
                            value={{value: defaultCountry, label: defaultCountry}}
                            onChange={residentCountryChanged}
                            styles={customSelectStyles}
                            isSearchable
                        />
                    </FormControl>


                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Phone number *
                        </Typography>

                        <CustomPhoneInput
                            value={customerDetails.mobile_phone}
                            onChange={phoneNumberChanged}
                            defaultCountry={defaultCountryCode}
                        />
                    </FormControl>

                    <CustomNBSTextField
                        label="Email address"
                        className="form-control"
                        value={customerDetails.email_address || ''}
                        onInputChange={emailChanged}
                        inputType="email"
                        placeholder="nbsbank@nbs.mw"
                        name="email_address"
                        required
                    />

                    {/*<FormControl fullWidth variant="outlined" sx={{ marginBottom: '16px' }}>*/}
                    {/*    <Typography*/}
                    {/*        variant="h6"*/}
                    {/*        sx={{*/}
                    {/*            ...CustomStyle.subtitle,*/}
                    {/*            textAlign: 'left',*/}
                    {/*            fontSize: '15px',*/}
                    {/*            marginBottom: '10px',*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Malawi National ID (Optional)*/}
                    {/*    </Typography>*/}
                    {/*    /!* Hidden file input field *!/*/}
                    {/*    <input*/}
                    {/*        type="file"*/}
                    {/*        id="file-upload"*/}
                    {/*        accept="image/*"*/}
                    {/*        onChange={malawiNationalIdChanged}*/}
                    {/*        style={{ display: 'none' }}*/}
                    {/*    />*/}
                    {/*    /!* Label to trigger file input *!/*/}
                    {/*    <label htmlFor="file-upload">*/}
                    {/*        <Button*/}
                    {/*            component="span"*/}
                    {/*            sx={{*/}
                    {/*                display: 'block',*/}
                    {/*                width: '100%',*/}
                    {/*                borderRadius: '10px',*/}
                    {/*                padding: '10px',*/}
                    {/*                fontSize: '1rem',*/}
                    {/*                textAlign: 'center',*/}
                    {/*                backgroundColor: '#2b357c', // NBS Bank blue*/}
                    {/*                color: '#fff',*/}
                    {/*                border: 'none',*/}
                    {/*                cursor: 'pointer',*/}
                    {/*                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',*/}
                    {/*                transition: 'background-color 0.3s',*/}
                    {/*                '&:hover': {*/}
                    {/*                    backgroundColor: '#1e2a5a', // Darker shade on hover*/}
                    {/*                },*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            Choose File*/}
                    {/*        </Button>*/}
                    {/*    </label>*/}
                    {/*</FormControl>*/}
                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>

                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Malawi National ID (Optional)

                        </Typography>
                        <CustomNBSBlueButton variant="contained" component="label">
                            Choose File
                            <input
                                type="file"
                                onChange={malawiNationalIdChanged}
                                name="NationalID"
                                accept="image/*"
                                style={{display: 'none'}} // Hide the file input
                            />
                        </CustomNBSBlueButton>


                        {/*<Box sx={{ marginTop: 2 }}>*/}

                        {/*<input*/}
                        {/*    type="file"*/}
                        {/*    onChange={malawiNationalIdChanged}*/}
                        {/*    accept="image/*"*/}
                        {/*    style={{ display: 'none' }} // Hide the file input*/}
                        {/*/>*/}

                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px', marginBottom: '10px'
                        }}>Uploaded {selectedFileName}</Typography>
                    </FormControl>


                    {/*<CustomNBSRedButton*/}
                    {/*    component="label"*/}
                    {/*>*/}
                    {/*    Choose File*/}
                    {/*    <input*/}
                    {/*        type="file"*/}
                    {/*        onChange={malawiNationalIdChanged}*/}
                    {/*        name="NationalID"*/}
                    {/*        accept="image/*"*/}
                    {/*        style={{ display: 'none' }} // Hide the file input*/}
                    {/*    />*/}
                    {/*</CustomNBSRedButton>*/}

                    <br/>
                    <Typography variant="h5" sx={CustomStyle.heading}>Current Address</Typography>
                    <Typography style={{
                        ...CustomStyle.contentText, color: "#ed1b2e", alignItems: 'center',
                        textAlign: 'center'
                    }}>Your address from the country you are residing now.</Typography>
                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            City/Town *
                        </Typography>

                        <VirtualizedAutoComplete label={"City"} itemsList={cityList} onChangeCallback={changedCity}/>

                        {/*<Select*/}
                        {/*    options={cityOptions}*/}
                        {/*    value={selectedCity}*/}
                        {/*    onChange={handleCityChange}*/}
                        {/*    styles={customSelectStyles}*/}
                        {/*    placeholder="Select City/Town"*/}
                        {/*    isClearable*/}
                        {/*    required*/}
                        {/*/>*/}
                    </FormControl>

                    <CustomNBSTextField
                        label="Physical Address *"
                        value={customerDetails.physical_address || ''}
                        onInputChange={physicalAddressChanged}
                        inputType="text"
                        required="true"
                        maxLength={25}
                    />

                    <CustomNBSTextField
                        label="Postal address *"
                        value={customerDetails.postal_address || ''}
                        onInputChange={postalAddressChanged}
                        inputType="text"
                        name="postal_address"
                        required="true"
                        maxLength={25}
                    />

                    <br/>
                    <Typography variant="h5" sx={CustomStyle.heading}>Permanent Home Address</Typography>
                    <Typography style={{
                        ...CustomStyle.contentText,
                        color: "#ed1b2e",
                        alignItems: 'center',
                        textAlign: 'center'
                    }}>Your Malawian address</Typography>
                    <Typography variant="h6" sx={{
                        ...CustomStyle.subtitle,
                        textAlign: 'left',
                        fontSize: '15px', marginBottom: '10px'
                    }}>District *</Typography>
                    <Districts style={styles.rounded} name="district" handleChange={setDistrict} required/>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Traditional Authority/Ward *
                        </Typography>
                        <Select
                            options={traditionalAuthorityOptions}
                            onChange={handleTraditionalAuthorityChange}
                            styles={customSelectStyles}
                            placeholder="Select Traditional Authority/Ward"
                            isClearable
                            required
                        />
                    </FormControl>

                    <CustomNBSTextField
                        label="Home village *"
                        value={customerDetails.village || ''}
                        onInputChange={setVillage}
                        inputType="text"
                        name="village"
                        required="true"
                        maxLength={25}
                    />

                    <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton type="submit"
                                            disabled={loading}
                        >
                            {loading ? <CircularProgress color="inherit" size={24}/> : 'Next'}
                        </CustomNBSRedButton>
                    </div>
                </form>
            </div>
        </div>


    )
});