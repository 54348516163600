import React from 'react';
const CustomCheckBox = React.memo(({id,value,handleCheckedValueChange,isChecked})=>{
    const handleOnClick=(e)=>{
        handleCheckedValueChange(e)
    }
    if(isChecked)
        return(
            <div className="container-fluid justify-content-start w3-text-indigo text-left">
                <div className="checkbox" >
                    <label><input type="checkbox" onClick={handleOnClick} id={id} checked/> {value}</label>
                </div>
            </div>
        );
    else
        return(
            <div className="container-fluid justify-content-start w3-text-indigo text-left">
                <div className="checkbox" >
                    <label><input type="checkbox" onClick={handleOnClick} id={id} /> {value}</label>
                </div>
            </div>
        );
})
export default CustomCheckBox;