import React, {useState, memo, useEffect} from 'react';
// import {Container, Row, Col, Alert, Card, Button} from 'react-bootstrap';
import {
    submitPassportFile,
    setFieldData,
    DIASPORA_ACCOUNT_TYPES,
    getBase64,
    triggerChoosingFileAgain,
    customerDataAlreadySubmitted,
    triggerScanningMalawianPassportFollowingReceiptOfForeignPassport,
    setMalawianPassportFieldData, isExpired, isAdult
} from '../../helpers/Diaspora.js';
import $, {data} from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import samplePassport from "../../assets/images/sample-passport.png";
import {convertBase64ToImageOrPDFFile, createFileUploadObject} from "../../helpers/Utils";
import {CustomStyle} from "../../helpers/styles";
import {Alert, Box, Card, CardContent, CircularProgress, Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const PassportReader = memo(({updateCurrentPage, customerDetails, setCustomerDetails}) => {
    const nextPage = "CustomerPic";
    const invalidPassportErrorMessage = "Could not read your passport. Please make sure its clear enough and valid";
    const noFileErrorMessage = "Please select a passport image file before proceeding";
    const connectionErrorMessage = "A connection error occurred";
    const passportRequirementMessage = "A copy of your passport is required to continue. Please make sure the entire passport is clearly visible and valid."
    const [customerDataIsAlreadySubmitted, setCustomerDataIsAlreadySubmitted] = useState(false);
    const [foreignPassportProvided, setForeignPassportProvided] = useState(false);
    const [scanningMalawianPassportAfterReceiptOfForeignPassport, setScanningMalawianPassportAfterReceiptOfForeignPassport] = useState(false);
    const [nonMalawiPassportDetectedAfterReceiptOfForeignPassport, setNonMalawiPassportDetectedAfterReceiptOfForeignPassport] = useState(false);
    const [passportIsExpired, setPassportIsExpired] = useState(false);
    const [passportIsAdult, setPassportIsAdult] = useState(true);

    const [showSamplePassport, setShowSamplePassport] = useState(true);

    const [firstPassportFullname, setFirstPassportFullname] = useState("");
    const [secondPassportFullname, setSecondPassportFullname] = useState("");
    const [scanSuccessful, setScanSuccessful] = useState(false); // New state variable

    function retryScan() {
        setFirstPassportFullname("");
        setSecondPassportFullname("");
        setCustomerDataIsAlreadySubmitted(false);
        setForeignPassportProvided(false);
        setScanningMalawianPassportAfterReceiptOfForeignPassport(false);
        setNonMalawiPassportDetectedAfterReceiptOfForeignPassport(false);
        setScanSuccessful(false); // Reset scan success state
        setShowSamplePassport(true);
    }

    const [passportDetails, setPassportDetails] = useState({});
    const [errorMessage, setErrorMessage] = useState(invalidPassportErrorMessage);
    // const [accountTypeChosen, setAccountType] = useState(false);
    const [accountType, setAccountType] = useState(DIASPORA_ACCOUNT_TYPES.TRANSACTIONAL);

    const diasporaTransactionalAccount = "Diaspora Transactional Account".toUpperCase();
    const diasporaInvestmentAccount = "Diaspora Investment Account".toUpperCase();


    //Default selection - we could do better !!
    const handleOnRadioChecked = (v) => {
        setAccountType(v);
        setCustomerDetails((prev) => ({...prev, account_product: v}));
    };

    const style = {
        centerItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        spaceOnTop: {
            margingTop: '2rem'
        },
        rounded: {
            borderRadius: '100px'
        }
    }

    async function submitPassportFileLocalFunc(e) {
        setShowSamplePassport(false);
        const passport = e.target.files[0];
        if (passport === null || passport === undefined) {
            setErrorMessage(noFileErrorMessage);
            $('#invalidPassportAlert').attr('hidden', false);
            console.log("No file provided");
            return;
        }

        const res = await submitPassportFile(e, scanningMalawianPassportAfterReceiptOfForeignPassport).then(r => r).catch(e => e)

        if (res.statusCode === 400 && res.data === null) {
            $("#topContent").show();
            $('#passportScanLoader').attr('hidden', true);
            $('#invalidPassportAlert').attr('hidden', false);
        } else if (![404, 200].includes(res.statusCode)) {
            setScanSuccessful(true);
            $("#topContent").show();
            $('#passportScanLoader').attr('hidden', true);
            setErrorMessage(connectionErrorMessage);
        } else if (res.data !== null && res.statusCode === 200) {
            const isCustomerDataAlreadySubmitted = await customerDataAlreadySubmitted(res.data).then(r => r).catch(e => e);

            console.log("value from scan " + isCustomerDataAlreadySubmitted)
            //Only check for the existence of Primary passport being used
            if (!scanningMalawianPassportAfterReceiptOfForeignPassport && isCustomerDataAlreadySubmitted) {
                setCustomerDataIsAlreadySubmitted(isCustomerDataAlreadySubmitted);
            }

            const base64 = await getBase64(passport)
                .then(r => r)
                .catch(e => {
                    console.error(e);
                    return ""
                });

            //We are doing a conversion because the uploaded file could be in another format but jpeg
            const passportFileJPeg = convertBase64ToImageOrPDFFile(base64, 'id_scanned_copy');

            const data = await createFileUploadObject(passportFileJPeg, 'id_scanned_copy', res.data.id_number, res.data.surname)
                .then(data => data)
                .catch(() => null);

            if (data != null) {
                setCustomerDetails((prev) => {
                    return ({...prev, [data['name']]: data['url']})
                });

                setCustomerDetails((prev) => {
                    prev.temp[data['name']] = base64;
                    return prev;
                });

                const isMalawianPassport = res.data.malawian;

                if (!scanningMalawianPassportAfterReceiptOfForeignPassport) {
                    setCustomerDetails((prev) => {
                        return ({...prev, ...res.data, id_scanned_copy: data['url']});
                    })
                }

                if (!isMalawianPassport) {
                    setForeignPassportProvided(true);
                } else {
                    setForeignPassportProvided(false);
                }

                $("#topContent").hide();

                if (!scanningMalawianPassportAfterReceiptOfForeignPassport) {
                    setFirstPassportFullname([res.data.firstname, res.data.surname].join(" "));
                }

                setPassportDetails(res.data);

                setPassportIsExpired(isExpired(res.data.id_expiry_date));

                setPassportIsAdult(isAdult(res.data.date_of_birth));

                // console.log(isAdult('2010-10-10'))


                if (scanningMalawianPassportAfterReceiptOfForeignPassport && isMalawianPassport) {
                    //Malawian passport data (being treated as second passport) after foreign passport was detected
                    setNonMalawiPassportDetectedAfterReceiptOfForeignPassport(false);
                    setCustomerDetails((prev) => {
                        return ({...prev, passport2: {...res.data, id_scanned_copy: data['url']}});
                    })

                    setPassportIsExpired(false); //We have a Malawian Passport (2nd), so we accept it whether expired or not

                    setSecondPassportFullname([res.data.firstname, res.data.surname].join(" "));
                    setMalawianPassportFieldData(res.data);
                } else if (scanningMalawianPassportAfterReceiptOfForeignPassport && !isMalawianPassport) {
                    setPassportIsExpired(false);
                    setNonMalawiPassportDetectedAfterReceiptOfForeignPassport(true);
                    setSecondPassportFullname([res.data.firstname, res.data.surname].join(" "));
                    setMalawianPassportFieldData(res.data);
                } else {
                    setNonMalawiPassportDetectedAfterReceiptOfForeignPassport(false);
                    setFieldData(res.data);
                }
            } else {
                //TODO: Raise an error
            }
            //End
        } else {
            $("#topContent").show();
            $('#passportScanLoader').attr('hidden', true);
            setErrorMessage(connectionErrorMessage);
        }
    }

    function confirmPassportDetails() {
        if (foreignPassportProvided) {
            setScanningMalawianPassportAfterReceiptOfForeignPassport(true);
            triggerScanningMalawianPassportFollowingReceiptOfForeignPassport();
        } else {
            setScanningMalawianPassportAfterReceiptOfForeignPassport(false);
            updateCurrentPage(nextPage);
        }
    }

    function firstAndSecondPassportDoNotMatch() {
        return (firstPassportFullname.length > 0 && secondPassportFullname.length > 0) && (firstPassportFullname !== secondPassportFullname);
    }

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}> Passport Document Scan </Typography>

                <Typography variant="h6" sx={CustomStyle.subtitle}>{passportRequirementMessage}</Typography>
                {!scanSuccessful && (
                    <>
                        <div className="wrapper">
                            <div className="input">
                                <input
                                    style={style.rounded}
                                    type="file"
                                    id="passport"
                                    name="passport"
                                    className="form-control form-control-sm"
                                    onChange={submitPassportFileLocalFunc}
                                    accept="image/png, image/jpeg"
                                />
                            </div>
                        </div>

                        {showSamplePassport && (
                            <>
                                <br/>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px',
                                    color: "#ed1b2e"
                                }}>Please make sure your passport is properly captured as shown
                                    below</Typography>
                                <img width="100%" height="auto" src={samplePassport} alt="Sample passport image"/>
                            </>
                        )}
                    </>
                )}

                <div id="passportScanLoader" className="text-center" hidden style={{color: "#2b357c"}}>
                    {/*<div className="spinner-border spinner-border-sm" role="status"></div>*/}
                    <CircularProgress/>
                    <span>Uploading Passport</span>
                </div>

                <div id="invalidPassportAlert" hidden style={{marginTop: '1rem'}}>

                    <Alert severity="error" onClose={() => {
                        $("#invalidPassportAlert").hide();
                    }}>
                        {errorMessage}
                    </Alert>
                </div>

                <br/>

                <div id="scannedResults" hidden>
                    <Typography style={{...CustomStyle.contentText, alignItems: 'center', textAlign: 'center',}}>Passport information</Typography>
                    <Typography style={{...CustomStyle.contentText}}>Please confirm details below before
                        proceeding</Typography>

                    <Box sx={{color: "#2b357c"}}>
                        <Card
                            sx={{
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                marginBottom: '20px',
                                maxWidth: '100%',
                                border: '2px solid #2b357c',
                            }}
                        >
                            <CardContent>
                                {[
                                    {label: 'FullName', id: 'fullName'},
                                    {label: 'Passport Number', id: 'passportNumber'},
                                    {label: 'Country', id: 'nationality'},
                                    {label: 'Date of Birth', id: 'dateOfBirth'},
                                    {label: 'Gender', id: 'sex'},
                                    {label: 'Expiry Date', id: 'expiryDate'},
                                ].map((item) => (
                                    <Box key={item.id} sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                                        <Typography variant="body2" sx={{color: '#2b357c', fontWeight: 'bold'}}>
                                            {item.label}:
                                        </Typography>
                                        <Typography variant="body2" sx={{color: '#2b357c'}} id={item.id}></Typography>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Box>

                    <br/>

                    <Box id="Passport2_scannedResults" sx={{color: "#2b357c", display: 'none'}}>
                        <Card
                            sx={{
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px',
                                backgroundColor: '#ffffff',
                                marginBottom: '20px',
                                maxWidth: '100%',
                                border: '2px solid #2b357c',
                            }}
                        >
                            <CardContent>
                                {[
                                    {label: 'FullName', id: 'Passport2_fullName'},
                                    {label: 'Passport Number', id: 'Passport2_passportNumber'},
                                    {label: 'Country', id: 'Passport2_nationality'},
                                    {label: 'Date of Birth', id: 'Passport2_dateOfBirth'},
                                    {label: 'Gender', id: 'Passport2_sex'},
                                    {label: 'Expiry Date', id: 'Passport2_expiryDate'},
                                ].map((item) => (
                                    <Box key={item.id} sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                                        <Typography variant="body2" sx={{color: '#2b357c', fontWeight: 'bold'}}>
                                            {item.label}:
                                        </Typography>
                                        <Typography variant="body2" sx={{color: '#2b357c'}} id={item.id}></Typography>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Box>

                    <br/>

                    {foreignPassportProvided && !nonMalawiPassportDetectedAfterReceiptOfForeignPassport && !customerDataIsAlreadySubmitted && !passportIsExpired && (
                        <>

                            <Alert severity="error" style={CustomStyle.alert}>
                                It appears you are using a non-malawian passport. We therefore require a copy of
                                your Malawian passport
                            </Alert>
                        </>
                    )}

                    {nonMalawiPassportDetectedAfterReceiptOfForeignPassport && !passportIsExpired && (
                        <>
                            <Alert severity="error" style={CustomStyle.alert}>
                                Your second passport <strong>must</strong> be of Malawian.
                                Please provide one
                            </Alert>

                        </>
                    )}

                    {customerDataIsAlreadySubmitted && !passportIsExpired && (
                        <>
                            <Alert severity="error" style={CustomStyle.alert}>
                                You cannot continue with this application because information bearing this passport
                                number was already submitted.
                            </Alert>
                            <br/>
                        </>
                    )}

                    {passportIsExpired && (
                        <>
                            <Alert severity="error" style={CustomStyle.alert}>
                                It appears your passport is expired. Please use a valid one.
                            </Alert>
                        </>
                    )}

                    {!customerDataIsAlreadySubmitted && !passportIsAdult && !passportIsExpired && (
                        <>
                            <Alert severity="error" style={CustomStyle.alert}>
                                It appears your passport is of a minor. Minimum age is 18.
                            </Alert>
                        </>
                    )}

                    {!passportIsExpired && !customerDataIsAlreadySubmitted && !nonMalawiPassportDetectedAfterReceiptOfForeignPassport && firstAndSecondPassportDoNotMatch() && (
                        <>
                            <Alert severity="error" style={CustomStyle.alert}>
                                Names on your passports do not match
                            </Alert>
                        </>
                    )}

                    <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={() => {
                            retryScan();
                            triggerChoosingFileAgain()
                        }}
                                            disabled={false}>
                            Retry
                        </CustomNBSRedButton>

                        {!customerDataIsAlreadySubmitted && !passportIsExpired && !firstAndSecondPassportDoNotMatch() && passportIsAdult && (

                            <CustomNBSRedButton onClick={() => confirmPassportDetails()}
                                                disabled={false}>
                                {foreignPassportProvided ? "Upload Malawian Passport" : "Confirm"}
                            </CustomNBSRedButton>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
})