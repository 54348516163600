import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { Spinner } from "react-bootstrap";

const IDDetailsCard = ({ idDetails, showSpinner }) => {
    const keys = Object.keys(idDetails);

    return (
        <Card
            sx={{
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                marginBottom: '16px',
                maxWidth: { xs: '100%', md: '600px' }, // Full width on mobile, limited width on larger screens
                border: '2px solid #2b357c',
                padding: { xs: '16px', md: '24px' }, // Adjust padding for mobile
                margin: 'auto',
            }}
        >
            <CardContent>
                <Typography variant="h6"
                            sx={{
                                mb: 2,
                                color: '#2b357c',
                                fontWeight: 'bold',
                                fontSize: { xs: '16px', md: '18px' }, // Proper font size for mobile
                                textAlign: 'center',
                            }}>
                    National ID Details
                </Typography>

                <Box sx={{ mt: 2 }}>
                    {keys.map((key) => (
                        <Box key={key} sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr', // Ensure two columns always
                            alignItems: 'center',
                            mb: 1,
                            borderBottom: '1px solid #e0e0e0',
                            padding: { xs: '8px 0', md: '12px 0' },
                        }}>
                            <Typography variant="body2" sx={{
                                color: '#2b357c',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                wordWrap: 'break-word',
                                fontSize: { xs: '14px', md: '16px' }, // Keep font smaller on mobile
                                textAlign: 'left',
                                flexShrink: 0, // Prevent key text from shrinking
                                whiteSpace: 'nowrap', // Ensure key doesn't break into multiple lines
                                overflow: 'hidden', // Hide overflow of long text
                                textOverflow: 'ellipsis', // Add ellipsis to long text
                                pr: 2, // Add some padding to the right of the key
                            }}>
                                {key.replace(/_/g, ' ').toUpperCase()}:
                            </Typography>
                            <Typography variant="body2" sx={{
                                color: '#2b357c',
                                textAlign: 'right',
                                wordWrap: 'break-word',
                                fontSize: { xs: '14px', md: '16px' }, // Keep font smaller on mobile
                                whiteSpace: 'nowrap', // Ensure value doesn't break into multiple lines
                                overflow: 'hidden', // Hide overflow of long text
                                textOverflow: 'ellipsis', // Add ellipsis to long text
                            }}>
                                {idDetails[key].toUpperCase()}
                            </Typography>
                        </Box>
                    ))}
                </Box>

                {showSpinner && (
                    <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                        <Spinner animation="border" />
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            Verifying your details. Please wait.
                        </Typography>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

export default IDDetailsCard;
