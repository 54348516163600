import React, {memo} from 'react';
import {CustomStyle} from "../../../helpers/styles";
import {Box, Typography} from "@mui/material";
import logo from "../../../assets/images/logo_nav.png";
import CustomNBSRedButton from "../../custom-componets/CustomNBSRedButton";

export const Requirements = memo(({updateCurrentPage, customerDetails, setCustomerDetails}) => {
    const goToDiasporaAccountsOverview = () => {
        updateCurrentPage("DiasporaAccountsOverview");
    }
    const title = "Requirements to open a diaspora account";

    const requirements = [
        'Passport',
        'Payslip (Not more than 3 months old) or Employer letter or Employment contract (Must state your monthly income)',
        '3-months Bank statement (If you are self-employed or unemployed)',
        'Identification Document of your Next of Kin (Malawi National ID, Valid Passport, or Driving License)',
        'Most recent Utility bill of your Next of Kin'
    ]

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    {title}</Typography>

                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    To open an account in diaspora, the following documents are required:</Typography>


                <Typography style={CustomStyle.contentText}>
                    {requirements.map((requirement, index) => (
                        <Box key={index} display="flex" alignItems="center" mb={1}>
                            <Box
                                component="img"
                                src={logo}
                                alt="Logo"
                                sx={{width: 20, borderRadius: '50%'}}
                            />
                            <Typography variant="body1" sx={{ml: 1}}>
                                {requirement}
                            </Typography>
                        </Box>
                    ))}
                </Typography>

                <div style={CustomStyle.buttonContainer}>

                <CustomNBSRedButton onClick={goToDiasporaAccountsOverview}> Next
                </CustomNBSRedButton>
                </div>

            </div>
        </div>
    );
})