let student = {
    "interest_rate": "2.2%",
    "bundled_fee": "K 0.00",
    "book_balance": "K 500.00",
    "atm_withdrawal_fees": "K 0.00",
    "cardless_withdrawal_fees": "K 420.00",
    // "internal_transfer_money_fee": "K 250.00",
    "interbank_transfer_fees": "K 500.00"
}

let pafupiUnbundled = {
    "name": "Pafupi Savings Accounts",
    "interest_rate": "2.0%",
    "book_balance": "K 2,000.00",
    "account_limit": "MWK300,000",
    "atm_withdrawal_fees": "K 0.00",
    "cardless_withdrawal_fees": "K 420.00",
    "internal_transfer_money_fee": "K0.00",
    "interbank_transfer_fees": "K 500.00"

}

// let eazyTransactUnbundled = Object.assign(
//     {},
//     student,
//     {"name": "Eazy Transact Accounts"},
//     {"book_balance": "K 5,000.00"},
//     {"internal_transfer_money_fee": "K0.00"},
// );
let currentSilverBundled = {
    "name": "Silver Current Accounts",
    "initial_deposit": "MK20,000",
    "interest_rate": "0.10% (As published from time to time)",
    "bundle_fee": "K 4,500",
    "book_balance": "Zero",
    "cheque_books": "50 Pages silver: MK 20,000",
    "atm_card": "New Card: MK 10,000 Fees: MK 700",
    "atm_withdrawal_fees": "K 0.00",
    "interest_threshold": "MK5,000,000",
    "interest_capitalization": "Monthly"
}
let currentSilverUnbundled = {
    "name": "Silver Current Accounts",
    "initial_deposit": "MK20,000",
    "interest_rate": "0.10% (As published from time to time)",
    "book_balance": "Zero",
    "bundle_fee": "As per current tariff",
    "cheque_books": "50 Pages silver: MK 20,000",
    "atm_card": "New Card: MK 10,000 Fees: MK 700",
    "interest_threshold": "MWK5,000,000",
    "interest_capitalization": "Monthly",
    "atm_withdrawal_fees": "K 0.00"
}

let currentGoldBundled = {
    "name": "Gold Current Accounts",
    "initial_deposit": "MK30,000",
    "interest_rate": "0.10% (As published from time to time)",
    "book_balance": "Zero",
    "bundle_fee": "As per tariff",
    "interest_threshold": "MWK5,000,000",
    "interest_capitalization": "Monthly"
}

let currentPlatinumBundled = {
    "name": "Platinum Current Accounts",
    "initial_deposit": "MK37,000",
    "interest_rate": "MK5,000,000-MK5,999,999=6%, MK6,000,000>=7%",
    "book_balance": "Zero",
    "bundle_fee": "As per tariff",
    "interest_threshold": "MWK5,000,000",
    "interest_capitalization": "Monthly"
}

let diasporaTransactional = {
    "name": "Platinum Current Accounts",
    "initial_deposit": "MK37,000",
    "interest_rate": "MK5,000,000-MK5,999,999=6%, MK6,000,000>=7%",
    "book_balance": "Zero",
    "bundle_fee": "As per tariff",
    "interest_threshold": "MWK5,000,000",
    "interest_capitalization": "Monthly"
}

let diasporaInvestment = {
    "name": "Platinum Current Accounts",
    "initial_deposit": "MK37,000",
    "interest_rate": "MK5,000,000-MK5,999,999=6%, MK6,000,000>=7%",
    "book_balance": "Zero",
    "interest_threshold": "MWK5,000,000",
    "interest_capitalization": "Monthly"
}
let eazyTransactUnbundled = {

    "name": "Eazy Transact Accounts",
    "interest_rate": "2.2%",
    "internal_transfer_money_fee": "K0.00",
    "book_balance": "K 5,000.00",
    "interest_threshold": "MWK50,000",
    "interest_capitalization": "Annually",
    "interbank_transfer_fees": "K 500.00",
    "atm_withdrawal_fees": "K 0.00",
    "cardless_withdrawal_fees": "K 420.00"
}

let eazyTransactBundled = {
    "name": "Eazy Transact Accounts",
    "interest_rate": "2.2%",
    "book_balance": "K 2,000.00",
    "bundled_fee": "K 2,500.00",
    "internal_transfer_money_fee": "K0.00",
    "interest_threshold": "MWK50,000",
    "interest_capitalization": "Annually",
    "interbank_transfer_fees": "K 500.00",
    "atm_withdrawal_fees": "K 0.00",
    "cardless_withdrawal_fees": "K 420.00",


}

let eazySaveBundled = Object.assign(
    {},
    eazyTransactBundled,
    {"name": "Eazy save Savings Accounts"},
    {"interest_rate": "2.0% to 7.0%"},
    {"book_balance": "K 20,000.00"},
    {"bundled_fee": "K 2,500.00"},
    {"internal_transfer_money_fee": "K0.00"}
);

let eazySaveUnBundled = Object.assign(
    {},
    eazySaveBundled,
    {"name": "Eazy save Savings Accounts"},
    {"bundled_fee": "K 0.00"},
    {"internal_transfer_money_fee": "K0.00"}
);

const diasporaAccountsKeyFacts = {
    accountTypes: {
        savings: {
            description: "A transactional savings account designed for Malawians in Diaspora, giving them an opportunity to save in Malawi.",
            interestRates: {
                description: "Interest rate",
                kwachaAccount: {
                    "title": "Kwacha".toUpperCase(),
                    LOWER: "4%: K50,000 threshold"
                },
                dollarAccount: {
                    title: "Dollar (USD)".toUpperCase(),
                    LOWER: '0.3%: $500 - $499,999',
                    MIDDLE: '0.50%: $500,000 - $999,999',
                    UPPER: '0.75%: $1,000,000 and Above'
                },
                poundAccount: {
                    title: "British Pound".toUpperCase(),
                    LOWER: '0.5%: GBP300 - GBP499,999',
                    MIDDLE: '0.75%: GBP500,000 - GBP999,999',
                    UPPER: '1.00%: GBP1,000,000 and Above'
                },
                southAfricanRandAccount: {
                    title: "RAND (ZAR)".toUpperCase(),
                    LOWER: '4%: ZAR10,000 - ZAR1,000,000',
                    MIDDLE: '4.5%: Above ZAR1,000,000'
                },
                euroAccount: {
                    title: "Euro".toUpperCase(),
                    LOWER: '0.25%: EUR400 - EUR499,999',
                    MIDDLE: '0.45%: EUR500,000 - EUR999,999',
                    HIGH: '0.75%: EUR1,000,000 and Above'
                }
            },
            bookBalance: {
                description: "Book Balance",
                fees: {
                    kwacha: "Kwacha: MWK50,000",
                    dollar: "Dollar: $ 0",
                    rand: "Rand: ZAR 0",
                    euro: "EURO: 0",
                    pound: "Pound: GBP 0"
                }
            },
            debitCard: {
                description: "Debit card",
                fees: "Visa Black card priced at MWK18,000 with an encashment limit of MWK800,000 on ATM and $5,000 online and POS",
                type: "Visa Black Card",
                price: "MWK18,000",
                encashmentLimit: {
                    atm: "MWK800,000",
                    online: "$5,000",
                    pos: "$5,000"
                }
            },
            accountClosure: {
                description: "Account closure within 6 months",
                fee: "MWK9,500"
            },
            swiftFromVostroAccount: {
                description: "Payment via SWIFT from Vostro account",
                fees: "1.2% min: MWK5,000, max: MWK30,000"
            },
            FCDATransfer: {
                toAccountOfAnotherBank: {
                    description: "Transfer from FCDA to MWK account of another bank",
                    fees: {
                        LOWER: "1.15% min MWK6,000, max MWK35,000",
                        MIDDLE: "Transfer after 3pm MWK39,400"
                    }
                },
                toCurrentAccountMaintainedWithNBSBank: {
                    description: "Transfer from FCDA to current account maintained within NBS Bank",
                    fee: "Free"
                },
                toAnotherBankInForeignCurrency: {
                    description: "Transfer from FCDA to another bank in foreign currency other transactions",
                    fee: "USD43/transfer"
                },
            },

            dishonouredForeignCheque: {
                description: "Dishonoured Foreign cheque",
                fee: "USD72 plus agent bank charges"
            },
            returningFunds: {
                description: "Returning Funds",
                fee: "$50"
            },
            swiftMessage: {
                description: "Swift message",
                fee: "$39"
            },
            postageOnForeignCheque: {
                description: "Postage on Foreign cheque deposited to FCDA",
                fee: "$49"
            },
            certificateOfBalances: {
                description: "Certificate of Balances",
                fee: "$26"
            },
            copyStatement: {
                sameYear: {
                    description: "Copy Statement-per page same year",
                    fee: "$23"
                },
                oneToTwoYears: {
                    description: "Copy Statement 1 to 2 years",
                    fee: "$41"
                },
                twoToSevenYears: {
                    description: "Copy Statement 2 to 7 years",
                    fee: "$69"
                }
            },
            investigating: {
                description: "Investigating beyond 6 months",
                fee: "$81"
            },
            sundryCharges: {
                description: "Sundry Charges",
                fees: [
                    "Statement e-mailer once-off: Free",
                    "Statement e-mailer Monthly charge for weekly statement: Free",
                    "Statement e-mailer Monthly per occurrence: Free",
                    "Statement e-mailer Monthly charge for daily statement: Free"
                ]
            },
            productBenefits: {
                description: "Benefits",
                fees: [
                    "Dedicated private banker",
                    "Account can be linked to internet banking and EazyApp",
                    "Can use the account to acquire offshore property",
                    "Visa debit card",
                    "No Ledger Fees",
                    "Earns Interest annually",
                    "Access to executive funeral cover (comes with a monthly fee or annual fees)"
                ]
            },
            whoCanAccess: "Malawians living in Diaspora",
            monthlyServiceFee: "No monthly service fee",
            accountAccessMethods: [
                "Service centres by using free cash withdrawal slips",
                "Internet Banking",
                "EazyApp",
                "Visa card"
            ],
            withdrawPenalty: "You can access your money anytime and there is no penalty"
        },
        investment: {
            description: "An investment account targeting the diaspora seeking to invest money home",
            interestRates: {
                description: "Interest rate",
                kwachaAccount: {
                    "title": "Kwacha".toUpperCase(),
                    LOWER: "5% per annum"
                },
                dollarAccount: {
                    title: "Dollar (USD)".toUpperCase(),
                    LOWER: '0.3%: $500 - $499,999',
                    MIDDLE: '0.50%: $500,000 - $999,999',
                    UPPER: '0.75%: $1,000,000 and Above'
                },
                poundAccount: {
                    title: "British Pound".toUpperCase(),
                    LOWER: '0.5%: GBP300 - GBP499,999',
                    MIDDLE: '0.75%: GBP500,000 - GBP999,999',
                    UPPER: '1.00%: GBP1,000,000 and Above'
                },
                southAfricanRandAccount: {
                    title: "RAND (ZAR)".toUpperCase(),
                    LOWER: '4%: ZAR10,000 - ZAR999,999',
                    MIDDLE: '4.5%: ZAR1,000,000 and above'
                },
                euroAccount: {
                    title: "Euro".toUpperCase(),
                    LOWER: '0.25%: EUR400 - EUR499,999',
                    MIDDLE: '0.45%: EUR500,000 - EUR999,999',
                    HIGH: '0.75%: EUR1,000,000 and Above'
                }
            },
            bookBalance: {
                description: "Book Balance",
                fees: {
                    kwacha: "Kwacha: MWK50,000",
                    dollar: "Dollar: $100",
                    rand: "Rand: ZAR 1,000",
                    euro: "EURO: 100",
                    pound: "Pound: GBP 100"
                }
            },
            accountClosure: {
                description: "Account closure within 6 months",
                fee: "MWK9,500"
            },
            withdrawalPenalty: {
                description: "Penalties for withdrawals",
                fees: "If notice is not given, customer will be charged 40% of interest earned that month"
            },
            productBenefits: {
                description: "Benefits",
                fees: [
                    "No monthly fees",
                    "Dedicated private banker",
                    "Wealth management",
                    "Executive funeral plan (with monthly charge)",
                    "Earns interest on monthly basis"
                ]
            },
            whoCanAccess: "Malawians living in Diaspora",
            monthlyServiceFee: "No monthly service fee",
            accountAccessMethods: [
                "Customer to give notice through private banker to transact",
                "Can indemnify the bank on transactions done through email instructions"
            ],
            withdrawPenalty: "Customers will forfeit 35% of interest earned that month if notice of withdrawal is not given"
        }
    },


}

export {
    student, pafupiUnbundled, eazyTransactUnbundled, eazyTransactBundled, eazySaveBundled, eazySaveUnBundled,
    diasporaAccountsKeyFacts,
    currentSilverUnbundled,
    currentSilverBundled, currentGoldBundled, currentPlatinumBundled, diasporaTransactional, diasporaInvestment
}