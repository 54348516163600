import React, {useState} from "react";
import {customSelectStyles, CustomStyle} from "../../helpers/styles";
import {FormControl, Typography} from "@mui/material";
import {CustomNBSTextField} from "../custom-componets/CustomNBSTextField";
import Select from "react-select";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const AvailableAccounts = (({updateCurrentPage, existingDetails, setExistingDetails}) => {
    const [errors, setErrors] = useState({});

    const short_name = existingDetails.short_name;
    const cell_phone = existingDetails.cell_phone;
    const allAccounts = existingDetails.all_accounts;
    const allowedAccounts = existingDetails.allowed_accounts;

    console.log(allAccounts)

    const optionList = allAccounts.map((account) => ({
        value: account[0], // Assuming account[0] is the account code or identifier
        label: account[1]  // Assuming account[1] is the account name or description
    }));

    console.log(optionList)

    console.log(allAccounts)
    const handleNextPage = (e) => {
        const isFormValid = validateForm();

        const selectedCode = parseInt(formData.accountType);

        console.log(selectedCode)
        // check if account is in allowed accounts proceed if is not, redirect
        const allowedCodes = allowedAccounts.map(account => account[0]);
        const allCodes = allAccounts.map(account => account[0]);

        console.log('Selected Code:', selectedCode);
        console.log('Allowed Codes:', allowedCodes);
        console.log('All Codes:', allCodes);

        if (isFormValid) {

            if (!allowedCodes.includes(selectedCode)) {
                console.log(`Code ${selectedCode} not found in allowed codes.`);
                setExistingDetails({
                    ...existingDetails,
                    account_category: selectedCode
                });
                updateCurrentPage("UpgradeRequirements");
                return;
            }
        }

        if (isFormValid) {
            setExistingDetails({
                ...existingDetails,
                account_category: formData.accountType
            });
            updateCurrentPage("AccountProductInformation");
        } else {
            console.log("Errors...");
        }
    }

    console.log(existingDetails)


    const handleChange = (selectedOption) => {
        setFormData({
            ...formData,
            accountType: selectedOption ? selectedOption.value : ""
        });
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...errors };

        if (!formData.accountType) {
            newErrors.accountType = "Please select an account.";
            valid = false;
        } else {
            newErrors.accountType = "";
        }

        setErrors(newErrors);
        return valid;
    };

    const [formData, setFormData] = useState({
        accountType: ""
    });


    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                Please select account type below:</Typography>

                <CustomNBSTextField
                    label="Account Name"
                    onInputChange={handleChange}
                    value={short_name}
                    inputType="text"
                    name="customer_name"
                    required="true"
                    disabled
                />

                <CustomNBSTextField
                    label="Cell phone"
                    onInputChange={handleChange}
                    value={cell_phone}
                    inputType="text"
                    name="cellPhone"
                    required="true"
                    disabled
                />

                <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                    <Typography variant="h6" sx={{
                        ...CustomStyle.subtitle,
                        textAlign: 'left',
                        fontSize: '16px',
                        marginBottom: '10px'
                    }}>
                        Available Accounts *
                    </Typography>
                    <Select
                        options={optionList}
                        value={optionList.find(option => option.value === formData.accountType)}
                        onChange={handleChange}
                        styles={customSelectStyles}
                        placeholder="Select Available Accounts"
                        isClearable
                        required
                    />
                    {errors.accountType &&
                        <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                            {errors.accountType}
                        </Typography>
                    }
                </FormControl>

                <div style={CustomStyle.buttonContainer}>

                <CustomNBSRedButton onClick={handleNextPage}
                                    disabled={false}>
                    Next
                </CustomNBSRedButton>
                </div>
            </div>
        </div>
    )
});