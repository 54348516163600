import React, {memo, useEffect, useState} from "react";
import {Scanner} from "./Scanner";
import {CustomStyle} from "../../helpers/styles";
import {Alert, Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const ScanComponent = memo(({updateCurrentPage, existingDetails, setExistingDetails}) => {
    const [message, setMessage] = useState(null);
    const [scannedData, setScannedData] = useState({});
    const [showAccountNumberField, setShowAccountNumberField] = useState(false);
    const account = existingDetails.account_number;

    const handleScannedData = async (data) => {
        console.log("Scanned datas " + data);
        setShowAccountNumberField(true);
        await setScannedData(data);
    }
    const handleNextPage = async (e) => {
        //const updatedExistingDetails = { ...existingDetails, scannedData };
        setExistingDetails({
            ...existingDetails, scannedData
        })
        updateCurrentPage("ScanVerification");

    }

    const handlePrevClick = () => {
        updateCurrentPage("AccountCheck");
    };
    console.log("initial value of scannedData:", scannedData);

    let isScanned = scannedData && Object.keys(scannedData).length > 10;

    if (Object.keys(scannedData).length > 10) {
        console.log("obj1 is not empty");
    } else {
        console.log("obj1 is empty");
    }

    console.log(scannedData)
    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>National ID Scanner</Typography>
                <Scanner onScan={handleScannedData}/>

                {message && (
                    <Alert severity="error" style={CustomStyle.alert}>
                        {message}
                    </Alert>
                )}
                <br/>
                <div style={CustomStyle.buttonContainer}>

                <CustomNBSRedButton onClick={handleNextPage}
                                    disabled={!isScanned}>
                    Confirm
                </CustomNBSRedButton>
                </div>


            </div>
        </div>
    );
});