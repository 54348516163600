import React from 'react';
import {Button} from "@mui/material";

const CustomNBSBlueButton = ({ children, onClick, ...props }) => {
    return (
        <Button
            onClick={onClick}
            variant="contained"
            sx={{
                backgroundColor: '#0056b3', // NBS blue color
                color: '#ffffff', // White text color
                borderRadius: '25px', // Rounded corners
                padding: '8px 16px', // Adjusted padding
                fontSize: '1rem', // Slightly reduced for better responsiveness
                textTransform: 'none', // Disable uppercase text
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow
                transition: 'background-color 0.3s, transform 0.3s', // Smooth transition
                width: '80%', // Use percentage width for responsiveness
                maxWidth: '250px', // Maximum width to prevent it from being too wide
                margin: '10px auto', // Center the button and add margin
                '&:hover': {
                    backgroundColor: '#004494', // Darker blue on hover
                    transform: 'scale(1.05)', // Slight zoom effect on hover
                },
                '&:active': {
                    backgroundColor: '#003366', // Even darker blue on active
                },
                '@media (max-width: 480px)': {
                    fontSize: '0.9rem', // Further reduce font size on very small screens
                    padding: '6px 12px', // Adjust padding for small screens
                },
            }}
            {...props}
        >
            {children}
        </Button>
    );
};

export default CustomNBSBlueButton;


