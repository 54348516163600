import React, {useState, useEffect} from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import {Box, Typography, Modal} from "@mui/material";
import CurrencyInput from "react-currency-input-field";
import {CustomStyle} from "../../helpers/styles";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import {CheckCircleOutline, ErrorOutline} from "@mui/icons-material";

export const FixedAccountDetails = ({updateCurrentPage, fixedDepositDetails, setFixedDepositDetails}) => {
    console.log(fixedDepositDetails)
    const [accountDetails, setAccountDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [redeemAmount, setRedeemAmount] = useState('');
    const [modalError, setModalError] = useState('');
    const [loadingPartialRedemption, setLoadingPartialRedemption] = useState(false);
    const [loadingFullRedemption, setLoadingFullRedemption] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [showTopUpModal, setShowTopUpModal] = useState(false);
    const [topUpAmount, setTopUpAmount] = useState('');
    const [topUpError] = useState('');
    const [loadingTopup, setLoadingTopup] = useState(false);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);


    useEffect(() => {
        queryAccountDetails();
    }, []);

    const queryAccountDetails = async () => {
        const requestBody = {
            request_type: 'QUERY_FIXED_ACCOUNT',
            fixed_account: fixedDepositDetails.selectedOption,
        };

        const url = '/query-fixed-account';
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const result = await axios.post(url, requestBody, config);

            if (result.data.status) {

                setAccountDetails(result.data);

            } else {
                setErrorMessage('Error fetching account details.');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Error fetching account details.');
        } finally {
            setLoading(false);
        }
    };


    const handleFullRedemption = async () => {
        const requestBody = {
            request_type: 'CLOSE_FIXED_ACCOUNT',
            fixed_account: accountDetails.account_number,
        };
        setLoadingFullRedemption(true);
        setShowDisclaimerModal(false)

        const url = '/full-redeem-account';
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const result = await axios.post(url, requestBody, config);
            if (result.data.status) {
                const referenceNumber = result.data.redeem_ref;
                setSuccessMessage(`Full redemption successful. Your reference number is: ${referenceNumber}`);
                // goToHome();
            } else {
                setErrorMessage('Error processing full redemption.');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Error processing full redemption.');
        } finally {
            setLoadingFullRedemption(false);
        }
    };

    const handleTopUpDeposit = () => {
        setShowTopUpModal(true);
    }

    const handleTopupSubmit = async () => {
        console.log("term acc " + accountDetails.account_number)

        if (!topUpAmount || parseFloat(topUpAmount) <= 0) {
            setModalError('Please enter a valid amount.');
            return;
        }

        const requestBody = {
            request_type: 'TOPUP_FIXED_ACCOUNT',
            fixed_account: accountDetails.account_number,
            amount: topUpAmount,
        };
        setLoadingTopup(true);
        setShowTopUpModal(false);

        const url = '/topup-deposit';
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const result = await axios.post(url, requestBody, config);
            if (result.data.status) {
                const referenceNumber = result.data.top_up_ref;
                setSuccessMessage(`Topup successful. Your reference number is: ${referenceNumber}`);
                // goToHome();
            } else {
                setErrorMessage('Error processing Topup.');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Error processing Topup.');
        } finally {
            setLoadingTopup(false);
        }
    };

    const handleRedeemSubmit = async () => {
        console.log("Redeem Amount on Submit:", redeemAmount);

        if (!redeemAmount || parseFloat(redeemAmount) <= 0) {
            setModalError('Please enter a valid redemption amount.');
            return;
        }

        const remainingBalance = accountDetails.available_balance - parseFloat(redeemAmount);
        console.log("Remaining Balance:", remainingBalance);
        if (remainingBalance < 50000) {
            setModalError('Remaining balance should not be less than MWK50,000.');
            return;
        }
        setLoadingPartialRedemption(true);
        setShowModal(false);

        const requestBody = {
            request_type: 'REDEEM_FIXED_ACCOUNT',
            fixed_account: accountDetails.account_number,
            amount: redeemAmount,
            payout_comp: 'P'
        };

        const url = '/partial-redeem-account';
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const result = await axios.post(url, requestBody, config);
            if (result.data.status) {
                setShowModal(false);
                const referenceNumber = result.data.redeem_ref;
                setSuccessMessage(`Partial redemption successful. Your reference number is: ${referenceNumber}`);
            } else {
                setErrorMessage('Error processing full redemption.');

                setModalError('Error processing partial redemption.');
            }
        } catch (error) {
            console.error(error);
            setModalError('Error processing partial redemption.');
        } finally {
            setLoadingPartialRedemption(false);

        }
    };

    const goToHome = () => {
        document.location.reload();
        updateCurrentPage('HomePage');
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'MWK'
        }).format(amount);
    };

    const renderContent = () => {
        if (loading || loadingPartialRedemption || loadingFullRedemption || loadingTopup) {
            return (
                <>
                    <CircularProgress size={50}/>
                    <Typography variant="h5" sx={CustomStyle.subtitle}>Please wait...</Typography>
                </>
            );
        }

        if (successMessage) {
            return (
                <>
                    <CheckCircleOutline style={{fontSize: 80, color: 'green'}}/>
                    <Typography variant="h5" sx={CustomStyle.subtitle}>
                        {successMessage}
                    </Typography>

                    <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={goToHome}
                                        disabled={false}>
                        Home
                    </CustomNBSRedButton>
                    </div>


                </>
            );
        }

        if (errorMessage) {
            return (
                <>
                    <ErrorOutline style={{fontSize: 80, color: 'red'}}/>
                    <Typography variant="h5" sx={CustomStyle.subtitle}>{errorMessage}</Typography>
                    <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={goToHome}
                                        disabled={false}>
                        Home
                    </CustomNBSRedButton>
                    </div>
                </>
            );
        }

        const handlePartialRedemption = () => {
            setShowModal(true);
        };

        const handleFullRedemptionClick = () => {
            setShowDisclaimerModal(true);
        };

        const translateAutoroll = (autoroll) => {
            return autoroll === 'Y' ? 'Yes' : 'No';
        };


        if (accountDetails) {
            return (
                // <div style={CustomStyle.pageWrapper}>
                //     <div style={CustomStyle.contentContainer}>
                <>
                    <Typography variant="h5" sx={CustomStyle.heading}>

                        Fixed Deposit Account Details

                    </Typography>

                    <Box sx={{
                        border: '2px solid #2b357c', // NBS blue border
                        borderRadius: '8px',
                        p: 3, // Padding inside the border
                        mb: 10
                    }}>

                        <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2}}>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                Customer Name:
                            </Typography>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                {accountDetails.customer_name}
                            </Typography>
                        </Box>


                        <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2}}>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                Fixed Account Number:
                            </Typography>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                {accountDetails.account_number}
                            </Typography>
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2}}>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                Account Balance (MWK):
                            </Typography>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                {formatAmount(accountDetails.available_balance)}
                            </Typography>
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2}}>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                Interest Rate (Per Annum):
                            </Typography>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                {accountDetails.interest_rate} %
                            </Typography>
                        </Box>


                        <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2}}>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                Maturity Date:
                            </Typography>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                {accountDetails.maturity_date}
                            </Typography>
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2}}>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                Rollover on Maturity:
                            </Typography>
                            <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                {translateAutoroll(accountDetails.auto_roll)}
                            </Typography>
                        </Box>

                    </Box>


                    <Typography variant="h5" sx={CustomStyle.subtitle}>Select option you would like to do with the
                        account:</Typography>

                    <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton
                        onClick={handlePartialRedemption}>
                        Partial Redemption
                    </CustomNBSRedButton>

                    <CustomNBSRedButton
                        onClick={handleFullRedemptionClick}>
                        Full Redemption
                    </CustomNBSRedButton>

                    <CustomNBSRedButton
                        onClick={handleTopUpDeposit}>
                        Top Up Deposit
                    </CustomNBSRedButton>
                    </div>

                </>
            );
        }

        return null;
    };

    return <div style={CustomStyle.pageWrapper}>
        <div style={CustomStyle.contentContainer}>
            {renderContent()}

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%', // Adjusts to 90% of the screen width
                    maxWidth: 500, // Maximum width remains 500px
                    maxHeight: '90vh', // Ensures the modal does not exceed the viewport height
                    bgcolor: 'background.paper',
                    borderRadius: '12px',
                    boxShadow: 24,
                    p: 4,
                    overflowY: 'auto'
                }}>
                    <Typography
                        id="modal-title"
                        variant="h5"
                        component="h2"
                        sx={{
                            color: '#2b357c',
                            mb: 3,
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}>
                        Partial Redemption
                    </Typography>

                    <Box sx={{
                        // border: '2px solid #2b357c', // NBS blue border
                        // borderRadius: '8px',
                        p: 3, // Padding inside the border
                        mb: 10
                    }}>
                        <div style={{marginBottom: '16px'}}>
                            <Typography variant="h6" sx={{
                                ...CustomStyle.subtitle,
                                textAlign: 'left',
                                fontSize: '15px',
                                marginBottom: '10px'
                            }}>
                                Redemption Amount (MK) *
                            </Typography>
                            <CurrencyInput
                                style={CustomStyle.currencyInput}
                                className="form-control"
                                id="redeemAmount"
                                name="redeemAmount"
                                placeholder="Please enter an amount"
                                min={1}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                value={redeemAmount}
                                onValueChange={(value) => {
                                    console.log("Value changed:", value);
                                    setRedeemAmount(value);
                                }}
                                required
                            />
                            {modalError &&
                                <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                    {modalError}
                                </Typography>
                            }
                        </div>

                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <CustomNBSRedButton
                            onClick={handleRedeemSubmit}
                        >
                            Proceed
                        </CustomNBSRedButton>
                    </Box>
                </Box>
            </Modal>


            <Modal
                open={showTopUpModal}
                onClose={() => setShowTopUpModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%', // Adjusts to 90% of the screen width
                    maxWidth: 500, // Maximum width remains 500px
                    maxHeight: '90vh', // Ensures the modal does not exceed the viewport height
                    bgcolor: 'background.paper',
                    borderRadius: '12px',
                    boxShadow: 24,
                    p: 4,
                    overflowY: 'auto'
                }}>
                    <Typography
                        id="modal-title"
                        variant="h5"
                        component="h2"
                        sx={{
                            color: '#2b357c',
                            mb: 3,
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}>
                        TopUp Deposit
                    </Typography>

                    <Box sx={{
                        // border: '2px solid #2b357c', // NBS blue border
                        // borderRadius: '8px',
                        p: 3, // Padding inside the border
                        mb: 10
                    }}>
                        <div style={{marginBottom: '16px'}}>
                            <Typography variant="h6" sx={{
                                ...CustomStyle.subtitle,
                                textAlign: 'left',
                                fontSize: '15px',
                                marginBottom: '10px'
                            }}>
                                Topup Amount (MK) *
                            </Typography>
                            <CurrencyInput
                                style={CustomStyle.currencyInput}
                                className="form-control"
                                id="topUpAmount"
                                name="topUpAmount"
                                placeholder="Please enter an amount"
                                min={1}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                value={topUpAmount}
                                onValueChange={(value) => {
                                    console.log("Value changed:", value);
                                    setTopUpAmount(value);
                                }}
                                required
                            />
                            {modalError &&
                                <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                                    {modalError}
                                </Typography>
                            }
                        </div>

                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <CustomNBSRedButton
                            onClick={handleTopupSubmit}
                        >
                            Proceed
                        </CustomNBSRedButton>
                    </Box>
                </Box>
            </Modal>


            <Modal
                open={showDisclaimerModal}
                onClose={() => setShowDisclaimerModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%', // Adjusts to 90% of the screen width
                    maxWidth: 500, // Maximum width remains 500px
                    maxHeight: '90vh', // Ensures the modal does not exceed the viewport height
                    bgcolor: 'background.paper',
                    borderRadius: '12px',
                    boxShadow: 24,
                    p: 4,
                    overflowY: 'auto'
                }}>
                    <Typography
                        id="modal-title"
                        variant="h5"
                        component="h2"
                        sx={{
                            color: '#2b357c',
                            mb: 3,
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}>
                        Full Redemption
                    </Typography>

                    <Box sx={{
                        // border: '2px solid #2b357c', // NBS blue border
                        // borderRadius: '8px',
                        p: 3, // Padding inside the border
                        mb: 10
                    }}>
                        <Typography variant="h5" sx={CustomStyle.subtitle}>
                            You are about to redeem and close this deposit account. Please confirm to
                            proceed.</Typography>

                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <CustomNBSRedButton
                            onClick={handleFullRedemption}
                        >
                            Proceed
                        </CustomNBSRedButton>
                    </Box>
                </Box>
            </Modal>
        </div>
    </div>;
};
