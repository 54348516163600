import React, { memo } from 'react';
import CustomRadioButton from "../custom-componets/RadioButton";
import CustomButton from "../custom-componets/Button";
//TODO:: (Code repetition alert) Merge DiasporaCustomerDetails into CustomerDetails component. Add checks to prevent National ID from being scanned there

export const DiasporaCustomerDetails=memo(({updateCurrentPage,setCustomerDetails})=>{
    const nextPage = "DiasporaCustomerDetails";
    const changeNeedCardSatatus=(val)=>{
        setCustomerDetails((prev)=>{
            return({...prev,marital_status:val})
        })
    }
    const handleChange = (e)=>{
        setCustomerDetails((prev)=>{
            return({...prev,cust_title:e.target.value})
        })
    }

    return(
        <div className="content">
            <p  style={{fontWeight:"bold",textAlign:"center"}}>
            Customer Details</p>
            <form  className="was-validated" >
                <span className="w3-text-indigo">Title *</span>
                <select className="form-select rounded-pill w3-text-indigo form-floating" onChange={handleChange} aria-label="Default select example" defaultValue="MR" required>
                    <option value="DR">Dr</option>
                    <option value="MISS">Miss</option>
                    <option value="MR" >Mr</option>
                    <option value="MRS">Mrs</option>
                    <option value="PROF">Prof</option>
                </select>
                <br/>
                <span className="w3-text-indigo">Marital status *</span>
                <br/>
                <div className="d-flex">
                    <CustomRadioButton label={"Single"} name="maritalStatus" checked="true" changeNeedCardSatatus={changeNeedCardSatatus}/>
                    <CustomRadioButton label={"Married"} name="maritalStatus" changeNeedCardSatatus={changeNeedCardSatatus}/>
                    {/* <CustomRadioButton label={"Divorced"} name="maritalStatus" changeNeedCardSatatus={changeNeedCardSatatus}/> */}
                </div>
                <br/>
                <br/>
                <CustomButton label="Next" updateCurrentPage={updateCurrentPage} next="ContactDetails"/>
            </form>    
        </div>
    );
})