import React, {useEffect, useState} from "react";
import {OtpVerification} from "./OtpVerification";
import axios from "axios";
import {CustomStyle} from "../../helpers/styles";
import {Box, CircularProgress, FormControl, FormControlLabel, Radio, Typography} from "@mui/material";
import {RadioGroup} from "react-radio-buttons";

export const AccountCheck = (({updateCurrentPage, existingDetails, setExistingDetails}) => {
    const [otpVerification, setOtpVerification] = useState("");
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);

    const accountNumber = existingDetails.account_number;
    console.log(existingDetails)

    const handleRadioChange = async (event) => {
        const selectedOption = event.target.value;
        setOtpVerification(selectedOption);

        if (selectedOption === "no") {
            updateCurrentPage("ScanComponent");
        } else if (selectedOption === "yes") {
            await performAPICall();  // Perform OTP API call if "yes" is selected
        }
    };

    useEffect(() => {
        if (otpVerification === "yes") {
            performAPICall();
        }
    }, [otpVerification]);

    const performAPICall = async () => {
        if (isRequesting) return;
        setIsRequesting(true);
        const accountBody = {
            "account_number": accountNumber,
            "type": "request"
        };

        const url = '/otp';
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        console.log("Inside perform api call function");
        try {
            setLoading(true);

            const result = await axios.post(url, accountBody, config);
            setLoading(false);
            setIsRequesting(false);
            if (result.status === 200 && result.data.status) {
                setExistingDetails({
                    ...existingDetails,
                    mobile_no: result.data.mobile_no,
                    msg: result.data.msg,
                    status: result.data.status,
                });

                updateCurrentPage('OtpVerification');
            } else {
                setMessage('Could not verify account, Please provide a valid account NBS number.');
            }

        } catch (error) {
            setLoading(false);
            setIsRequesting(false);
            setMessage('Something went wrong. Try again later.');
            console.error(error);
        }


    };

    const [formData, setFormData] = useState({
        accountType: ""
    });

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Choose verification</Typography>


                <Typography variant="h6" sx={{
                    ...CustomStyle.subtitle,
                    textAlign: 'left',
                    fontSize: '16px',
                    marginBottom: '10px'
                }}>Select Option *</Typography>

                <Box sx={{mb: 3, textAlign: 'left'}}>
                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>

                        <RadioGroup
                            aria-label="verification"
                            name="verification"
                            onChange={handleRadioChange}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'left',
                                marginBottom: '16px'
                            }}
                        >
                            <FormControlLabel
                                label="One Time Pin"
                                value="yes"
                                control={<Radio/>}


                            />
                            <FormControlLabel
                                label="Scan ID"
                                value="no"
                                control={<Radio/>}


                            />
                        </RadioGroup>
                        {/*{errors.auto_roll && (*/}
                        {/*    <Typography variant="body2" color="error">*/}
                        {/*        {errors.auto_roll}*/}
                        {/*    </Typography>*/}
                        {/*)}*/}
                    </FormControl>

                    {loading && (
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress/>
                        </Box>
                    )}
                </Box>

            </div>
        </div>
    );
});