import React, {useState, useEffect} from 'react';
import Alert from '../../componets/Alert';
import QrReader from "react-qr-reader";
import QrScanner from "qr-scanner";
import {Button} from "react-bootstrap";
import IDDetailsCard from "../custom-componets/IDDetailsCard";
import {Box, FormControl, Typography} from "@mui/material";
import {customSelectStyles, CustomStyle} from "../../helpers/styles";
import Select from "react-select";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import {AlertModal} from "../custom-componets/AlertModal";

export const Scanner = (props) => {
    const {onScan} = props;
    const [uploadPic, setUploadPic] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [selected, setSelected] = useState("environment");
    const [startScan, setStartScan] = useState(false);
    const [showAlertMsg, setShowAlertMsg] = useState("Failed to scan the QR code.\nPlease make sure the QR is well focused");
    const [loadingScan, setLoadingScan] = useState(false);
    const [data, setData] = useState("");
    const [idDetails, setIdDetails] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        setStartScan(true)
    }, [])

    let stream = null;
    const setStream = (val) => {
        stream = val;
    }

    const handleClose = () => {
        setShowAlert(false);
    };

    const handleScan = async (scanData) => {
        setLoadingScan(true);
        // console.log(`loaded data data`, scanData);
        if (scanData && scanData !== "") {
            // console.log(`loaded >>>`, scanData);
            setData(scanData);
            //enableNextButton(true);
            setStartScan(false);
            setLoadingScan(false);
            setUploadPic(false);

            // setPrecScan(scanData);

            let exp_date = scanData.replace(/</g, " ").split("~")[2];
            exp_date = exp_date.match(/(M|F)([0-9]+)/)[2];

            let charLength = scanData.split("~").length;
            let partsDiffFactor = 0;
            if (charLength === 11) {
                partsDiffFactor = 1;
            }

            setIdDetails({
                "firstname": scanData.replace(/</g, " ").split("~")[6],
                "surname": scanData.replace(/</g, " ").split("~")[4],
                "other_name": scanData.replace(/</g, " ").split("~")[7],
                "id_number": scanData.replace(/</g, " ").split("~")[5],
                "gender": scanData.replace(/</g, " ").split("~")[8 - partsDiffFactor],
                "id_issue_date": new Date(scanData.replace(/</g, " ").split("~")[10 - partsDiffFactor]).toDateString().toUpperCase(),
                "date_of_birth": new Date(scanData.replace(/</g, " ").split("~")[9 - partsDiffFactor]).toDateString().toUpperCase(),
                "id_expiry_date": new Date("20" + exp_date.substring(0, 2) + "/" + exp_date.substring(2, 4) + "/" + exp_date.substring(4, 6)).toDateString().toUpperCase()
            });

        }
    };
    const imageQRClick = () => {
        if (stream !== null)
            stream.getTracks().forEach(function (track) {
                track.stop();
                stream.removeTrack(track)
            });
        setStartScan(false);
        setUploadPic(true);
    }

    let dataFields = (Object.keys(idDetails).length !== 0) &&
        <IDDetailsCard idDetails={idDetails} showSpinner={showSpinner}/>

    const handleError = (err) => {
        // console.error(err);
    };

    const fileUploaded = (event) => {
        // const file_selector = document.getElementById('file-selector');
        // const image = file_selector.files[0];

        const image = event.target.files[0];

        if (!image) return;

        // Check if the uploaded file is an image
        if (!image.type.startsWith('image/')) {
            setShowAlertMsg("Please upload an image file (JPEG or PNG) with a visible QR code.");
            setShowAlert(true);
            return;
        }

        QrScanner.scanImage(image, {returnDetailedScanResult: true}).then(result => handleScan(result.data))
            .catch(error => {
                setShowAlertMsg("Failed to scan the QR code.\nPlease make sure the QR is well focused")
                setShowAlert(true)
                // console.log(error || 'No QR code found.')
            })
    }

    // console.log(idDetails)

    if (onScan) {
        onScan(JSON.stringify(idDetails))
    }

    const cameraOptions = [
        {value: 'environment', label: 'Back Camera'},
        {value: 'user', label: 'Front Camera'},
    ]

    return (
        <>
            <Typography variant="h6" sx={{
                ...CustomStyle.subtitle,
                fontSize: '15px', marginBottom: '10px'
            }}>Please scan the QR at the back of your ID</Typography>

            {startScan && (
                <>
                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}
                        >Camera Options</Typography>
                        <Select
                            options={cameraOptions}
                            value={cameraOptions.find(option => option.value === selected)}
                            onChange={(selectedOption) => setSelected(selectedOption.value)}
                            styles={customSelectStyles}
                            required
                        />
                    </FormControl>

                    <div
                        style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: '10px'}}>

                        <QrReader
                            facingMode={selected}
                            delay={1000}
                            onError={handleError}
                            onScan={handleScan}
                            // chooseDeviceId={()=>selected}
                            style={{
                                width: "300px", display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        />
                    </div>

                    <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton
                        onClick={imageQRClick}
                        disabled={false}
                    >
                        Upload the QR image
                    </CustomNBSRedButton>
                    </div>


                </>
            )}


            {/*{loadingScan && <p>Loading</p>}*/}

            {data !== "" && <div>{dataFields}</div>}

            {showAlert ?
                // <Alert setShowAlert={setShowAlert} title={"Error"} body={showAlertMsg}/>

                <AlertModal open={showAlert} onClose={handleClose} message={showAlertMsg} />

                :
                uploadPic &&
                (
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h5" sx={CustomStyle.subtitle}>
                            Select the image with visible QR Code</Typography>


                        <Box sx={{ marginTop: 2 }}>
                            <div style={CustomStyle.buttonContainer}>

                            <CustomNBSRedButton
                                variant="contained"
                                component="label"
                            >
                                Choose File
                                <input
                                    type="file"
                                    onChange={fileUploaded}
                                    name="NationalID"
                                    accept="image/png, image/jpeg"
                                    style={{ display: 'none' }} // Hide the file input
                                />
                            </CustomNBSRedButton>
                            </div>
                        </Box>
                    </Box>
                )}
        </>
    );
};

