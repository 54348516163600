import React from 'react';
import Select from 'react-select';
import {customSelectStyles} from "../helpers/styles";

const Districts = ({ name, defaultValue, handleChange, nullDefault }) => {
        const options = [
                { value: 'Balaka', label: 'Balaka' },
                { value: 'Blantyre', label: 'Blantyre' },
                { value: 'Chikwawa', label: 'Chikwawa' },
                { value: 'Chiradzulu', label: 'Chiradzulu' },
                { value: 'Chitipa', label: 'Chitipa' },
                { value: 'Dedza', label: 'Dedza' },
                { value: 'Dowa', label: 'Dowa' },
                { value: 'Karonga', label: 'Karonga' },
                { value: 'Kasungu', label: 'Kasungu' },
                { value: 'Likoma', label: 'Likoma' },
                { value: 'Lilongwe', label: 'Lilongwe' },
                { value: 'Machinga', label: 'Machinga' },
                { value: 'Mangochi', label: 'Mangochi' },
                { value: 'Mchinji', label: 'Mchinji' },
                { value: 'Nkhotakota', label: 'Nkhotakota' },
                { value: 'Mulanje', label: 'Mulanje' },
                { value: 'Mwanza', label: 'Mwanza' },
                { value: 'Mzimba', label: 'Mzimba' },
                { value: 'Mzuzu', label: 'Mzuzu' },
                { value: 'Nkhata Bay', label: 'Nkhata Bay' },
                { value: 'Nsanje', label: 'Nsanje' },
                { value: 'Ntcheu', label: 'Ntcheu' },
                { value: 'Ntchisi', label: 'Ntchisi' },
                { value: 'Thyolo', label: 'Thyolo' },
                { value: 'Phalombe', label: 'Phalombe' },
                { value: 'Rumphi', label: 'Rumphi' },
                { value: 'Salima', label: 'Salima' },
                { value: 'Zomba', label: 'Zomba' },
        ];

        const defaultOption = nullDefault ? { value: '', label: 'Select a District' } : options.find(option => option.value === defaultValue);

        return (
            <Select
                name={name}
                options={nullDefault ? [{ value: '', label: '' }, ...options] : options}
                defaultValue={defaultOption}
                onChange={(selectedOption) => handleChange({ target: { name, value: selectedOption.value } })}
                styles={customSelectStyles}
                isSearchable
                placeholder="Select a District"
            />
        );
}

export default React.memo(Districts);
