import React, {memo, useState} from "react";
import {Scanner} from "../existing-accounts/Scanner";
import {CustomStyle} from "../../helpers/styles";
import {Alert, CircularProgress, Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const ScannerComponent = memo(({ updateCurrentPage, fixedDepositDetails, setFixedDepositDetails }) => {
    const [message, setMessage] = useState(null);
    const [scannedData, setScannedData] = useState({});
    const [showAccountNumberField, setShowAccountNumberField] = useState(false);
    const account = fixedDepositDetails.account_number;
    const [loading, setLoading] = useState(false);

    const handleScannedData = async (data) => {
        console.log("Scanned datas " + data);
        setShowAccountNumberField(true);
        await setScannedData(data);
    }
    const handleNextPage = async (e) => {
        //const updatedExistingDetails = { ...existingDetails, scannedData };
        setFixedDepositDetails({
            ...fixedDepositDetails, scannedData
        })
        updateCurrentPage("Verification");

    }
    console.log("initial value of scannedData:", scannedData);

    let isScanned = scannedData && Object.keys(scannedData).length > 10;

    if (Object.keys(scannedData).length > 10) {
        console.log("obj1 is not empty");
    } else {
        console.log("obj1 is empty");
    }

    console.log(scannedData)

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h6" sx={CustomStyle.heading}>
                    ID Scanner
                </Typography>
                <Scanner onScan={handleScannedData} />

                {message && (
                    <Alert severity="info" style={CustomStyle.alert}>
                        {message}
                    </Alert>
                )}
                <br/>

                <div style={CustomStyle.buttonContainer}>

                <CustomNBSRedButton onClick={handleNextPage}
                                    disabled={!isScanned} style={{marginTop:'5px'}}>
                    {loading ? <CircularProgress color="inherit" size={24}/> : 'Confirm'}
                </CustomNBSRedButton>
                </div>
            </div>
        </div>
    );
});