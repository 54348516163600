import React, {useState, useEffect} from 'react';
import QrScanner from 'qr-scanner';
import moment from 'moment';
import QrReader from "react-qr-reader";
import {customerDataAlreadySubmitted} from '../helpers/Diaspora'
import IDDetailsCard from "./custom-componets/IDDetailsCard";
import {customSelectStyles, CustomStyle} from "../helpers/styles";
import {Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Typography} from "@mui/material";
import Select from "react-select";
import CustomNBSRedButton from "./custom-componets/CustomNBSRedButton";
import {AlertModal} from "./custom-componets/AlertModal";

const ReactQRScanner = (props) => {
    const {enableNextButton, customerDetailsObject, setLoadingScanState} = props
    const {customerDetails, setCustomerDetails} = customerDetailsObject;
    const [uploadPic, setUploadPic] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [selected, setSelected] = useState("environment");
    const [startScan, setStartScan] = useState(false);
    const [showAlertMsg, setShowAlertMsg] = useState("");
    const [loadingScan, setLoadingScan] = useState(false);
    const [data, setData] = useState("");
    const [idDetails, setIdDetails] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        setStartScan(true)
    }, [])

    const handleClose = () => {
        setShowAlert(false);
    };

    let stream = null;
    const setStream = (val) => {
        stream = val;
    }

    const handleScan = async (scanData) => {
        setLoadingScan(true);
        setLoadingScanState(true);
        // console.log(`loaded data data`, scanData);
        if (scanData && scanData !== "") {
            // console.log(`loaded >>>`, scanData);
            setData(scanData);
            // enableNextButton(true);
            setStartScan(false);
            // setLoadingScan(false);
            setUploadPic(false);
            // setPrecScan(scanData);

            let exp_date = scanData.replace(/</g, " ").split("~")[2];
            exp_date = exp_date.match(/(M|F)([0-9]+)/)[2];

            let charLength = scanData.split("~").length;
            let partsDiffFactor = 0;
            if (charLength === 11) {
                partsDiffFactor = 1;
            }

            // Handle both old and new patterns
            let scanned_details = {
                "firstname": scanData.replace(/</g, " ").split("~")[6],
                "surname": scanData.replace(/</g, " ").split("~")[4],
                "other_name": scanData.replace(/</g, " ").split("~")[7],
                "id_number": scanData.replace(/</g, " ").split("~")[5],
                "gender": scanData.replace(/</g, " ").split("~")[8 - partsDiffFactor],
                "id_issue_date": new Date(scanData.replace(/</g, " ").split("~")[10 - partsDiffFactor]).toDateString().toUpperCase(),
                "date_of_birth": new Date(scanData.replace(/</g, " ").split("~")[9 - partsDiffFactor]).toDateString().toUpperCase(),
                "id_expiry_date": new Date("20" + exp_date.substring(0, 2) + "/" + exp_date.substring(2, 4) + "/" + exp_date.substring(4, 6)).toDateString().toUpperCase()
            }

            // Adjust date formats
            let scanned_details_pre = scanned_details;

            console.log(scanned_details.date_of_birth)
            scanned_details_pre.date_of_birth = moment(
                scanned_details.date_of_birth,
                "ddd MMM DD YYYY",
                true // Strict parsing to avoid fallbacks
            ).format("YYYY-MM-DD");
            const dob = new Date(scanned_details.date_of_birth);
            const ageDiff = Date.now() - dob.getTime();
            const ageDate = new Date(ageDiff); // miliseconds from epoch
            const age = Math.abs(ageDate.getUTCFullYear() - 1970);

            console.log("Age: " + age)
            // console.log("product: " + customerDetails.account_product)

            if (age < 16) {
                setShowAlertMsg("You must be 16 years or older to proceed.");
                setShowAlert(true);
                enableNextButton(false);

                // to fix the rolling back to scanner on existing ID
                setStartScan(true);
                setIdDetails({});
                setLoadingScan(false);
                setLoadingScanState(false);
                return;
            } else if (age < 18 && customerDetails.account_product !== "student") {
                setShowAlertMsg("You must be 18 years or older to proceed for a non-student account.");
                setShowAlert(true);
                enableNextButton(false);

                // to fix the rolling back to scanner on existing ID
                setStartScan(true);
                setIdDetails({});
                setLoadingScan(false);
                setLoadingScanState(false);
                return;
            }

            // console.log("ID DETAILS FROM : " + JSON.stringify(scanned_details))
            customerDataAlreadySubmitted(scanned_details_pre).then((res) => {
                console.log("RESPONSE FROM SUBMISSION" + res)
                if (res) {
                    setShowAlertMsg(`Dear ${customerDetails.cust_title.toString().trim().replace(/^\w/, (c) => c.toUpperCase())} ${scanned_details.firstname.charAt(0)}. ${scanned_details.surname}, you already have an account with NBS Bank.\nPlease visit your nearest service center or call 322.`)
                    setShowAlert(true);
                    //enableNextButton(false);
                    enableNextButton(true);

                    // for now to fix the rolling back to scanner on existing ID
                    setStartScan(true);
                    setIdDetails({});
                } else {
                    enableNextButton(true);
                }
                setShowSpinner(false);
                setLoadingScan(false);
                setLoadingScanState(false);
            });
            setIdDetails(scanned_details);
            setShowSpinner(true);

        }
    };

    useEffect(() => {
        setCustomerDetails((prev) => {
            return ({...prev, ...idDetails})
        })
    }, [idDetails, setCustomerDetails])
    const imageQRClick = () => {
        if (stream !== null)
            stream.getTracks().forEach(function (track) {
                track.stop();
                // code new
                stream.removeTrack(track)
            });
        setStartScan(false);
        setUploadPic(true);
    }

    let dataFields = (Object.keys(idDetails).length !== 0) &&
        <IDDetailsCard idDetails={idDetails} showSpinner={showSpinner}/>

    const handleError = (err) => {
        console.error(err);
    };

    const fileUploaded = (event) => {
        // const file_selector = document.getElementById('file-selector');
        // const image = file_selector.files[0];

        const image = event.target.files[0];
        if (!image) return;
        QrScanner.scanImage(image, {returnDetailedScanResult: true}).then(result => handleScan(result.data))
            .catch(error => {
                setShowAlertMsg("Failed to scan the QR code.\nPlease make sure the QR is well focused")
                setShowAlert(true)
                console.log(error || 'No QR code found.')
            })
    }

    const cameraOptions = [
        {value: 'environment', label: 'Back Camera'},
        {value: 'user', label: 'Front Camera'},
    ]

    // console.log(idDetails)
    return (
        <>
            <Typography variant="h6" sx={{
                ...CustomStyle.subtitle,
                fontSize: '15px', marginBottom: '10px'
            }}>Please scan the QR at the back of your ID</Typography>
            {startScan && (
                <>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}
                        >Camera Options</Typography>
                        <Select
                            options={cameraOptions}
                            value={cameraOptions.find(option => option.value === selected)}
                            onChange={(selectedOption) => setSelected(selectedOption.value)}
                            styles={customSelectStyles}
                            required
                        />
                    </FormControl>

                    <div
                        style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: '10px'}}>
                        <QrReader
                            facingMode={selected}
                            delay={1000}
                            onError={handleError}
                            onScan={handleScan}
                            // chooseDeviceId={()=>selected}
                            style={{
                                width: "300px", display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        />
                    </div>

                    <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton
                        onClick={imageQRClick}
                        disabled={false}
                    >
                        Upload QR image
                    </CustomNBSRedButton>
                    </div>
                </>
            )}


            {/*{loadingScan && <p>Loading</p>}*/}

            {data !== "" && <p>{dataFields}</p>}

            {showAlert ?
                // <Alert setShowAlert={setShowAlert} title={"Error"} body={showAlertMsg}/>
                <AlertModal open={showAlert} onClose={handleClose} message={showAlertMsg} />

                :
                uploadPic &&
                (
                    <>
                        <Typography variant="h5" sx={CustomStyle.subtitle}>
                            Select the image with visible QR Code</Typography>


                        <div style={CustomStyle.buttonContainer}>

                        {/*<Box sx={{ marginTop: 2 }}>*/}
                            <CustomNBSRedButton
                                variant="contained"
                                component="label"
                            >
                                Choose File
                                <input
                                    type="file"
                                    onChange={fileUploaded}
                                    name="NationalID"
                                    accept="image/png, image/jpeg"
                                    style={{ display: 'none' }} // Hide the file input
                                />
                            </CustomNBSRedButton>
                        </div>
                        {/*</Box>*/}
                    </>
                )}



        </>
    );
};

export default React.memo(ReactQRScanner);