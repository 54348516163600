import React, {memo, useEffect, useState} from 'react';
import {Container, Row, Col, ListGroup, Button, Image} from 'react-bootstrap';

export const CurrentRequirements = memo(({ updateCurrentPage, customerDetails, setCustomerDetails }) => {

    useEffect(() => setCustomerDetails((prev)=>{
        return({...prev,account_product:"CURRENT"})
    }),[])
    const goToCurrentAccountsOverview = () => {
        //updateCurrentPage("CurrentAccountsOverview");
        updateCurrentPage("PaymentBasis");

    }
    const title = "Requirements to open a current account";

    return (
        <div className="content">
            <p  style={{fontWeight:"bold",textAlign:"left"}}>
                {title}</p>

            <p className="w3-text-indigo" style={{textAlign:"justify"}}>
                To open an current account, the following documents are required:</p>

            <p>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <ul style={{ listStyle: "none", paddingLeft: "0"}}>
                            <li className="w3-text-indigo">&#x2022;Payslip (Not more than 3 months old) or Employer letter or Employment contract (Must state your monthly income)</li>
                            <li className="w3-text-indigo">&#x2022;3-months Bank statement (If you are self-employed or unemployed)</li>
                            <li className="w3-text-indigo">&#x2022;National ID</li>
                            <li className="w3-text-indigo">&#x2022;Most recent Utility bill</li>
                        </ul>
                    </ListGroup.Item>
                </ListGroup>
            </p>

            <Row>
                <Col>
                    <Button className="rounded-pill btn-light mx-auto d-block mx-auto" style={{ backgroundColor: "#ed1b2e", color: "white" }} onClick={goToCurrentAccountsOverview}>Next</Button>
                </Col>
            </Row>
        </div>
    );
})