import React, { useEffect, useState } from "react";
import {MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const LocationMarker=({setDisplayNextButton})=>{
    const [position, setPosition] = useState(null)

    const map = useMapEvents({
        click(e) {
            //map.locate()
            setPosition(e.latlng);
            setDisplayNextButton(true)
        }
    })
    return position === null ? null : (
        <Marker position={position}>
            <Popup></Popup>
        </Marker>
    )
}

const HomeLocationMap=({setDisplayNextButton})=>{
    return (
        <>
            <MapContainer center={[-15.7839273,35.0042092]} zoom={13} id="map"
                          style={{ width: '100%', height: '400px', border: '4px solid red' }} // Adjust height as per your layout
            >
                <TileLayer /*attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'*/ url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png">
                </TileLayer>
                <LocationMarker  setDisplayNextButton={setDisplayNextButton}/>
            </MapContainer>
        </>
    )
}
export default HomeLocationMap;
