import {styled} from "@mui/material/styles";
import {Button} from "react-bootstrap";
import {Button as MUIButton} from "@mui/material";

export const NBSRedButton = styled(Button)({
    borderRadius: '50px',
    backgroundColor: '#ed1b2e',
    color: 'white',
    // fontSize: '1.25rem',
    '&:hover': {
        backgroundColor: '#ed1b2e',
        color: 'white',
    },
});

export const NBSMUIRedButton = styled(Button)({
    borderRadius: '50px',
    backgroundColor: '#ed1b2e',
    color: 'white',
    // fontSize: '1.25rem',
    '&:hover': {
        backgroundColor: '#ed1b2e',
        color: 'white',
    },
});