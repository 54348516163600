import React, {useState} from "react";
import {CustomStyle} from "../../helpers/styles";
import {Typography} from "@mui/material";

export const AccountServices = (({updateCurrentPage, existingDetails, setExistingDetails}) => {

    console.log(existingDetails);

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const [formData, setFormData] = useState({
        smsAlert: false,
        eazyMobile: false,
        eazyApp: false,
        internetBanking: false,
        atm: false,
        atmSecretQuestion: "",
        atmSecretAnswer: "",

    });

    const [errors, setErrors] = useState({
        atmSecretQuestion: "",
        atmSecretAnswer: "",
    });

    console.log(existingDetails);

    const validateForm = () => {
        let valid = true;
        const newErrors = {...errors};


        if (formData.atm && !formData.atmSecretQuestion.trim()) {
            newErrors.atmSecretQuestion = "Please provide ATM secret question";
            valid = false;
        } else {
            newErrors.atmSecretQuestion = "";
        }

        if (formData.atm && !formData.atmSecretAnswer.trim()) {
            newErrors.atmSecretAnswer = "Please provide ATM secret answer";
            valid = false;
        } else {
            newErrors.atmSecretAnswer = "";
        }

        console.log(newErrors)
        setErrors(newErrors);
        return valid;
    };

    // "atm_secret_question": "",
    // "atm_secret_answer": "",


    const handleChange = (event) => {
        const {name, value, type, checked} = event.target;
        if (type === "checkbox") {
            setFormData({...formData, [name]: checked});
            let newServices = [...existingDetails.services];
            if (checked) {
                newServices.push(name);
            } else {
                newServices = newServices.filter(service => service !== name);
            }
            setExistingDetails({...existingDetails, services: newServices});
        } else {
            setFormData({...formData, [name]: value});
        }

    };
    console.log("Form Data values" + JSON.stringify(formData))

    const handleNextPage = (e) => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const selectedServices = [];
            if (formData.smsAlert) selectedServices.push("sms_alert");
            if (formData.eazyMobile) selectedServices.push("eazy_mobile");
            if (formData.eazyApp) selectedServices.push("eazy_app");
            if (formData.internetBanking) selectedServices.push("internet_banking");
            if (formData.atm) {
                selectedServices.push("atm_card");
                setExistingDetails({
                    ...existingDetails,
                    atm_secret_question: formData.atmSecretQuestion,
                    atm_secret_answer: formData.atmSecretAnswer,
                });
            }

            // Check if scannedData exists and account category is 1003
            console.log("About to check if stmt")
            if (existingDetails.scannedData && (parseInt(existingDetails.existing_category) === 6212 || parseInt(existingDetails.existing_category) === 6023)) {
                const parsedData = JSON.parse(existingDetails.scannedData);

                console.log("Diaspora National ID processing")
                const {
                    id_number,
                    id_issue_date,
                    id_expiry_date
                } = parsedData;

                const formattedData = {
                    id_number,
                    id_issue_date: formatDate(id_issue_date),
                    id_expiry_date: formatDate(id_expiry_date),
                    id_type: "NATIONALID"
                };

                // Set the scanned data values on existingDetails object
                const updatedDetails = {
                    ...existingDetails,
                    ...formattedData,
                    services: selectedServices,
                    atm_secret_question: formData.atmSecretQuestion,
                    atm_secret_answer: formData.atmSecretAnswer,
                };

                setExistingDetails(updatedDetails);

            } else {
                setExistingDetails({
                    ...existingDetails,
                    services: selectedServices,
                    atm_secret_question: formData.atmSecretQuestion,
                    atm_secret_answer: formData.atmSecretAnswer,
                });
            }
            updateCurrentPage("KeyFacts");
        } else {
            console.log("Form is invalid");
        }

    }

    const handlePrevClick = () => {
        updateCurrentPage("AvailableAccounts");
    };

    const style = {
        input: {
            color: '#2b375c',
        }
    }

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                Select which services you would like</Typography>
            <span style={{textAlign: "justify", color: "red"}}>
                * Fees are subject to change at the bank's discretion</span>
            <br/>

            <div className="form-group mt-3">
                <div className="form-check">
                    <input className="form-check-input" onChange={handleChange} checked={formData.smsAlert}
                           type="checkbox" name="smsAlert" id="smsAlert"/>
                    <label className="form-check-label" style={style.input} htmlFor="smsAlert">
                        SMS Alerts
                    </label>
                </div>

            </div>

            <div className="form-group mt-3">
                <div className="form-check">
                    <input className="form-check-input" onChange={handleChange} checked={formData.eazyMobile}
                           type="checkbox" name="eazyMobile" id="eazyMobile"/>
                    <label className="form-check-label" style={style.input} htmlFor="eazyMobile">
                        Eazy Mobile
                    </label>
                </div>

            </div>

            <div className="form-group mt-3">
                <div className="form-check">
                    <input className="form-check-input" onChange={handleChange} checked={formData.eazyApp}
                           type="checkbox" name="eazyApp" id="eazyApp"/>
                    <label className="form-check-label" style={style.input} htmlFor="eazyApp">
                        Eazy App
                    </label>
                </div>

            </div>


            <div className="form-group mt-3">
                <div className="form-check">
                    <input className="form-check-input" onChange={handleChange} checked={formData.internetBanking}
                           type="checkbox" name="internetBanking" id="internetBanking"/>
                    <label className="form-check-label" style={style.input} htmlFor="internetBanking">
                        Internet Banking
                    </label>
                </div>

            </div>

            <div className="form-group mt-3">
                <div className="form-check">
                    <input className="form-check-input" onChange={handleChange} checked={formData.atm} type="checkbox"
                           id="atm" name="atm"/>
                    <label className="form-check-label" style={style.input} htmlFor="atm">
                        Apply for ATM Card
                    </label>
                </div>

            </div>
            <br/>

            {formData.atm && (
                <>
                    <div className="form-floating mb-3">
                        <input type="text" value={formData.atmSecretQuestion}
                               autoComplete="false" onInput={handleChange}
                               id="atmSecretQuestion"
                               placeholder="ATM secret question"
                               className={`form-control rounded-pill ${errors.atmSecretQuestion ? 'is-invalid' : ''}`}
                               name="atmSecretQuestion"/>
                        <label htmlFor="atmSecretQuestion" style={style.input}
                               className="form-label">ATM secret question</label>
                        {errors.atmSecretQuestion && (
                            <div className="invalid-feedback">{errors.atmSecretQuestion}</div>
                        )}
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" value={formData.atmSecretAnswer}
                               autoComplete="false" onInput={handleChange}
                               id="atmSecretAnswer"
                               placeholder="ATM secret answer"
                               className={`form-control rounded-pill ${errors.atmSecretAnswer ? 'is-invalid' : ''}`}
                               name="atmSecretAnswer"/>
                        <label htmlFor="atmSecretAnswer" style={style.input}
                               className="form-label">ATM secret answer</label>
                        {errors.atmSecretAnswer && (
                            <div className="invalid-feedback">{errors.atmSecretAnswer}</div>
                        )}
                    </div>
                </>

            )}
            <br/>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>

                <button className="rounded-pill btn-light mx-auto d-block"
                        style={{backgroundColor: "#ed1b2e", color: "white"}}
                        onClick={handleNextPage}>Next
                </button>
            </div>
        </div>
        </div>
    );
})