import React, {memo, useEffect, useState} from "react";
import {FaExclamationTriangle} from "react-icons/fa";
import axios from "axios";
import {Container, Divider, Grid, Typography} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import jsPDF from 'jspdf';
import {CustomStyle} from "../../helpers/styles";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export async function submitCustomerData(existingDetails) {
    return await axios.post("/submit-fixed-account", existingDetails, {
        headers: {
            "Authorization": `Token ${process.env.REACT_APP_TOKEN}`,
        }, maxContentLength: Infinity, maxBodyLength: Infinity
    })
        .then(res => res)
        .catch(err => err);
}

function formatDate(date) {
    console.log(date)
    const options = {day: 'numeric', month: 'long', year: 'numeric'};
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

function generateReportData(fixedAccountDetails, fixedAccount, refNumber, rate) {
    const today = new Date();
    const formattedDate = formatDate(today);

    const effectiveDate = new Date();
    const formattedEffectiveDate = formatDate(effectiveDate);
    console.log("Today unformatted: " + today)
    console.log("Formatted date " + formattedDate)
    console.log("Effective date unformatted: " + fixedAccountDetails.effective_date)

    return {
        "Date": formattedDate,
        "Name": fixedAccountDetails.accountName,
        "Address": `${fixedAccountDetails.postalAddress} ${fixedAccountDetails.postalTown}`,

        "Owner": fixedAccountDetails.customerNumber,

        "Ref Number": refNumber,

        "Account Number": fixedAccount,
        "Currency": "MWK",
        "Product": `Fixed Deposits - ${fixedAccountDetails.period} month${fixedAccountDetails.period > 1 ? 's' : ''}`,
        "Deposit Amount": `MWK ${Number(fixedAccountDetails.amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}`,
        "Interest Rate per annum": `${rate} (%)`,
        "Period": `${fixedAccountDetails.period} month${fixedAccountDetails.period > 1 ? 's' : ''}`,

    };
}

function generateReceiptPDF(reportData) {
    const pdf = new jsPDF();
    const yOffset = 10;

    pdf.setFontSize(16);
    pdf.text('----------------------------------------------', 10, yOffset);
    pdf.text('NEW ARRANGEMENT ADVICE', 10, yOffset + 10);
    pdf.text('----------------------------------------------', 10, yOffset + 20);

    // Add more lines for Date, Name, Address, etc.
    pdf.setFontSize(12);
    pdf.text('NBS BANK', 10, yOffset + 30);

    pdf.text('-----------------------------------------------', 10, yOffset + 40);

    let yOffsetData = yOffset + 60;

    // for (const key in reportData) {
    //     pdf.text(`${key}: ${reportData[key]}`, 10, yOffsetData);
    //     yOffsetData += 10;
    // }
    //pdf.text('-----------------------------------------------', 10, yOffset);

    for (const key in reportData) {
        // Set styles for keys
        pdf.setTextColor(33, 37, 122); // Adjust color based on your design

        // Draw keys
        pdf.text(key, 10, yOffsetData);

        // Set styles for values
        pdf.setTextColor(0, 0, 0); // Reset color

        const value = String(reportData[key]);

        // Draw values with an offset for alignment
        pdf.text(value, 80, yOffsetData);

        yOffsetData += 10;
    }

    pdf.text('-----------------------------------------------', 10, yOffset);

    return pdf;
}

function downloadReceipt(reportData, fileName) {
    const pdf = generateReceiptPDF(reportData);

    // Save the PDF file
    pdf.save(fileName);
}

export const FixedDepositFinish = memo(({updateCurrentPage, fixedDepositDetails, setFixedDepositDetails}) => {
    console.log("On finish Page " + JSON.stringify(fixedDepositDetails))
    console.log(fixedDepositDetails)

    const [submissionCompletedSuccessfully, setSubmissionCompletedSuccessfully] = useState(false);
    const [submittingApplication, setSubmittingApplication] = useState(true);
    const [unknownError, setUnknownError] = useState(false);
    const [resubmit, setResubmit] = useState(false);
    const [accountDetails, setAccountDetails] = useState({});
    const [submissionStatus, setSubmissionStatus] = useState("submitting"); // Possible values: "submitting", "completed", "failed"
    const [fixedAccount, setFixedAccount] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [report, setReport] = useState({});

    useEffect(() => {
        if (submissionStatus === "completed" && Object.keys(report).length > 0) {
            // Use a function to create and download the receipt
            const downloadReceiptAutomatically = async () => {
                await downloadReceipt(report, `${fixedDepositDetails.familyName}_receipt.pdf`);
                // You can add additional logic here if needed
            };

            // Trigger the download
            downloadReceiptAutomatically();
        }
    }, [submissionStatus, report, fixedDepositDetails.familyName]);


    const goToHome = () => {
        document.location.reload()
        updateCurrentPage("HomePage");
    }

    const resubmitData = () => {
        setSubmittingApplication(true)
        setResubmit(!resubmit)
    }

    const clearFormDataFromLocalStorage = () => {
        // Clear the form data from localStorage
        sessionStorage.removeItem('contractFormData');

        const resetFields = [
            "available_balance",
            "accountName",
            "customerNumber"
        ];

        const resetDetails = {...fixedDepositDetails};

        resetFields.forEach((field) => {
            resetDetails[field] = null;
        });

        setFixedDepositDetails(resetDetails);

    };

    async function checkStatusUntilSuccess(filteredData, attempts = 0) {
        if (attempts > 30) { // Adjust the maximum attempts as needed
            setSubmissionStatus("failed");
            setErrorMessage("Something went wrong. Please try again later.");
            return;
        }

        const response = await submitCustomerData(filteredData).then(r => r.data).catch(err => {
            console.error(err);
            console.log("Response " + response)
            setSubmissionStatus("failed");
            setErrorMessage("Failed to submit data");
            return null;
        });

        if (response && response.status === 1) {
            console.log("CREATED DETAILS HERE!!!....");
            setAccountDetails(response);
            setFixedAccount(response.fixed_account);
            setSubmissionStatus("completed");

            const reportData = generateReportData(fixedDepositDetails, response.fixed_account, response.ref_number, response.fixed_rate);
            setReport(reportData);
            clearFormDataFromLocalStorage();
            return;
        }

        console.log("DETAILES SUBMITTED ALREADY FUNC!!! ");
        // Continue checking until status changes to 1
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for a while before checking again

        checkStatusUntilSuccess(filteredData, attempts + 1);
    }


    useEffect(() => {
        async function submitData() {

            const filterKeys = (obj, keys) => {
                const filtered = {};
                for (const key of keys) {
                    if (obj.hasOwnProperty(key)) {
                        filtered[key] = obj[key];
                    }
                }
                return filtered;
            };

            const keysToKeep = [
                "period",
                "account_number",
                "amount",
                "fixed_rate",
                "auto_roll",
                "roll_type",
                // "special_amount"
            ];

            const filteredData = filterKeys(fixedDepositDetails, keysToKeep);

            const res = await submitCustomerData(filteredData).then(r => r.data).catch(err => {
                console.error(err);
                //Here
                setSubmissionStatus("failed")
                setErrorMessage("Failed to submit data");

                return null;
            });

            console.log(res.status)


            if (res.status === 0) {
                console.log("SUBMITTED!!!, Now Checking...")
                setSubmittingApplication(false);
                await checkStatusUntilSuccess(filteredData);

            } else if (res.status === 2 || !res.status) {
                setSubmittingApplication(false);
                setSubmissionStatus("failed");
                setErrorMessage(res.msg);
                setSubmissionCompletedSuccessfully(false);
                setUnknownError(true)
            } else {
                setSubmissionStatus("completed");
                // setSubmittingApplication(false);
                setSubmissionCompletedSuccessfully(true);
                //setFixedAccount(res.fixed_account);

                const reportData = generateReportData(fixedDepositDetails, res.fixed_account, res.ref_number, res.fixed_rate);
                setReport(reportData);
                clearFormDataFromLocalStorage();

            }
        }

        submitData();
    }, [resubmit]);


    return (
        <>
            {(() => {
                let element;
                if (submissionStatus === "completed") {
                    element = (
                        <div style={CustomStyle.pageWrapper}>
                            <div style={CustomStyle.contentContainer}>

                                <CheckCircleIcon sx={{color: 'green', fontSize: '4rem'}}/>
                                <Typography variant="h5" sx={CustomStyle.heading}>
                                    Your Fixed Deposit Account request is Successful.
                                </Typography>

                                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                    <Grid container spacing={2}>
                                        {Object.keys(report).length > 0 &&
                                            Object.keys(report).map((key) => (
                                                <React.Fragment key={key}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6"
                                                                    sx={{color: '#2b357c', fontWeight: 600}}>
                                                            {key}:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h6"
                                                                    sx={{color: '#2b357c', fontWeight: 600}}>
                                                            {report[key]}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                    </Grid>
                                </div>

                                <div style={CustomStyle.buttonContainer}>

                                    <CustomNBSRedButton
                                        disabled={false}
                                        onClick={goToHome}
                                    >
                                        Home
                                    </CustomNBSRedButton>
                                </div>


                            </div>
                        </div>
                    );
                } else if (submissionStatus === "failed") {
                    element = (
                        <div style={CustomStyle.pageWrapper}>
                            <div style={CustomStyle.contentContainer}>
                                <div className="text-center" style={{fontSize: "30px", color: "#ed1b2e"}}>
                                    <FaExclamationTriangle/>
                                </div>
                                <Typography variant="h5" sx={CustomStyle.heading}>
                                    {errorMessage}</Typography>

                                <div style={CustomStyle.buttonContainer}>

                                    <CustomNBSRedButton onClick={resubmitData}
                                                        disabled={false}>
                                        Retry
                                    </CustomNBSRedButton>

                                    <CustomNBSRedButton onClick={goToHome}
                                                        disabled={false}>
                                        Home
                                    </CustomNBSRedButton>
                                </div>


                            </div>
                        </div>
                    );
                } else if (submissionStatus === "submitting") {
                    element = (
                        <div style={CustomStyle.pageWrapper}>
                            <div style={CustomStyle.contentContainer}>
                                <CircularProgress/>
                                <Typography variant="h5" sx={CustomStyle.subtitle}>
                                    Submitting your application, please wait...</Typography>
                            </div>
                        </div>

                    );
                }
                return element;
            })()}
        </>
    );
});