import React, { memo, useState, useEffect } from 'react';
// import {Container, Table, Col, Row, Button} from 'react-bootstrap';
import { Transactional } from './Transactional';
import { diasporaAccountsKeyFacts as keyFacts } from '../../Fees';
import { DIASPORA_ACCOUNT_TYPES, fetchBranches, getDefaultMandatoryFieldData } from '../../../helpers/Diaspora';
import {
    Box, Button,
    Container, Divider, List, ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {CustomStyle} from "../../../helpers/styles";
import CustomNBSRedButton from "../../custom-componets/CustomNBSRedButton";

// const renderInterestRates = (interestRates) => (
//     <List dense sx={{ padding: 0 }}>
//         {Object.entries(interestRates).map(([key, account], index) => (
//             <li key={index}>
//                 <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
//                     {account.title}
//                 </Typography>
//                 {Object.values(account).map((rate, idx) => (
//                     idx > 0 && (
//                         <Typography key={idx} variant="body2" sx={{ marginLeft: '1rem' }}>
//                             {rate}
//                         </Typography>
//                     )
//                 ))}
//             </li>
//         ))}
//     </List>
// );

const renderInterestRates = (interestRates) => {
    return (
        <div>
            <div><strong>{interestRates.kwachaAccount.title}</strong>: {interestRates.kwachaAccount.LOWER}</div>
            <div><strong>{interestRates.dollarAccount.title}</strong>: {interestRates.dollarAccount.LOWER}, {interestRates.dollarAccount.MIDDLE}, {interestRates.dollarAccount.UPPER}</div>
            <div><strong>{interestRates.poundAccount.title}</strong>: {interestRates.poundAccount.LOWER}, {interestRates.poundAccount.MIDDLE}, {interestRates.poundAccount.UPPER}</div>
            <div><strong>{interestRates.southAfricanRandAccount.title}</strong>: {interestRates.southAfricanRandAccount.LOWER}, {interestRates.southAfricanRandAccount.MIDDLE}</div>
            <div><strong>{interestRates.euroAccount.title}</strong>: {interestRates.euroAccount.LOWER}, {interestRates.euroAccount.MIDDLE}, {interestRates.euroAccount.HIGH}</div>
        </div>
    );
};

const renderBookBalance = (fees) => {
    return (
        <>
            {Object.values(fees).map((fee, index) => (
                <Typography variant="body2" key={index}>
                    {fee}
                </Typography>
            ))}
        </>
    );
};

export const Overview = memo(({ updateCurrentPage, customerDetails, setCustomerDetails }) => {
    const [key, setKey] = useState('transactional');
    //const goToDiasporaPassportReader = () => { updateCurrentPage("PassportReader") }

    const goToDiasporaPassportReader = () => { updateCurrentPage("PassportReader") }


    const savings = keyFacts.accountTypes.savings;
    const investment = keyFacts.accountTypes.investment;

    const transactionalAccountBenefits = savings.productBenefits.fees;
    const investmentAccountBenefits = investment.productBenefits.fees;
    const savingsAccountAccessMethods = savings.accountAccessMethods;
    const investmentAccountAccessMethods = investment.accountAccessMethods;

    function setDefaultMandatoryFields() {
        setCustomerDetails((prev) => ({
            ...prev,
            id_scanned_copy_url: "/home", //Remove this - It MUST be handled at the backend
            monthly_transactions: null,
            net_worth: null,
            one_off_income: null,
            family_income: null,
            other_sources_income: null,
            //will have to confirm below values
            product_group: 0,
            industry: 0,
            sector: 0,
            existing_customer: 0
        }));
    }


    function setToTransactionalAccount() {
        setCustomerDetails((prev) => ({ ...prev, account_product: DIASPORA_ACCOUNT_TYPES.TRANSACTIONAL }));
        setDefaultMandatoryFields();
        goToDiasporaPassportReader();
    }
    function setToInvestmentAccount() {
        setCustomerDetails((prev) => ({ ...prev, account_product: DIASPORA_ACCOUNT_TYPES.INVESTMENT }));
        setDefaultMandatoryFields();
        goToDiasporaPassportReader();
    }
    const nbsBlue = "#2b357c";

    return (
        // <Container>
        //     <Row>
        //         {/*<h4 style={{ fontWeight: "600", color: "#2b357c", textAlign: "start"}}> Diaspora accounts overview </h4>*/}
        //         <p  style={{fontWeight:"bold",textAlign:"start"}}>
        //             Diaspora accounts overview</p>
        //         <h6 className="w3-text-indigo" style={{textAlign:"justify"}}>Table below explains the two kinds of diaspora accounts one can open.</h6>
        //     </Row>
        //
        //     <div className="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl">
        //         <Table className="table content-table">
        //             <thead>
        //             <tr className="unhovarable">
        //                 <th></th>
        //                 <th>Transactional</th>
        //                 <th>Investment</th>
        //             </tr>
        //             </thead>
        //             <tbody>
        //             <tr className="unhovarable">
        //                 <td>Description</td>
        //                 <td>{keyFacts.accountTypes.savings.description}</td>
        //                 <td>{keyFacts.accountTypes.investment.description}</td>
        //             </tr>
        //
        //             <tr className="unhovarable">
        //                 <td>Benefits</td>
        //                 <td>
        //                     {transactionalAccountBenefits.map(benefit => <li style={{ listStylePosition: "inside" }}>{benefit}</li>)}
        //                 </td>
        //                 <td>
        //                     {investmentAccountBenefits.map(benefit => <li style={{ listStylePosition: "inside" }}>{benefit}</li>)}
        //                 </td>
        //             </tr>
        //
        //             <tr className="unhovarable">
        //                 <td>Who can access the product</td>
        //                 <td>{keyFacts.accountTypes.savings.whoCanAccess}</td>
        //                 <td>{keyFacts.accountTypes.investment.whoCanAccess}</td>
        //             </tr>
        //
        //             <tr className="unhovarable">
        //                 <td>How much do I pay</td>
        //                 <td>{keyFacts.accountTypes.savings.monthlyServiceFee}</td>
        //                 <td>{keyFacts.accountTypes.investment.monthlyServiceFee}</td>
        //             </tr>
        //
        //             <tr className="unhovarable">
        //                 <td>Access to the account</td>
        //                 <td>
        //                     {savingsAccountAccessMethods.map(accessMethod => <li style={{ listStylePosition: "inside" }}>{accessMethod}</li>)}
        //                 </td>
        //                 <td>
        //                     {investmentAccountAccessMethods.map(accessMethod => <li style={{ listStylePosition: "inside" }}>{accessMethod}</li>)}
        //                 </td>
        //             </tr>
        //
        //             <tr className="unhovarable">
        //                 <td>Penalties for withdraw</td>
        //                 <td>{keyFacts.accountTypes.savings.withdrawPenalty}</td>
        //                 <td>{keyFacts.accountTypes.investment.withdrawPenalty}</td>
        //             </tr>
        //
        //             <tr className="unhovarable">
        //                 <td>{savings.interestRates.description}</td>
        //                 {/* Savings */}
        //                 <td>
        //                     {/* Kwacha Account */}
        //                     <div style={{ marginBottom: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{savings.interestRates.kwachaAccount.title}</span></div>
        //                         <div><small>{savings.interestRates.kwachaAccount.LOWER}</small></div>
        //                     </div>
        //                     <hr />
        //                     {/* Dollar Account */}
        //                     <div style={{ marginBottom: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{savings.interestRates.dollarAccount.title}</span></div>
        //                         <div><small>{savings.interestRates.dollarAccount.LOWER}</small></div>
        //                         <div><small>{savings.interestRates.dollarAccount.MIDDLE}</small></div>
        //                         <div><small>{savings.interestRates.dollarAccount.UPPER}</small></div>
        //                     </div>
        //                     <hr />
        //                     {/* Pound Account */}
        //                     <div style={{ marginBottom: '-1rem', marginTop: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{savings.interestRates.poundAccount.title}</span></div>
        //                         <div><small>{savings.interestRates.poundAccount.LOWER}</small></div>
        //                         <div><small>{savings.interestRates.poundAccount.MIDDLE}</small></div>
        //                         <div><small>{savings.interestRates.poundAccount.UPPER}</small></div>
        //                     </div>
        //                     <hr />
        //
        //                     {/* South African Rand Account */}
        //                     <div style={{ marginBottom: '-1rem', marginTop: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{savings.interestRates.southAfricanRandAccount.title}</span></div>
        //                         <div><small>{savings.interestRates.southAfricanRandAccount.LOWER}</small></div>
        //                         <div><small>{savings.interestRates.southAfricanRandAccount.MIDDLE}</small></div>
        //                         <div><small>{savings.interestRates.southAfricanRandAccount.UPPER}</small></div>
        //                     </div>
        //                     <hr />
        //
        //                     {/* Euro Account */}
        //                     <div style={{ marginTop: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{savings.interestRates.euroAccount.title}</span></div>
        //                         <div><small>{savings.interestRates.euroAccount.LOWER}</small></div>
        //                         <div><small>{savings.interestRates.euroAccount.MIDDLE}</small></div>
        //                         <div><small>{savings.interestRates.euroAccount.UPPER}</small></div>
        //                     </div>
        //                 </td>
        //
        //                 {/* Investment */}
        //                 <td>
        //                     {/* Kwacha Account */}
        //                     <div style={{ marginBottom: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{investment.interestRates.kwachaAccount.title}</span></div>
        //                         <div><small>{investment.interestRates.kwachaAccount.LOWER}</small></div>
        //                     </div>
        //                     <hr />
        //                     {/* Dollar Account */}
        //                     <div style={{ marginBottom: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{investment.interestRates.dollarAccount.title}</span></div>
        //                         <div><small>{investment.interestRates.dollarAccount.LOWER}</small></div>
        //                         <div><small>{investment.interestRates.dollarAccount.MIDDLE}</small></div>
        //                         <div><small>{investment.interestRates.dollarAccount.UPPER}</small></div>
        //                     </div>
        //                     <hr />
        //                     {/* Pound Account */}
        //                     <div style={{ marginBottom: '-1rem', marginTop: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{investment.interestRates.poundAccount.title}</span></div>
        //                         <div><small>{investment.interestRates.poundAccount.LOWER}</small></div>
        //                         <div><small>{investment.interestRates.poundAccount.MIDDLE}</small></div>
        //                         <div><small>{investment.interestRates.poundAccount.UPPER}</small></div>
        //                     </div>
        //                     <hr />
        //
        //                     {/* South African Rand Account */}
        //                     <div style={{ marginBottom: '-1rem', marginTop: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{investment.interestRates.southAfricanRandAccount.title}</span></div>
        //                         <div><small>{investment.interestRates.southAfricanRandAccount.LOWER}</small></div>
        //                         <div><small>{investment.interestRates.southAfricanRandAccount.MIDDLE}</small></div>
        //                         <div><small>{investment.interestRates.southAfricanRandAccount.UPPER}</small></div>
        //                     </div>
        //                     <hr />
        //
        //                     {/* Euro Account */}
        //                     <div style={{ marginTop: '-1rem' }}>
        //                         <div><span style={{ fontWeight: 'bold' }}>{investment.interestRates.euroAccount.title}</span></div>
        //                         <div><small>{investment.interestRates.euroAccount.LOWER}</small></div>
        //                         <div><small>{investment.interestRates.euroAccount.MIDDLE}</small></div>
        //                         <div><small>{investment.interestRates.euroAccount.UPPER}</small></div>
        //                     </div>
        //                 </td>
        //
        //
        //             </tr>
        //
        //             <tr className="unhovarable">
        //                 <td>Book Balance</td>
        //                 <td>
        //                     <div><small>{savings.bookBalance.fees.kwacha}</small></div>
        //                     <div><small>{savings.bookBalance.fees.dollar}</small></div>
        //                     <div><small>{savings.bookBalance.fees.rand}</small></div>
        //                     <div><small>{savings.bookBalance.fees.euro}</small></div>
        //                     <div><small>{savings.bookBalance.fees.pound}</small></div>
        //                 </td>
        //                 <td>
        //                     <div><small>{investment.bookBalance.fees.kwacha}</small></div>
        //                     <div><small>{investment.bookBalance.fees.dollar}</small></div>
        //                     <div><small>{investment.bookBalance.fees.rand}</small></div>
        //                     <div><small>{investment.bookBalance.fees.euro}</small></div>
        //                     <div><small>{investment.bookBalance.fees.pound}</small></div>
        //                 </td>
        //             </tr>
        //             </tbody>
        //         </Table>
        //     </div>
        //
        //     <Container className="diaspora-button-links mt-3">
        //         <Button
        //             className="rounded-pill btn-light d-block"
        //             style={{backgroundColor:"#ed1b2e", color:"white", width: "100%"}}
        //             onClick={setToTransactionalAccount}>
        //             Transactional account
        //         </Button>
        //
        //         <Button
        //             className="rounded-pill btn-light d-block"
        //             style={{backgroundColor:"#ed1b2e", color:"white",width: "100%"}}
        //             onClick={setToInvestmentAccount}>
        //             Investment account
        //         </Button>
        //
        //     </Container>
        // </Container>

        <div style={CustomStyle.pageWrapper}>
            <div style={{ ...CustomStyle.contentContainer, maxWidth: '1200px'}}>
            {/* Header Section */}

                <Typography variant="h5" sx={CustomStyle.heading}>
                    Diaspora Accounts Overview
                </Typography>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    The table below explains the two kinds of diaspora accounts one can open.
                </Typography>


            {/* Table Section */}
                <Table sx={{
                    width: '100%', // Expand table width
                    border: `1px solid ${nbsBlue}`,
                    borderRadius: '8px',
                    borderCollapse: 'collapse', // Collapses table borders
                    '& th, & td': {
                        border: `1px solid ${nbsBlue}`,
                        textAlign: 'left',
                        backgroundColor: 'white', // Ensure background color is white
                        color: `${nbsBlue}`,
                        padding: '8px', // Add some padding for better spacing
                    },
                    '& th': {
                        backgroundColor: nbsBlue,
                        color: 'white',
                        fontWeight: 'bold',
                    },
                    '& tr:hover': {
                        backgroundColor: '#f2f2f2', // Subtle hover effect
                    },
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Transactional</TableCell>
                            <TableCell>Investment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Description Row */}
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>{keyFacts.accountTypes.savings.description}</TableCell>
                            <TableCell>{keyFacts.accountTypes.investment.description}</TableCell>
                        </TableRow>

                        {/* Benefits Row */}
                        <TableRow>
                            <TableCell>Benefits</TableCell>
                            <TableCell>
                                <List dense>
                                    {transactionalAccountBenefits.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </List>
                            </TableCell>
                            <TableCell>
                                <List dense>
                                    {investmentAccountBenefits.map((benefit, index) => (
                                        <li key={index}>{benefit}</li>
                                    ))}
                                </List>
                            </TableCell>
                        </TableRow>

                        {/* Who Can Access Row */}
                        <TableRow>
                            <TableCell>Who Can Access the Product</TableCell>
                            <TableCell>{keyFacts.accountTypes.savings.whoCanAccess}</TableCell>
                            <TableCell>{keyFacts.accountTypes.investment.whoCanAccess}</TableCell>
                        </TableRow>

                        {/* Monthly Service Fee Row */}
                        <TableRow>
                            <TableCell>How Much Do I Pay</TableCell>
                            <TableCell>{keyFacts.accountTypes.savings.monthlyServiceFee}</TableCell>
                            <TableCell>{keyFacts.accountTypes.investment.monthlyServiceFee}</TableCell>
                        </TableRow>

                        {/* Access to the Account Row */}
                        <TableRow>
                            <TableCell>Access to the Account</TableCell>
                            <TableCell>
                                <List dense>
                                    {savingsAccountAccessMethods.map((accessMethod, index) => (
                                        <li key={index}>{accessMethod}</li>
                                    ))}
                                </List>
                            </TableCell>
                            <TableCell>
                                <List dense>
                                    {investmentAccountAccessMethods.map((accessMethod, index) => (
                                        <li key={index}>{accessMethod}</li>
                                    ))}
                                </List>
                            </TableCell>
                        </TableRow>

                        {/* Withdraw Penalty Row */}
                        <TableRow>
                            <TableCell>Penalties for Withdraw</TableCell>
                            <TableCell>{keyFacts.accountTypes.savings.withdrawPenalty}</TableCell>
                            <TableCell>{keyFacts.accountTypes.investment.withdrawPenalty}</TableCell>
                        </TableRow>

                        {/* Interest Rates Row */}
                        <TableRow>
                            <TableCell>Interest Rates</TableCell>
                            <TableCell>
                                {renderInterestRates(savings.interestRates)}
                            </TableCell>
                            <TableCell>
                                {renderInterestRates(investment.interestRates)}
                            </TableCell>
                        </TableRow>

                        {/* Book Balance Row */}
                        <TableRow>
                            <TableCell>Book Balance</TableCell>
                            <TableCell>
                                {renderBookBalance(savings.bookBalance.fees)}
                            </TableCell>
                            <TableCell>
                                {renderBookBalance(investment.bookBalance.fees)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <div style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                    marginTop: '12px'
                }}>
                    <CustomNBSRedButton onClick={setToTransactionalAccount}
                                        disabled={false}>
                        Transactional Account
                    </CustomNBSRedButton>

                    <CustomNBSRedButton onClick={setToInvestmentAccount}
                                        disabled={false}>
                        Investment Account
                    </CustomNBSRedButton>
                </div>

            {/* Buttons Section */}
            {/*<Box mt={3} display="flex" gap={2}>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        color="secondary"*/}
            {/*        onClick={setToTransactionalAccount}*/}
            {/*        fullWidth*/}
            {/*    >*/}
            {/*        Transactional Account*/}
            {/*    </Button>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        color="secondary"*/}
            {/*        onClick={setToInvestmentAccount}*/}
            {/*        fullWidth*/}
            {/*    >*/}
            {/*        Investment Account*/}
            {/*    </Button>*/}
            {/*</Box>*/}
            </div>
        </div>
    );
})