import React, {useEffect} from 'react';
import CustomContainer from './componets/custom-componets/Container';
import './custom.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
const App = ()=> {
    useEffect(() => {
        // Clear session storage when the app loads
        sessionStorage.clear();
    }, []);
  return (
    // <>
    //   <CustomContainer/>
    // </>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<CustomContainer />} />
              <Route exact path="changu-online" element={<CustomContainer routeKind="changu-online"/>} />
          </Routes>
      </BrowserRouter>

  );
}
export default React.memo(App);
