import React, {useEffect, useState} from "react";
import CurrencyInput from "react-currency-input-field";
import axios from "axios";
import {customSelectStyles, CustomStyle} from "../../helpers/styles";
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Typography,
    Modal, Radio, FormControlLabel
} from "@mui/material";
import {CustomNBSTextField} from "../custom-componets/CustomNBSTextField";
import Select from "react-select";
import {RadioGroup} from "react-radio-buttons";
import {CustomNBSRadioButton} from "../custom-componets/CustomNBSRadioButton";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const ContractScreen = ({updateCurrentPage, fixedDepositDetails, setFixedDepositDetails}) => {

    const [enableButton, setEnableButton] = useState(true);
    const [formData, setFormData] = useState({
        customer_name: fixedDepositDetails.accountName,
        customer_number: fixedDepositDetails.customerNumber,
        amount: "",
        currency: "MWK",
        period: "",
        auto_roll: false,
        roll_type: "",
        available_balance: "0.00"
    });

    const [errors, setErrors] = useState({});
    const [interestPreview, setInterestPreview] = useState(null);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        const storedFormData = sessionStorage.getItem('contractFormData');
        if (storedFormData) {
            const parsedData = JSON.parse(storedFormData);
            setFormData({
                ...parsedData,
                auto_roll: parsedData.auto_roll === 'true',
            });
        } else {
            setFormData((prevData) => ({
                ...prevData,
                period: "",
            }));
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('contractFormData', JSON.stringify(formData));
    }, [formData]);

    useEffect(() => {
        if (fixedDepositDetails.account_number) {
            const fetchBalance = async () => {
                try {
                    const balanceResponse = await axios.post("/balance", {
                        account_number: fixedDepositDetails.account_number,
                    });

                    if (balanceResponse.data.status) {
                        const accountDetails = balanceResponse.data.data[0];
                        setFormData((prevData) => ({
                            ...prevData,
                            available_balance: formatCurrency(accountDetails.avlbal),
                        }));
                    } else {
                        console.error("Failed to query balance");
                    }
                } catch (error) {
                    console.error("Error fetching account balance:", error);
                }
            };
            fetchBalance();
        }
    }, [fixedDepositDetails.account_number]);

    const formatCurrency = (value) => {
        const numericValue = parseFloat(value);
        return isNaN(numericValue) ? "0.00" : numericValue.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const handleCurrencyChange = (name) => (value) => {
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        console.log(`Handle Change - Name: ${name}, Value: ${value}`);

        if (name === 'period') {
            let fixedRate = 0;
            switch (value) {
                case '1':
                    fixedRate = 6.5;
                    break;
                case '2':
                    fixedRate = 6.5;
                    break;
                case '3':
                    fixedRate = 6;
                    break;
                default:
                    break;
            }
            setFormData((prevData) => ({
                ...prevData,
                period: value,
                fixed_rate: fixedRate,
            }));
        } else if (name === 'auto_roll') {
            const autoRollValue = value === 'true';
            console.log(`Auto Roll Value: ${autoRollValue}`);
            setFormData((prevData) => ({
                ...prevData,
                auto_roll: autoRollValue,
                roll_type: autoRollValue ? prevData.roll_type : "I",
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };


    const validateFormData = () => {
        const validationErrors = {};
        if (!formData.amount || isNaN(formData.amount) || formData.amount < 50000) {
            validationErrors.amount = "Amount must be above MK50,000";
        }
        if (!formData.period) {
            validationErrors.period = "Maturity option is required";
        }
        if (formData.auto_roll === undefined) {
            validationErrors.auto_roll = "Please select Rollover or Close on Maturity";
        }
        if (formData.auto_roll && !formData.roll_type) {
            validationErrors.roll_type = "Please select a Rollover Type";
        }
        return validationErrors;
    };

    const handleConfirm = () => {
        const validationErrors = validateFormData();
        if (Object.keys(validationErrors).length === 0) {
            mergeFormData();
            fetchInterestPreview();
        } else {
            console.log(validationErrors)
            setErrors(validationErrors);
        }
    };

    const fetchInterestPreview = async () => {
        const requestBody = {
            request_type: 'PREVIEW_FIXED_ACCOUNT',
            account_number: fixedDepositDetails.account_number,
            amount: formData.amount,
            period: formData.period,
            //fixed_rate: formData.fixed_rate, // Include fixed_rate in the request

        };

        setLoadingPreview(true);
        try {
            const result = await axios.post('/interest-preview', requestBody, {
                headers: {'Content-Type': 'application/json'},
            });

            if (result.data.status) {
                setInterestPreview(result.data);
                setShowModal(true);
            } else {
                console.error('Error processing interest preview.');
            }
        } catch (error) {
            console.error('Error fetching interest preview:', error);
        } finally {
            setLoadingPreview(false);
            setEnableButton(true);
        }
    };

    const handleProceed = () => {
        setShowModal(false);
        updateCurrentPage("KeyFactStatement");
    };

    const mergeFormData = () => {
        const updatedDetails = {...fixedDepositDetails};
        Object.keys(formData).forEach(key => {
            updatedDetails[key] = formData[key];
        });
        setFixedDepositDetails(updatedDetails);
    };

    const depositOptions = [
        {value: '1', label: '1 Month (6.5% Interest per annum)'},
        {value: '2', label: '2 Months (6.5% Interest per annum)'},
        {value: '3', label: '3 Months (6% Interest per annum)'}
    ];

    const rollOverOptions = [
        {value: 'P', label: 'Principal'},
        // {value: 'T', label: 'Interest'},
        {value: 'I', label: 'Principal + Interest'}
    ]

    const handleRadioChange = (event) => {
        const {value} = event.target;
        const autoRollValue = value === 'true';

        setFormData((prevData) => ({
            ...prevData,
            auto_roll: autoRollValue,
            roll_type: autoRollValue ? prevData.roll_type : "I",
        }));
    };

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Contract
                </Typography>

                <CustomNBSTextField
                    label="Customer Name"
                    onInputChange={handleChange}
                    value={formData.customer_name}
                    inputType="text"
                    name="customer_name"
                    required="true"
                    disabled
                />

                <CustomNBSTextField
                    label="Customer Number"
                    onInputChange={handleChange}
                    value={formData.customer_number}
                    inputType="text"
                    name="customer_number"
                    required="true"
                    disabled
                />

                <CustomNBSTextField
                    label="Available Balance (MK)"
                    onInputChange={handleChange}
                    value={formData.available_balance}
                    inputType="text"
                    name="available_balance"
                    required="true"
                    disabled={true}
                />

                <div style={{marginBottom: '16px'}}>
                    <Typography variant="h6" sx={{
                        ...CustomStyle.subtitle,
                        textAlign: 'left',
                        fontSize: '15px',
                        marginBottom: '10px'
                    }}>
                        Deposit Amount (MK) *
                    </Typography>
                    <CurrencyInput
                        style={CustomStyle.currencyInput}
                        className="form-control"
                        id="amount"
                        name="amount"
                        placeholder="Please enter an amount"
                        min={1}
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        value={formData.amount || ''}
                        onValueChange={handleCurrencyChange('amount')}
                        required
                    />
                    {errors.amount &&
                        <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                            {errors.amount}
                        </Typography>
                    }
                </div>

                <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                    <Typography variant="h6" sx={{
                        ...CustomStyle.subtitle,
                        textAlign: 'left',
                        fontSize: '16px',
                        marginBottom: '10px'
                    }}>
                        Maturity Date *
                    </Typography>
                    <Select
                        options={depositOptions}
                        value={depositOptions.find(option => option.value === formData.period)} // Ensure correct value selection
                        onChange={(selectedOption) => handleChange({
                            target: {
                                name: 'period',
                                value: selectedOption.value
                            }
                        })}
                        styles={customSelectStyles}
                        placeholder="Select Maturity Date"
                        isClearable
                        required
                    />
                    {errors.period &&
                        <Typography variant="body2" sx={{textAlign: "left", color: "#ed1b2e", mb: 2}}>
                            {errors.period}
                        </Typography>
                    }
                </FormControl>


                <Typography variant="h6" sx={{
                    ...CustomStyle.subtitle,
                    textAlign: 'left',
                    fontSize: '16px',
                    marginBottom: '10px'
                }}>Action on Maturity *</Typography>

                <Box sx={{mb: 3, textAlign: 'left'}}>
                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>

                        <RadioGroup
                            aria-label="auto_roll"
                            name="auto_roll"
                            value={formData.auto_roll.toString()}
                            onChange={handleRadioChange}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'left',
                                marginBottom: '16px'
                            }}
                        >
                            <FormControlLabel
                                label="Rollover"
                                value="true"
                                control={<Radio/>}


                            />
                            <FormControlLabel
                                label="Close on Maturity"
                                value="false"
                                control={<Radio/>}


                            />
                        </RadioGroup>
                        {errors.auto_roll && (
                            <Typography variant="body2" color="error">
                                {errors.auto_roll}
                            </Typography>
                        )}
                    </FormControl>
                </Box>

                {formData.auto_roll && (
                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Roll Over Type *
                        </Typography>
                        <Select
                            options={rollOverOptions}
                            value={rollOverOptions.find(option => option.value === formData.roll_type)}
                            onChange={(selectedOption) => handleChange({
                                target: {
                                    name: 'roll_type',
                                    value: selectedOption.value
                                }
                            })}
                            styles={customSelectStyles}
                            placeholder="Rollover Type"
                            isClearable
                            required
                        />
                    </FormControl>
                )}

                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton
                        disabled={loadingPreview}
                        onClick={handleConfirm}
                    >
                        {loadingPreview ? <CircularProgress size={24} color="inherit"/> : 'Continue'}
                    </CustomNBSRedButton>
                </div>

                <Modal
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%', // Adjusts to 90% of the screen width
                        maxWidth: 500, // Maximum width remains 500px
                        maxHeight: '90vh', // Ensures the modal does not exceed the viewport height
                        bgcolor: 'background.paper',
                        borderRadius: '12px',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto'
                    }}>
                        <Typography
                            id="modal-title"
                            variant="h5"
                            component="h2"
                            sx={{
                                color: '#2b357c',
                                mb: 3,
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}>
                            Interest Preview
                        </Typography>

                        <Box sx={{
                            border: '2px solid #2b357c', // NBS blue border
                            borderRadius: '8px',
                            p: 3, // Padding inside the border
                            mb: 10
                        }}>


                            {interestPreview ? (
                                <>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2}}>
                                        <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                            Maturity Date:
                                        </Typography>
                                        <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                            {interestPreview.date}
                                        </Typography>
                                    </Box>

                                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2}}>
                                        <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                            Interest Amount (MK):
                                        </Typography>
                                        <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                            {formatCurrency(interestPreview.interest_amount)}
                                        </Typography>
                                    </Box>

                                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2, mb: 4}}>
                                        <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                            Principal Amount (MK):
                                        </Typography>
                                        <Typography sx={{color: '#2b357c', fontWeight: 500}}>
                                            {formatCurrency(interestPreview.principal_balance)}
                                        </Typography>
                                    </Box>

                                </>
                            ) : (
                                <Typography
                                    id="modal-description"
                                    variant="h6"
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        textAlign: 'center',
                                        color: '#333'
                                    }}>
                                    No preview available.
                                </Typography>
                            )}
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                            <CustomNBSRedButton
                                onClick={handleProceed}
                            >
                                Proceed to Key Facts
                            </CustomNBSRedButton>
                        </Box>
                    </Box>
                </Modal>

            </div>
        </div>

    );
}