import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {NBSMUIRedButton} from "./NBSRedButton";
import {NBSMUIBlueButton} from "./NBSBlueButton";
import Dialog from "@mui/material/Dialog";
import React, {memo} from "react";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ImagePreviewDialog = memo(({src, open, cancelCallback, okCallback}) => {
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Review document."}</DialogTitle>
                <DialogContent>
                    <img src={src} alt="" width="100%"/>
                </DialogContent>
                <DialogActions>
                    <NBSMUIRedButton size="sm" onClick={() => cancelCallback()}>Cancel</NBSMUIRedButton>
                    <NBSMUIBlueButton size="sm" onClick={() => okCallback()}>Ok</NBSMUIBlueButton>
                </DialogActions>
            </Dialog>
        </>
    );
});