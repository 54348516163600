import { React, memo, useState } from 'react';
import {Form, Row, Jumbotron, Button, Card, ListGroup, Col, Modal, Badge} from 'react-bootstrap';
import CustomButton from '../custom-componets/Button.js';
import { DIASPORA_ACCOUNT_TYPES } from '../../helpers/Diaspora.js';
import {FaCheck} from "react-icons/fa";
import {CustomStyle} from "../../helpers/styles";
import {Box, CardContent, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const Summary = memo(({ updateCurrentPage, customerDetails, setCustomerDetails }) => {
    const [phone, setPhone] = useState();
    const title = "Summary of account opening information";
    const subTitle = "Please check and verify information below, and confirm if correct";
    const applicantDetailsTitle = "Applicant Details";
    const incomeProofDetailstitle = "Proof of Income Details";
    const nextOfKinDetails = "Next of Kin Details";
    const receivedDocuments = "Documents Received";
    const fullName = [customerDetails.cust_title, customerDetails.firstname, customerDetails.surname].join(" ");
    const [preview, setPreview] = useState(false);
    const [pdfPreview, setPDFPreview] = useState(false);
    const [previewSrc, setPreviewSrc] = useState();
    const [previewPDFSrc, setPreviewPDFSrc] = useState();
    const income = [customerDetails.currency, Number(customerDetails.gross_monthly_income).toLocaleString()].join("");

    function exists(value) {
        return value !== null && value !== undefined && value !== '';
    }

    function purposeForOpeningAccount() {
        let purpose = "";
        if (customerDetails.purpose_of_account === "transactionalAccount") {
            return DIASPORA_ACCOUNT_TYPES.TRANSACTIONAL;
        } else if (customerDetails.purpose_of_account === "investmentAccount") {
            return DIASPORA_ACCOUNT_TYPES.INVESTMENT;
        }
        return purpose;
    }

    function showDocumentPreviewModal(v) {
        setPreview(v);
    }

    function showPDFDocumentPreviewModal(v) {
        setPDFPreview(v);
    }


    console.log(customerDetails);
    const style = {
        title: {
            color: "#2b357c"
        }
    }

    const fileKeys = [
        'foreign_bank_statement',
        'payslip',
        'employment_letter',
        'kin_identification',
        'kin_utility_bill',
    ];

    const allowedKeys = [
        fullName,
        'marital_status',
        'gender',
        'date_of_birth',
        'id_number',
        'mobile_phone',
        'resident_country',
        'physical_address',
        'postal_address',
        'email_address',
        'employment_status',
        'income_main_source',
        'occupation_of_customer',
        'work_phone',
        'employer_name',
        'work_address',
        'gross_monthly_income',
        'purpose_of_account',
        'kin_fullname',
        'kin_tel_number',
        'kin_physical_address',
        'kin_physical_address',
        'kin_postal_address',
        ...fileKeys
    ];

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                                {title}</Typography>
                <Typography variant="h5" sx={CustomStyle.subtitle}>
                    {subTitle}</Typography>



                <Card
                    sx={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        marginBottom: '20px',
                        maxWidth: '100%',
                        border: '2px solid #2b357c',
                    }}
                >
                    <CardContent>
                        <Box sx={{ mt: 2 }}>
                            {allowedKeys.map((key) => (
                                exists(customerDetails[key]) && (
                                    <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                        <Typography variant="body2" sx={{ color: '#2b357c', fontWeight: 'bold' }}>
                                            {key.replace(/_/g, ' ').toUpperCase()}:
                                        </Typography>
                                        {fileKeys.includes(key) ? (
                                            <CheckCircleIcon sx={{ color: 'green' }} />
                                        ) : (
                                            <Typography variant="body2" sx={{ color: '#2b357c' }}>
                                                {typeof customerDetails[key] === 'string'
                                                    ? customerDetails[key].toUpperCase()
                                                    : JSON.stringify(customerDetails[key])}
                                            </Typography>
                                        )}
                                    </Box>
                                )
                            ))}
                        </Box>
                    </CardContent>
                </Card>
                {/* Applicant Details */}
                <br/>
                {/*<h6 style={{ fontWeight: "500", color: "#2b357c" }}>{applicantDetailsTitle}</h6>*/}

                {/*<Card>*/}
                {/*    <Card.Body>*/}
                {/*        <ListGroup variant="flush">*/}
                {/*            {exists(fullName) && (*/}
                {/*                <>*/}
                {/*                    <ListGroup.Item>*/}
                {/*                        <Row>*/}
                {/*                            <Col>FullName:</Col>*/}
                {/*                            <Col xs={8}>{fullName}</Col>*/}
                {/*                        </Row>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                </>*/}
                {/*            )}*/}

                {/*            {exists(customerDetails.marital_status) && (*/}
                {/*                <>*/}
                {/*                    <ListGroup.Item>*/}
                {/*                        <Row>*/}
                {/*                            <Col>Marital Status:</Col>*/}
                {/*                            <Col xs={8}>{customerDetails.marital_status}</Col>*/}
                {/*                        </Row>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                </>*/}
                {/*            )}*/}

                {/*            {exists(customerDetails.gender) && (*/}
                {/*                <>*/}
                {/*                    <ListGroup.Item>*/}
                {/*                        <Row>*/}
                {/*                            <Col>Gender:</Col>*/}
                {/*                            <Col xs={8}>{customerDetails.gender}</Col>*/}
                {/*                        </Row>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                </>*/}
                {/*            )}*/}

                {/*            {exists(customerDetails.date_of_birth) && (*/}
                {/*                <>*/}
                {/*                    <ListGroup.Item>*/}
                {/*                        <Row>*/}
                {/*                            <Col>Date of Birth:</Col>*/}
                {/*                            <Col xs={8}>{customerDetails.date_of_birth}</Col>*/}
                {/*                        </Row>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                </>*/}
                {/*            )}*/}

                {/*            {exists(customerDetails.id_number) && (*/}
                {/*                <>*/}
                {/*                    <ListGroup.Item>*/}
                {/*                        <Row>*/}
                {/*                            <Col>Passport Number:</Col>*/}
                {/*                            <Col xs={8}>{customerDetails.id_number}</Col>*/}
                {/*                        </Row>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                </>*/}
                {/*            )}*/}

                {/*            {exists(customerDetails.mobile_phone) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Phone:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.mobile_phone}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.resident_country) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Location:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.resident_country}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.physical_address) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Physical Address:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.physical_address}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.postal_address) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Postal Address:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.postal_address}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.email_address) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Email:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.email_address}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}
                {/*        </ListGroup>*/}
                {/*    </Card.Body>*/}
                {/*</Card>*/}

                {/*/!* Income proof details *!/*/}
                {/*<h6 style={{ fontWeight: "500", color: "#2b357c" }}>{incomeProofDetailstitle}</h6>*/}
                {/*<Card>*/}
                {/*    <Card.Body>*/}
                {/*        <ListGroup variant="flush">*/}
                {/*            {exists(customerDetails.employment_status) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Employment Status:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.employment_status}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.income_main_source) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Source of funds:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.income_main_source}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.foreign_bank_statement) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Bank Statement:</Col>*/}
                {/*                        <Col xs={8}>*/}
                {/*                            <FaCheck size="15" color="green"/>*/}
                {/*                        </Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.occupation_of_customer) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Occupation:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.occupation_of_customer}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.work_phone) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Work Phone:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.work_phone}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.employer_name) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Employer Name:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.employer_name}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.work_address) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Employer Address:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.work_address}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.payslip) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Pay slip:</Col>*/}
                {/*                        <Col xs={8}>*/}
                {/*                            <FaCheck size="15" color="green"/>*/}
                {/*                            /!*<a*!/*/}
                {/*                            /!*    style={{ ...style.title, fontWeight: '700', cursor: 'pointer' }}*!/*/}
                {/*                            /!*    onClick={() => {*!/*/}
                {/*                            /!*        showDocumentPreviewModal(true);*!/*/}
                {/*                            /!*        setPreviewSrc(customerDetails.payslip)*!/*/}
                {/*                            /!*    }}>*!/*/}
                {/*                            /!*    VIEW*!/*/}
                {/*                            /!*</a>*!/*/}
                {/*                        </Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.employment_letter) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Employer letter:</Col>*/}
                {/*                        <Col xs={8}>*/}
                {/*                            <FaCheck size="15" color="green"/>*/}
                {/*                            /!*<a*!/*/}
                {/*                            /!*    style={{ ...style.title, fontWeight: '700', cursor: 'pointer' }}*!/*/}
                {/*                            /!*    onClick={() => {*!/*/}

                {/*                            /!*        showPDFDocumentPreviewModal(true)*!/*/}
                {/*                            /!*        setPreviewPDFSrc(customerDetails.employment_letter)*!/*/}
                {/*                            /!*    }}>*!/*/}
                {/*                            /!*    VIEW*!/*/}
                {/*                            /!*</a>*!/*/}
                {/*                        </Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.gross_monthly_income) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Monthly income:</Col>*/}
                {/*                        <Col xs={8}>{income}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.purpose_of_account) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Purpose for opening account:</Col>*/}
                {/*                        <Col xs={8}>{purposeForOpeningAccount()}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}
                {/*        </ListGroup>*/}
                {/*    </Card.Body>*/}
                {/*</Card>*/}

                {/*/!* Next of Kin Details *!/*/}
                {/*<h6 style={{ fontWeight: "500", color: "#2b357c" }}>{nextOfKinDetails}</h6>*/}
                {/*<Card>*/}
                {/*    <Card.Body>*/}
                {/*        <ListGroup variant="flush">*/}
                {/*            {exists(customerDetails.kin_fullname) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Full Name:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.kin_fullname}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.kin_relationship_type) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Relationship:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.kin_relationship_type}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.kin_tel_number) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Phone:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.kin_tel_number}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.kin_email) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Email:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.kin_email}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.kin_physical_address) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Physical Address:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.kin_physical_address}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.kin_postal_address) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Postal Address:</Col>*/}
                {/*                        <Col xs={8}>{customerDetails.kin_postal_address}</Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.kin_identification) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Identification Document:</Col>*/}
                {/*                        <Col xs={8}>*/}
                {/*                            <FaCheck size="15" color="green"/>*/}
                {/*                            /!*<a*!/*/}
                {/*                            /!*    style={{ ...style.title, fontWeight: '700', cursor: 'pointer' }}*!/*/}
                {/*                            /!*    onClick={() => {*!/*/}

                {/*                            /!*        showDocumentPreviewModal(true);*!/*/}
                {/*                            /!*        setPreviewSrc(customerDetails.kin_identification)*!/*/}
                {/*                            /!*    }}>*!/*/}
                {/*                            /!*    VIEW*!/*/}
                {/*                            /!*</a>*!/*/}
                {/*                        </Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}
                {/*            }*/}

                {/*            {exists(customerDetails.kin_utility_bill) &&*/}
                {/*                <ListGroup.Item>*/}
                {/*                    <Row>*/}
                {/*                        <Col>Utility Bill:</Col>*/}
                {/*                        <Col xs={8}>*/}
                {/*                            <FaCheck size="15" color="green"/>*/}

                {/*                        </Col>*/}
                {/*                    </Row>*/}
                {/*                </ListGroup.Item>*/}

                {/*            }*/}
                {/*        </ListGroup>*/}
                {/*    </Card.Body>*/}
                {/*</Card>*/}

                {/*<br />*/}
                {/*<div style={{ marginBottom: '2rem' }}>*/}
                {/*    <CustomButton label="Next" updateCurrentPage={updateCurrentPage} next="DiasporaTermsAndConditions" />*/}
                {/*</div>*/}

                <CustomNBSRedButton onClick={() => updateCurrentPage('DiasporaTermsAndConditions')}
                                    disabled={false}>
                    Next
                </CustomNBSRedButton>

                {/*<Modal*/}
                {/*    show={preview}*/}
                {/*    onHide={() => showDocumentPreviewModal(false)}*/}
                {/*    dialogClassName="modal-90w"*/}
                {/*    aria-labelledby="example-custom-modal-styling-title"*/}
                {/*>*/}
                {/*    <Modal.Header closeButton></Modal.Header>*/}
                {/*    <Modal.Body>*/}
                {/*        <img className="mx-auto" style={{ height: '500px', width: '400px' }} src={previewSrc}  alt=""/>*/}
                {/*    </Modal.Body>*/}
                {/*</Modal>*/}

                {/*<Modal size="lg" show={pdfPreview} onHide={() => showPDFDocumentPreviewModal(false)}>*/}
                {/*    <Modal.Header >*/}
                {/*        <Modal.Title>DOCUMENT</Modal.Title>*/}
                {/*    </Modal.Header>*/}
                {/*    <Modal.Body className="zoom">*/}
                {/*        <embed src={previewPDFSrc} type="application/pdf" width="100%" height="600px" />*/}
                {/*    </Modal.Body>*/}
                {/*    <Modal.Footer>*/}
                {/*        <Button variant="primary" onClick={() => showPDFDocumentPreviewModal(false)} style={{ width: "100%", backgroundColor: "#ff0000" }}>*/}
                {/*            Ok*/}
                {/*        </Button>*/}
                {/*    </Modal.Footer>*/}
                {/*</Modal>*/}
            </div>

        </div>
    )
});