import React from 'react';
import PhoneInput from "react-phone-number-input";

const CustomPhoneInput = ({ value, onChange, defaultCountry }) => (
    <div className="phone-input-container">
        <PhoneInput
            international={true}
            withCountryCallingCode={true}
            defaultCountry={defaultCountry}
            placeholder="Enter phone number"
            limitMaxLength={true}
            value={value}
            onChange={onChange}
        />
    </div>
);

export default CustomPhoneInput;
