import React, {memo, useState} from 'react';
import {Form, Row, Card, Button, Spinner} from 'react-bootstrap';
import {resolveAndGetImageFileBase64} from '../../helpers/Diaspora.js';
import {convertBase64ToImageOrPDFFile, createFileUploadObject} from "../../helpers/Utils";
import VirtualizedAutoComplete from "../VirtualizedAutoComplete";
import {customSelectStyles, CustomStyle} from "../../helpers/styles";
import {CircularProgress, FormControl, Typography} from "@mui/material";
import {CustomNBSTextField} from "../custom-componets/CustomNBSTextField";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import Select from "react-select";
import CustomNBSBlueButton from "../custom-componets/CustomNBSBlueButton";

export const NextOfKinDetails = memo(({updateCurrentPage, customerDetails, setCustomerDetails}) => {
    const [fullname, setFullName] = useState(customerDetails.kin_fullname);
    const title = "Next of Kin Details";
    const subTitle = "Your next of kin must be residing in Malawi and the details here should be current";
    const nextPage = "DiasporaSummary";

    const relationshipTypes = [
        {value: "AUNT", label: "Aunt"},
        {value: "BROTHER", label: "Brother"},
        {value: "CHILD", label: "Child"},
        {value: "COUSIN", label: "Cousin"},
        {value: "GRANNY", label: "Grand Parent"},
        {value: "NEPHEW", label: "Nephew"},
        {value: "NIECE", label: "Niece"},
        {value: "PARENT", label: "Parent"},
        {value: "SISTER", label: "Sister"},
        {value: "UNCLE", label: "Uncle"},
        {value: "WIFE", label: "Wife"},
        {value: "HUSBAND", label: "Husband"}
    ];
    const [loading, setLoading] = useState(false);

    const styles = {
        rounded: {
            borderRadius: '100px'
        }
    }

    //TODO: Create setters and set customer data in setEffect lambda to reduce code repetition

    function fullNameChanged(e) {
        console.log(`Kin full name : ${e.target.value}`);
        setFullName(e.target.value);
        setCustomerDetails((prev) => {
            return ({...prev, kin_fullname: e.target.value})
        });
        console.dir(customerDetails);
    }

    function setPhoneNumber(e) {
        setCustomerDetails((prev) => {
            return ({...prev, kin_tel_number: e.target.value})
        });
    }

    function setPhysicalAddress(e) {
        setCustomerDetails((prev) => {
            return ({...prev, kin_physical_address: e.target.value})
        });
    }

    function setPostalAddress(e) {
        setCustomerDetails((prev) => {
            return ({...prev, kin_postal_address: e.target.value})
        });
    }

    async function setIdentificationDocument(e) {
        const ID = e.target.files[0];
        // const base64 = await getBase64(ID)
        //     .then(r => r)
        //     .catch(e => { console.error(e); return "" });

        let base64 = "";
        if (ID) {
            setLoading(true);
            base64 = await resolveAndGetImageFileBase64(ID);
            setLoading(false);
        }

        const IDJPegFile = convertBase64ToImageOrPDFFile(base64, 'kin_identification');
        const data = await createFileUploadObject(IDJPegFile, 'kin_identification', customerDetails.id_number, customerDetails.surname)
            .then(data => data)
            .catch(() => null);

        if (data != null) {
            setCustomerDetails((prev) => {
                return {...prev, [data['name']]: data['url']}
            })
            setCustomerDetails((prev) => {
                prev.temp[data['name']] = base64;
                return prev;
            });
        } else {
            //TODO: handle error
        }
        //
        // setCustomerDetails((prev) => {
        //     return ({ ...prev, kin_identification: base64 })
        // });
    }

    async function setUtilityBill(e) {
        const utilityBill = e.target.files[0];
        // const base64 = await getBase64(utilityBill)
        //     .then(r => r)
        //     .catch(e => { console.error(e); return "" });

        let base64 = "";
        if (utilityBill) {
            setLoading(true);
            base64 = await resolveAndGetImageFileBase64(utilityBill);
            setLoading(false);
        }

        const utilityBillJPegFile = convertBase64ToImageOrPDFFile(base64, 'kin_utility_bill');
        const data = await createFileUploadObject(utilityBillJPegFile, 'kin_utility_bill', customerDetails.id_number, customerDetails.surname)
            .then(data => data)
            .catch(() => null);

        if (data != null) {
            setCustomerDetails((prev) => {
                return {...prev, [data['name']]: data['url']}
            })
            setCustomerDetails((prev) => {
                prev.temp[data['name']] = base64;
                return prev;
            });
        } else {
            //TODO: handle error
        }


        // setCustomerDetails((prev) => {
        //     return ({ ...prev, kin_utility_bill: base64 })
        // });
    }

    function setEmail(e) {
        setCustomerDetails((prev) => {
            return ({...prev, kin_email: e.target.value})
        });
    }

    function setNextOfKinRelationship(e) {
        setCustomerDetails((prev) => {
            return ({...prev, kin_relationship_type: e.target.value})
        });
    }

    function setRefereeName(e) {
        setCustomerDetails((prev) => {
            return ({...prev, ref_name: e.target.value})
        });
    }

    function setRefereePhone(e) {
        setCustomerDetails((prev) => {
            return ({...prev, ref_phone_number: e.target.value})
        });
    }

    function setRefereeAddress(e) {
        setCustomerDetails((prev) => {
            return ({...prev, residence_status: e.target.value})
        });
    }

    function submitForm() {
        updateCurrentPage(nextPage);
    }

    const handleSelectChange = (name) => (selectedOption) => {
        setCustomerDetails(prev => ({
            ...prev,
            [name]: selectedOption?.value || ''
        }));
    };


    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>{title}</Typography>
                <form onSubmit={submitForm}>

                    <Typography variant="h5" sx={CustomStyle.subtitle}>{subTitle}</Typography>
                    <CustomNBSTextField
                        label="Full Name *"
                        value={fullname}
                        onInputChange={fullNameChanged}
                        inputType="text"
                        required="true"
                        maxLength={25}
                    />

                    <CustomNBSTextField
                        label="Email"
                        value={customerDetails.kin_email}
                        onInputChange={setEmail}
                        inputType="email"
                        required="false"
                        maxLength={25}
                    />

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Relationship *
                        </Typography>
                        <Select
                            options={relationshipTypes}
                            value={relationshipTypes.find(option => option.value === customerDetails.kin_relationship_type)}
                            onChange={handleSelectChange('kin_relationship_type')}
                            styles={customSelectStyles}
                            placeholder="Select Relationship"
                            isClearable
                            required
                        />
                    </FormControl>

                    {/*<Form.Group>*/}
                    {/*    <Form.Label>Relationship <span style={{ color: "#ed1b2e" }}>*</span></Form.Label>*/}
                    {/*    <Form.Select style={styles.rounded} size="sm" onChange={setNextOfKinRelationship} required>*/}
                    {/*        <option value=""></option>*/}
                    {/*        { relationshipTypes.map((rt) => <option value={rt.key}> {rt.value} </option>)}*/}
                    {/*    </Form.Select>*/}
                    {/*    <Form.Text>Your relationship with the next of kin</Form.Text>*/}
                    {/*</Form.Group>*/}

                    <CustomNBSTextField
                        label="Phone *"
                        value={customerDetails.kin_tel_number || ''}
                        onInputChange={setPhoneNumber}
                        inputType="number"
                        name="kin_tel_number"
                        required="true"

                    />


                    <CustomNBSTextField
                        label="Physical Address *"
                        value={customerDetails.kin_physical_address || ''}
                        onInputChange={setPhysicalAddress}
                        inputType="text"
                        required="true"
                        maxLength={25}
                    />

                    <CustomNBSTextField
                        label="Postal address *"
                        value={customerDetails.kin_postal_address || ''}
                        onInputChange={setPostalAddress}
                        // valid={formErrors.postal_address ? '' : 'Valid'}
                        inputType="text"
                        name="kin_postal_address"
                        required="true"
                        maxLength={25}
                    />

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>

                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            ID (National ID, Passport, or Driver's Licence) *

                        </Typography>

                        <CustomNBSBlueButton variant="contained" component="label">
                            Choose File
                            <input
                                type="file"
                                onChange={setIdentificationDocument}
                                name="kin_identification"
                                accept="image/*"
                                style={{display: 'none'}}
                                required
                            />
                        </CustomNBSBlueButton>
                        {/*<CustomNBSRedButton*/}
                        {/*    variant="contained"*/}
                        {/*    component="label"*/}
                        {/*>*/}
                        {/*    Choose File*/}
                        {/*    <input*/}
                        {/*        type="file"*/}
                        {/*        onChange={setIdentificationDocument}*/}
                        {/*        name="NationalID"*/}
                        {/*        accept="image/*"*/}
                        {/*        style={{display: 'none'}} // Hide the file input*/}
                        {/*    />*/}
                        {/*</CustomNBSRedButton>*/}
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>

                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Utility Bill (Most recent) *

                        </Typography>
                        <CustomNBSBlueButton variant="contained" component="label">
                            Choose File
                            <input
                                type="file"
                                onChange={setUtilityBill}
                                name="kin_utility_bill"
                                accept="image/*"
                                style={{display: 'none'}}
                                required
                            />
                        </CustomNBSBlueButton>
                        {/*<CustomNBSRedButton*/}
                        {/*    variant="contained"*/}
                        {/*    component="label"*/}
                        {/*>*/}
                        {/*    Choose File*/}
                        {/*    <input*/}
                        {/*        type="file"*/}
                        {/*        onChange={setUtilityBill}*/}
                        {/*        name="NationalID"*/}
                        {/*        accept="image/*"*/}
                        {/*        style={{display: 'none'}} // Hide the file input*/}
                        {/*    />*/}
                        {/*</CustomNBSRedButton>*/}
                    </FormControl>


                    {/*<Form.Group className="mb-3" controlId="diasporaCustomerNextOfKinIdentification">*/}
                    {/*        <Form.Label>ID (National ID, Passport, or Driver's Licence) <span style={{ color: "#ed1b2e" }}>*</span></Form.Label>*/}
                    {/*        <Form.Control style={styles.rounded} type="file" placeholder="National ID, Passport, or Driver's Licence" accept="image/*" onChange={setIdentificationDocument} required />*/}
                    {/*    </Form.Group>*/}

                    {/*<Form.Group className="mb-3" controlId="diasporaCustomerNextOfKinUtilityBill">*/}
                    {/*    <Form.Label>Utility Bill (Most recent) <span style={{ color: "#ed1b2e" }}>*</span></Form.Label>*/}
                    {/*    <Form.Control style={styles.rounded} type="file" placeholder="Utility Bill" accept="image/*" onChange={setUtilityBill} required />*/}
                    {/*</Form.Group>*/}

                    <br/>
                    <Typography variant="h5" sx={CustomStyle.heading}>Referee</Typography>

                    <CustomNBSTextField
                        label="Referee name *"
                        onInputChange={setRefereeName}
                        value={customerDetails.ref_name}
                        inputType="text"
                        name="ref_name"
                        required="true"
                    />

                    <CustomNBSTextField
                        label="Referee phone *"
                        onInputChange={setRefereePhone}
                        value={customerDetails.ref_phone_number}
                        inputType="text"
                        name="ref_phone_number"
                        required="true"
                    />

                    <CustomNBSTextField
                        label="Referee Address *"
                        onInputChange={setRefereeAddress}
                        value={customerDetails.residence_status}
                        inputType="text"
                        name="residence_status"
                        required="true"
                    />

                    <br/>
                    <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton type="submit"
                                            disabled={loading}>
                            {loading ? <CircularProgress color="inherit" size={24}/> : 'Next'}
                        </CustomNBSRedButton>
                    </div>

                </form>
            </div>
        </div>

    )
});