import React, {memo, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {Box, Typography} from '@mui/material';
import {NBSRedButton} from "./custom-componets/NBSRedButton";
import CustomNBSRedButton from "./custom-componets/CustomNBSRedButton";

export const HomePage = memo(({updateCurrentPage}) => {

    const title = 'Welcome to NBS Bank changu online';
    const subtitle =
        'Start your account creation or document resubmission process online without the need to visit our branches.' +
        ' Click on your preferred option below to begin.';
    const [notRobot, setNotRobot] = useState(false);
    const [showAccountButtons, setShowAccountButtons] = useState(true);

    const style = {
        pageWrapper: {
            backgroundColor: '#f0f0f0', // Full-page background color
            minHeight: '100vh', // Full viewport height
            display: 'flex',
            alignItems: 'center', // Center vertically
            justifyContent: 'center', // Center horizontally
            padding: '20px', // Ensure padding around the edges
        },
        contentContainer: {
            textAlign: 'center',
            padding: '20px',
            backgroundColor: '#ffffff', // White background for the content area
            borderRadius: '10px', // Rounded corners for better visuals
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            maxWidth: '800px', // Limit the width for better readability
            width: '100%',
            marginTop: '20px',
            '@media (max-width: 600px)': {
                marginTop: '10px',
                alignSelf: 'flex-start',
            }

        },
        heading: {
            color: '#2b357c',
            marginBottom: '20px',
            fontWeight: 'bold',
            fontSize: '2.5rem',
            lineHeight: '1.2'
        },
        subtitle: {
            color: '#2b357c',
            marginBottom: '30px',
            fontSize: '1.25rem', // Slightly larger font size for the subtitle
            lineHeight: '1.5', // Improved line spacing
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.5rem',
        },
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    };

    const goToDocumentsAmendment = () => {
        updateCurrentPage('AmendDocuments');
    };
    const goToDiasporaAccount = () => {
        updateCurrentPage('DiasporaAccountsRequirements');
    };
    const goToOrdinaryAccount = () => {
        updateCurrentPage('Home');
        //loadSelfOnboard();
    }

    const goToExistingAccount = () => {
        updateCurrentPage("AccountNumber")
        //updateCurrentPage("KeyFacts")

    }

    const goToFixedDepositAccount = () => {
        updateCurrentPage("AccountNumberFixedDeposit")
    }
    const verifyCallback = (response) => {
        console.log(response);
        setNotRobot(true);
    };

    function onChange(value) {
        console.log('Captcha value:', value);
        if (value.length > 10) setNotRobot(true);
        else setNotRobot(false);
    }

    const handleCreateAccountClick = () => {
        setShowAccountButtons(false);
    };

    return (
        <div style={style.pageWrapper}>
            <div style={style.contentContainer}>
                <Typography variant="h5" sx={style.heading}>
                    {title}
                </Typography>
                <Box sx={{mt: 2}}/>

                <Typography variant="h6" sx={style.subtitle}>
                    {subtitle}
                </Typography>

                <Box sx={{mt: 2}}/>

                <Box sx={{marginTop: 3}}>
                    {!notRobot ? (
                        <div style={containerStyle}>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY2} onChange={onChange}
                                       callback={verifyCallback}/>
                        </div>
                    ) : (
                        <>
                            {showAccountButtons ? (
                                <div style={style.buttonContainer}>
                                    <CustomNBSRedButton
                                                  onClick={handleCreateAccountClick}>
                                        Create account
                                    </CustomNBSRedButton>
                                    {/*<div style={{height: '1rem'}}></div>*/}
                                    <CustomNBSRedButton
                                                  onClick={goToDocumentsAmendment}>
                                        Resubmit documents
                                    </CustomNBSRedButton>
                                </div>
                            ) : (
                                <div style={style.buttonContainer}>
                                    <CustomNBSRedButton
                                                  onClick={goToOrdinaryAccount
                                    }>
                                        Ordinary account
                                    </CustomNBSRedButton>
                                    <CustomNBSRedButton
                                                  onClick={goToDiasporaAccount}>
                                        Diaspora account
                                    </CustomNBSRedButton>
                                    <CustomNBSRedButton
                                                  onClick={goToExistingAccount}>
                                        Secondary account
                                    </CustomNBSRedButton>
                                    <CustomNBSRedButton
                                                  onClick={goToFixedDepositAccount}>
                                        Fixed Deposit account
                                    </CustomNBSRedButton>
                                </div>
                            )}
                        </>
                    )}
                </Box>
            </div>
        </div>
    );
});