export const occupationTypes = [
    "Politically Exposed Persons",
    "Foreigners (Using foreign Passports)",
    "Reverends/Pastors",
    "Chief Executives of Multinationals",
    "High Net worth income >100M (annual)",
    "Employees in Casino/Armaments",
    "Malawians in Diaspora",
    "Private Banking",
    "Employed Professionals (Private Sectors)",
    "Civil Servant/NBS Staff",
    "Students/Minor",
    "Traditional Farmer /Housewife",
    "Traditional Business (Unregistered)",
    "Retired Employees (Not PEPs)"
];

export const businessTypes=[
    "Farmer",
    "Housewife",
    "Retired Employees",
    "Wood",
    "Water Transport",
    "Water Distribution",
    "Travel Booking Agents",
    "Tourism/Entertainment",
    "Tobacco",
    "Textiles",
    "Tea and Beverages",
    "Tea",
    "Sugar cane",
    "Storage",
    "Soya Beans",
    "Security Services",
    "Sanitary Services",
    "Rubber Products",
    "Road Freight Transport",
    "Rice",
    "Retail Services",
    "Restaurants",
    "Recreation and Cultural Services",
    "Rail",
    "Quarries",
    "Property & Estate Management",
    "Printing and Publishing",
    "Postal Office",
    "Plastic Products",
    "Pharmaceuticals",
    "Petroleum Products",
    "Personal & Household Services",
    "Paprika",
    "Paper and Paper Products",
    "Other",
    "Oil Exploration & Products",
    "Non-Government organisations",
    "Motor Trade",
    "Metal Ore Mining",
    "Media Industries",
    "Maize",
    "Machinery",
    "Local Government",
    "Livestock & Poultry",
    "Legal Service",
    "Leather",
    "IT /Telecommunications",
    "Investment & Development",
    "Hotels",
    "Groundnuts",
    "Gas Distribution",
    "Garage and Similar Services",
    "Furniture",
    "Forestry",
    "Food Manufacturers",
    "Fishing",
    "Fabricated Metal Products",
    "Equipment",
    "Energy",
    "Electronics",
    "Electricity Supply",
    "Electrical Equipment Manufacturing",
    "Drinks",
    "Distributors-Tobacco",
    "Distributors- Tea",
    "Cotton",
    "Community, Social & Personal Service",
    "Communication",
    "Coal Products",
    "Clothing & Tailoring",
    "Civil Engineer",
    "Schools",
    "Chemicals",
    "Charitable Organisations",
    "Central Bank",
    "Business Service",
    "Bus Passenger Transport",
    "Building & Construction",
    "Building & Mutual Societies",
    "Brokers & Insurance",
    "Breweries & Distillers",
    "Basic Mental Industries",
    "Banking",
    "Banks",
    "Air Transport",
    "Admin General Account",
  ];

export const districtByCategory = {
    BALAKA:["Balaka Town","TA Kalembo","TA Nsamala"],
    BLANTYRE:["TA Chigaru","TA Kapeni","TA Kuntaja","TA Kunthembwe","TA Lundu","TA Machinjili","TA Makata","TA Somba"],
    BLANTYRECITY:["Bangwe Ward","Blantyre Central Ward","Blantyre East Ward","Blantyre West Ward","Chichiri Ward","Chigumula Ward","Chilomoni Ward","Likhubula Ward","Limbe Central Ward","Limbe East Ward","Limbe West Ward","Mapanga Ward","Michiru Ward","Misesa Ward","Msamba Ward","Mzedi Ward","Namiyango Ward","Nancholi Ward","Ndirande North Ward","Ndirande South Ward","Ndirande West Ward","Nkolokoti Ward","Nyambadwe Ward","Soche East Ward","Soche West Ward","South Lunzu Ward"],
    CHIKWAWA:["Chikwawa Boma","Lengwe National Park","Majete Game Reserve - Chikwawa","Ngabu Urban","TA Chapananga","TA Kasisi","TA Katunga","TA Lundu","TA Makhwira","TA Maseya","TA Ngabu"],
    CHIRADZULU:["Chiradzulu Boma","TA Chitera","TA Kadewere","TA Likoswe","TA Mpama","TA Nchema","TA Nkalo"],
    CHITIPA:["Chitipa Boma","Nyika National Park - Chitipa","TA Kameme","TA Mwabulambya","TA Mwenemisuku","TA Mwenewenya","TA Nthalire"],
    DEDZA:["Dedza Boma","SC Chauma","SC Chilikumwendo","SC Kamenya Gwaza","TA Kachindamoto","TA Kaphuka","TA Kasumbu","TA Pemba","TA Tambala"],
    DOWA:["Dowa Boma","Mponela Urban","SC Chakhaza","SC Kayembe","SC Mkukula","SC Mponela","TA Chiwere","TA Dzoole","TA Msakambewa",],
    KARONGA:["Karonga Town","Nyika National Park - Karonga","SC Mwakaboko","SC Mwirang'ombe","TA Kilupula","TA Kyungu","TA Wasambo"],
    KASUNGU:["Kasungu Boma","Kasungu National Park","SC Chilowamatambe","SC Chisikwa","SC Kawamba","SC Lukwa","SC M'nyanja","SC Njombwa","SC Simlemba","TA Chulu","TA Kaluluma","TA Kaomba","TA Kapelula","TA Mwase","TA Santhe","TA Wimbe"],
    LIKOMA:["TA Mkumpha"],
    LILONGWE: ["SC Chitekwele","SC Mtema","SC Njewa","SC Tsabango","TA Chadza","TA Chimutu","TA Chiseka","TA Chitukula","TA Kabudula","TA Kalolo","TA Kalumba","TA Kalumbu","TA Khongoni","TA Malili","TA Mazengera"],
    LILONGWECITY:["Area 1","Area 10","Area 11","Area 12","Area 13","Area 14","Area 15","Area 16","Area 17","Area 18","Area 19","Area 2","Area 20","Area 21","Area 22","Area 23","Area 24","Area 25","Area 26","Area 27","Area 28","Area 29","Area 30","Area 31","Area 32","Area 33","Area 34","Area 35","Area 36","Area 37","Area 38","Area 39","Area 4","Area 40","Area 41","Area 42","Area 43","Area 44","Area 45","Area 46","Area 47","Area 48","Area 49","Area 5","Area 50","Area 51","Area 52","Area 53","Area 54","Area 55","Area 56","Area 57","Area 58","Area 6","Area 7","Area 8","Area 9"],
    MACHINGA:["Lake Chilwa","Lake Chiuta","Liwonde National Park","Liwonde Town","Machinga Boma","SC Chamba","SC Chikweo","SC Chiwalo","SC Mlomba","SC Mposa","SC Ngokwe","SC Sitola","TA Kawinga","TA Liwonde","TA Nyambi"],
    MANGOCHI:["Lake Malawi Natl. Park - Mangochi","Lake Malombe","Mangochi Town","Monkey Bay Urban","SC Chowe","SC Mbwana Nyambi","SC Namabvi","TA Chimwala","TA Jalasi","TA Katuli","TA Makanjila","TA Mponda","TA Nankumba"],
    MCHINJI:["Mchinji Boma","SC Dambe","SC Mavwere","SC Mduwa","TA Mkanda","TA Mlonyeni","TA Zulu"],
    MULANJE:["Mulanje Boma","Mulanje Mountain Reserve","SC Juma","SC Laston Njema","TA Chikumbu","TA Mabuka","TA Nkanda","TA Nthiramanja"],
    MWANZA:["Majete Game Reserve - Mwanza","Mwanza Boma","TA Dambe","TA Kanduku","TA Mlauli","TA Ngozi","TA Nthache","TA Symon"],
    MZIMBA:["Mzimba Boma","SC Jaravikuba Munthali","SC Kampingo Sibande","SC Khosolo Gwaza Jere","TA Chindi","TA Mabulabo","TA M'Mbelwa","TA Mpherembe","TA Mtwalo","TA Mzikubola","TA Mzukuzuku","Vwaza Marsh Reserve - Mzimba"],
    MZUZU:["Chasefu Ward","Chibanja Ward","Chiputula Ward","Jombo Ward","Kaning'ina Ward","Katawa Ward","Katoto Ward","Lupaso Ward","Masasa Ward","Mchengautuwa Ward","Msongwe Ward","Muzilawayingwe Ward","New Airport Site","Nkhorongo Ward","Viphya Ward","Zolozolo Ward"],
    NKHATABAY:["SC Fukamalaza","SC Malanda","SC Mkondowe","SC Mkumbira","SC Nyaluwanga","SC Zilakoma","TA Boghoyo","TA Fukamapiri","TA Kabunduli","TA Malenga Mzoma","TA Mankhambira","TA Musisya","TA Timbiri"],
    NKHOTAKOTA:["Nkhotakota Boma","Nkhotakota Game Reserve","Nkhotakota Game Reserve","SC Kafuzila","SC Mphonde","SC Mwansambo","TA Kanyenda","TA Malenga Chanzi","TA Mwadzama"],
    NSANJE:["Nsanje Boma","SC Makoka","SC Mbenje","TA Chimombo","TA Malemia","TA Mlolo","TA Ndamera","TA Ngabu","TA Nyachikadza","TA Tengani"],
    NTCHEU:["Ntcheu Boma","SC Champiti","SC Goodson Ganya","SC Makwangwala","TA Chakhumbira","TA Kwataine","TA Masasa","TA Mpando","TA Njolomole","TA Phambala","TA Phambala"],
    NTCHISI:["Ntchisi Boma","SC Chilooko","SC Nthondo","TA Chikho","TA Kalumo","TA Kasakula"],
    PHALOMBE:["Lake Chilwa","Phalombe Boma","TA Mkhumba","TA Nazombe"],
    RUMPHI:["Nyika National Park - Rumphi","Rumphi Boma","SC Chapinduka","SC Kachulu","SC Mwahenga","SC Mwalweni","SC Mwankhunikira","TA Chikulamayembe","TA Katumbi","TA Mwamlowe","TA Zolokere","Vwaza Marsh Reserve - Rumphi"],
    SALIMA:["Chipoka Urban","Lake Malawi Natl. Park - Salima","Salima Town","SC Kambalame","SC Kambwiri","SC Msosa","SC Mwanza","TA Karonga","TA Khombedza","TA Kuluunda","TA Maganga","TA Ndindi","TA Pemba"],
    THYOLO:["SC Kwethemule","SC Mbawela","SC Mphuka","SC Thukuta","TA Bvumbwe","TA Changata","TA Chimaliro","TA Kapichi","TA Nchilamwela","TA Nsabwe","TA Thomas","Thyolo Boma"],
    ZOMBA:["Lake Chilwa","SC Mbiza","SC Mkumbira","TA Chikowi","TA Kuntumanji","TA Malemia","TA Mlumbe","TA Mwambo"],
    ZOMBACITY:["Chambo Ward","Chikamveka North Ward","Chikamveka Ward","Chirunga East Ward","Chirunga Ward","Likangala Central Ward","Likangala South Ward","Likangala Ward","Likangala Ward","Masongola Ward","Masongola Ward","Mbedza Ward","Mtiya Ward","Sadzi Ward","Zakazaka Ward","Zomba Central Ward"],
}

export const occupations = [
    { label: "Politician", value: "POLITICIAN" },
    { label: "Accountant", value: "ACCOUNTANT" },
    { label: "Actuary", value: "ACTUARY" },
    { label: "Barista", value: "BARISTA" },
    { label: "Executive Manager", value: "EXEMANAGER" },
    { label: "Lawyer", value: "LAWYER" },
    { label: "Professor", value: "PROFESSOR" },
    { label: "Auditor", value: "AUDITOR" },
    { label: "Board Member", value: "BOARD" },
    { label: "Business Analyst", value: "ANALYST" },
    { label: "Railroad Engineer", value: "ENGINEER" },
    { label: "Entrepreneur", value: "ENTERPR" },
    { label: "Journalist", value: "JOURNALIST" },
    { label: "Dentist", value: "DENTIST" },
    { label: "Doctor", value: "DOCTOR" },
    { label: "Film Maker", value: "FILMMAKER" },
    { label: "Police Officer", value: "POLICE" },
    { label: "Small Business Owner", value: "SMALLBUSNS" },
    { label: "Surveyor", value: "SURVEYOR" },
    { label: "Tradesperson", value: "TRADEPRSN" },
    { label: "Chef", value: "CHEF" },
    { label: "Civil Servant", value: "CIVILSERV" },
    { label: "Equipment Operator", value: "OPERATOR" },
    { label: "Medical Professional", value: "MEDICAL" },
    { label: "Pilot", value: "PILOT" },
    { label: "Actor", value: "ACTOR" },
    { label: "Agent", value: "AGENT" },
    { label: "Art Director", value: "ARTDIRECTR" },
    { label: "Courier", value: "COURIER" },
    { label: "Craftsperson", value: "CRAFTPESN" },
    { label: "Mathematician", value: "MATHEMATI" },
    { label: "Midwife", value: "MIDWIFE" },
    { label: "Military Personnel", value: "MILPERSONEL" },
    { label: "Nurse", value: "NURSE" },
    { label: "Optician", value: "OPTICIAN" },
    { label: "Performance Artist", value: "PERFARTIST" },
    { label: "Researcher", value: "RESEARCHER" },
    { label: "Sailor", value: "SAILOR" },
    { label: "Sea Captain", value: "SEACAPTN" },
    { label: "Social Worker", value: "SOCIALWK" },
    { label: "Statistician", value: "STATICIAN" },
    { label: "Surgeon", value: "SURGEON" },
    { label: "Tutor", value: "TUTOR" },
    { label: "Self-Employed", value: "SELFEMPLOY" },
    { label: "Administrator", value: "ADMINI" },
    { label: "Architect", value: "ARCHITECT" },
    { label: "Computer Scientist", value: "ITSCNTIST" },
    { label: "Economist", value: "ECONOMIST" },
    { label: "Electrician", value: "ELECTRICIAN" },
    { label: "Fitness Trainer", value: "TRAINER" },
    { label: "Information Technology Professional", value: "ITPROF" },
    { label: "Manager", value: "MANAGER" },
    { label: "Marketing Professional", value: "MARKETPROF" },
    { label: "Office Worker", value: "OFFICEWORK" },
    { label: "Salesperson", value: "SALESPERS" },
    { label: "Software Developer", value: "DEVELOPER" },
    { label: "Agricultural Worker", value: "AGRIWORKER" },
    { label: "Childcare Worker", value: "CHILDWORKR" },
    { label: "Company Worker", value: "COMPWORKER" },
    { label: "Curator", value: "CURATOR" },
    { label: "Maintenance Worker", value: "MAINTWORK" },
    { label: "Manufacturing Worker", value: "MANUFAWORK" },
    { label: "Artist", value: "ARTIST" },
    { label: "Athlete", value: "ATHLETE" },
    { label: "Bartender/ Waitress", value: "BARTENDER" },
    { label: "Construction Worker", value: "CONSTRCTOR" },
    { label: "Flight Attendant", value: "FLIGHTATT" },
    { label: "Freelancer", value: "FREELANCER" },
    { label: "Investor", value: "INVESTOR" },
    { label: "Librarian", value: "LIBRARIAN" },
    { label: "Merchant", value: "MERCHANT" },
    { label: "Photographer", value: "PHOTGRPHER" },
    { label: "Retail Employee", value: "RETAILEMP" },
    { label: "Scientist", value: "SCIENTIST" },
    { label: "Copywriter", value: "COPYWRITER" },
    { label: "Customer Service Representative", value: "SERVICREP" },
    { label: "Firefighter", value: "FIREFIGHTR" },
    { label: "Fisherman", value: "FISHERMAN" },
    { label: "Florist", value: "FLORIST" },
    { label: "Gardener", value: "GARDENER" },
    { label: "Home Care Aid", value: "HOMECARE" },
    { label: "Homemaker", value: "HOMEMAKER" },
    { label: "Rigger", value: "RIGGER" },
    { label: "Secretary", value: "SECRETARY" },
    { label: "Spokesperson", value: "SPOKESPER" },
    { label: "Trainer", value: "TRAINER" },
    { label: "Veterinarian", value: "VTERIAN" },
    { label: "Videographer", value: "VGRAPHER" },
    { label: "Waiter", value: "WAITER" },
    { label: "Waste Collector", value: "WSTCOLECTR" },
    { label: "Consultant", value: "CONSULTANT" },
    { label: "Designer", value: "DESIGNER" },
    { label: "Driver", value: "DRIVER" },
    { label: "Farmer", value: "FARMER" },
    { label: "Hair Stylist", value: "HAIRDRESER" },
    { label: "Hospitality Worker", value: "HOSPWORKER" },
    { label: "Mechanic", value: "MECHANIC" },
    { label: "Retiree", value: "RETIREE" },
    { label: "Tailor", value: "TAILOR" },
    { label: "Teacher", value: "TEACHER" },
    { label: "Technician", value: "TECHNICIAN" },
    { label: "Truck Driver", value: "TRUCK" },
    { label: "Writer", value: "WRITER" },
    { label: "Zoologist", value: "ZOOLOGIST" },
    { label: "Baker", value: "BAKER" },
    { label: "Housekeeper", value: "HOUSEKEEP" },
    { label: "Nanny", value: "NANNY" },
    { label: "Office Clerk", value: "OFFICECLEK" },
    { label: "Stay-At-Home Parent", value: "STAYHOMEPA" },
    { label: "Translator", value: "TRANSLATOR" },
    { label: "Musician", value: "MUSICIAN" },
    { label: "Student", value: "STUDENT" },
    { label: "Unemployed", value: "UNEMPLOYED" },
    { label: "Cleaner", value: "CLEANER" },
    { label: "Laborer", value: "LABORER" }
];

export const industryOptions = [
    { label: 'Metal Ore Mining', value: 'Metal Ore Mining' },
    { label: 'Petrolium Products', value: 'Petrolium Products' },
    { label: 'Non-Government organisations', value: 'Non-Government organisations' },
    { label: 'Quarries', value: 'Quarries' },
    { label: 'Coal Products', value: 'Coal Products' },
    { label: 'Oil Exploration & Products', value: 'Oil Exploration & Products' },
    { label: 'Gas Distribution', value: 'Gas Distribution' },
    { label: 'Energy', value: 'Energy' },
    { label: 'Building & Construction', value: 'Building & Construction' },
    { label: 'Charitable Organisations', value: 'Charitable Organisations' },
    { label: 'Business Service', value: 'Business Service' },
    { label: 'Legal Service', value: 'Legal Service' },
    { label: 'Property & Estate Management', value: 'Property & Estate Management' },
    { label: 'Banking', value: 'Banking' },
    { label: 'Fabricated Metal Products', value: 'Fabricated Metal Products' },
    { label: 'Electrical Equipment Manufacturing', value: 'Electrical Equipment Manufacturing' },
    { label: 'Chemicals', value: 'Chemicals' },
    { label: 'Leather', value: 'Leather' },
    { label: 'Civil Engineer', value: 'Civil Engineer' },
    { label: 'Motor Trade', value: 'Motor Trade' },
    { label: 'IT /Telecommunications', value: 'IT /Telecommunications' },
    { label: 'Churches/Schools', value: 'Churches/Schools' },
    { label: 'Security Services', value: 'Security Services' },
    { label: 'Local Government', value: 'Local Government' },
    { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
    { label: 'Staff', value: 'Staff' },
    { label: 'Central Bank', value: 'Central Bank' },
    { label: 'BANK', value: 'BANK' },
    { label: 'Brokers & Insurance', value: 'Brokers & Insurance' },
    { label: 'Buiding & Mutual Socities', value: 'Buiding & Mutual Socities' },
    { label: 'Rubber Products', value: 'Rubber Products' },
    { label: 'Paper and Paper Products', value: 'Paper and Paper Products' },
    { label: 'Electricity Supply', value: 'Electricity Supply' },
    { label: 'Distributors-Tobacco', value: 'Distributors-Tobacco' },
    { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
    { label: 'Tourism/Entertainment', value: 'Tourism/Entertainment' },
    { label: 'Air Transport', value: 'Air Transport' },
    { label: 'Travel Booking Agents', value: 'Travel Booking Agents' },
    { label: 'Sanitary Services', value: 'Sanitary Services' },
    { label: 'Investment & Development', value: 'Investment & Development' },
    { label: 'Plastic Products', value: 'Plastic Products' },
    { label: 'Food Manufacturers', value: 'Food Manufacturers' },
    { label: 'Tea and Beverages', value: 'Tea and Beverages' },
    { label: 'Textiles', value: 'Textiles' },
    { label: 'Clothing & Tailoring', value: 'Clothing & Tailoring' },
    { label: 'Furniture', value: 'Furniture' },
    { label: 'Electronics', value: 'Electronics' },
    { label: 'Distributors- Tea', value: 'Distributors- Tea' },
    { label: 'Retail Services', value: 'Retail Services' },
    { label: 'Restaurant', value: 'Restaurant' },
    { label: 'Hotels', value: 'Hotels' },
    { label: 'Rail', value: 'Rail' },
    { label: 'Water Transport', value: 'Water Transport' },
    { label: 'Media Industries', value: 'Media Industries' },
    { label: 'Postal Office', value: 'Postal Office' },
    { label: 'Printing and Publishing', value: 'Printing and Publishing' },
    { label: 'Water Distribution', value: 'Water Distribution' },
    { label: 'Recreation and Cultural Services', value: 'Recreation and Cultural Services' },
    { label: 'Garage and Similar Services', value: 'Garage and Similar Services' },
    { label: 'Admin General Account', value: 'Admin General Account' },
    { label: 'Other', value: 'Other' },
    { label: 'Tea', value: 'Tea' },
    { label: 'Tobacco', value: 'Tobacco' },
    { label: 'Fishing', value: 'Fishing' },
    { label: 'Wood', value: 'Wood' },
    { label: 'Breweries & Distillers', value: 'Breweries & Distillers' },
    { label: 'Drinks', value: 'Drinks' },
    { label: 'Basic Metal Industries', value: 'Basic Metal Industries' },
    { label: 'Equipment', value: 'Equipment' },
    { label: 'Bus Passenger Transport', value: 'Bus Passenger Transport' },
    { label: 'Road Freight Transport', value: 'Road Freight Transport' },
    { label: 'Communication', value: 'Communication' },
    { label: 'Sugar cane', value: 'Sugar cane' },
    { label: 'Groundnuts', value: 'Groundnuts' },
    { label: 'Rice', value: 'Rice' },
    { label: 'Forestry', value: 'Forestry' },
    { label: 'Livestock & Poultry', value: 'Livestock & Poultry' },
    { label: 'Cotton', value: 'Cotton' },
    { label: 'Maize', value: 'Maize' },
    { label: 'Parprika', value: 'Parprika' },
    { label: 'Soya Beans', value: 'Soya Beans' },
    { label: 'Machinery', value: 'Machinery' },
    { label: 'Storage', value: 'Storage' },
    { label: 'Community, Social & Personal Servic', value: 'Community, Social & Personal Servic' },
    { label: 'Personal & Household Services', value: 'Personal & Household Services' }
];

export const sourceOfIncome = [
    { label: 'Lottery', value: 'L' },
    { label: 'Gambling', value: 'G' },
    { label: 'Gifts', value: 'GF' },
    { label: 'Donation', value: 'D' },
    { label: 'Investment/Dividend Income', value: 'INV' },
    { label: 'Loan Proceeds', value: 'LP' },
    { label: 'Personal Savings', value: 'PS' },
    { label: 'Personal Wealth', value: 'PW' },
    { label: 'Inheritance', value: 'INH' },
    { label: 'Trust', value: 'T' },
    { label: 'Sale of Asset (business / property)', value: 'SOA' },
    { label: 'Pension', value: 'PEN' },
    { label: 'Salary', value: 'SAL' },
    { label: 'Legal Settlement', value: 'LS' },
    { label: 'Alimony', value: 'AL' },
    { label: 'Others', value: 'O' },
    { label: 'Farming', value: 'FAR' },
    { label: 'Upkeep', value: 'UP' },
    { label: 'Real Estate', value: 'REA' },
    { label: 'Business/Trading', value: 'BUS' }
];

export const sectors = [
    { label: "Agriculture, forestry, fishing and hunting", value: "Agriculture, forestry, fishing and hunting" },
    { label: "Mining and quarrying", value: "Mining and quarrying" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Electricity, gas, water and energy", value: "Electricity, gas, water and energy" },
    { label: "Construction and engineering", value: "Construction and engineering" },
    { label: "Wholesale and retail trade", value: "Wholesale and retail trade" },
    { label: "Restaurants and hotels", value: "Restaurants and hotels" },
    { label: "Transport, storage and communications", value: "Transport, storage and communications" },
    { label: "Community, social and personal service", value: "Community, social and personal service" },
    { label: "Real estate", value: "Real estate" },
    { label: "Staff", value: "Staff" },
    { label: "Financial Services", value: "Financial Services" },
    { label: "Central Bank", value: "Central Bank" },
    { label: "Bank", value: "Bank" }
];


