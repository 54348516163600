import React, {memo, useEffect, useState} from "react";
import {FaExclamationTriangle} from "react-icons/fa";
import axios from "axios";
import {CustomStyle} from "../../helpers/styles";
import {Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import CircularProgress from "@mui/material/CircularProgress";
import {CheckCircleOutline} from "@mui/icons-material";

export async function submitCustomerData(existingDetails) {
    return await axios.post("/submit-second-account", existingDetails, {
        headers: {
            "Authorization": `Token ${process.env.REACT_APP_TOKEN}`,
        }, maxContentLength: Infinity, maxBodyLength: Infinity
    })
        .then(res => res)
        .catch(err => err);
}

export const FinishPage = memo(({updateCurrentPage, existingDetails, setExistingDetails}) => {
            console.log("On finish Page " + JSON.stringify(existingDetails))
            console.log(existingDetails)
            const title = "Your account opening request has been received successfully.";

            const [submissionCompletedSuccessfully, setSubmissionCompletedSuccessfully] = useState(false);
            const [customerAlreadyExists, setCustomerAlreadyExists] = useState(false);
            const [submittingApplication, setSubmittingApplication] = useState(true);
            const [unknownError, setUnknownError] = useState(false);
            const [resubmit, setResubmit] = useState(false);

            const goToHome = () => {
                document.location.reload()
                updateCurrentPage("HomePage");
            }

            const resubmitData = () => {
                setSubmittingApplication(true)
                setResubmit(!resubmit)
            }

            useEffect(() => {
                async function submitData() {
                    const res = await submitCustomerData(existingDetails).then(r => r.data).catch(err => {
                        console.error(err);
                        return null;
                    });

                    console.log(res.data)

                    if (res.status) {
                        setSubmittingApplication(false);
                        setSubmissionCompletedSuccessfully(true)
                    } else {
                        setSubmittingApplication(false);
                        setSubmissionCompletedSuccessfully(false);
                        setUnknownError(true)

                    }

                }

                submitData();
            }, [resubmit]);

            return (
                <>
                    {(() => {
                        let element;
                        if (submissionCompletedSuccessfully) {
                            element = <>
                                <div style={CustomStyle.pageWrapper}>
                                    <div style={CustomStyle.contentContainer}>
                                        <CheckCircleOutline style={{fontSize: 80, color: 'green'}}/>

                                        <Typography variant="h5" sx={CustomStyle.subtitle}>{title}</Typography>
                                        <div style={CustomStyle.buttonContainer}>

                                            <CustomNBSRedButton onClick={goToHome}
                                                                disabled={false}>
                                                Finish
                                            </CustomNBSRedButton>
                                        </div>

                                    </div>
                                </div>
                            </>
                        } else if (customerAlreadyExists) {
                            element = <>
                                <div style={CustomStyle.pageWrapper}>
                                    <div style={CustomStyle.contentContainer}>
                                        <FaExclamationTriangle style={{fontSize: 80, color: 'red'}}/>
                                        <Typography variant="h5" sx={CustomStyle.subtitle}>Information for a customer was
                                            already
                                            submitted</Typography>

                                        <div style={CustomStyle.buttonContainer}>

                                            <CustomNBSRedButton onClick={goToHome}
                                                                disabled={false}>
                                                Finish
                                            </CustomNBSRedButton>
                                        </div>


                                    </div>
                                </div>
                            </>
                        } else if (submittingApplication) {
                            element = <>
                                <div style={CustomStyle.pageWrapper}>
                                    <div style={CustomStyle.contentContainer}>
                                        <CircularProgress/>
                                        <Typography variant="h5" sx={CustomStyle.subtitle}>Submitting your application, please
                                            wait...</Typography>
                                    </div>
                                </div>
                            </>
                        } else if (unknownError) {
                            element = <>
                                <div style={CustomStyle.pageWrapper}>
                                    <div style={CustomStyle.contentContainer}>

                                        <FaExclamationTriangle style={{fontSize: 80, color: 'red'}}/>

                                        <Typography variant="h5" sx={CustomStyle.subtitle}>We're sorry, something went wrong
                                            while
                                            submitting your application. Please try again later</Typography>

                                        <div style={CustomStyle.buttonContainer}>

                                            <CustomNBSRedButton onClick={resubmitData}
                                                                disabled={false}>
                                                Retry
                                            </CustomNBSRedButton>

                                            <CustomNBSRedButton onClick={goToHome}
                                                                disabled={false}>
                                                Home
                                            </CustomNBSRedButton>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }
                        return element;
                    })()}
                </>
            );
        }
    )
;