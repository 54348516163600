let existingProfile={
    "account_number":"",
    "service_center": "",
    "account_category": "",
    "atm_secret_question": "",
    "atm_secret_answer": "",
    "short_name":"",
    "cell_phone":"",
    "allowed_accounts":"",
    "services":[],
    "occupation":""
}
export {existingProfile};