import React, {memo, useState, useEffect} from 'react';
import {
    fetchBranches,
    getBase64,
    getDocLinks,
    getPdfFile,
    resolveAndGetImageFileBase64,
    validateCustomerData
} from '../../helpers/Diaspora.js';
import {DIASPORA_ACCOUNT_TYPES} from '../../helpers/Diaspora.js';
import SweetAlert from "react-bootstrap-sweetalert";
import {PDFDocumentViewer} from "../PDFDocumentViewer";
import {documentCodes, documents} from "../../helpers/Documents";
import {customSelectStyles, CustomStyle} from "../../helpers/styles";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Typography
} from "@mui/material";
import Select from "react-select";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const DiasporaTermsAndConditions = memo(({updateCurrentPage, customerDetails, setCustomerDetails}) => {
    const [termsAndConditionsRead, setTermsAndConditionsRead] = useState(false);
    const [branches, setBranches] = useState([]);
    const [signatureUploaded, setSignatureUploaded] = useState(false);
    const stringSeperator = "|";
    const [docs, setDocs] = useState({});

    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [showKeyFactStatements, setShowKeyFactStatements] = useState(false);
    const [showTermsAndConditionsDigital, setShowTermsAndConditionsDigital] = useState(false);

    const [show, setShow] = useState(["terms", "fact_stmt", "fact_digital"]);
    const [count, setCount] = useState(0);
    const [keyFactStatementRead, setKeyFactStatementRead] = useState(false);
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
    const [, setTermsAndConditionsDigitalAccepted] = useState(false);

    const [acknowledgementDone, setAcknowledgementDone] = useState(false);
    const [serviceCenterPicked, setServiceCenterPicked] = useState(false);
    const [activateDeclineModal, setActivateDeclineModal] = useState(false);
    const [uploadedSignatureSrc, setUploadedSignatureSrc] = useState();
    const [serviceCenterSelected, setServiceCenterSelected] = useState(false);

    const changeSignature = async (e) => {
        const signaturePhoto = e.target.files[0];

        let base64 = "";
        if (signaturePhoto) {
            base64 = await resolveAndGetImageFileBase64(signaturePhoto);
        }

        // const base64 = await getBase64(signaturePhoto)
        //     .then(r => r)
        //     .catch(e => {
        //         console.error(e);
        //         return ""
        //     });

        setCustomerDetails((prev) => {
            prev.signature = base64;
            prev.signature_photo = base64;
            prev.customer_picture = prev.customer_photo_url;
            prev.location = prev.residential_map_url;
            prev.utility_bill_pic = prev.kin_utility_bill;
            return prev;
        })
        setSignatureUploaded(customerDetails.signature.length > 0);
        setUploadedSignatureSrc(customerDetails.signature);
    }

    const handleTermsAndConditions = () => {
        setTermsAndConditionsAccepted(true);
        if (show.includes("terms")) {
            setCount((pre) => {
                return (pre + 2)
            });
            setShow((prev) => prev.filter((x) => {
                return x !== "terms"
            }))
        }
        setShowTermsAndConditions(false);
    }
    const handleKeyFactStatements = () => {
        setKeyFactStatementRead(true);
        if (show.includes("terms")) {
            setCount((pre) => {
                return (pre + 2)
            });
            setShow((prev) => prev.filter((x) => {
                return x !== "terms"
            }))
        }
        setShowKeyFactStatements(false);
    }

    const handleShowTermsAndConditions = () => {
        if (show.includes("terms")) setCount((pre) => {
            return (pre - 1)
        });
        setShowTermsAndConditions(true);
    }

    const handleShowKeyFactStatements = () => {
        if (show.includes("terms")) setCount((pre) => {
            return (pre - 1)
        });
        setShowKeyFactStatements(true);
    }
    let key_fact_statement;
    if (customerDetails.account_product === DIASPORA_ACCOUNT_TYPES.TRANSACTIONAL) {
        key_fact_statement = documents()[documentCodes().diaspora_transactional_savings];
    } else if (customerDetails.account_product === DIASPORA_ACCOUNT_TYPES.INVESTMENT) {
        key_fact_statement = documents()[documentCodes().diaspora_investment];
    }

    useEffect(() => {
        // async function fetchDocumentsLinks() {
        //     let documents = await getDocLinks();
        //     setDocs(documents);
        // }

        customerDetails = validateCustomerData(customerDetails);
        setCustomerDetails(customerDetails);

        async function fetchNBSBranches() {
            let branches = await fetchBranches();
            branches = branches.filter(b => !["Head Office", "Haile Selassie Service Centre"].includes(b.branch_name));
            //console.log(branches);
            setBranches(branches);
        }

        // fetchDocumentsLinks();
        fetchNBSBranches();
    }, []);

    const acceptTermsAndConditions = () => {
        updateCurrentPage("Signature");
    }
    const serviceCenterOptions = [
        {value: 'BALAKA', label: 'Balaka'},
        {value: 'BLANTYRE', label: 'Blantyre'},
        {value: 'CAPITAL CITY', label: 'Capital City'},
        {value: 'DEDZA', label: 'Dedza'},
        {value: 'DWANGWA', label: 'Dwangwa'},
        {value: 'GINCO', label: 'Ginnery Corner'},
        {value: 'KANENGO', label: 'Kanengo'},
        {value: 'KARONGA', label: 'Karonga'},
        {value: 'KASUNGU', label: 'Kasungu'},
        {value: 'LILONGWE', label: 'Lilongwe'},
        {value: 'LIMBE', label: 'Limbe'},
        {value: 'LIWONDE', label: 'Liwonde'},
        {value: 'MANGOCHI', label: 'Mangochi'},
        {value: 'MCHINJI', label: 'Limbe'},
        {value: 'MPONELA', label: 'Mponela'},
        {value: 'MULANJE', label: 'Mulanje'},
        {value: 'MZIMBA', label: 'Mzimba'},
        {value: 'MZUZU', label: 'Mzuzu'},
        {value: 'NCHALO', label: 'Nchalo'},
        {value: 'NKHATABAY', label: 'Nkhatabay'},
        {value: 'NKHOTAKOTA', label: 'Nkhotokota'},
        {value: 'NTCHEU', label: 'Ntcheu'},
        {value: 'RUMPHI', label: 'Rumphi'},
        {value: 'SALIMA', label: 'Salima'},
        {value: 'ZOMBA', label: 'Zomba'},

    ];

    const handleSelectChange = (selectedOption) => {
        setServiceCenterSelected(selectedOption?.value || "");
        setCustomerDetails((prev) => {
            return {
                ...prev,
                nearest_service_center: selectedOption?.value || "",
                company_book: selectedOption?.value || ""
            };
        });
    };


    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Key Fact Statement</Typography>

                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    <label>
                        <a onClick={handleShowKeyFactStatements} style={{color: "#0f9d58"}}>
                            Click here
                        </a>
                    </label> to read the key fact statement.
                </Typography>

                <Dialog open={showKeyFactStatements} onClose={() => setShowKeyFactStatements(false)}>
                    <DialogTitle>Key Fact Statement</DialogTitle>
                    <DialogContent>
                        <PDFDocumentViewer documentType={key_fact_statement}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" style={{width: '100%'}}
                                onClick={handleKeyFactStatements}>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>

                <Typography variant="h5" sx={CustomStyle.heading}>Terms and conditions</Typography>
                {/*<h4 style={{fontWeight: "500", color: "#2b357c"}}>Terms and conditions</h4>*/}

                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    <label>
                        <a onClick={handleShowTermsAndConditions} style={{color: "#0f9d58"}}>
                            Click here
                        </a>
                    </label> to read the Terms and Conditions.
                </Typography>
                <Dialog open={showTermsAndConditions} onClose={() => setShowTermsAndConditions(false)}>
                    <DialogTitle>Terms and Conditions</DialogTitle>

                    <DialogContent>
                        <PDFDocumentViewer documentType={documents()[documentCodes().terms_and_conditions]}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" style={{width: '100%'}}
                                onClick={handleTermsAndConditions}>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>

                <form onSubmit={acceptTermsAndConditions}>

                    {/*<Typography variant="body1" sx={CustomStyle.subtitle}>*/}

                    <label style={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
                        <Checkbox
                            checked={termsAndConditionsRead}
                            onChange={setTermsAndConditionsRead}
                        />
                        <span style={{marginLeft: '8px'}}>I acknowledge to have read the key fact statement and the terms and conditions</span>
                    </label>
                    {/*</Typography>*/}

                    <br/>

                    <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Service Centre *
                        </Typography>
                        <Select
                            options={serviceCenterOptions}
                            onChange={handleSelectChange}
                            styles={customSelectStyles}
                            placeholder="Select Service Centre"
                            isClearable
                            required
                        />
                    </FormControl>
                    <Typography style={{...CustomStyle.contentText, color: "#ed1b2e", alignItems: 'left'}}>This is the
                        branch where your account will be held</Typography>

                    <br/>

                    <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton onClick={() => setActivateDeclineModal(true)}
                                            disabled={false}>
                            Decline
                        </CustomNBSRedButton>

                        <CustomNBSRedButton type="submit"
                                            disabled={!termsAndConditionsAccepted || !keyFactStatementRead || !serviceCenterSelected}>
                            Accept
                        </CustomNBSRedButton>
                    </div>

                </form>


                <SweetAlert
                    show={activateDeclineModal}
                    warning
                    showCancel={true}
                    confirmBtnText={"YES"}
                    cancelBtnText={"NO"}
                    confirmBtnStyle={{backgroundColor: "#2b357c", color: "white"}}
                    cancelBtnStyle={{backgroundColor: "#ed1b2e", color: "white", textDecoration: "none"}}
                    onConfirm={() => {
                        document.location.reload(true)
                        updateCurrentPage("HomePage")
                    }} //close
                    onCancel={() => setActivateDeclineModal(false)} //contine with app
                >
                    <p>
                                <span>
                                    You cannot continue if you do not read and agree to the terms and conditions attached herein.
                                </span>
                    </p>
                    <p>
                        <span>Are you sure you want to cancel the application?</span>
                    </p>

                </SweetAlert>
            </div>
        </div>
    )
});



