import React, { useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import {
    student,
    pafupiUnbundled,
    eazyTransactUnbundled,
    eazyTransactBundled,
    eazySaveBundled,
    eazySaveUnBundled,
    currentSilverBundled,
    currentSilverUnbundled,
    currentGoldBundled,
    currentPlatinumBundled
} from './Fees';

const AccountProduct = ({ customerObject }) => {
    const { setCustomerDetails } = customerObject;
    let account_product = { ...pafupiUnbundled };

    if (/student/i.test(customerObject.customerDetails.account_product)) {
        account_product = { ...student };
    } else if (/EazyTransactUnbundled/i.test(customerObject.customerDetails.account_product)) {
        account_product = { ...eazyTransactUnbundled };
    } else if (/EazySaveUnBundled/i.test(customerObject.customerDetails.account_product)) {
        account_product = { ...eazySaveUnBundled };
    } else if (/EazySaveBundled/i.test(customerObject.customerDetails.account_product)) {
        account_product = { ...eazySaveBundled };
    } else if (/EazyTransactBundled/i.test(customerObject.customerDetails.account_product)) {
        account_product = { ...eazyTransactBundled };
    } else if (/CurrentSilverBundled/i.test(customerObject.customerDetails.account_product)) {
        account_product = { ...currentSilverBundled };
    } else if (/CurrentSilverUnbundled/i.test(customerObject.customerDetails.account_product)) {
        account_product = { ...currentSilverUnbundled };
    } else if (/CurrentGoldBundled/i.test(customerObject.customerDetails.account_product)) {
        account_product = { ...currentGoldBundled };
    } else if (/CurrentPlatinumBundled/i.test(customerObject.customerDetails.account_product)) {
        account_product = { ...currentPlatinumBundled };
    }

    useEffect(() => {
        setCustomerDetails((prev) => ({ ...prev, book_balance: account_product.book_balance }));
    }, [account_product.book_balance, setCustomerDetails]);

    return (
        <Box
            sx={{
                margin: '20px auto',
                maxWidth: '800px',
                borderRadius: '12px',
                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                overflow: 'hidden',
                backgroundColor: '#f9f9f9'
            }}
        >
            <TableContainer component={Paper} elevation={0}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                sx={{
                                    backgroundColor: '#2b357c',
                                    color: '#ffffff',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    padding: '16px',
                                    borderBottom: '2px solid #1a1a1a'
                                }}
                            >
                                <Typography variant="h6" sx={{ margin: 0 }}>
                                    {customerObject.customerDetails.account_product}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(account_product).map(([key, value]) => (
                            key !== 'account_limit' && (
                                <TableRow key={key}>
                                    <TableCell
                                        sx={{
                                            fontWeight: 'bold',
                                            borderBottom: '1px solid #ddd',
                                            padding: '12px',
                                            fontSize: '16px',
                                            color: '#2b357c',
                                            '&:hover': {
                                                color: '#ffffff'
                                            }
                                        }}
                                    >
                                        {formatLabel(key)}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            borderBottom: '1px solid #ddd',
                                            padding: '12px',
                                            fontSize: '16px',
                                            color: '#2b357c',
                                            '&:hover': {
                                                color: '#ffffff'
                                            }
                                        }}
                                    >
                                        {value}
                                    </TableCell>
                                </TableRow>
                            )
                        ))}
                        {/PafupiUnbundled/i.test(customerObject.customerDetails.account_product) && (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        fontWeight: 'bold',
                                        borderBottom: '1px solid #ddd',
                                        padding: '12px',
                                        fontSize: '16px',
                                        color: '#2b357c',
                                        '&:hover': {
                                            color: '#ffffff'
                                        }
                                    }}
                                >
                                    Account Limit
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        borderBottom: '1px solid #ddd',
                                        padding: '12px',
                                        fontSize: '16px',
                                        color: '#2b357c',
                                        '&:hover': {
                                            color: '#ffffff'
                                        }
                                    }}
                                >
                                    {account_product.account_limit}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

// Helper function to format the label
const formatLabel = (key) => {
    switch (key) {
        case 'interest_rate':
            return 'Interest Rate';
        case 'bundled_fee':
            return 'Bundled Fee';
        case 'book_balance':
            return 'Book Balance';
        case 'cardless_withdrawal_fees':
            return 'Cardless Fee';
        case 'internal_transfer_money_fee':
            return 'Internal Transfer Money Fee';
        case 'interbank_transfer_fees':
            return 'Interbank Transfer Fee';
        default:
            return key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
    }
};

export default React.memo(AccountProduct);
