import React, {useEffect, useState} from 'react'
import CustomCheckBox from './custom-componets/CustomCheckBox';
import CustomInput from './custom-componets/CustomInput';
import CustomButton from "./custom-componets/Button";
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {businessTypes, industryOptions, occupations, sectors, sourceOfIncome} from './data';
import {IncomeProof} from './MultiComponets';
import {Form} from 'react-bootstrap';
import {RadioGroup, RadioButton} from 'react-radio-buttons';
import {DIASPORA_ACCOUNT_TYPES, getBase64, resetSelfEmploymentSourceOfFundsInputData} from '../helpers/Diaspora.js';
import {setEmployment} from '../redux/Data';
import {useSelector, useDispatch} from 'react-redux'
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";

const GeneralDeclarationForm = React.memo(({customerObject}) => {
    const {customerDetails, setCustomerDetails, updateCurrentPage} = customerObject;
    const employment = useSelector((state) => state.data.employment)
    const dispatch = useDispatch();
    const [disableButton, setDisableButton] = useState(true)
    const [contactDetailsData, setContactDetailsData] = useState({})
    const [selected, setSelected] = useState([customerDetails.type_of_business]);
    const [mobilePhone, setMobilePhone] = useState({class_name: customerDetails.mobile_phone === "" || customerDetails.mobile_phone === undefined ? "form-control rounded-pill is-invalid" : "form-control rounded-pill is-valid"})
    const [grossMonthlyIncome, setGrossMonthlyIncome] = useState({class_name: customerDetails.gross_monthly_income === "" || customerDetails.gross_monthly_income === undefined ? "form-control rounded-pill is-invalid" : "form-control rounded-pill is-valid"})
    const [showOtherInput, setShowOtherInput] = useState(false);


    let classValue = "form-control rounded-pill"
    if (/PafupiUnbundled|student/i.test(customerDetails.account_product.toString())) {
        classValue = "d-none"
    }

    useEffect(() => {
        if (customerDetails.account_product === "PafupiUnbundled") {
            setCustomerDetails((prev) => {
                return {...prev, trad_auth: "", district: ""};
            })
        }

        if (customerDetails.account_product === "student") {
            setCustomerDetails((prev) => {
                return {...prev, employment_status: ""};
            });
        }
    }, []);

    const employmentStatusStates = {
        EMPLOYED: "Employed",
        SELF_EMPLOYED: "Self-Employed"
    };
    const [employmentStatus, setEmploymentStatus] = useState(employmentStatusStates.EMPLOYED);


    const [otherPhone, setOtherPhone] = useState({class_name: classValue})

    let isDiasporaAccount = [DIASPORA_ACCOUNT_TYPES.TRANSACTIONAL, DIASPORA_ACCOUNT_TYPES.INVESTMENT].includes(customerDetails.account_product);

    let isCurrentAccount = ["CurrentSilverBundled", "CurrentSilverUnbundled", "CurrentGoldBundled", "CurrentPlatinumBundled"].includes(customerDetails.account_product);
    const handleDiasporaPaySlipInputChange = async (e) => {
        const url = await getBase64(e.target.files[0])
            .then(r => r)
            .catch(err => {
                console.error(err);
                return "";
            })
        setCustomerDetails((prev) => {
            return ({...prev, income_proof_url: url})
        })
    }


    const handleDiasporaEmployerOrContractDocumentInputChange = (e) => {
        // setCustomerDetails((prev)=>{
        //     return({...prev,income_proof_url:getBase64(e.target.files[0])})
        // })
    }

    const handleCheckedValueChange = (e) => {
        const {id, checked} = e.target;

        setCustomerDetails((prev) => {
            return ({...prev, ...{[e.target.id]: e.target.checked ? 1 : 0}})
        })
        if (id === "other") {
            setShowOtherInput(checked);
        }

    }
    const handleInputChange = (e) => {
        setCustomerDetails((prev) => {
            return ({...prev, ...{[e.target.name]: e.target.value}})
        })
    }
    const changeNeedCardStatus = (val) => {
        setEmploymentStatus(val);
        if (val === employmentStatusStates.EMPLOYED) {
            clearSelfEmployedCustomerData();
        } else if (val === employmentStatusStates.SELF_EMPLOYED) {
            clearEmployedCustomerData();
        }
        setCustomerDetails((prev) => ({...prev, employment_status: val}));
    };


    function clearSelfEmployedCustomerData() {
        setCustomerDetails((prev) => ({
            ...prev,
            type_of_business: "",
            income_main_source: "",
            occupation: "",
            one_off_income: "",
            gross_monthly_income: "",
            other_sources_income: "",
            burseries: ""
        }));
    }

    function clearEmployedCustomerData() {
        setCustomerDetails((prev) => ({
            ...prev,
            employment_status: "",
            occupation: "",
            type_of_business: "",
            work_phone: "",
            gross_monthly_income: "",
            burseries: ""
        }));
    }

    useEffect(() => {
        validateForm();
    }, [customerDetails]);

    useEffect(() => {
        setCustomerDetails((prev) => ({...prev, type_of_business: selected[0]}));
    }, [selected]);

    const handleContactDetailsData = (e) => {
        const {name, value} = e.target;
        if (/mobile_phone/i.test(name)) setMobilePhone({class_name: validateMobilePhone(value)});
        if (/gross_monthly_income/i.test(name)) setGrossMonthlyIncome({class_name: validateGrossMonthlyIncome(value)});
        setContactDetailsData((prev) => ({...prev, [name]: value}));
        setCustomerDetails((prev) => ({...prev, [name]: value}));
    };
    const validateMobilePhone = (phone) => phone ? "form-control rounded-pill is-valid" : "form-control rounded-pill is-invalid";
    const validateGrossMonthlyIncome = (income) => income ? "form-control rounded-pill is-valid" : "form-control rounded-pill is-invalid";


    const styles = {
        rounded: {
            borderRadius: '100px',
            height: '50px'
        }
    }

    const changeMonthlyIncome = (e) => {
        setCustomerDetails((prev) => {
            return { ...prev, gross_monthly_income: e }
        })
    }

    const changeOtherSourcesIncome = (e) => {
        setCustomerDetails((prev) => {
            return { ...prev, other_sources_income: e }
        })
    }

    const changeOneOffIncome = (e) => {
        setCustomerDetails((prev) => {
            return { ...prev, one_off_income: e }
        })
    }


    const changeIncomeMainSource = (e) => {
        setCustomerDetails((prev) => {
            return { ...prev, income_main_source: e }
        })
    }

    const handleIndustryChange = (selectedOption) => {
        handleContactDetailsData({
            target: {
                name: 'type_of_business',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const handleSectorChange = (selectedOption) => {
        handleContactDetailsData({
            target: {
                name: 'burseries',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const handleOccupationChange = (selectedOption) => {
        handleContactDetailsData({
            target: {
                name: 'occupation',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const handleSourceOfIncomeChange = (selectedOption) => {
        handleContactDetailsData({
            target: {
                name: 'income_main_source',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const validateForm = () => {
        const isValid = [
            customerDetails.type_of_business,
            customerDetails.gross_monthly_income,
            customerDetails.mobile_phone,
            customerDetails.purpose_of_account,
            customerDetails.occupation,
            customerDetails.burseries
        ].every(field => field !== undefined && field !== null && field !== "");

        setDisableButton(!isValid);
    };


    let checkBoxes = (
        <>
            <CustomCheckBox id="contract" isChecked={customerDetails.contract} value="Contract/Piece Work"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="import" isChecked={customerDetails.import} value="Import"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="export" isChecked={customerDetails.export} value="Export"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="rentalIncome" isChecked={customerDetails.rentalIncome} value="Rental Income"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="annuity" isChecked={customerDetails.annuity} value="Annuity"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="directorsFees" isChecked={customerDetails.directorsFees} value="Directors Fees"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="interest" isChecked={customerDetails.interest} value="Interest"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="other" isChecked={customerDetails.other} value="Other"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="donation" isChecked={customerDetails.donation} value="Donation"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="loan" isChecked={customerDetails.loan} value="Loan"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
            <CustomCheckBox id="Inheritance" isChecked={customerDetails.Inheritance} value="Inheritance"
                            handleCheckedValueChange={handleCheckedValueChange}/>
            <br/>
        </>
    );
    if (customerDetails.account_product === "student")
        checkBoxes = (
            <>
                <CustomCheckBox id="contract" isChecked={customerDetails.contract} value="Contract/Piece Work"
                                handleCheckedValueChange={handleCheckedValueChange}/>
                <br/>
                <CustomCheckBox id="gvt_loans" isChecked={customerDetails.gvt_loans} value="Government Loans"
                                handleCheckedValueChange={handleCheckedValueChange}/>
                <br/>
                <CustomCheckBox id="burseries" isChecked={customerDetails.burseries} value="Burseries"
                                handleCheckedValueChange={handleCheckedValueChange}/>
                <br/>
                <CustomCheckBox id="private_sponsorship" isChecked={customerDetails.private_sponsorship}
                                value="Private Sponsorship" handleCheckedValueChange={handleCheckedValueChange}/>
                <br/>
                <CustomCheckBox id="guardian" isChecked={customerDetails.guardian} value="From Guardian"
                                handleCheckedValueChange={handleCheckedValueChange}/>
                <br/>
            </>
        );

    if (customerDetails.account_product === "PafupiUnbundled")
        return (
            <div className="content">
                <form className="was-validated">
                    <p style={{fontWeight: "bold", textAlign: "center"}}>
                        Income Declaration form</p>
                    <CustomInput label="Mobile phone number*" className={mobilePhone.class_name}
                                 value={customerDetails.mobile_phone} onInputChange={handleContactDetailsData}
                                 valid="Valid" error="Please enter a valid value" inputType="number"
                                 placeholder="08/09..." id="mobile_phone" name="mobile_phone" required="true"/>
                    {/*<CustomInput label="Other mobile phone number" className={otherPhone.class_name}*/}
                    {/*             value={customerDetails.other_phone} onInputChange={handleContactDetailsData}*/}
                    {/*             valid="Valid" error="Please enter a valid value" inputType="number" placeholder="08/09"*/}
                    {/*             name="other_phone" required="false"/>*/}

                    <br/>
                    <label htmlFor="occupation">Select Occupation Type *</label>
                    <Select
                        styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                        onChange={handleOccupationChange}
                        options={occupations}
                        value={occupations.find(option => option.value === customerDetails.occupation)}
                        name="occupation"
                        isClearable
                        required
                    />

                    <br/>
                    <label htmlFor="">Industry type *</label>
                    <Select
                        styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                        onChange={handleIndustryChange}
                        options={industryOptions}
                        value={industryOptions.find(option => option.value === customerDetails.type_of_business)}
                        name="type_of_business"
                        isClearable
                        required
                    />
                    <br/>

                    <label htmlFor="sector">Select Your Sector *</label>
                    <Select
                        styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                        onChange={handleSectorChange}
                        options={sectors}
                        value={sectors.find(option => option.value === customerDetails.sector)}
                        name="sector"
                        isClearable
                        required
                    />
                    <br/>

                    <label htmlFor="source_of_income">Select Source of Income *</label>
                    <Select
                        styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                        onChange={handleSourceOfIncomeChange}
                        options={sourceOfIncome}
                        value={sourceOfIncome.find(option => option.value === customerDetails.source_of_income)}
                        name="source_of_income"
                        isClearable
                        required
                    />
                    <br/>

                    <label htmlFor="admissionYear">Purpose of opening account *</label>
                    <select className="rounded-pill form-select w3-text-indigo form-floating" name="purpose_of_account"
                            onChange={handleContactDetailsData} aria-label="Default select example"
                            defaultValue="Balaka" id="admissionYear" required>
                        <option value=""></option>
                        <option value="savingsAccount">Savings account</option>
                        <option value="transactionalAccount">Transactional account</option>
                    </select>
                    <br/>

                    <div style={{marginBottom: '10px'}}>
                        <label htmlFor="gross_monthly_income">Your Monthly Income (MK)*</label>
                        <CurrencyInput
                            style={styles.rounded}
                            className="form-control"
                            id="gross_monthly_income"
                            name="gross_monthly_income"
                            placeholder="Please enter an amount"
                            min={1}
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            onValueChange={changeMonthlyIncome}
                            required
                        />
                    </div>
                    <CustomButton label="Next" disableButton={disableButton} updateCurrentPage={updateCurrentPage}
                                  next="CustomerPic"/>
                </form>
            </div>
        );
    else if (customerDetails.account_product === "student")
        return (
            <div className="content">
                <form className="was-validated">
                    <p style={{fontWeight: "bold", textAlign: "center"}}>
                        Income Declaration form</p>

                    <br/>
                    <label htmlFor="occupation">Select Occupation Type *</label>
                    <Select
                        styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                        onChange={handleOccupationChange}
                        options={occupations}
                        value={occupations.find(option => option.value === customerDetails.occupation)}
                        name="occupation"
                        isClearable
                        required
                    />
                    <br/>

                    <label htmlFor="">Industry type *</label>
                    <Select
                        styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                        onChange={handleIndustryChange}
                        options={industryOptions}
                        value={industryOptions.find(option => option.value === customerDetails.type_of_business)}
                        name="type_of_business"
                        isClearable
                        required
                    />
                    <br/>


                    <label htmlFor="sector">Select Your Sector *</label>
                    <Select
                        styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                        onChange={handleSectorChange}
                        options={sectors}
                        value={sectors.find(option => option.value === customerDetails.sector)}
                        name="sector"
                        isClearable
                        required
                    />
                    <br/>

                    <label htmlFor="source_of_income">Select Source of Income *</label>
                    <Select
                        styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                        onChange={handleSourceOfIncomeChange}
                        options={sourceOfIncome}
                        value={sourceOfIncome.find(option => option.value === customerDetails.source_of_income)}
                        name="source_of_income"
                        isClearable
                        required
                    />
                    <br/>

                    <label htmlFor="admissionYear">Purpose of opening account *</label>
                    <select className="rounded-pill form-select w3-text-indigo form-floating"
                            onChange={handleContactDetailsData} aria-label="Default select example"
                            name="purpose_of_account" required>
                        <option value=""></option>
                        <option value="savingsAccount">Savings account</option>
                        <option value="transactionalAccount">Transactional account</option>
                    </select>
                    {/*<p><span className="fundsSource">Your source of funds</span></p>*/}
                    {/*{checkBoxes}*/}

                    <div>
                        <label htmlFor="one_off_income">One off income (MK)</label>
                        <CurrencyInput
                            style={styles.rounded}
                            className="form-control"
                            id="one_off_income"
                            name="one_off_income"
                            placeholder="Please enter an amount"
                            min={1}
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            onValueChange={changeOneOffIncome}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="gross_monthly_income">Your Monthly Income (MK)*</label>
                        <CurrencyInput
                            style={styles.rounded}
                            className="form-control"
                            id="gross_monthly_income"
                            name="gross_monthly_income"
                            placeholder="Please enter an amount"
                            min={1}
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            onValueChange={changeMonthlyIncome}
                            required
                        />
                    </div>

                    <div style={{marginBottom: '10px'}}>
                        <label htmlFor="other_sources_income">Income from other sources (MK)</label>
                        <CurrencyInput
                            style={styles.rounded}
                            className="form-control"
                            id="other_sources_income"
                            name="other_sources_income"
                            placeholder="Please enter an amount"
                            min={1}
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            onValueChange={changeOtherSourcesIncome}
                            required
                        />
                    </div>

                    <CustomButton label="Next" disableButton={disableButton} updateCurrentPage={updateCurrentPage}
                                  next="StudentIDPhoto"/>
                </form>
            </div>
        );
    else
        return (
            <>
                <div className="content">
                    <div>
                        <p style={{fontWeight: "bold", textAlign: "center"}}> Proof of income </p>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Employment Status</Form.Label>
                                <RadioGroup onChange={changeNeedCardStatus} horizontal value={"Employed"}>
                                    <RadioButton value={employmentStatusStates.EMPLOYED} pointColor="#2b357c">
                                        Employed
                                    </RadioButton>
                                    <RadioButton value={employmentStatusStates.SELF_EMPLOYED} pointColor="#2b357c">
                                        Self-Employed
                                    </RadioButton>
                                </RadioGroup>
                            </Form.Group>
                        </Form>
                    </div>

                    {/*  Options  */}
                    <div>
                        {employmentStatus === employmentStatusStates.EMPLOYED && (
                            <IncomeProof
                                customerObject={customerObject}
                                generalDeclarationFormVariables={{
                                    grossMonthlyIncome,
                                    handleContactDetailsData,
                                    changeNeedCardStatus,
                                    handleInputChange,
                                    selected,
                                    setSelected,
                                    mobilePhone,
                                    setMobilePhone,
                                    setGrossMonthlyIncome,
                                    disableButton,
                                    setDisableButton,
                                    classValue,
                                    otherPhone,
                                    setOtherPhone,
                                    handleDiasporaEmployerOrContractDocumentInputChange,
                                    handleDiasporaPaySlipInputChange
                                }}
                            />
                        )}

                        {employmentStatus === employmentStatusStates.SELF_EMPLOYED && (
                            <form className="was-validated">

                                <br/>
                                <label htmlFor="occupation">Select Occupation Type *</label>
                                <Select
                                    styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                                    onChange={handleOccupationChange}
                                    options={occupations}
                                    value={occupations.find(option => option.value === customerDetails.occupation)}
                                    name="occupation"
                                    isClearable
                                    required
                                />
                                <br/>

                                <label htmlFor="">Business type *</label>
                                <Select
                                    styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                                    onChange={handleIndustryChange}
                                    options={industryOptions}
                                    value={industryOptions.find(option => option.value === customerDetails.type_of_business)}
                                    name="type_of_business"
                                    isClearable
                                    required
                                />
                                <br/>

                                <label htmlFor="sector">Select Your Sector *</label>
                                <Select
                                    styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                                    onChange={handleSectorChange}
                                    options={sectors}
                                    value={sectors.find(option => option.value === customerDetails.sector)}
                                    name="sector"
                                    isClearable
                                    required
                                />
                                <br/>

                                <label htmlFor="source_of_income">Select Source of Income *</label>
                                <Select
                                    styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}
                                    onChange={handleSourceOfIncomeChange}
                                    options={sourceOfIncome}
                                    value={sourceOfIncome.find(option => option.value === customerDetails.source_of_income)}
                                    name="source_of_income"
                                    isClearable
                                    required
                                />
                                <br/>

                                {(() => {
                                    if (isDiasporaAccount) {
                                        return <div>
                                            <label htmlFor="">Purpose of opening account *</label>
                                            <select className="rounded-pill form-select w3-text-indigo form-floating"
                                                    name="purpose_of_account" onChange={handleContactDetailsData}
                                                    aria-label="Default select example" required>
                                                <option value=""></option>
                                                <option value="diasporaTransactionalAccount">Transactional Account
                                                </option>
                                                <option value="diasporaInvestmentAccount">Investment Account</option>
                                            </select>
                                        </div>
                                    } else if (isCurrentAccount) {
                                        return <></>
                                    } else {
                                        return <div>
                                            <label htmlFor="">Purpose of opening account *</label>
                                            <select className="rounded-pill form-select w3-text-indigo form-floating"
                                                    name="purpose_of_account" onChange={handleContactDetailsData}
                                                    aria-label="Default select example" required>
                                                <option value=""></option>
                                                <option value="savingsAccount">Savings Account</option>
                                                <option value="transactionalAccount">Transactional Account</option>
                                            </select>
                                        </div>
                                    }
                                })()}

                                {/*`<p><span className="fundsSource">Your source of funds</span></p>*/}
                                {/*{checkBoxes}`*/}
                                {showOtherInput && (

                                    <div>
                                        <label htmlFor="income_main_source">Other source of funds (MK)</label>
                                        <CurrencyInput
                                            style={styles.rounded}
                                            className="form-control"
                                            id="income_main_source"
                                            name="income_main_source"
                                            placeholder="Please enter an amount"
                                            min={1}
                                            decimalsLimit={2}
                                            allowNegativeValue={false}
                                            onValueChange={changeIncomeMainSource}
                                            required
                                        />
                                    </div>


                                )}
                                <div>
                                    <label htmlFor="one_off_income">One off income (MK)</label>
                                    <CurrencyInput
                                        style={styles.rounded}
                                        className="form-control"
                                        id="one_off_income"
                                        name="one_off_income"
                                        placeholder="Please enter an amount"
                                        min={1}
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        onValueChange={changeOneOffIncome}
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="gross_monthly_income">Monthly income* (MK)</label>
                                    <CurrencyInput
                                        style={styles.rounded}
                                        className="form-control"
                                        id="gross_monthly_income"
                                        name="gross_monthly_income"
                                        placeholder="Please enter an amount"
                                        min={1}
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        onValueChange={changeMonthlyIncome}
                                        required
                                    />
                                </div>

                                <div style={{marginBottom: '10px'}}>
                                    <label htmlFor="other_sources_income">Income from other sources (MK)</label>
                                    <CurrencyInput
                                        style={styles.rounded}
                                        className="form-control"
                                        id="other_sources_income"
                                        name="other_sources_income"
                                        placeholder="Please enter an amount"
                                        min={1}
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        onValueChange={changeOtherSourcesIncome}
                                        required
                                    />
                                </div>

                                {(() => {
                                    if (isDiasporaAccount) {
                                        return <CustomButton label="Next" disableButton={disableButton}
                                                             updateCurrentPage={updateCurrentPage}
                                                             next="ResidentialDetails"/>
                                    } else {
                                        return <CustomButton label="Next" disableButton={disableButton}
                                                             updateCurrentPage={updateCurrentPage} next="CustomerPic"/>
                                    }
                                })()}

                            </form>
                        )}

                    </div>
                </div>
            </>
        );

})
export default GeneralDeclarationForm;