import React, { useState } from 'react'
import { Image} from 'react-bootstrap';
const UnBundledServiceComponent=React.memo(({customerObject})=>{  
    let className = {sms_alert:"rounded-circle img-fluid logo",mobile_banking:"rounded-circle img-fluid logo",eazy_app:"rounded-circle img-fluid logo",internet_banking:"rounded-circle img-fluid logo",atm_card:"rounded-circle img-fluid logo"}
    let sms_alert = null;
    let mobile_banking = null;
    let eazy_app = null;
    let internet_banking = null;
    let atm_card = null;
    customerObject.customerDetails.sms_alert===1?sms_alert=<>SMS Alerts<br/></>:className.sms_alert="rounded-circle img-fluid logo d-none"
    customerObject.customerDetails.mobile_banking===1?mobile_banking=<>Eazy Mobile transactions<br/></>:className.mobile_banking="rounded-circle img-fluid logo d-none"
    customerObject.customerDetails.eazy_app===1?eazy_app=<>Eazy App transactions<br/></>:className.eazy_app="rounded-circle img-fluid logo d-none"
    customerObject.customerDetails.internet_banking===1?internet_banking=<>Internet Banking<br/></>:className.internet_banking="rounded-circle img-fluid logo d-none"
    customerObject.customerDetails.atm_card===1?atm_card=<>ATM Card<br/></>:className.atm_card="rounded-circle img-fluid logo d-none"
    return(
            <p className="w3-text-indigo" style={{textAlign:"left"}}>
                <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.sms_alert}/>&nbsp;&nbsp;{sms_alert}
                <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.mobile_banking}/>&nbsp;&nbsp;{mobile_banking}
                <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.eazy_app}/>&nbsp;&nbsp;{eazy_app}
                <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.internet_banking}/>&nbsp;&nbsp;{internet_banking}
                <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.atm_card}/>&nbsp;&nbsp;{atm_card}
            </p>    
    );
})
export default UnBundledServiceComponent;