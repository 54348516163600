let customerProfile={
    "SessionID": "",
    "existing_customer": 0,
    "occupation_of_customer": "",
    "malawian": 1,
    "id_type": "NATIONALID",
    "id_number": "",
    "id_issue_date": "",
    "id_expiry_date": "",
    "id_scanned_copy_url": "",
    "id_scanned_copy":"",
    "customer_select_type": "INDIVIDUAL",
    "sector": "",
    "industry": "",
    "product_group": "",
    "company_book": "",
    "account_product": "",
    "sms_alert": 0,
    "atm_card": 0,
    "eazy_app": 0,
    "atm_withdraw": 0,
    "atm_secret_question": "",
    "atm_secret_answer": "",
    "mobile_banking": 0,
    "internet_banking": 0,
    "email_statement": 0,
    "cheque_book": 0,
    "cust_title": "MR",
    "firstname": "",
    "surname": "",
    "other_name": "",
    "gender": "",
    "marital_status": "SINGLE",
    "spouse_name": "",
    "spouse_occupation": "",
    "spouse_phone": "",
    "spouse_postal": "",
    "date_of_birth": "",
    "customer_photo_url": "",
    "mobile_phone": "",
    "other_phone": "",
    "work_phone": "",
    "work_address": "",
    "email_address": "",
    "postal_address": "",
    "physical_address": "",
    "postal_town": "",
    "employment_status": "EMPLOYED",
    "occupation": "",
    "income_main_source": "",
    "type_of_business": "",
    "gross_monthly_income": "", //
    "net_worth": "", //networth
    "monthly_transactions": "", //money from risk rating form
    "regular_transactions": "",
    "purpose_of_account": "",
    "income_proof_url": "",
    "resident_country": "MALAWIAN",
    "residence_status": "",
    "residence_type": "",
    "residence_since": "",
    "residence_value": 0,
    "trad_auth": "Balaka Town",
    "village": "",
    "district": "BALAKA",
    "street_address": "",
    "district_address":"",
    "utility_bill_url": "",
    "residential_map_url": "",
    "kin_fullname": "",
    "kin_tel_number": "",
    "kin_relationship_type": "",
    "kin_email": "",
    "kin_address": "",
    "kin_identification": "",
    "kin_utility_bill":"",

    "kin_physical_address": "",
    "kin_postal_address": "",
    "kin_traditional_authority": "",
    "kin_district": "",
    "kin_village": "",

    "customer_has_ref": 0,
    "ref_name": "",
    "ref_phone_number": "",
    "ref_address":"",
    "ref_relationship_type": "",
    "ref_bank_name": "",
    "ref_id_no": "",
    "ref_bank_no": "",
    "signature_photo_url": "",
    "signature": "",
    "input_date": (new Date()).toDateString(),
    "inputter": 0,
    "inputter_name": "",
    "form_complete": 0,
    "total_risk_score": 0,
    "risk_rating": "",
    "view_status": 1,
    "channel":"self_onboarding",
    "one_off_income": "",
    "family_income": "",
    "other_sources_income": "",
    //student
    "student_id": null,
    "institution": null,
    "admission_year": null,
    "completion_year": null,

    // Diaspora
    "diaspora_national_id": "",
    "foreign_bank_statement":"",
    "payslip":"",
    "employment_letter": "",
    "currency": "",
    "nearest_service_center": "",
    "employer_name": "",
    "employer_address": "",

    "utility_bill_pic": "",
    "location": "",

    //Ideally should contain anything temporary (must NOT be submitted to the backend)
    temp: {}
}
export {customerProfile};