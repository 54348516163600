import React from 'react';
import {
    currentSilverUnbundled
} from '../Fees';
import {Box, FormControlLabel, Radio, Typography} from "@mui/material";
const CurrentPerTransactionFees = ({ setButtonLabel, selectedLabel }) => {
    const fees = currentSilverUnbundled; // Use the data object for dynamic rendering
    const keys = Object.keys(fees);

    return (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <FormControlLabel
                    control={
                        <Radio
                            value="CurrentSilver"
                            onChange={(e) => setButtonLabel(e.target.value)}
                            checked={selectedLabel === "CurrentSilver"}
                            sx={{ mr: 2 }}
                        />
                    }
                    label={
                        <Typography variant="h6" sx={{ color: '#2b357c', fontWeight: 'bold' }}>
                            Silver Current Unbundled
                        </Typography>
                    }
                />
            </Box>
            <Box sx={{ mt: 3 }}>
                {keys.map((key) => (
                    <Box
                        key={key}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: 2,
                            alignItems: 'center',
                            mb: 1,
                            borderBottom: '1px solid #e0e0e0',
                            padding: '8px 0',
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                color: '#2b357c',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                wordWrap: 'break-word',
                            }}
                        >
                            {key.replace(/_/g, ' ')}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: '#2b357c',
                                textAlign: 'right',
                                wordWrap: 'break-word',
                            }}
                        >
                            {fees[key]}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
export default React.memo(CurrentPerTransactionFees);