import React from 'react';
const CustomInput=(props)=>{
    const {label,valid,error,inputType, maxLength, placeholder,name,required,id,onInputChange,value,className,autocomplete,dataUrl} = props;
    let class_name;
    if(className===undefined)class_name="form-control rounded-pill"
    else class_name=className;
    let field =(
        <div className="form-floating mb-3 w3-round-xxlarge">
            <input type={inputType} value={value} className={class_name} maxLength={maxLength} id={id} onInput={onInputChange} placeholder={placeholder} name={name} required/>
            <label htmlFor={id} className="form-label">{label}</label>
            <div className="valid-feedback">{valid}</div>
            <div className="invalid-feedback">{error}</div>
        </div>
    );
    if (required==="false")
        field = (
            <div className="form-floating mb-3">
                <input type={inputType} value={value} maxLength={maxLength} className={class_name} onInput={onInputChange} id={id} placeholder={placeholder} name={name} />
                <label htmlFor={id} className="form-label">{label}</label>
            </div>
        );
    if (autocomplete!==undefined)
        field = (
            <div className="form-floating mb-3">
                <input type={inputType} value={value} className={class_name} dataUrl={dataUrl} maxLength={maxLength} autocomplete={autocomplete} onInput={onInputChange} id={id} placeholder={placeholder} name={name} />
                <label htmlFor={id} className="form-label">{label}</label>
                <div className="valid-feedback">{valid}</div>
                <div className="invalid-feedback">{error}</div>
            </div>
        );
    return field;
}
export default React.memo(CustomInput);
