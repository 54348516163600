import Select from "react-select";
import {styled} from "@mui/material/styles";
import {FormControlLabel, RadioGroup, Tabs, Tab, Typography} from "@mui/material";

export const CustomStyle = {
    pageWrapper: {
        backgroundColor: '#f0f0f0', // Full-page background color
        minHeight: '100vh', // Full viewport height
        display: 'flex',
        alignItems: 'center', // Center vertically
        justifyContent: 'center', // Center horizontally
        padding: '20px', // Ensure padding around the edges'
        paddingTop: '80px',
    },
    contentContainer: {
        textAlign: 'center',
        padding: '40px',
        backgroundColor: '#ffffff', // White background for the content area
        borderRadius: '10px', // Rounded corners for better visuals
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // Subtle shadow for depth
        maxWidth: '800px', // Limit the width for better readability
        width: '100%',
        marginTop: '20px',
        '@media (max-width: 600px)': {
            marginTop: '10px',
            alignSelf: 'flex-start'
        }
    },
    heading: {
        color: '#2b357c',
        marginBottom: '20px',
        fontWeight: 'bold',
        fontSize: '1.5rem', // Larger font size for the heading
        lineHeight: '1.6',
        // textAlign: 'center',
    },
    subtitle: {
        color: '#2b357c',
        marginBottom: '30px',
        fontSize: '1.25rem', // Slightly larger font size for the subtitle
        lineHeight: '1.5', // Improved line spacing
        // textAlign: 'center',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column', // Stack buttons vertically
        alignItems: 'center', // Center align buttons horizontally
        gap: '10px', // Spacing between buttons
        marginTop: '20px', // Add some margin on top
        padding: '0 10px', // Add some horizontal padding
        width: '100%', // Full width to keep buttons within bounds
    },
    button: {
        backgroundColor: '#ed1b2e',
        color: '#ffffff',
        borderRadius: '25px', // Rounded corners for buttons
        padding: '10px 20px', // Increased padding for better touch targets
        fontSize: '1rem', // Adjusted text size
        textTransform: 'none', // Regular text (not uppercase)
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', // Subtle shadow for depth
        transition: 'background-color 0.3s, transform 0.3s', // Smooth transition effects
        '&:hover': {
            backgroundColor: '#d31a22',
            transform: 'scale(1.05)', // Slight zoom effect on hover
        },
        '&:active': {
            backgroundColor: '#c8101d', // Even darker red on active
        },
    },
    buttonHover: {
        backgroundColor: '#d31a22',
        transform: 'scale(1.05)', // Slight zoom effect on hover
    },
    centerItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentText: {
        color: '#2b357c',
        fontSize: '1.2rem', // Adjust font size for text
        margin: '0 0 20px', // Margin between paragraphs
        textAlign: 'left', // Align text to the left
    },
    currencyInput: {
        border: '2px solid #2b357c', // NBS Bank blue border
        borderRadius: '10px', // Rounded corners
        padding: '10px', // Padding inside the input field
        fontSize: '1rem', // Consistent font size with other inputs
        width: '100%', // Full width of the container
        boxSizing: 'border-box', // Ensures padding doesn't affect the overall width
        outline: 'none', // Remove default focus outline
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', // Subtle shadow for depth
        transition: 'border-color 0.3s, box-shadow 0.3s', // Smooth transition effects
        '&:focus': {
            borderColor: '#ed1b2e', // NBS Bank red on focus
            boxShadow: '0 0 5px rgba(237, 27, 46, 0.5)', // Red shadow on focus
        },
        height: '60px'
    },
    alert: {
        marginTop: '20px',
        marginBottom: '10px'
    },
    formControlLabel: {
        marginBottom: '16px', // Adjust spacing as needed
        alignItems: 'center', // Align radio buttons vertically with their labels
        justifyContent: 'space-between', // Space out label and radio buttons
        display: 'flex', // Ensure label and radio buttons are in a row
    },

};

export const CustomTabs = styled(Tabs)({
    borderBottom: '1px solid #dcdcdc',
    '& .MuiTabs-indicator': {
        backgroundColor: '#2b357c', // NBS blue
    },
});

export const CustomTab = styled(Tab)(({ selected }) => ({
    textTransform: 'uppercase', // Make text uppercase
    fontWeight: selected ? 'bold' : 'normal',
    color: '#2b357c', // Default blue for both states
    fontSize: '1rem', // Make the font size larger
    backgroundColor: selected ? '#eaf0ff' : 'transparent', // Light blue background for selected tab
    borderRadius: '4px',
    marginRight: '4px',
    '&:last-child': {
        marginRight: '0',
    },
    '&:hover': {
        backgroundColor: '#f5f5f5', // Light gray background on hover
    },
}));
export const customSelectStyles = {
    container: (provided) => ({
        ...provided,
        marginBottom: '16px',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '2px solid #2b357c', // NBS Bank blue
        borderRadius: '5px',
        padding: '10px 12px',
        fontSize: '16px',
        outline: 'none',
        boxShadow: state.isFocused ? '0 0 0 1px #ed1b2e' : 'none', // NBS Bank red on focus
        '&:hover': {
            borderColor: '#ed1b2e', // NBS Bank red on hover
        },
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '5px',
        border: '2px solid #2b357c',
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ed1b2e' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : provided.color,
        '&:hover': {
            backgroundColor: '#ed1b2e',
            color: '#fff',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#2b357c',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#2b357c',
    }),
};


export const FieldLabel = (props) => (
    <Typography variant="body1" sx={{ marginBottom: '12px', color: '#2b357c', fontSize: '1.5rem' }}>
        {props.children}
    </Typography>
);

export const StyledRadioGroup = styled(RadioGroup)({
    flexDirection: 'row',
    justifyContent: 'space-between',
});

export const CustomRadioButtonStyled = styled(FormControlLabel)(({ theme }) => ({
    '.MuiTypography-root': {
        color: theme.palette.text.primary,
    },
}));
