import React, {Component} from 'react';
import CustomButton from "./custom-componets/Button";
import {Button} from 'react-bootstrap';
import {resolveAndGetImageFileBase64, uploadFileWithNameObject} from "../helpers/Diaspora";
import {CircularProgress} from "@mui/material";
import CustomNBSRedButton from "./custom-componets/CustomNBSRedButton";
import {CustomStyle} from "../helpers/styles";

export class CameraFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageCaptured: false,
            stream: null,
            canvas: null,
            capturedPhotoFile: null,
            buttonLoading: false
        };
    }

    async componentDidMount() {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            this.processDevices(devices);
        } catch (err) {
            console.error(err);
        }
    }

    processDevices(devices) {
        devices.every(device => {
            if ((/front/ig.test(device.label) && this.props.facingMode === "user") ||
                /Integrated Camera/ig.test(device.label)) {
                this.setDevice();
                return false;
            } else if (/back/ig.test(device.label) && this.props.facingMode === "environment") {
                this.setDevice();
                return false;
            } else if (/videoinput/ig.test(device.kind) && (this.props.facingMode === "environment" || this.props.facingMode === "user")) {
                this.setDevice();
                return false;
            }
            return true;
        });
    }

    async setDevice() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {facingMode: this.props.facingMode}
            });
            this.setState({stream});
            this.videoPlayer.srcObject = stream;
            this.videoPlayer.play();
        } catch (err) {
            console.error(err);
            alert('Could not start video source. Please check if your camera is in use or if permissions are granted.');
        }
    }

    takePhoto = () => {
        // Get the dimensions of the video feed
        const videoWidth = this.videoPlayer.videoWidth;
        const videoHeight = this.videoPlayer.videoHeight;

        // Set canvas dimensions to match video feed resolution
        this.canvas.width = videoWidth;
        this.canvas.height = videoHeight;

        // Draw the image onto the canvas with the correct aspect ratio
        const context = this.canvas.getContext('2d');
        context.drawImage(this.videoPlayer, 0, 0, videoWidth, videoHeight);

        // Stop the video stream
        this.state.stream.getTracks().forEach(track => track.stop());
        this.canvas.toBlob(blob => {
            if (blob) {
                this.setState({
                    capturedPhotoFile: new File([blob], 'photo.jpg', {type: 'image/jpeg'}),
                    canvas: this.canvas.toDataURL(),
                    imageCaptured: true
                });
            }
        }, 'image/jpeg');
    }

    redo = () => {
        this.setDevice();
        this.setState({
            imageCaptured: false,
            capturedPhotoFile: null
        });
    }

    handleClick = async () => {
        if (this.state.capturedPhotoFile && this.state.imageCaptured) {
            this.setState({buttonLoading: true});

            const fileSrc = document.getElementById('imgPreview').src;

            const fileList = [this.state.capturedPhotoFile];
            const fileNames = {[this.state.capturedPhotoFile.name]: `${this.props.name}.jpg`};

            const dataObject = {
                national_id_details: {
                    id_number: this.props.customerObject.customerDetails.id_number,
                    surname: this.props.customerObject.customerDetails.surname
                },
                file_names: fileNames
            };

            try {
                const data = await uploadFileWithNameObject(fileList, dataObject);
                const key = data.name;
                const value = data.url;

                this.props.customerObject.setCustomerDetails(prev => ({
                    ...prev,
                    [key]: value,
                    temp: {...prev.temp, [key]: fileSrc}
                }));

                this.props.customerObject.updateCurrentPage(this.props.next);
            } finally {
                this.setState({buttonLoading: false});
            }
        }
    };

    render() {
        let element = (
            <div>
                <div style={{marginBottom: '20px'}}>

                    <video className="d-flex m-auto" style={{border: "4px solid red", marginBottom: '10px'}}
                           ref={ref => (this.videoPlayer = ref)}
                           width="100%" height="100%"/>
                </div>
                <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={this.takePhoto}
                                        disabled={false}>
                        {this.props.label}
                    </CustomNBSRedButton>
                </div>
                <canvas height={480} width={640} ref={ref => (this.canvas = ref)} style={{display: 'none'}}/>
            </div>
        );

        if (this.state.imageCaptured) {
            element = (
                <div>
                    <img width="100%" height="100%" style={{border: "4px solid red"}} alt={this.props.name}
                         src={this.state.canvas} className="img-responsive" id="imgPreview"/>

                    <div style={CustomStyle.buttonContainer}>
                        <CustomNBSRedButton onClick={this.handleClick}
                                            disabled={this.state.buttonLoading}>
                            {this.state.buttonLoading ? <CircularProgress/> : 'Next'}
                        </CustomNBSRedButton>
                        {/*<CustomNBSRedButton onClick={() => updateCurrentPage('Signature')}*/}

                        <CustomNBSRedButton onClick={this.redo}
                                            disabled={false}>
                            Redo
                        </CustomNBSRedButton>
                    </div>
                </div>
            );
        }

        return <>{element}</>;
    }
}