import React, {useEffect, useState} from 'react';
import {PDFDocumentViewer} from "../PDFDocumentViewer";
import {documentCodes, documents} from "../../helpers/Documents";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Typography
} from "@mui/material";
import {customSelectStyles, CustomStyle} from "../../helpers/styles";
import Select from "react-select";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

const KeyFacts = ({updateCurrentPage, existingDetails, setExistingDetails}) => {
    console.log(existingDetails)

    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [factsAccepted, setFactsAccepted] = useState(false);
    const [showKeyFactsModel, setShowKeyFactsModel] = useState(false);
    const [conditionsConfirmed, setConditionsConfirmed] = useState(false);
    const [keyFactsConfirmed, setKeyFactsConfirmed] = useState(false);
    const [informationConfirmed, setInformationConfirmed] = useState(false);
    const [serviceCenterSelected, setServiceCenterSelected] = useState(false);
    const [disableButton, setDisableButton] = useState(true)
    const accountProduct = existingDetails.account_category.toString();

    console.log("product", accountProduct)
    const handleAcceptTermsAndConditions = () => {
        setTermsAccepted(true);
        setShowTermsAndConditions(false);
    };

    const handleAcceptKeyFacts = () => {
        setFactsAccepted(true);
        setShowKeyFactsModel(false);
    };

    const handleCheckboxChange = (setter) => (e) => {
        setter(e.target.checked);
    };

    const handleSelectChange = (selectedOption) => {
        setServiceCenterSelected(selectedOption?.value || "");

        setExistingDetails((prev) => ({...prev, service_center: selectedOption?.value || ""}))
    };

    useEffect(() => {
        const allAccepted = termsAccepted && factsAccepted && conditionsConfirmed && keyFactsConfirmed && informationConfirmed && serviceCenterSelected;
        setDisableButton(!allAccepted);
    }, [termsAccepted, factsAccepted, conditionsConfirmed, keyFactsConfirmed, informationConfirmed, serviceCenterSelected]);

    const getKeyFactStatement = () => {
        switch (accountProduct) {
            case "6005":
            case "6040":
                return documents()[documentCodes().student_yaaza];
            case "6006":
                return documents()[documentCodes().pafupi_and_pafupi_plus];
            case "6001":
            case "6045":
                return documents()[documentCodes().eazy_transact];
            case "6003":
            case "6044":
                return documents()[documentCodes().eazy_save];
            case "1001":
            case "1030":
                return documents()[documentCodes().current_silver];
            case "6212":
                return documents()[documentCodes().diaspora_investment];
            case "6023":
                return documents()[documentCodes().diaspora_transactional_savings];
            default:
                return "";
        }
    };


    const serviceCenterOptions = [
        {value: '', label: ''},
        {value: '002', label: 'Balaka'},
        {value: '003', label: 'Blantyre'},
        {value: '004', label: 'Capital City'},
        {value: '008', label: 'Dedza'},
        {value: '009', label: 'Dwangwa'},
        {value: '010', label: 'Ginnery Corner'},
        {value: '012', label: 'Kanengo'},
        {value: '013', label: 'Karonga'},
        {value: '014', label: 'Kasungu'},
        {value: '016', label: 'Lilongwe'},
        {value: '017', label: 'Limbe'},
        {value: '018', label: 'Liwonde'},
        {value: '019', label: 'Mangochi'},
        {value: '020', label: 'Mchinji'},
        {value: '021', label: 'Mponela'},
        {value: '022', label: 'Mulanje'},
        {value: '023', label: 'Mzimba'},
        {value: '024', label: 'Mzuzu'},
        {value: '025', label: 'Nchalo'},
        {value: '027', label: 'Nkhatabay'},
        {value: '028', label: 'Nkhotakota'},
        {value: '029', label: 'Ntcheu'},
        {value: '032', label: 'Rumphi'},
        {value: '033', label: 'Salima'},
        {value: '034', label: 'Zomba'}
    ]

    const handleNextPage = (e) => {
        const images = existingDetails.images || [];

        console.log("images1 " + existingDetails.images);

        setExistingDetails((prev) => ({
            ...prev,
            allowed_accounts: undefined,
            short_name: undefined,
            cell_phone: undefined,
            all_accounts: undefined,
            requirements: undefined,
            scannedData: undefined,
            status: undefined,
            msg: undefined,
            existing_category: undefined,
            images: images,
        }));
        //console.log("Unique images" + JSON.stringify(uniqueImages))
        updateCurrentPage("FinishPage");
    }

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Terms and Conditions</Typography>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    * Read the key fact sheet statement, terms and conditions to proceed to the next stage
                </Typography>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    <label>
                        <a onClick={() => setShowTermsAndConditions(true)} style={{color: "#0f9d58"}}>
                            Click here
                        </a>
                    </label> to read terms and conditions
                </Typography>
                {!termsAccepted && <Typography variant="body2" sx={{...CustomStyle.subtitle, color: '#bf221d'}}>
                    * Please accept the Terms and Conditions
                </Typography>}
                <div style={CustomStyle.subtitle}>
                    <label style={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            checked={conditionsConfirmed}
                            onChange={handleCheckboxChange(setConditionsConfirmed)}
                        />
                        <span
                            style={{marginLeft: '8px'}}>I have read and agreed to The NBS Bank terms and conditions</span>
                    </label>
                </div>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    <label>
                        <a onClick={() => setShowKeyFactsModel(true)} style={{color: "#0f9d58"}}>
                            Click here
                        </a>
                        <span> to access the key fact statements</span>
                    </label>
                </Typography>
                {!factsAccepted && <Typography variant="body2" sx={{...CustomStyle.subtitle, color: '#bf221d'}}>
                    * Please accept the Key Fact Statements
                </Typography>}
                <Dialog open={showTermsAndConditions} onClose={() => setShowTermsAndConditions(false)}>
                    <DialogTitle>Terms and Conditions</DialogTitle>

                    <DialogContent>
                        <PDFDocumentViewer documentType={documents()[documentCodes().terms_and_conditions]}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" style={{width: '100%'}}
                                onClick={handleAcceptTermsAndConditions}>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={showKeyFactsModel} onClose={() => setShowKeyFactsModel(false)}>
                    <DialogTitle>Key Fact Statement</DialogTitle>
                    <DialogContent>
                        <PDFDocumentViewer documentType={getKeyFactStatement()}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" style={{width: '100%'}}
                                onClick={handleAcceptKeyFacts}>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
                <Typography variant="body1" sx={CustomStyle.subtitle}>
                    <label style={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            checked={keyFactsConfirmed}
                            onChange={handleCheckboxChange(setKeyFactsConfirmed)}
                        />
                        <span style={{marginLeft: '8px'}}>I understand the key facts and benefits of this account product.</span>
                    </label>

                </Typography>
                <Typography variant="body1" sx={CustomStyle.subtitle}>

                    <label style={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            checked={informationConfirmed}
                            onChange={handleCheckboxChange(setInformationConfirmed)}
                        />
                        <span style={{marginLeft: '8px'}}>I confirm that the information provided is correct.</span>
                    </label>
                </Typography>
                <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                    <Typography variant="h6" sx={{
                        ...CustomStyle.subtitle,
                        textAlign: 'left',
                        fontSize: '15px',
                        marginBottom: '10px'
                    }}>
                        Service Centre *
                    </Typography>
                    <Select
                        options={serviceCenterOptions}
                        onChange={handleSelectChange}
                        styles={customSelectStyles}
                        placeholder="Select Service Centre"
                        isClearable
                        required
                    />
                </FormControl>
                <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={handleNextPage}
                                        disabled={disableButton}>
                        Register Account
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>
    );

}

export default React.memo(KeyFacts);