import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    FormControl,
    Checkbox
} from '@mui/material';

import {PDFDocumentViewer} from "./PDFDocumentViewer";
import {documentCodes, documents} from "../helpers/Documents";
import {customSelectStyles, CustomStyle} from "../helpers/styles";
import Select from "react-select";

const ConditionSection = ({customerObject}) => {
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [factsAccepted, setFactsAccepted] = useState(false);
    const [showKeyFactsModel, setShowKeyFactsModel] = useState(false);
    const [conditionsConfirmed, setConditionsConfirmed] = useState(false);
    const [keyFactsConfirmed, setKeyFactsConfirmed] = useState(false);
    const [informationConfirmed, setInformationConfirmed] = useState(false);
    const [serviceCenterSelected, setServiceCenterSelected] = useState(false);

    const handleAcceptTermsAndConditions = () => {
        setTermsAccepted(true);
        setShowTermsAndConditions(false);
    };

    const handleAcceptKeyFacts = () => {
        setFactsAccepted(true);
        setShowKeyFactsModel(false);
    };

    const handleCheckboxChange = (setter) => (e) => {
        setter(e.target.checked);
    };

    const handleSelectChange = (selectedOption) => {
        setServiceCenterSelected(selectedOption?.value || "");
        customerObject.customerDetails.company_book = selectedOption?.value || "";
    };

    useEffect(() => {
        const allAccepted = termsAccepted && factsAccepted && conditionsConfirmed && keyFactsConfirmed && informationConfirmed && serviceCenterSelected;
        customerObject.setDisableButton(!allAccepted);
    }, [termsAccepted, factsAccepted, conditionsConfirmed, keyFactsConfirmed, informationConfirmed, serviceCenterSelected]);

    const getKeyFactStatement = () => {
        const product = customerObject.customerDetails.account_product;
        switch (product) {
            case "student":
                return documents()[documentCodes().student_yaaza];
            case "PafupiUnbundled":
                return documents()[documentCodes().pafupi_and_pafupi_plus];
            case "EazyTransactUnbundled":
            case "EazyTransactBundled":
                return documents()[documentCodes().eazy_transact];
            case "EazySaveUnbundled":
            case "EazySaveBundled":
                return documents()[documentCodes().eazy_save];
            case "CurrentSilverBundled":
            case "CurrentSilverUnbundled":
                return documents()[documentCodes().current_silver];
            case "DiasporaTrans":
                return documents()[documentCodes().diaspora_transactional_savings];
            case "DiasporaInvest":
                return documents()[documentCodes().diaspora_investment];
            default:
                return "";
        }
    };

    const serviceCenterOptions = [
        {value: 'BALAKA', label: 'Balaka'},
        {value: 'BLANTYRE', label: 'Blantyre'},
        {value: 'CAPITAL CITY', label: 'Capital City'},
        {value: 'DEDZA', label: 'Dedza'},
        {value: 'DWANGWA', label: 'Dwangwa'},
        {value: 'GINCO', label: 'Ginnery Corner'},
        {value: 'KANENGO', label: 'Kanengo'},
        {value: 'KARONGA', label: 'Karonga'},
        {value: 'KASUNGU', label: 'Kasungu'},
        {value: 'LILONGWE', label: 'Lilongwe'},
        {value: 'LIMBE', label: 'Limbe'},
        {value: 'LIWONDE', label: 'Liwonde'},
        {value: 'MANGOCHI', label: 'Mangochi'},
        {value: 'MCHINJI', label: 'Limbe'},
        {value: 'MPONELA', label: 'Mponela'},
        {value: 'MULANJE', label: 'Mulanje'},
        {value: 'MZIMBA', label: 'Mzimba'},
        {value: 'MZUZU', label: 'Mzuzu'},
        {value: 'NCHALO', label: 'Nchalo'},
        {value: 'NKHATABAY', label: 'Nkhatabay'},
        {value: 'NKHOTAKOTA', label: 'Nkhotokota'},
        {value: 'NTCHEU', label: 'Ntcheu'},
        {value: 'RUMPHI', label: 'Rumphi'},
        {value: 'SALIMA', label: 'Salima'},
        {value: 'ZOMBA', label: 'Zomba'},

    ];

    return (
        <>

            <Typography variant="h6" sx={CustomStyle.subtitle}>
                * Read the key fact sheet statement, terms and conditions to proceed to the next stage
            </Typography>
            <Typography variant="h6" sx={CustomStyle.subtitle}>
                <label>
                    <a onClick={() => setShowTermsAndConditions(true)} style={{color: "#0f9d58"}}>
                        Click here
                    </a>
                </label> to read terms and conditions
            </Typography>
            {!termsAccepted && <Typography variant="body2" sx={{...CustomStyle.subtitle, color: '#bf221d'}}>
                * Please accept the Terms and Conditions
            </Typography>}
            <Typography variant="body1" sx={CustomStyle.subtitle}>

                <label style={{display: 'flex', alignItems: 'center'}}>
                    <Checkbox
                        checked={conditionsConfirmed}
                        onChange={handleCheckboxChange(setConditionsConfirmed)}
                    />
                    <span style={{marginLeft: '8px'}}>I have read and agreed to The NBS Bank terms and conditions</span>
                </label>
            </Typography>
            <Typography variant="h6" sx={CustomStyle.subtitle}>
                <label>
                    <a onClick={() => setShowKeyFactsModel(true)} style={{color: "#0f9d58"}}>
                        Click here
                    </a>
                    <span> to access the key fact statements</span>
                </label>
            </Typography>
            {!factsAccepted && <Typography variant="body2" sx={{...CustomStyle.subtitle, color: '#bf221d'}}>
                * Please accept the Key Fact Statements
            </Typography>}
            <Dialog open={showTermsAndConditions} onClose={() => setShowTermsAndConditions(false)}>
                <DialogTitle>Terms and Conditions</DialogTitle>

                <DialogContent>
                    <PDFDocumentViewer documentType={documents()[documentCodes().terms_and_conditions]}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" style={{width: '100%'}}
                            onClick={handleAcceptTermsAndConditions}>
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showKeyFactsModel} onClose={() => setShowKeyFactsModel(false)}>
                <DialogTitle>Key Fact Statement</DialogTitle>
                <DialogContent>
                    <PDFDocumentViewer documentType={getKeyFactStatement()}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" style={{width: '100%'}} onClick={handleAcceptKeyFacts}>
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography variant="body1" sx={CustomStyle.subtitle}>
                <label style={{display: 'flex', alignItems: 'center'}}>
                    <Checkbox
                        checked={keyFactsConfirmed}
                        onChange={handleCheckboxChange(setKeyFactsConfirmed)}
                    />
                    <span style={{marginLeft: '8px'}}>I understand the key facts and benefits of this account product.</span>
                </label>

            </Typography>
            <Typography variant="body1" sx={CustomStyle.subtitle}>

                <label style={{display: 'flex', alignItems: 'center'}}>
                    <Checkbox
                        checked={informationConfirmed}
                        onChange={handleCheckboxChange(setInformationConfirmed)}
                    />
                    <span style={{marginLeft: '8px'}}>I confirm that the information provided is correct.</span>
                </label>
            </Typography>
            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                <Typography variant="h6" sx={{
                    ...CustomStyle.subtitle,
                    textAlign: 'left',
                    fontSize: '15px',
                    marginBottom: '10px'
                }}>
                    Service Centre *
                </Typography>
                <Select
                    options={serviceCenterOptions}
                    onChange={handleSelectChange}
                    styles={customSelectStyles}
                    placeholder="Select Service Centre"
                    isClearable
                    required
                />
            </FormControl>
        </>
    );


}

export default React.memo(ConditionSection);