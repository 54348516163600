import React, {useState} from "react";
import axios from "axios";
import {Alert, CircularProgress, Typography} from "@mui/material";
import {CustomNBSTextField} from "../custom-componets/CustomNBSTextField";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import {CustomStyle} from "../../helpers/styles";

const getEligibleAccounts = async (accountNumber) => {
    const eligibleBody = {
        account_number: accountNumber,
        type: "eligible",
    };

    const config = {
        method: "post",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const eligibleUrl = "/second-account-eligibility";
    try {
        const result = await axios.post(eligibleUrl, eligibleBody, config);

        if (result.status === 200 && result.data.status) {
            const codes = result.data.allowed_accounts;
            return {success: true, codes};
        } else {
            const errorMessage = result.data.msg;
            // const errorMessage =
            //     "Dear Customer, you are not eligible for Secondary Accounts";
            return {success: false, message: errorMessage};
        }
    } catch (error) {
        console.error(error);
        return {success: false, message: "Error occurred during eligibility check."};
    }
};

export const AccountNumber = ({
                                  updateCurrentPage,
                                  existingDetails,
                                  setExistingDetails,
                              }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [showHomeButton, setShowHomeButton] = useState(false);
    const [formData, setFormData] = useState({
        accountNumber: "",
        shortName: "",
        cellPhone: "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        // Reset fields and message if account number is changed
        if (name === "accountNumber") {
            setMessage(null);
            setShowHomeButton(false);
        }
    };

    const handleSearch = async () => {
        const accountNumber = formData.accountNumber.trim();

        if (accountNumber === "") {
            setMessage("Please provide an account number.");
            return;
        }
        if (!/^\d+$/.test(accountNumber)) {
            setMessage("Account number must contain only numbers.");
            return;
        }
        if (accountNumber.length < 8) {
            setMessage("Account number must be at least 8 digits long.");
            return;
        }

        setLoading(true);

        const accountBody = {
            account_number: formData.accountNumber,
        };

        const url = "/second-account";
        const config = {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
        };
        try {
            const result = await axios.post(url, accountBody, config);

            if (result.status === 200 && result.data.status) {
                setFormData({
                    ...formData,
                    cellPhone: result.data.mobileno,
                    shortName: result.data.shortName,
                });

                // Call eligible accounts before updating page
                const response = await getEligibleAccounts(formData.accountNumber);

                if (response.success) {
                    setExistingDetails({
                        ...existingDetails,
                        cell_phone: result.data.mobileNo,
                        short_name: result.data.shortName,
                        allowed_accounts: result.data.allowed_accounts,
                        existing_category: result.data.accountCategory,
                        account_number: formData.accountNumber,
                        all_accounts: response.codes
                    });

                    // Only navigate when all details are set
                    updateCurrentPage("AccountCheck");
                } else {
                    // Handle non-positive eligible response
                    setMessage(response.message);
                    setShowHomeButton(true);
                }
            } else {
                setMessage(
                    "Could not verify account, Please provide a valid account NBS number."
                );
            }
        } catch (error) {
            console.error(error);
            setMessage("Something went wrong. Try again later.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Please provide your Existing NBS Bank Account Number below:</Typography>

                <CustomNBSTextField
                    label="Account Number *"
                    onInputChange={handleChange}
                    value={formData.accountNumber || ""}
                    inputType="text"
                    name="accountNumber"
                    required
                />

                {message && <Alert severity="error">{message}</Alert>}

                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton onClick={handleSearch} disabled={loading}>
                        {loading ? <CircularProgress color="inherit" size={24}/> : "Search"}
                    </CustomNBSRedButton>

                    {showHomeButton && (
                        <CustomNBSRedButton
                            onClick={() => updateCurrentPage("HomePage")}
                        >
                            Home
                        </CustomNBSRedButton>
                    )}
                </div>
            </div>
        </div>
    );
};
