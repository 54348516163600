import React, {memo, useState, useEffect} from 'react';
import {Form, Row, Col, Container, Card, Button, ListGroup, Spinner} from 'react-bootstrap';
import country from "country-list-js";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import CustomButton from '../custom-componets/Button.js';
import {RadioGroup, RadioButton} from 'react-radio-buttons';
import CurrencyInput from 'react-currency-input-field';
import {getBase64, resolveAndGetImageFileBase64} from '../../helpers/Diaspora.js';
import {occupations, industryOptions, sourceOfIncome, sectors} from '../data';
import {getAllISOCodes} from "iso-country-currency";
import {convertBase64ToImageOrPDFFile, createFileUploadObject} from "../../helpers/Utils";
import Select from "react-select";
import {customSelectStyles, CustomStyle, CustomTab, CustomTabs} from "../../helpers/styles";
import {
    Alert,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, MenuItem,
    Radio,
    Typography
} from "@mui/material";
import {CustomNBSTextField} from "../custom-componets/CustomNBSTextField";
import CustomPhoneInput from "../custom-componets/CustomPhoneInput";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";
import CustomNBSBlueButton from "../custom-componets/CustomNBSBlueButton";

const currencies = ['GBP', 'USD', 'EUR', 'ZAR'];

export const IncomeProof = memo(({updateCurrentPage, customerDetails, setCustomerDetails}) => {
    const employmentStatusStates = {
        EMPLOYED: "Employed",
        SELF_EMPLOYED: "Self-Employed"
    };

    const docMessage = {
        missing: "Payslip or Employment Letter is Missing. Please provide one.",
        employmentDocNotification: "If Pay slip not available. This must state your monthly income"
    };


    const [phone, setPhone] = useState();
    const [employmentStatus, setEmploymentStatus] = useState(employmentStatusStates.EMPLOYED);
    const [monthlyIncome, setMonthlyIncome] = useState();
    const [sourceOfFundsFromList, setSourceOfFundsFromList] = useState();
    const [showOtherSourceOfFunds, setShowOtherSourceOfFunds] = useState(false);

    const [sourceOfFundsSelected, setSourceOfFundsSelected] = useState(new Set());
    const [displayEmptySourceOfFundsError, setDisplayEmptySourceOfFundsError] = useState(false);

    const allIsoCodes = getAllISOCodes().unique();
    const defaultCountryIso2Code = "MW";
    // const defaultCountryIso2Code = country.findByName(customerDetails.resident_country).code.iso2;
    const [currency, setCurrency] = useState(currencies[0]);
    const [requiredDocumentMissing, setRequiredDocumentMissing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [selectedPayslip, setSelectedPayslip] = useState('No file chosen');
    const [selectedEmploymentLetter, setSelectedEmploymentLetter] = useState('No file chosen');

    const [isoCodes, setIsoCodes] = useState(
        allIsoCodes.splice(
            0,
            0,
            allIsoCodes.splice(allIsoCodes.indexOf(allIsoCodes.find((c) => c.iso === defaultCountryIso2Code)), 1)[0]
        )
    )

    const styles = {
        rounded: {
            borderRadius: '100px'
        }
    }

    const currencyOptions = [
        {value: 'GBP', label: 'GBP'},
        {value: 'USD', label: 'USD'},
        {value: 'EUR', label: 'EUR'},
        {value: 'ZAR', label: 'ZAR'}
    ];

    useEffect(() => {
        setCustomerDetails((prev) => {
            return {...prev, currency: currency}
        })
    }, [currency]);

    useEffect(() => {
        setCustomerDetails((prev) => {
            return {...prev, employment_status: employmentStatus}
        })

        if (employmentStatus === employmentStatusStates.EMPLOYED) {
            //On initial load, remove reference to documents (Payslip & Employment Letter/Contract) - We want customer to explicitly provide
            setCustomerDetails((prev) => {
                return {...prev, employment_letter: "", payslip: ""}
            })
        }

    }, [employmentStatus]);

    function clearSelfEmployedCustomerData() {
        setCustomerDetails((prev) => {
            return {
                ...prev,
                income_main_source: "",
                foreign_bank_statement: ""
            }
        })
    }

    function clearEmployedCustomerData() {
        setCustomerDetails((prev) => {
            return {
                ...prev,
                employer_name: "",
                work_address: "",
                work_phone: "",
                payslip: "",
                employment_letter: ""
            }
        })
    }


    const changeEmploymentStatus = (e) => {
        setEmploymentStatus(e);
        if (employmentStatus === employmentStatusStates.EMPLOYED) {
            //remove income declaration details related to self-employed
            clearSelfEmployedCustomerData();
        } else if (employmentStatus === employmentStatusStates.SELF_EMPLOYED) {
            //remove income declaration details related to employed
            clearEmployedCustomerData();

        }
        setCustomerDetails((prev) => {
            return {...prev, employment_status: e}
        })
    }

    const addSourceOfFunds = (e) => {
        setSourceOfFundsSelected((prev) => {
            if (e.target.checked) {
                prev.add(e.target.id);
            } else {
                prev.delete(e.target.id);
            }
            return prev;
        })

        if (e.target.id === "other") {
            setShowOtherSourceOfFunds(true);
            setCustomerDetails((prev) => {
                return ({...prev, ...{[e.target.id]: Number(!e.target.checked)}})
            });
        } else {
            setCustomerDetails((prev) => {
                return ({...prev, ...{[e.target.id]: Number(e.target.checked)}})
            });
        }

    }

    const changeSourceOfFunds = (e) => {
        setCustomerDetails((prev) => {
            return {...prev, income_main_source: e.target.value}
        })
    }

    async function changeBankStatement(e) {
        const bankStatement = e.target.files[0];
        // const base64 = await getBase64(bankStatement)
        //     .then(r => r)
        //     .catch(e => { console.error(e); return "" });

        let base64 = "";
        if (bankStatement) {
            setLoading(true);
            base64 = await resolveAndGetImageFileBase64(bankStatement);
            setLoading(false);
        }

        setCustomerDetails((prev) => {
            return {...prev, foreign_bank_statement: base64}
        })
    }

    const changeOccupation = (e) => {
        setCustomerDetails((prev) => {
            return {...prev, occupation_of_customer: e.target.value}
        })
    }
    const changeWorkPhoneNumber = (e) => {
        setCustomerDetails((prev) => {
            return {...prev, work_phone: e}
        })
    }

    async function changePaySlip(e) {
        const paySlip = e.target.files[0];
        // const base64 = await getBase64(paySlip)
        //     .then(r => r)
        //     .catch(e => { console.error(e); return "" });

        let base64 = "";
        if (paySlip) {
            setSelectedPayslip(paySlip.name);
            setLoading(true);
            base64 = await resolveAndGetImageFileBase64(paySlip);
            setLoading(false);
        }

        const paySlipJPegFile = convertBase64ToImageOrPDFFile(base64, 'payslip');

        const data = await createFileUploadObject(paySlipJPegFile, 'payslip', customerDetails.id_number, customerDetails.surname)
            .then(data => {
                return data;
            })
            .catch((err) => {
                console.error(err);
                return null;
            });

        if (data != null) {
            setCustomerDetails((prev) => {
                return {...prev, [data['name']]: data['url']}
            })
            setCustomerDetails((prev) => {
                prev.temp[data['name']] = base64;
                return prev;
            });
        } else {
            //TODO: handle error
        }


        // setCustomerDetails((prev) => {
        //     return { ...prev, payslip: base64 }
        // })
    }

    async function changeEmploymentLetter(e) {
        const employmentLetter = e.target.files[0];
        // const base64 = await getBase64(employmentLetter)
        //     .then(r => r)
        //     .catch(e => { console.error(e); return "" });

        let base64 = "";
        if (employmentLetter) {
            setSelectedEmploymentLetter(employmentLetter.name);
            setLoading(true);
            base64 = await resolveAndGetImageFileBase64(employmentLetter);
            setLoading(false);
        }

        const employmentLetterFile = convertBase64ToImageOrPDFFile(base64, 'employment_letter');
        const data = await createFileUploadObject(employmentLetterFile, 'employment_letter', customerDetails.id_number, customerDetails.surname)
            .then(data => data)
            .catch(() => null);

        if (data != null) {
            setCustomerDetails((prev) => {
                return {...prev, [data['name']]: data['url']}
            })
            setCustomerDetails((prev) => {
                prev.temp[data['name']] = base64;
                return prev;
            });
        } else {
            //TODO: handle error
        }


        // setCustomerDetails((prev) => {
        //     return { ...prev, employment_letter: base64 }
        // })
    }

    const changeMonthlyIncome = (e) => {
        setMonthlyIncome(e);
        setCustomerDetails((prev) => {
            return {...prev, gross_monthly_income: e}
        })
    }
    const changePurposeForOpeningAccount = (e) => {
        setCustomerDetails((prev) => {
            const n = {...prev, purpose_of_account: e.target.value}
            console.log(n);
            return n;
        })
    }

    const changeEmployerName = (e) => {
        setCustomerDetails((prev) => {
            return {...prev, employer_name: e.target.value}
        })
    }

    const changeEmployerAddress = (e) => {
        setCustomerDetails((prev) => {
            return {...prev, work_address: e.target.value}
        })
    }

    const accountOpeningPurposes = [
        {key: "transactionalAccount", value: "Transactional Account"},
        {key: "investmentAccount", value: "Investment Account"}
    ];


    // const changeCurrency = (e) => {
    //     setCurrency(e.target.value)
    //     setCustomerDetails((prev) => {
    //         return {...prev, currency: e.target.value}
    //     })
    // }

    const changeCurrency = (selectedOption) => {
        setSelectedCurrency(selectedOption);
        setCustomerDetails((prev) => ({
            ...prev,
            currency: selectedOption?.value || '',
        }));
    };

    const changeOccupationType = (selectedOption) => {
        setCustomerDetails((prev) => {
            return {...prev, occupation: selectedOption ? selectedOption.value : ""}
        });
    }

    const changeBusinessType = (selectedOption) => {
        setCustomerDetails((prev) => {
            return {...prev, type_of_business: selectedOption ? selectedOption.value : ""}
        });
    }

    const changeSector = (selectedOption) => {
        setCustomerDetails((prev) => {
            return {...prev, burseries: selectedOption ? selectedOption.value : ""}
        });
    }

    const changeSourceOfIncome = (selectedOption) => {
        setCustomerDetails((prev) => {
            return {...prev, income_main_source: selectedOption ? selectedOption.value : ""}
        });
    }

    function documentMissingRightBeforeSubmission() {
        console.log("payslip length " + customerDetails.payslip.length)
        return {
            payslip: customerDetails.payslip.length === 0,
            employment_letter: customerDetails.employment_letter.length === 0
        }
    }

    function submitForm(event) {
        event.preventDefault();
        const docsMissing = documentMissingRightBeforeSubmission();
        setDisplayEmptySourceOfFundsError(false);

        if ((employmentStatus === employmentStatusStates.EMPLOYED) && (docsMissing.payslip && docsMissing.employment_letter)) {
            //At least one document must be submitted otherwise do not proceed submission
            setRequiredDocumentMissing(true);
        } else {
            if (employmentStatus === employmentStatusStates.SELF_EMPLOYED) {
                clearEmployedCustomerData();
                // setCustomerDetails((prev) => {
                //     return { ...prev, occupation_of_customer: employmentStatusStates.SELF_EMPLOYED }
                // })
            } else if (employmentStatus === employmentStatusStates.EMPLOYED) {
                clearSelfEmployedCustomerData();
            }
            updateCurrentPage(nextPage);
        }
    }

    const handleSelectChange = (name) => (selectedOption) => {
        setCustomerDetails(prev => ({...prev, [name]: selectedOption?.value || ''}));
    };

    const title = "Proof of Income";
    const subTitle = "";
    const nextPage = "DiasporaNextOfKinDetails";

    const radioStyles = {
        color: '#2b357c',  // Custom point color for the radio button
        '&.Mui-checked': {
            color: '#2b357c',
        },
    };

    const formControlLabelStyles = {
        fontSize: '1rem', // Customize the font size
    };

    const handleCurrencyChange = (name) => (value) => {
        setCustomerDetails(prev => ({...prev, [name]: value}));
    };

    const sortedOccupations = occupations.sort((a, b) => a.label.localeCompare(b.label));
    const sortedIndustryOptions = industryOptions.sort((a, b) => a.label.localeCompare(b.label));
    const sortedSectors = sectors.sort((a, b) => a.label.localeCompare(b.label));
    const sortedSourceOfIncome = sourceOfIncome.sort((a, b) => a.label.localeCompare(b.label));


    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>

                <Typography variant="h5" sx={CustomStyle.heading}>
                    {title}
                </Typography>
                <form onSubmit={submitForm}>
                    <Form.Group className="mb-3">
                        <Form.Label>Employment Status</Form.Label>
                        <RadioGroup onChange={changeEmploymentStatus} horizontal value={employmentStatus}>
                            <RadioButton value={employmentStatusStates.EMPLOYED} pointColor="#2b357c">
                                Employed
                            </RadioButton>
                            <RadioButton value={employmentStatusStates.SELF_EMPLOYED} pointColor="#2b357c">
                                Self-Employed
                            </RadioButton>
                        </RadioGroup>
                    </Form.Group>

                    {/*<FormControl component="fieldset" fullWidth variant="outlined" style={{ marginBottom: '16px' }}>*/}
                    {/*    <FormLabel component="legend" sx={{ color: '#2b357c', marginBottom: '8px', fontSize: '15px' }}>*/}
                    {/*        Employment Status*/}
                    {/*    </FormLabel>*/}
                    {/*    <RadioGroup*/}
                    {/*        row // Makes the RadioGroup horizontal*/}
                    {/*        value={employmentStatus}*/}
                    {/*        onChange={changeEmploymentStatus}*/}
                    {/*    >*/}
                    {/*        <FormControlLabel*/}
                    {/*            value={employmentStatusStates.EMPLOYED}*/}
                    {/*            control={<Radio sx={radioStyles} />}*/}
                    {/*            label="Employed"*/}
                    {/*            sx={formControlLabelStyles}*/}
                    {/*        />*/}
                    {/*        <FormControlLabel*/}
                    {/*            value={employmentStatusStates.SELF_EMPLOYED}*/}
                    {/*            control={<Radio sx={radioStyles} />}*/}
                    {/*            label="Self-Employed"*/}
                    {/*            sx={formControlLabelStyles}*/}
                    {/*        />*/}
                    {/*    </RadioGroup>*/}
                    {/*</FormControl>*/}

                    {employmentStatus === employmentStatusStates.SELF_EMPLOYED && (
                        <>
                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Occupation Type *
                                </Typography>
                                <Select
                                    options={sortedOccupations}
                                    value={sortedOccupations.find(option => option.value === customerDetails.occupation)}
                                    onChange={handleSelectChange('occupation')}
                                    styles={customSelectStyles}
                                    placeholder="Select Occupation Type"
                                    isClearable
                                    required
                                />
                            </FormControl>


                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Business Type *
                                </Typography>
                                <Select
                                    options={sortedIndustryOptions}
                                    value={sortedIndustryOptions.find(option => option.value === customerDetails.type_of_business)}
                                    onChange={handleSelectChange('type_of_business')}
                                    styles={customSelectStyles}
                                    placeholder="Select Industry Type"
                                    isClearable
                                    required
                                />
                            </FormControl>

                            {/*<Form.Group className="mb-3" controlId="diasporaCustomerBusinessType">*/}
                            {/*                <Form.Label>Business Type <span style={{color: "#ed1b2e"}}>*</span></Form.Label>*/}
                            {/*                <Select*/}
                            {/*                    styles={{control: (base) => ({...base, ...styles.rounded})}}*/}
                            {/*                    onChange={changeBusinessType}*/}
                            {/*                    options={industryOptions}*/}
                            {/*                    value={industryOptions.find(option => option.value === customerDetails.type_of_business)}*/}
                            {/*                    name="type_of_business"*/}
                            {/*                    isClearable*/}
                            {/*                    required*/}
                            {/*                />*/}
                            {/*            </Form.Group>*/}

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Sector *
                                </Typography>
                                <Select
                                    options={sortedSectors}
                                    value={sortedSectors.find(option => option.value === customerDetails.burseries)}
                                    onChange={handleSelectChange('burseries')}
                                    styles={customSelectStyles}
                                    placeholder="Select Sector"
                                    isClearable
                                    required
                                />
                            </FormControl>


                            {/*<Form.Group className="mb-3" controlId="diasporaCustomerBusinessType">*/}
                            {/*                <Form.Label>Sector <span style={{color: "#ed1b2e"}}>*</span></Form.Label>*/}
                            {/*                <Select*/}
                            {/*                    styles={{control: (base) => ({...base, ...styles.rounded})}}*/}
                            {/*                    onChange={changeSector}*/}
                            {/*                    options={sectors}*/}
                            {/*                    value={sectors.find(option => option.value === customerDetails.sector)}*/}
                            {/*                    name="sector"*/}
                            {/*                    isClearable*/}
                            {/*                    required*/}
                            {/*                />*/}
                            {/*            </Form.Group>*/}

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Source of Income *
                                </Typography>
                                <Select
                                    options={sortedSourceOfIncome}
                                    value={sortedSourceOfIncome.find(option => option.value === customerDetails.income_main_source)}
                                    onChange={handleSelectChange('income_main_source')}
                                    styles={customSelectStyles}
                                    placeholder="Select Source of Income"
                                    isClearable
                                    required
                                />
                            </FormControl>

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>

                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Bank statement (Atleast 3 months)

                                </Typography>
                                <CustomNBSBlueButton variant="contained" component="label">
                                    Choose File
                                    <input
                                        type="file"
                                        onChange={changeBankStatement}
                                        name="NationalID"
                                        accept=".pdf, .doc, .docx"
                                        style={{display: 'none'}} // Hide the file input
                                    />
                                </CustomNBSBlueButton>

                                {/*<CustomNBSRedButton*/}
                                {/*    variant="contained"*/}
                                {/*    component="label"*/}
                                {/*>*/}
                                {/*    Choose File*/}
                                {/*    <input*/}
                                {/*        type="file"*/}
                                {/*        onChange={changeBankStatement}*/}
                                {/*        name="NationalID"*/}
                                {/*        accept=".pdf, .doc, .docx"*/}
                                {/*        style={{ display: 'none' }} // Hide the file input*/}
                                {/*    />*/}
                                {/*</CustomNBSRedButton>*/}
                                <Typography
                                    style={{...CustomStyle.contentText, color: "#ed1b2e", alignItems: 'center'}}>Your
                                    foreign bank statement.</Typography>

                            </FormControl>
                            {/*<Form.Group className="mb-3" controlId="diasporaCustomerForeignBankStatement">*/}
                            {/*    <Form.Label>Bank statement (Atleast 3 months)</Form.Label>*/}
                            {/*    <Form.Control style={styles.rounded} type="file" accept=".pdf, .doc, .docx"*/}
                            {/*                  onChange={changeBankStatement}/>*/}
                            {/*    <Form.Text style={{color: "#ed1b2e"}}>Your foreign bank statement</Form.Text>*/}
                            {/*</Form.Group>*/}
                        </>

                    )}

                    {employmentStatus === employmentStatusStates.EMPLOYED && (
                        <>

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Occupation Type *
                                </Typography>
                                <Select
                                    options={sortedOccupations}
                                    value={sortedOccupations.find(option => option.value === customerDetails.occupation)}
                                    onChange={handleSelectChange('occupation')}
                                    styles={customSelectStyles}
                                    placeholder="Select Occupation Type"
                                    isClearable
                                    required
                                />
                            </FormControl>

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Business Type *
                                </Typography>
                                <Select
                                    options={industryOptions}
                                    value={industryOptions.find(option => option.value === customerDetails.type_of_business)}
                                    onChange={handleSelectChange('type_of_business')}
                                    styles={customSelectStyles}
                                    placeholder="Select Industry Type"
                                    isClearable
                                    required
                                />
                            </FormControl>

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Sector *
                                </Typography>
                                <Select
                                    options={sortedSectors}
                                    value={sortedSectors.find(option => option.value === customerDetails.burseries)}
                                    onChange={handleSelectChange('burseries')}
                                    styles={customSelectStyles}
                                    placeholder="Select Sector"
                                    isClearable
                                    required
                                />
                            </FormControl>

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Source of Income *
                                </Typography>
                                <Select
                                    options={sortedSourceOfIncome}
                                    value={sortedSourceOfIncome.find(option => option.value === customerDetails.income_main_source)}
                                    onChange={handleSelectChange('income_main_source')}
                                    styles={customSelectStyles}
                                    placeholder="Select Source of Income"
                                    isClearable
                                    required
                                />
                            </FormControl>

                            <CustomNBSTextField
                                label="Employer Name *"
                                value={customerDetails.employer_name || ''}
                                onInputChange={changeEmployerName}
                                inputType="text"
                                required="true"
                                maxLength={25}
                            />

                            <CustomNBSTextField
                                label="Employer Address *"
                                value={customerDetails.work_address || ''}
                                onInputChange={changeEmployerAddress}
                                inputType="text"
                                required="true"
                                maxLength={25}
                            />

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Work Phone *
                                </Typography>

                                <CustomPhoneInput
                                    value={customerDetails.work_phone}
                                    onChange={changeWorkPhoneNumber}
                                    defaultCountry={defaultCountryIso2Code}
                                />
                            </FormControl>

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>

                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Pay slip

                                </Typography>
                                <CustomNBSBlueButton variant="contained" component="label">
                                    Choose File
                                    <input
                                        type="file"
                                        onChange={changePaySlip}
                                        name="NationalID"
                                        accept="image/*"
                                        style={{display: 'none'}} // Hide the file input
                                    />
                                </CustomNBSBlueButton>

                                {/*<CustomNBSRedButton*/}
                                {/*    variant="contained"*/}
                                {/*    component="label"*/}
                                {/*>*/}
                                {/*    Choose File*/}
                                {/*    <input*/}
                                {/*        type="file"*/}
                                {/*        onChange={changePaySlip}*/}
                                {/*        name="NationalID"*/}
                                {/*        accept="image/*"*/}
                                {/*        style={{ display: 'none' }} // Hide the file input*/}
                                {/*    />*/}
                                {/*</CustomNBSRedButton>*/}
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px', marginBottom: '10px'
                                }}>Uploaded {selectedPayslip}</Typography>
                                <Typography
                                    sx={{
                                        color: '#ed1b2e',
                                        padding: '4px 8px',
                                        borderRadius: '4px'
                                    }}
                                >Payslip must not be more than 3 months old.</Typography>

                            </FormControl>


                            {/*<Form.Group className="mb-3" controlId="diasporaCustomerPayslip">*/}
                            {/*    <Form.Label>Pay slip</Form.Label>*/}
                            {/*    <Form.Control style={styles.rounded} type="file" accept="image/*"*/}
                            {/*                  onChange={changePaySlip}/>*/}
                            {/*    <Form.Text style={{color: "#ed1b2e"}}>*/}
                            {/*        Payslip must not be more than 3 months old*/}
                            {/*    </Form.Text>*/}
                            {/*</Form.Group>*/}
                            <br/>

                            <FormControl fullWidth variant="outlined" style={{marginBottom: '16px'}}>

                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    marginBottom: '10px'
                                }}>
                                    Employer letter or Employment contract

                                </Typography>
                                <CustomNBSBlueButton variant="contained" component="label">
                                    Choose File
                                    <input
                                        type="file"
                                        onChange={changeEmploymentLetter}
                                        name="NationalID"
                                        accept=".pdf, .doc, .docx"
                                        style={{display: 'none'}} // Hide the file input
                                    />
                                </CustomNBSBlueButton>
                                <Typography variant="h6" sx={{
                                    ...CustomStyle.subtitle,
                                    textAlign: 'left',
                                    fontSize: '15px', marginBottom: '10px'
                                }}>Uploaded {selectedEmploymentLetter}</Typography>
                                {/*<CustomNBSRedButton*/}
                                {/*    variant="contained"*/}
                                {/*    component="label"*/}
                                {/*>*/}
                                {/*    Choose File*/}
                                {/*    <input*/}
                                {/*        type="file"*/}
                                {/*        onChange={changeEmploymentLetter}*/}
                                {/*        name="NationalID"*/}
                                {/*        accept=".pdf, .doc, .docx"*/}
                                {/*        style={{ display: 'none' }} // Hide the file input*/}
                                {/*    />*/}
                                {/*</CustomNBSRedButton>*/}
                                <Typography
                                    sx={{
                                        color: '#ed1b2e',
                                        // backgroundColor: requiredDocumentMissing ? '#ed1b2e' : 'transparent', // Background color only if requiredDocumentMissing is true
                                        padding: requiredDocumentMissing ? '4px 8px' : '0', // Optional padding when background is present
                                        borderRadius: requiredDocumentMissing ? '4px' : '0', // Optional border-radius
                                    }}
                                >
                                    {requiredDocumentMissing ? docMessage.missing : docMessage.employmentDocNotification}
                                </Typography>
                            </FormControl>

                        </>
                    )}

                    {/* Common to both employed and Unemployed */}
                    {/*<Form.Group className="mb-3" controlId="diasporaCustomerPhysicalAddress">*/}
                    {/*    <Form.Label>Monthly Income <span style={{color: "#ed1b2e"}}>*</span></Form.Label>*/}
                    {/*    <Row>*/}
                    {/*        <Col lg={4} md={4} sm={4} xs={4}>*/}
                    {/*            <Form.Select defaultValue={currencies[0]}*/}
                    {/*                         style={{...styles.rounded, height: "36px"}}*/}
                    {/*                         onChange={changeCurrency}>*/}
                    {/*                {currencies.map(c => c).unique().map(currency => <option*/}
                    {/*                    value={currency}>{currency}</option>)}*/}
                    {/*            </Form.Select>*/}
                    {/*        </Col>*/}
                    {/*        <Col>*/}
                    {/*            <CurrencyInput*/}
                    {/*                style={styles.rounded}*/}
                    {/*                className="form-control"*/}
                    {/*                id="diasporaCustomerMonthlyIncome"*/}
                    {/*                name="diasporaCustomerPhysicalAddress"*/}
                    {/*                placeholder="Please enter an amount"*/}
                    {/*                min={1}*/}
                    {/*                decimalsLimit={2}*/}
                    {/*                allowNegativeValue={false}*/}
                    {/*                onValueChange={changeMonthlyIncome}*/}
                    {/*                required*/}
                    {/*            />*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Form.Group>*/}

                    <div style={{marginBottom: '16px'}}>
                        <Typography variant="h6" sx={{
                            ...CustomStyle.subtitle,
                            textAlign: 'left',
                            fontSize: '15px',
                            marginBottom: '10px'
                        }}>
                            Monthly Income *
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Select
                                    options={currencyOptions}
                                    value={selectedCurrency}
                                    onChange={changeCurrency}
                                    styles={customSelectStyles}
                                    // placeholder="Select Currency"
                                    isClearable
                                    required
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <CurrencyInput
                                    style={CustomStyle.currencyInput}
                                    className="form-control"
                                    id="gross_monthly_income"
                                    name="gross_monthly_income"
                                    placeholder="Please enter an amount"
                                    min={1}
                                    decimalsLimit={2}
                                    allowNegativeValue={false}
                                    value={customerDetails.gross_monthly_income || ''}
                                    onValueChange={handleCurrencyChange('gross_monthly_income')}
                                    required
                                />
                            </Grid>
                        </Grid>

                    </div>

                    {/*<Button*/}
                    {/*    type="submit"*/}
                    {/*    disabled={loading}*/}
                    {/*    className="rounded-pill btn-light mx-auto d-block"*/}
                    {/*    style={{*/}
                    {/*        backgroundColor: "#ed1b2e",*/}
                    {/*        color: "white",*/}
                    {/*        marginTop: '2rem',*/}
                    {/*        marginBottom: '2rem'*/}
                    {/*    }}>*/}
                    {/*    {*/}
                    {/*        loading*/}
                    {/*            ? <Spinner className="content" animation="border"/>*/}
                    {/*            : "Next"*/}
                    {/*    }*/}
                    {/*</Button>*/}
                    <div style={CustomStyle.buttonContainer}>

                        <CustomNBSRedButton type="submit"
                                            disabled={loading}>
                            {loading ? <CircularProgress color="inherit" size={24}/> : 'Next'}
                        </CustomNBSRedButton>
                    </div>
                </form>

            </div>
        </div>
    )
});