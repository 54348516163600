import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// Set the worker URL for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFPreview = ({ fileUrl }) => {
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        // Load PDF document and get the total number of pages
        const loadingTask = pdfjs.getDocument(fileUrl);
        loadingTask.promise.then((pdf) => {
            setNumPages(pdf.numPages);
        });
    }, [fileUrl]);

    return (
        <div>
            <Document file={fileUrl}>
                {Array.from(new Array(numPages), (_, index) => (
                    <Page key={index + 1} pageNumber={index + 1} />
                ))}
            </Document>
        </div>
    );
};

export default PDFPreview;
