import React from "react";
import { Card, CardContent, Typography, Box } from '@mui/material';

export const SummaryPageTable = React.memo(({customerObject}) => {
    const allowedKeys = [
        "id_number", "firstname", "surname", "other_name", "gender", "date_of_birth",
        "id_issue_date", "id_expiry_date", "mobile_phone", "email_address", "postal_address",
        "postal_town", "occupation", "income_main_source", "type_of_business",
        "gross_monthly_income", "trad_auth", "village", "district",
        "kin_fullname", "kin_tel_number", "kin_relationship_type", "kin_email"
    ];
    // console.log(JSON.stringify(customerObject))

    return (
        <Card
            sx={{
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                marginBottom: '20px',
                maxWidth: '100%',
                border: '2px solid #2b357c',
            }}
        >
            <CardContent>
                {/*<Typography variant="h6" sx={{ mb: 2, color: '#2b357c', fontWeight: 'bold', textAlign: 'center' }}>*/}
                {/*    Summary*/}
                {/*</Typography>*/}

                <Box sx={{ mt: 2 }}>
                    {allowedKeys.map((key) => (
                        customerObject[key] && (
                            <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                <Typography variant="body2" sx={{ color: '#2b357c', fontWeight: 'bold' }}>
                                    {key.replace(/_/g, ' ').toUpperCase()}:
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#2b357c' }}>
                                    {typeof customerObject[key] === 'string'
                                        ? customerObject[key].toUpperCase()
                                        : JSON.stringify(customerObject[key])}
                                </Typography>
                            </Box>
                        )
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
})