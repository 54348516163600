import React, {useState} from 'react'
import { Navbar , Image} from 'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";
const Header=({pageTracker,updateCurrentPage})=>{
    const set = new Set(pageTracker);
    const browsingAtHomePage = set.size === 1 && set.has("HomePage");

    const onBackClick = () =>{
        // console.log(pageTracker);
        pageTracker.pop();
        // console.log(pageTracker[pageTracker.length-1]);
        let nextPage = pageTracker[pageTracker.length-1];
        pageTracker.pop()
        updateCurrentPage(nextPage)
    }
    const goHome=()=>{
      console.log("Going home")
      localStorage.clear();
      updateCurrentPage("HomePage")
    }
  let element = (
    <Navbar  className="navbar navbar-expand-sm fixed-top">
        <div className="container-fluid justify-content-end">
            <Image src={require("../assets/images/logo_nav.png")} alt="Logo" onClick={goHome} className="rounded-circle img-fluid logo"/>
        </div>
      </Navbar>
  );
  if (pageTracker.length > 1)
    element = (
        <Navbar  className="navbar navbar-expand-sm fixed-top">
            {/*Do not show back Button/Icon when at HomePage*/}
            {!browsingAtHomePage && (
                <div className="btn d-flex" onClick={onBackClick} style={{ color: "#2b357c" }}>
                    <IoIosArrowBack className="header-icon" />
                    <span  className="header-back"><strong>Back</strong></span>
                </div>
            )}

            <div className="container-fluid justify-content-end">
              <a href="/">
                  <Image src={require("../assets/images/logo_nav.png")} alt="Logo" className="rounded-circle img-fluid logo"/>
              </a>
            </div>
        </Navbar>
    );

  return element;
}

export default React.memo(Header);