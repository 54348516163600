import React, { useEffect, useState } from "react";
import domtoimage from "dom-to-image-more";
import HomeLocationMap from "../../componets/HomeLocationMap";

export const Location = ({
    updateCurrentPage,
  existingDetails,
  setExistingDetails
}) => {
  console.log("On Location Page!!");
  const [disableButton, setDisableButton] = useState(true);
  const [displayNextButton, setDisplayNextButton] = useState(false);

  const [className, setClassName] = useState({
    next: "rounded-pill btn-light mx-auto d-none",
    confirm: "rounded-pill btn-light mx-auto d-none",
  });
  const [enableButton, setEnableButton] = useState(true);
  const buttonStyle = {
    backgroundColor: "#ed1b2e",
    color: "white",
  };
  let mapLoaded = false;

  const handleNextPage = () => {
    captureImage("AccountServices");
  };

  const captureImage = (page) => {
    const mapNode = document.getElementById("map");
    if (mapNode) {
      domtoimage
        .toPng(mapNode)
        .then(function (dataUrl) {
            setDisplayNextButton(false);

          // Check if the location already exists in images array
          const isLocationExists = existingDetails.images.some(
            (image) => image.location === dataUrl
          );

          if (!isLocationExists) {
            // Location doesn't exist, add a new location object
            const newImage = { location: dataUrl };
            setExistingDetails((prev) => {
              return {
                ...prev,
                images: [...prev.images, newImage],
              };
            });
          }

          // Update current page
    
              // Update current page
     
          updateCurrentPage(page);

        //   page && console.log("updateCurrentPage:", page);
        })
        .catch(function (error) {
          console.error("Oops, something went wrong!", error);
        });
    }
  };

  useEffect(() => {
    if (displayNextButton) {
      setClassName((prev) => {
        return {
          ...prev,
          confirm: "rounded-pill btn-light mx-auto d-none",
          next: "rounded-pill btn-light mx-auto d-block",
        };
      });
    }
  }, [displayNextButton]);

  return (
    <div className="content">
      <p>Choose your Location</p>
      <HomeLocationMap
        setDisplayNextButton={setDisplayNextButton}
        handleLocationPick={setDisplayNextButton}
        handleLocationClear={setDisplayNextButton}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {displayNextButton && (
          <button
            className="rounded-pill btn-light mx-auto d-block pt-3"
            style={buttonStyle}
            onClick={handleNextPage}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};
