import React, {useEffect, useState} from "react";
import axios from "axios";
import {CustomStyle} from "../../helpers/styles";
import {Alert, CircularProgress, Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const ScanVerification = (({updateCurrentPage, existingDetails, setExistingDetails}) => {
    console.log(existingDetails);
    const [message, setMessage] = useState("Verifying ID Details, Please wait...");
    const [showButton, setShowButton] = useState(false);
    const {scannedData} = existingDetails;
    const parsedData = JSON.parse(scannedData);
    const account = existingDetails.account_number;
    const [loading, setLoading] = useState(false);
    const id_number = parsedData.id_number;
    console.log(id_number)

    useEffect(() => {
        const fetchData = async () => {
            const url = '/verify-scan';
            const body = {
                type: "scanning",
                id_number: id_number,
                account_number: account
            };

            try {
                setLoading(true);
                const response = await axios.post(url, body);
                setLoading(false);

                console.log("API response:", response);
                if (response.status === 200) {
                    if (response.data.status) {
                        updateCurrentPage("AvailableAccounts");
                    } else {
                        setMessage(response.data.msg);
                        setShowButton(true);
                    }
                }

            } catch (error) {
                console.error("Failed to verify scan:", error);
            }
        };

        fetchData();
    }, []);

    const handleVerifyPage = () => {
        updateCurrentPage("ScanComponent");
    }
    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>National ID Verification</Typography>

                {message && (
                    <>
                    <CircularProgress/>
                    <Alert severity="info" style={CustomStyle.alert}>
                        {message}
                    </Alert>
                    </>
                )}
                {showButton && (
                    <div style={CustomStyle.buttonContainer}>

                    <CustomNBSRedButton onClick={handleVerifyPage}
                                        disabled={!loading}>
                        {loading ? <CircularProgress color="inherit" size={24}/> : 'Rescan'}
                    </CustomNBSRedButton>
                    </div>
                )}
            </div>
        </div>
    )
});
