import React, {useEffect, useState} from "react";
import {ListGroup} from "react-bootstrap";
import axios from "axios";
import {CustomStyle} from "../../helpers/styles";
import {Typography} from "@mui/material";

export const UpgradeRequirements = ({updateCurrentPage, existingDetails, setExistingDetails, updateFormData}) => {
    const [requirements, setRequirements] = useState(null);
    const account_category = existingDetails.account_category;
    const account_number = existingDetails.account_number;

    useEffect(() => {
        const fetchRequirements = async () => {
            const requirementsBody = {
                account_number: account_number,
                account_category: account_category,
                type: 'requirements',
            };

            const config = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: requirementsBody,
            };

            const eligibleUrl = '/requirements';

            try {
                const result = await axios.post(eligibleUrl, requirementsBody, config);

                if (result.status === 200) {
                    if (result.data.status) {
                        const requirements = result.data.requirements;
                        setRequirements(requirements);
                    } else {
                        console.log('Error in requirements fetch.');
                    }
                } else {
                    console.log('Error.');
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchRequirements();
    }, []);
    useEffect(() => {
        if (requirements) {
            updateFormData('requirements', requirements);
        }
    }, [requirements, updateFormData]);
    const [enableButton, setEnableButton] = useState(true);

    const buttonStyle = {
        backgroundColor: enableButton ? '#ed1b2e' : '#de5959',
        color: 'white',
    };

    const handleNextPage = () => {
        updateCurrentPage('AdditionalFields');
    };

    const formatRequirement = (requirement) => {
        // Convert requirement from underscore-separated to capitalized words
        const words = requirement.split('_');
        let formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

        // Special case for 'utility_bill_url'
        if (requirement === 'utility_bill_url') {
            formattedWords = ['Utility', 'Bill'];
        }

        // Special case for 'utility_bill_url'
        if (requirement === 'id_scanned_copy_url') {
            formattedWords = ['National ID', 'Copy'];
        }
        return formattedWords.join(' ');
    };

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>Additional Requirements needed for this
                    account:</Typography>

                <p>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <ul style={{paddingLeft: '0'}}>
                                {requirements ? (
                                    ['diaspora_national_id', 'kin_utility_bill', 'employment_letter',
                                        'kin_identification', 'utility_bill_url', 'passport', 'foreign_bank_statement',
                                        'institution_letter', 'student_id', 'payslip', 'id_scanned_copy_url', 'location'].map((requirement, index) => (
                                        requirements.includes(requirement) && (
                                            <li className="w3-text-indigo" key={index}>
                                                {formatRequirement(requirement)}
                                            </li>
                                        )
                                    ))
                                ) : (
                                    <li>Loading requirements...</li>
                                )}
                            </ul>
                        </ListGroup.Item>
                    </ListGroup>
                </p>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <button
                        className="rounded-pill btn-light mx-auto d-block"
                        style={buttonStyle}
                        disabled={!enableButton}
                        onClick={handleNextPage}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};