import React from 'react';
import { Button } from '@mui/material';

const CustomRedButton = ({ children, onClick, ...props }) => {
    return (
        <Button
            onClick={onClick}
            variant="contained"
            sx={{
                backgroundColor: '#ed1b2e',
                color: '#ffffff',
                borderRadius: '25px',
                padding: '8px 16px',
                fontSize: '1rem',
                textTransform: 'none',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s, transform 0.3s',
                width: '100%',
                maxWidth: '300px', // Limits maximum width
                minWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', // Prevents text from wrapping initially
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap', // Allows text to wrap inside button without overflowing
                '&:hover': {
                    backgroundColor: '#d31a22',
                    transform: 'scale(1.05)',
                },
                '&:active': {
                    backgroundColor: '#c8101d',
                },
                '@media (max-width: 600px)': {
                    padding: '6px 12px',
                    fontSize: '0.9rem',
                },
            }}
            {...props}
        >
            {children}
        </Button>
    );
};

export default CustomRedButton;
