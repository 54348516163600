import React, {useState} from 'react';
import country from "country-list-js";
import Select from "react-select";
import {occupations as occptns, occupationTypes} from "../data";
import {CustomStyle} from "../../helpers/styles";
import {Typography} from "@mui/material";

export const AdditionalFields = (({formData, updateCurrentPage, existingDetails, setExistingDetails}) => {
    const accountType = existingDetails.account_category;
    const [enableButton, setEnableButton] = useState(true);
    const [countries, setCountries] = useState(country.names());
    const [message, setMessage] = useState(null);
    const [validFileFormat, setValidFileFormat] = useState(true);

    const [errors, setErrors] = useState({});
    const [previewImage, setPreviewImage] = useState(null);

    const currencies = ['GBP', 'USD', 'EUR', 'ZAR'];

    const buttonStyle = {
        backgroundColor: enableButton ? '#ed1b2e' : '#de5959',
        color: 'white',
    };

    const handleNextPage = () => {
        const isValid = validateFields();

        if (isValid) {
            const updatedExistingDetails = {...existingDetails, ...formData};
            const base64Keys = Object.keys(updatedExistingDetails).filter(
                (key) =>
                    typeof updatedExistingDetails[key] === 'string' &&
                    updatedExistingDetails[key].startsWith('data:')
            );

            updatedExistingDetails.images = base64Keys.map((key) => {
                const imageObject = {[key]: updatedExistingDetails[key]};
                delete updatedExistingDetails[key];
                return imageObject;
            });

            updatedExistingDetails.type = 'addition';

            setExistingDetails(updatedExistingDetails);

            if (formData.requirements && formData.requirements.includes('location')) {
                updateCurrentPage("Location");
            } else if (formData.requirements && formData.requirements.includes('id_number')) {
                updateCurrentPage("ScanDiaspora");
            } else {
                updateCurrentPage("KeyFacts");
            }
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;

        setExistingDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }


    const style = {
        input: {
            color: '#2b375c',
            height: '50px'
        }
    }


    const handleFileChange = async (e) => {
        // As files
        // const file = e.target.files[0];
        // setPreviewImage(URL.createObjectURL(file));

        // setExistingDetails((prevState) => ({
        //     ...prevState,
        //     [e.target.name]: file,
        // }));

        const file = e.target.files[0];
        const reader = new FileReader();

        const fieldKey = e.target.name;
        const field = fields.find(field => field.key === fieldKey);

        if (!field) {
            // Field not found, handle accordingly
            return;
        }
        const acceptedFormats = field.acceptedFormats || [];

        if (acceptedFormats.length > 0 && !acceptedFormats.includes(file.type)) {
            // Invalid file format
            setValidFileFormat(false);
            e.target.value = "";

            setMessage(`Invalid file format. Accepted formats: ${acceptedFormats.join(', ')}`)
            //alert(`Invalid file format. Accepted formats: ${acceptedFormats.join(', ')}`);
            return;
        }

        setValidFileFormat(true);

        if (file.type.startsWith('image/')) {
            reader.onloadend = () => {
                const image = new Image();
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Set canvas dimensions to match the image
                    canvas.width = image.width;
                    canvas.height = image.height;

                    // Draw the image onto the canvas
                    ctx.drawImage(image, 0, 0);

                    // Convert the canvas content to a base64-encoded JPEG string
                    canvas.toBlob((blob) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const base64String = reader.result;
                            setPreviewImage(base64String);

                            setExistingDetails((prevState) => ({
                                ...prevState,
                                [e.target.name]: base64String,
                            }));
                        };
                        reader.readAsDataURL(blob);
                    }, 'image/jpeg', 1); // JPEG quality: 1 (highest)
                };

                image.src = reader.result;
            };
        } else {
            // Handle non-image files (e.g., word documents) here
            // You can skip the conversion or perform any other desired action
            reader.onloadend = () => {
                const base64String = reader.result;
                setExistingDetails((prevState) => ({
                    ...prevState,
                    [e.target.name]: base64String,
                }));

            };
        }

        if (file) {
            reader.readAsDataURL(file);
        }

    };


    const occupations = {
        key: "occupation",
        label: "Occupation *",
        type: "select",
        mandatory: true,
        options: occptns

    }

    const fields = [
        {
            key: "employement_status",
            label: "Employment Status *",
            type: "select",
            mandatory: true,
            options: [
                {value: "employed", label: "Employed"},
                {value: "self_employed", label: "Self-employed"},
            ],
        },
        {
            key: "income_main_source",
            label: "Income Main Source",
            type: "text",
            mandatory: false,
        },
        {
            key: "gross_monthly_income",
            label: "Gross Monthly Income *",
            type: "number",
            mandatory: true,
        },
        {
            key: "purpose_of_account",
            label: "Purpose of Account *",
            type: "select",
            mandatory: true,
            options: [
                {value: "transactionalAccount", label: "Transactional Account"},
                {value: "investmentAccount", label: "Investment Account"},
            ],

        },

        {
            key: "currency",
            label: "Currency *",
            type: "select",
            mandatory: true,
            options: currencies.map((currency) => ({
                value: currency,
                label: currency,
            })),
        },
        {
            key: "foreign_bank_statement",
            label: "Foreign Bank Statement *",
            type: "file",
            mandatory: false,
            acceptedFormats: ["application/msword", ".doc", ".docx", "application/pdf"]

        },
        {
            key: "employer_address",
            label: "Employer Address *",
            type: "text",
            mandatory: false,
        },
        {
            key: "employer_name",
            label: "Employer Name",
            type: "text",
            mandatory: false,
        },
        {
            key: "employment_letter",
            label: "Employment letter *",
            type: "file",
            mandatory: true,
            acceptedFormats: ["application/msword", ".doc", ".docx", "application/pdf"]
        },
        {
            key: "work_phone",
            label: "Work phone",
            type: "text",
            mandatory: false,
        },
        {
            key: "work_address",
            label: "Work Address",
            type: "text",
            mandatory: false,
        },
        {
            key: "diaspora_national_id",
            label: "Diaspora National Id *",
            type: "file",
            mandatory: false,
            // acceptedFormats: [".png", ".jpg", ".jpeg", ".gif"]

        },
        {
            key: "id_scanned_copy_url",
            label: "National ID *",
            type: "file",
            mandatory: true,
            // acceptedFormats: [".png", ".jpg", ".jpeg", ".gif"]
        },
        {
            key: "passport",
            label: "Passport *",
            type: "file",
            // acceptedFormats: [".png", ".jpg", ".jpeg", ".gif"]
        },
        {
            key: "payslip",
            label: "New Proof of Income (Payslip) *",
            type: "file",
            // acceptedFormats: [".png", ".jpg", ".jpeg", ".gif"]
        },
        {
            key: "email_address",
            label: "Email Address",
            type: "text",
            mandatory: true,
        },
        {
            key: "postal_address",
            label: "Postal Address",
            type: "text",
            mandatory: true,
        },
        {
            key: "student_id",
            label: "Student ID",
            type: "file",
            mandatory: true,
        },
        {
            key: "institution_letter",
            label: "Institution Letter",
            type: "file",
            mandatory: true,
            acceptedFormats: ["application/msword", ".doc", ".docx", "application/pdf"]
        },
        {
            key: "postal_town",
            label: "Postal Town",
            type: "select",
            mandatory: true,
            options: [
                {value: "Balaka", label: "Balaka"},
                {value: "Blantyre", label: "Blantyre"},
                {value: "Chikwawa", label: "Chikwawa"},
                {value: "Chiradzulu", label: "Chiradzulu"},
                {value: "Chitipa", label: "Chitipa"},
                {value: "Dedza", label: "Dedza"},
                {value: "Dowa", label: "Dowa"},
                {value: "Karonga", label: "Karonga"},
                {value: "Kasungu", label: "Kasungu"},
                {value: "Likoma", label: "Likoma"},
                {value: "Lilongwe", label: "Lilongwe"},
                {value: "Machinga", label: "Machinga"},
                {value: "Mangochi", label: "Mangochi"},
                {value: "Mchinji", label: "Mchinji"},
                {value: "Mchinji", label: "Nkhotakota"},
                {value: "Mulanje", label: "Mulanje"},
                {value: "Mwanza", label: "Mwanza"},
                {value: "Mzimba", label: "Mzimba"},
                {value: "Mzuzu", label: "Mzuzu"},
                {value: "Nkhata Bay", label: "Nkhata Bay"},
                {value: "Nsanje", label: "Nsanje"},
                {value: "Ntcheu", label: "Ntcheu"},
                {value: "Ntchisi", label: "Ntchisi"},
                {value: "Thyolo", label: "Thyolo"},
                {value: "Phalombe", label: "Phalombe"},
                {value: "Rumphi", label: "Rumphi"},
                {value: "Salima", label: "Salima"},
                {value: "Zomba", label: "Zomba"},
            ],
        },
        {
            key: "resident_country",
            label: "Resident Country",
            type: "select",
            mandatory: true,
            options: countries.map((country) => ({
                value: country,
                label: country,
            })),
        },
        {
            key: "utility_bill_url",
            label: "Utility Bill *",
            type: "file",
            mandatory: true,
            // acceptedFormats: [".png", ".jpg", ".jpeg", ".gif"]
        },
        {
            key: "kin_identification",
            label: "kin Identification *",
            type: "file",
            mandatory: false,
            // acceptedFormats: [".png", ".jpg", ".jpeg", ".gif"]
        },
        {
            key: "kin_mobile_no",
            label: "kin Mobile Number *",
            type: "text",
            mandatory: true,
        },
        {
            key: "kin_physical_address",
            label: "kin Physical Address *",
            type: "text",
            mandatory: true,
        },
        {
            key: "kin_postal_address",
            label: "kin Postal Address *",
            type: "text",
            mandatory: true,
        },
        {
            key: "kin_utility_bill",
            label: "kin Utility Bill *",
            type: "file",
            mandatory: false,
            // acceptedFormats: [".png", ".jpg", ".jpeg", ".gif"]
        }
    ];

    const validateFields = () => {
        const updatedErrors = {};
        let isValid = true;


        fields.forEach((field) => {
            // Check if the field key is included in existingDetails.requirements
            const isFieldRequired = formData.requirements && formData.requirements.includes(field.key);

            // Only validate the field if it is required and rendered
            if (field.mandatory && isFieldRequired && !existingDetails[field.key]) {
                updatedErrors[field.key] = "This field is required";
                isValid = false;
            }
        });

        setErrors(updatedErrors);

        return isValid;

    };

    const shouldShowOccupationField = existingDetails.account_category !== 6005;


    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Additional Requirements for this account:</Typography>

                {shouldShowOccupationField && (
                    <div className="form-group mb-3">
                        <label htmlFor="occupation" className="form-label">
                            Occupation
                        </label>
                        {/*<Select*/}
                        {/*    styles={{ control: (base) => ({ ...base, ...styles.rounded }) }}*/}
                        {/*    onChange={changeOccupationType}*/}
                        {/*    options={occupations}*/}
                        {/*    value={occupations.find(option => option.value === customerDetails.occupation)}*/}
                        {/*    name="occupation"*/}
                        {/*    isClearable*/}
                        {/*    required*/}
                        {/*/>*/}
                        <select
                            className="rounded-pill form-select w3-text-indigo"
                            id="occupation"
                            name="occupation"
                            value={existingDetails.occupation}
                            onChange={handleChange}
                            style={style.input}
                        >
                            <option value="">Select an option</option>
                            {occupations.options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {errors.occupation && (
                            <div className="text-danger">{errors.occupation}</div>
                        )}
                    </div>
                )}

                {fields.map((field) => {
                    const isFieldRequired = formData.requirements && formData.requirements.includes(field.key);
                    const fieldValue = existingDetails[field.key] || "";

                    if (isFieldRequired) {
                        if (field.key === "employment_letter" || field.key === "institution_letter" || field.key === "foreign_bank_statement") {
                            return (
                                <div key={field.key}>
                                    <label htmlFor={field.key}>{field.label}</label>
                                    <input
                                        type="file"
                                        id={field.key}
                                        name={field.key}
                                        accept=".doc, .docx, application/msword, .pdf"
                                        className="form-control rounded-pill"
                                        onChange={handleFileChange}
                                    />
                                    {existingDetails[field.key] && (
                                        <span>{existingDetails[field.key].name}</span>
                                    )}
                                    {errors[field.key] && (
                                        <div className="text-danger">{errors[field.key]}</div>
                                    )}
                                </div>
                            );
                        } else {
                            return (
                                <div key={field.key}>
                                    {field.type === "text" && (
                                        <div className="form-floating mb-3 mt-3">
                                            <input
                                                type="text"
                                                id={field.key}
                                                className="form-control rounded-pill"
                                                placeholder={field.label}
                                                name={field.key}
                                                value={fieldValue}
                                                onChange={handleChange}
                                                style={style.input}
                                            />
                                            <label htmlFor={field.key} className="form-label">{field.label}</label>
                                            {errors[field.key] && (
                                                <div className="text-danger">{errors[field.key]}</div>
                                            )}
                                        </div>
                                    )}

                                    {field.type === "number" && (
                                        <div className="form-floating mb-3">
                                            <input
                                                type="number"
                                                id={field.key}
                                                className="form-control rounded-pill"
                                                placeholder={field.label}
                                                name={field.key}
                                                value={fieldValue}
                                                onChange={handleChange}
                                                style={style.input}
                                            />
                                            <label htmlFor={field.key} className="form-label">{field.label}</label>
                                            {errors[field.key] && (
                                                <div className="text-danger">{errors[field.key]}</div>
                                            )}
                                        </div>
                                    )}

                                    {field.type === "select" && (
                                        <div className="form-group mb-3">
                                            <label htmlFor={field.key} className="form-label">{field.label}</label>

                                            <select
                                                className="rounded-pill form-select w3-text-indigo"
                                                id={field.key}
                                                name={field.key}
                                                value={fieldValue}
                                                onChange={handleChange}
                                                style={style.input}
                                            >
                                                <option value="">Select an option</option>
                                                {field.options.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors[field.key] && (
                                                <div className="text-danger">{errors[field.key]}</div>
                                            )}
                                        </div>
                                    )}

                                    {field.type === "file" && (
                                        <div className="form-group mb-3">
                                            <label htmlFor={field.key}>{field.label}</label>
                                            <input
                                                type="file"
                                                id={field.key}
                                                name={field.key}
                                                accept="image/png, image/jpeg"
                                                className="form-control rounded-pill"
                                                onChange={handleFileChange}
                                            />
                                            {existingDetails[field.key] && (
                                                <span>{existingDetails[field.key].name}</span>
                                            )}
                                            {errors[field.key] && (
                                                <div className="text-danger">{errors[field.key]}</div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        }
                    }
                    return null;
                })}

                {message && (<div className="alert alert-info mt-3">{message}</div>)}


                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>

                    <button className="rounded-pill btn-light mx-auto d-block"
                            style={buttonStyle}
                            disabled={!enableButton || !validFileFormat}
                            onClick={handleNextPage}>Next
                    </button>
                </div>
            </div>
        </div>

    )

});