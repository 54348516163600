import {
    currentSilverBundled, student, eazyTransactBundled,
    eazyTransactUnbundled, eazySaveBundled, eazySaveUnBundled, pafupiUnbundled, currentSilverUnbundled,
    diasporaTransactional, diasporaInvestment
} from "../Fees";
import React, {useState} from "react";
import {CustomStyle} from "../../helpers/styles";
import {Box, Card, CardContent, Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const AccountProductInformation = (({updateCurrentPage, existingDetails, setExistingDetails}) => {
    console.log(existingDetails)

    const productMapping = {
        1001: currentSilverBundled,
        1030: currentSilverUnbundled,

        6001: eazyTransactBundled,
        6003: eazySaveUnBundled,

        6006: pafupiUnbundled,

        6040: student,
        6044: eazySaveBundled,
        6045: eazyTransactUnbundled,

        6212: diasporaInvestment,
        6023: diasporaTransactional
    };

    console.log(eazyTransactBundled);

    const accountProduct = existingDetails.account_category;
    console.log(accountProduct)
    const product = productMapping[accountProduct];

    console.log(product.initial_deposit)

    const keys = Object.keys(product);

    const [enableButton, setEnableButton] = useState(true);

    const handleNextPage = (e) => {
        updateCurrentPage("KeyFacts")
    };

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Product Information</Typography>
                <Card
                    style={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        marginBottom: '10px',
                        maxWidth: '100%',
                        border: '2px solid #2b357c'
                    }}
                >
                    <CardContent>
                        <Box sx={{mt: 2}}>
                            {keys.map((key) => (
                                <Box key={key} sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                                    <Typography variant="body2" sx={{color: '#2b357c', fontWeight: 'bold'}}>
                                        {key.replace(/_/g, ' ').toUpperCase()}:
                                    </Typography>
                                    <Typography variant="body2" sx={{color: '#2b357c'}}>
                                        {product[key]}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </CardContent>
                </Card>

                <div style={CustomStyle.buttonContainer}>

                <CustomNBSRedButton onClick={handleNextPage}
                                    disabled={!enableButton}>
                    Next
                </CustomNBSRedButton>

                </div>
            </div>
        </div>
    )
})