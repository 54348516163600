import moment from "moment/moment";
import {uploadFileWithNameObject} from "./Diaspora";

export const nbsColors = {
    blue: "#2b357c",
    red: "#ed1b2e"
}

export const documentResubmissionRemarks = {
    missing: "missing",
    unclear: "unclear",
    incorrect: "incorrect",
    ok: "ok",
    expired: "expired"
}

export function cleanUpSomeCustomerDetails(customerDetails) {
    customerDetails.id_issue_date = moment(customerDetails.id_issue_date).format("YYYY-MM-DD")
    customerDetails.id_expiry_date = moment(customerDetails.id_expiry_date).format("YYYY-MM-DD")
    customerDetails.date_of_birth = moment(customerDetails.date_of_birth).format("YYYY-MM-DD")
    customerDetails.occupation = customerDetails.occupation.toUpperCase()
    customerDetails.postal_address = customerDetails.postal_address.toUpperCase()
    customerDetails.type_of_business = customerDetails.type_of_business.toUpperCase()
    customerDetails.postal_town = customerDetails.postal_town.toUpperCase()
    customerDetails.trad_auth = customerDetails.trad_auth.toUpperCase()
    customerDetails.street_address = customerDetails.street_address.toUpperCase()
    customerDetails.village = customerDetails.village.toUpperCase()
    customerDetails.kin_fullname = customerDetails.kin_fullname.toUpperCase()
    customerDetails.district_address = customerDetails.district_address.toUpperCase()
    customerDetails.employment_status = customerDetails.employment_status.toUpperCase()
    customerDetails.gender = customerDetails.gender.toUpperCase()
    customerDetails.district = customerDetails.district.toUpperCase()
    customerDetails.marital_status = customerDetails.marital_status.toUpperCase()
    if(/^$/ig.test(customerDetails.monthly_transactions)){
        customerDetails.monthly_transactions = 0.0
    }
    return customerDetails;
}

export function pageIsBeingRenderedFromMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let mobile = false;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
        mobile = true;
    } else if (userAgent.match(/Android/i)) {
        mobile = true;
    }
    return mobile;
}

export async function cameraPermissionsGranted() {
    let permissionsCanBeGranted = false;
    try{
        permissionsCanBeGranted = await navigator.mediaDevices.getUserMedia({audio: false, video: true}).then(() => {
            return true;
        }).catch((err) =>  {
            return false;
        })
    }catch(err){
        console.log("Failed to get camera permissions");
    }
    return permissionsCanBeGranted;
}

export async function emptyStringIfNull(str) {
    return (str === null || str === undefined) ? "" : str
}

export function loadSelfOnboard() {
    let url = window.location;
    try {
        url = process.env.REACT_APP_SELF_ONBOARDING_URL;
    } catch (error) {
        console.error(error);
    }
    window.location.assign(url);
}

export function formatNationalIDExtractedData(data) {
    let updatedData = [];
    let updatedDataString = "";

    for (let i = 0; i < data.length; i++) {
        if (data[i] !== "<") {
            updatedDataString += data[i];
        }
    }

    let start = 0;
    for (let i = 0; i < updatedDataString.length; i++) {
        if (updatedDataString[i] === "~") {
            updatedData.push(updatedDataString.substring(start, i));
            start = i + 1;
        }
    }

    const expDateReg = updatedData[2].match(/(M|F)([0-9]+)/)[2]
    return {
        firstname: updatedData[6],
        surname: updatedData[4],
        other_name: updatedData[7],
        id_number: updatedData[5],
        gender: updatedData[8],
        id_issue_date: new Date(updatedData[10]).toDateString().toUpperCase(),
        date_of_birth: new Date(updatedData[9]).toDateString().toUpperCase(),
        id_expiry_date: new Date("20"+expDateReg.substring(0,2)+"/"+expDateReg.substring(2,4)+"/"+expDateReg.substring(4,6))
            .toDateString().toUpperCase()
    }
}

export function getUniqueList(array) {
    const uniqueSet = new Set(array);
    return Array.from(uniqueSet);
}

export function isBase64PDF(base64String) {
    if (!base64String.startsWith('data:')) {
        return false;
    }

    const startIndex = base64String.indexOf(',') + 1;
    const encodedData = base64String.substring(startIndex);

    const decodedData = atob(encodedData);

    const signature = decodedData.substring(0, 4);
    return signature === '%PDF';
}

export const convertBase64ToImageOrPDFFile = (base64, name) => {
    const isPDF = base64.startsWith('data:application/pdf');
    const type = isPDF ? 'pdf' : 'jpg';

    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const contentType = isPDF ? 'application/pdf' : 'image/jpeg';
    const blob = new Blob([ab], { type: contentType });

    const fileName = `${name}.${type}`;
    return new File([blob], fileName, { type: contentType });
};


/**
 * Creates an object to send to the backend to save a file
 * @param file file to be saved
 * @param fieldName the field name of the customerProfile to which we want to attach to
 * @param customerIdNumber National ID number of the customer
 * @param customerSurname Surname of the customer
 * @returns {Promise<any|null|undefined>}
 */
export const createFileUploadObject = async (file, fieldName, customerIdNumber, customerSurname) => {
    let fileList = [];
    let fileNames = {};

    fileList.push(file);
    fileList.forEach(_file => {
        const fileName = _file.name;
        const fileExtension = fileName.split('.').pop();
        fileNames[fileName] = `${fieldName}.${fileExtension}`; //TODO: We could do better - could cause bug (value duplicate) if fileList is more than 1
    });

    let dataObject = {
        "national_id_details": {
            "id_number": customerIdNumber,
            "surname": customerSurname
        },
        "file_names": fileNames
    }

    return await uploadFileWithNameObject(fileList, dataObject)
        .then((data) => data)
        .catch((err) => {
            console.error(err);
            return null;
        })
}

/**
 * Removes the 'temp' key from the customerProfile object
 * @param customerProfile object containing customer details
 */
export const removeTempKeyFromCustomerProfileIfExists = (customerProfile) => {
    const keys = Object.keys(customerProfile);
    if (keys.includes("temp")) {
        delete customerProfile['temp'];
    }
    return customerProfile;
}