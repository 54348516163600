import React, {useEffect, useState} from "react";
import {documentCodes, documents} from "../../helpers/Documents";
import {PDFDocumentViewer} from "../PDFDocumentViewer";
import {FixedDepositFinish} from "./FixedDepositFinish";
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Typography
} from "@mui/material";
import {customSelectStyles, CustomStyle} from "../../helpers/styles";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const KeyFactStatement = (({updateCurrentPage, fixedDepositDetails, setFixedDepositDetails}) => {
    console.log(fixedDepositDetails);

    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [factsAccepted, setFactsAccepted] = useState(false);
    const [showKeyFactsModel, setShowKeyFactsModel] = useState(false);
    const [conditionsConfirmed, setConditionsConfirmed] = useState(false);
    const [keyFactsConfirmed, setKeyFactsConfirmed] = useState(false);
    const [informationConfirmed, setInformationConfirmed] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const handleAcceptTermsAndConditions = () => {
        setTermsAccepted(true);
        setShowTermsAndConditions(false);
    };

    const handleAcceptKeyFacts = () => {
        setFactsAccepted(true);
        setShowKeyFactsModel(false);
    };

    const handleCheckboxChange = (setter) => (e) => {
        setter(e.target.checked);
    };

    useEffect(() => {
        const allAccepted = termsAccepted && factsAccepted && conditionsConfirmed && keyFactsConfirmed && informationConfirmed;
        setDisableButton(!allAccepted);
    }, [termsAccepted, factsAccepted, conditionsConfirmed, keyFactsConfirmed, informationConfirmed]);

    function getKeyFactStatement() {
        return documents()[documentCodes().fixed_deposit]
    }

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Terms and Key Fact Statement
                </Typography>

                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    * Read the key fact sheet statement, terms and conditions to proceed to the next stage
                </Typography>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    <label>
                        <a onClick={() => setShowTermsAndConditions(true)} style={{color: "#0f9d58"}}>
                            Click here
                        </a>
                    </label> to read terms and conditions
                </Typography>
                {!termsAccepted && <Typography variant="body2" sx={{...CustomStyle.subtitle, color: '#bf221d'}}>
                    * Please accept the Terms and Conditions
                </Typography>}
                <Typography variant="body1" sx={CustomStyle.subtitle}>
                    <label style={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            checked={conditionsConfirmed}
                            onChange={handleCheckboxChange(setConditionsConfirmed)}
                        />
                        <span
                            style={{marginLeft: '8px'}}>I have read and agreed to The NBS Bank terms and conditions</span>
                    </label>
                </Typography>
                <Typography variant="h6" sx={CustomStyle.subtitle}>
                    <label>
                        <a onClick={() => setShowKeyFactsModel(true)} style={{color: "#0f9d58"}}>
                            Click here
                        </a>
                        <span> to access the key fact statements</span>
                    </label>
                </Typography>
                {!factsAccepted && <Typography variant="body2" sx={{...CustomStyle.subtitle, color: '#bf221d'}}>
                    * Please accept the Key Fact Statements
                </Typography>}
                <Dialog open={showTermsAndConditions} onClose={() => setShowTermsAndConditions(false)}>
                    <DialogTitle>Terms and Conditions</DialogTitle>

                    <DialogContent>
                        <PDFDocumentViewer documentType={documents()[documentCodes().terms_and_conditions]}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" style={{width: '100%'}}
                                onClick={handleAcceptTermsAndConditions}>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={showKeyFactsModel} onClose={() => setShowKeyFactsModel(false)}>
                    <DialogTitle>Key Fact Statement</DialogTitle>
                    <DialogContent>
                        <PDFDocumentViewer documentType={getKeyFactStatement()}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" style={{width: '100%'}}
                                onClick={handleAcceptKeyFacts}>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
                <Typography variant="body1" sx={CustomStyle.subtitle}>
                    <label style={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            checked={keyFactsConfirmed}
                            onChange={handleCheckboxChange(setKeyFactsConfirmed)}
                        />
                        <span style={{marginLeft: '8px'}}>I understand the key facts and benefits of this account product.</span>
                    </label>

                </Typography>
                <Typography variant="body1" sx={CustomStyle.subtitle}>
                    <label style={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            checked={informationConfirmed}
                            onChange={handleCheckboxChange(setInformationConfirmed)}
                        />
                        <span style={{marginLeft: '8px'}}>I confirm that the information provided is correct.</span>
                    </label>
                </Typography>

                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton
                        disabled={disableButton}
                        onClick={() => updateCurrentPage('FixedDepositFinish')}
                    >
                        Submit
                    </CustomNBSRedButton>
                </div>

            </div>
        </div>
    );
});