import {memo, useEffect, useState} from "react";
import {Scanner} from "./Scanner";
import axios from "axios";

export const ScanDiaspora = memo(({ updateCurrentPage, existingDetails, setExistingDetails }) => {
    const [message, setMessage] = useState(null);
    const [scannedData, setScannedData] = useState({});
    const [showAccountNumberField, setShowAccountNumberField] = useState(false);
    const account = existingDetails.account_number;
    const [loading, setLoading] = useState(false);

    const handleScannedData = async (data) => {
        console.log("Scanned datas " + data);
        setShowAccountNumberField(true);
        await setScannedData(data);
    }
    const handleNextPage = async (e) => {
        //const updatedExistingDetails = { ...existingDetails, scannedData };
        setExistingDetails({
            ...existingDetails, scannedData
        })
        updateCurrentPage("AccountServices");

    }

    console.log("initial value of scannedData:", scannedData);

    let isScanned = scannedData && Object.keys(scannedData).length > 10;

    if (Object.keys(scannedData).length > 10) {
        console.log("obj1 is not empty");
    } else {
        console.log("obj1 is empty");
    }

    const buttonStyle = {
        backgroundColor: isScanned ? '#ed1b2e' : '#de5959',
        color: 'white',
    };
    console.log(scannedData)
    return (
        <div className="content">
            <p style={{ fontWeight: "bold", textAlign: "center" }}>ID Scanner</p>
            <Scanner onScan={handleScannedData} />
            {message && <div className="alert alert-info">{message}</div>}
            <br />
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
                {/*<button className="rounded-pill btn-light mx-auto d-block"*/}
                {/*        style={{ backgroundColor: "#ed1b2e", color: "white" }}*/}

                {/*        onClick={handlePrevClick}>Previous*/}
                {/*</button>*/}
                <button className="rounded-pill btn-light mx-auto d-block" style={buttonStyle}
                        disabled={!isScanned}
                        onClick={handleNextPage}>Confirm
                </button>
            </div>
        </div>
    );
});