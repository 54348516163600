import React, {memo} from 'react';
import {CustomStyle} from "../../helpers/styles";
import {Typography} from "@mui/material";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const DeclinedTermsAndConditions = memo(({updateCurrentPage, customerDetails, setCustomerDetails}) => {
    const goToDiasporaAccountsOverview = () => {
        updateCurrentPage("DiasporaAccountsOverview")
    }
    const title = "You cannot continue if you do not read and accept to the terms and conditions attached herein";

    function continueWithApplication() {
        updateCurrentPage("DiasporaSummary");
    }

    function goToHome() {
        updateCurrentPage("HomePage");
    }

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>{title}</Typography>
                <br/>

                <div style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '10px',
                    marginTop: '12px'
                }}>
                    <CustomNBSRedButton onClick={continueWithApplication}
                                        disabled={false}>
                        Continue with Application
                    </CustomNBSRedButton>

                    <CustomNBSRedButton onClick={goToHome}
                                        disabled={false}>
                        Close
                    </CustomNBSRedButton>
                </div>

            </div>
        </div>

    );
})