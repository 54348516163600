import React, {useEffect, useState} from "react";
import axios from "axios";
import {occupations} from "../data";

export const Occupation = (({updateCurrentPage, existingDetails, setExistingDetails}) => {
    console.log(existingDetails);

    const [formData, setFormData] = useState({
        occupation:""
    });
    const [disableButton, setDisableButton] = useState(false);//true

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const buttonStyle = {
        backgroundColor: disableButton ? '#de5959' : '#ed1b2e',
        color: 'white',
    };

    const handleNextPage = () => {
        updateCurrentPage("KeyFacts")
    }

    return (
        <div className="content">
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
                Select your Occupation</p>

            <div className="form-group mb-3 noValidate">

                <div className="form-group">
                    <label htmlFor="occupation">Select occupation *</label>

                    <select className="rounded-pill form-select w3-text-indigo form-floating"
                            onChange={handleChange}
                            name="occupation"
                            id="occupation"
                            value={formData.occupation}
                            aria-label="Default select example"
                            required={true}
                    >
                        <option value=""></option>
                        {occupations.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>

                    {/*{errors.occupation && (*/}
                    {/*    <div className="text-danger">{errors.accountType}</div>*/}
                    {/*)}*/}

                    <br/>
                </div>

                <br />
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>

                    <button className="rounded-pill btn-light mx-auto d-block"
                            style={buttonStyle}
                            disabled={disableButton}
                            onClick={handleNextPage}>Next
                    </button>
                </div>


            </div>
        </div>
    )
});