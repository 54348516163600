import React, {useEffect, useState} from "react";
import axios from "axios";
import {CustomStyle} from "../../helpers/styles";
import {Alert, CircularProgress, Typography} from "@mui/material";
import {CustomNBSTextField} from "../custom-componets/CustomNBSTextField";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const OtpVerification = (({updateCurrentPage, existingDetails, setExistingDetails}) => {
    console.log(existingDetails);
    const phone = existingDetails.mobile_no;
    const account = existingDetails.account_number;
    const [formData, setFormData] = useState({
        otp:""
    });
    const [message, setMessage] = useState(existingDetails.msg);
    const [disableButton, setDisableButton] = useState(true);//true
    const [loading, setLoading] = useState(false);


    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const verifyOTP = async (otp) => {
        const url = '/verify-otp'; // Replace with your API endpoint for OTP verification
        const body = {
            otp: otp,
            type:"verify",
            mobile_no: phone,
            account_number: account
            // Additional parameters if required
        };

        try {
            setLoading(true);
            const response = await axios.post(url, body);
            setLoading(false);

            console.log(response)
            return response; // Modify this based on your API response
        } catch (error) {
            throw new Error("Failed to verify OTP");
        }
    };

    useEffect(() => {
        if (formData.otp.length === 6) {
            console.log("verifying otp!!!");
            verifyOTP(formData.otp)
                .then((verificationResult) => {
                    if (verificationResult) {
                        console.log(verificationResult)
                        if (verificationResult.status === 200) {
                            if (verificationResult.data.status) {
                                setMessage(verificationResult.data.msg);
                                // enable next button
                                setDisableButton(false);
                            } else {
                                setMessage(verificationResult.data.msg)
                            }
                        }  else {
                            setMessage("Something went wrong. Try again later.");
                        }

                        // OTP verification successful
                        //updateCurrentPage("AvailableAccounts");
                    } else {
                        // OTP verification failed
                        console.log("OTP verification failed");
                    }
                })
                .catch((error) => {
                    // Handle the error
                    console.error("Error during OTP verification:", error);
                });
        }
    }, [formData.otp]);


    const handleNextPage = () => {
        updateCurrentPage("AvailableAccounts")
    }

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                OTP Verification</Typography>

                {message && (
                    <Alert severity="info" style={CustomStyle.alert}>
                        {message}
                    </Alert>
                )}

                <CustomNBSTextField
                    label="Enter One Time Pin *"
                    onInputChange={handleChange}
                    value={formData.otp || ''}
                    inputType="text"
                    name="otp"
                    required="true"
                />
                <div style={CustomStyle.buttonContainer}>

                <CustomNBSRedButton onClick={handleNextPage}
                                    disabled={disableButton}>
                    {loading ? <CircularProgress color="inherit" size={24}/> : 'Next'}
                </CustomNBSRedButton>
                </div>

            </div>
        </div>
    )
});