import React from 'react'
import {Image} from 'react-bootstrap';
import {CustomStyle} from "../helpers/styles";
import {Box, Typography} from "@mui/material";

const BundledServicesComponent = React.memo(() => {
    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Image
                    src={require("../assets/images/logo_nav.png")}
                    style={{ width: "20px" }}
                    className="rounded-circle img-fluid logo"
                    alt="Logo"
                />
                <Typography sx={{ ml: 1 }} variant="body1">
                    SMS Alerts
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Image
                    src={require("../assets/images/logo_nav.png")}
                    style={{ width: "20px" }}
                    className="rounded-circle img-fluid logo"
                    alt="Logo"
                />
                <Typography sx={{ ml: 1 }} variant="body1">
                    Eazy Mobile transactions
                </Typography>
            </Box>
        </Box>
    );
})
export default BundledServicesComponent;