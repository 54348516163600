import React from 'react';
import Container from '@mui/material/Container';
import Main from '../Main';
const CustomContainer=({routeKind})=>{
  return (
      <Container
          maxWidth={false}
          style={{ padding: 0 }}
      >
        <Main routeKind={routeKind}/>
    </Container>
  );
}
export default React.memo(CustomContainer);
