import React from 'react'
import { Image} from 'react-bootstrap';
import BundledServicesComponent from './BundledServicesComponent';
import UnBundledServiceComponent from './UnBundledServiceComponent';
const XtraServices=React.memo(({customerObject})=>{ 
    let element;
    if (/Unbundled/i.test(customerObject.customerDetails.account_product)){
        let className = {sms_alert:"rounded-circle img-fluid logo",mobile_banking:"rounded-circle img-fluid logo",eazy_app:"rounded-circle img-fluid logo",internet_banking:"rounded-circle img-fluid logo",atm_card:"rounded-circle img-fluid logo"}
        let sms_alert = null;
        let mobile_banking = null;
        let eazy_app = null;
        let internet_banking = null;
        let atm_card = null;
        let service_available=false;
        if(customerObject.customerDetails.sms_alert===1){
            service_available=true;
            sms_alert=<>SMS Alerts<br/></>
        }else className.sms_alert="rounded-circle img-fluid logo d-none"
        if(customerObject.customerDetails.mobile_banking===1){
            service_available=true;
            mobile_banking=<>Eazy Mobile transactions<br/></>
        }else className.mobile_banking="rounded-circle img-fluid logo d-none"
        if(customerObject.customerDetails.eazy_app===1){
            service_available=true;
            eazy_app=<>Eazy App transactions<br/></>
        }else className.eazy_app="rounded-circle img-fluid logo d-none"
        if(customerObject.customerDetails.internet_banking===1){
            service_available=true;
            internet_banking=<>Internet Banking<br/></>
        }else className.internet_banking="rounded-circle img-fluid logo d-none"
        // customerObject.customerDetails.internet_banking===1?internet_banking=<>Internet Banking<br/></>:className.internet_banking="rounded-circle img-fluid logo d-none"
        if(customerObject.customerDetails.atm_card===1){
            service_available=true;
            atm_card=<>ATM Card<br/></>
        }else className.atm_card="rounded-circle img-fluid logo d-none"
        element = service_available
        ?
        (
            <>
                <p className="w3-text-indigo" style={{textAlign:"justify"}}>
                    Below are unbundled services attached to the above account:       
                </p>
                {/* <p className="w3-text-indigo" style={{textAlign:"left"}}>
                    <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.sms_alert}/>&nbsp;&nbsp;{sms_alert}
                    <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.mobile_banking}/>&nbsp;&nbsp;{mobile_banking}
                    <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.eazy_app}/>&nbsp;&nbsp;{eazy_app}
                    <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.internet_banking}/>&nbsp;&nbsp;{internet_banking}
                    <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className={className.atm_card}/>&nbsp;&nbsp;{atm_card}
                </p>  */}
                <UnBundledServiceComponent {...{customerObject}}/>
            </>   
        )
        :
        <></>
    }else
        element =   
            (
                <>
                    <p className="w3-text-indigo" style={{textAlign:"justify"}}>
                        Below are bundled services attached to the above account:
                    </p>
                    <BundledServicesComponent/>
                    {/* // <p className="w3-text-indigo" style={{textAlign:"left"}}>
                    //     <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className="rounded-circle img-fluid logo"/>&nbsp;&nbsp;SMS Alerts<br/>
                    //     <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className="rounded-circle img-fluid logo"/>&nbsp;&nbsp;Eazy Mobile transactions<br/>
                    //     <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className="rounded-circle img-fluid logo"/>&nbsp;&nbsp;Eazy App transactions<br/>
                    //     <Image src={require("../assets/images/logo_nav.png")} style={{width:"20px"}} className="rounded-circle img-fluid logo"/>&nbsp;&nbsp;ATM Cardless transactions<br/>
                    // </p> */}
                </>    
            );
    return element;
})
export default XtraServices;