import React from "react";
import {Typography} from "@mui/material";
import {CustomStyle} from "../../helpers/styles";
import CustomNBSRedButton from "../custom-componets/CustomNBSRedButton";

export const RequestType = ({updateCurrentPage, fixedDepositDetails, setFixedDepositDetails}) => {
    const goToFixedDepositAccountCreation = () => {
        updateCurrentPage("FixedDepositContractPage")

    }

    const goToFixedDepositAccountCancellation = () => {
        updateCurrentPage("FixedAccountsList")

    }

    return (
        <div style={CustomStyle.pageWrapper}>
            <div style={CustomStyle.contentContainer}>
                <Typography variant="h5" sx={CustomStyle.heading}>
                    Choose option below:
                </Typography>

                <div style={CustomStyle.buttonContainer}>
                    <CustomNBSRedButton
                        onClick={goToFixedDepositAccountCreation}

                    >
                        Create Fixed account
                    </CustomNBSRedButton>
                    <CustomNBSRedButton
                        onClick={goToFixedDepositAccountCancellation}
                    >
                        Existing Fixed Deposits
                    </CustomNBSRedButton>
                </div>
            </div>
        </div>
    );
}
