import React from 'react';
import { Button } from '@mui/material';

const CustomNBSLabel = ({ label, onClick, isAlert, showAlert }) => {
    return (
        <>
            <Button
                onClick={(e) => {
                    onClick(e);
                    if (label === "Non-malawian") {
                        showAlert(true);
                    }
                }}
                variant="contained"
                sx={{
                    backgroundColor: '#ed1b2e', // Red background color
                    color: '#ffffff', // White text color
                    borderRadius: '25px', // Rounded corners
                    padding: '12px 15px', // Increased padding
                    fontSize: '1.1rem',
                    textTransform: 'none', // No uppercase text
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow
                    transition: 'background-color 0.3s, transform 0.3s', // Smooth transition
                    display: 'block', // Full width block display
                    width: '80%', // Adjusted width for responsiveness
                    maxWidth: '300px', // Maximum width to control size on larger screens
                    margin: '10px auto', // Margin between buttons and centered
                    whiteSpace: 'nowrap', // Prevent text from wrapping
                    overflow: 'hidden', // Hide overflow text
                    textOverflow: 'ellipsis', // Show ellipsis for overflowing text
                    '&:hover': {
                        backgroundColor: '#d31a22', // Darker red on hover
                        transform: 'scale(1.05)', // Slight zoom effect on hover
                    },
                    '&:active': {
                        backgroundColor: '#c8101d', // Even darker red on active
                    },
                }}
            >
                {label}
            </Button>
            {isAlert && label === "Non-malawian" && (
                <div style={{ marginTop: '1rem' }} className="alert alert-danger alert-dismissible" role="alert">
                    <strong>Please visit our NBS Bank!</strong> The self onboarding is only available for Malawian nationals. For non-Malawians, visit the NBS Branch.
                </div>
            )}
        </>
    );
};

export default CustomNBSLabel;
